import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";

import { DashboardProfileButtons } from "../../ReusableComponents/CustomDesignMUI/CustomMUI";
import UpdateContact from "../../UpdateEmployee/UpdateContact/UpdateContact";
import UpdateEducation from "../../UpdateEmployee/UpdateEducation/UpdateEducation";
import UpdateFamily from "../../UpdateEmployee/UpdateFamily/UpdateFamily";
import UpdateExperience from "../../UpdateEmployee/UpdateExperience/UpdateExperience";
import UpdatePersonal from "../../UpdateEmployee/UpdatePersonal/UpdatePersonal";
import UpdateFinancial from "../../UpdateEmployee/UpdateFinancial/UpdateFinancial";
import {
  setemployeeAllData,
} from "../../../Slices/employeeDetailSlice";
import Loader from "../../Loader/Loader";
import { getEmployeeDetails } from "../../../Services/EmployeeManageServices";
import usePermissions from "../../RolePermission/usePermissions";
import { ProfileMainBox, LoadingBox, MainBoxProfile, RenderTabBox, PersonalDetailsButton, ContactDetailsButton, EducationQualificationButton, FamilyDetailsButton, ExperienceDetailsButton, FinancialDetailsButton } from "../../../Styles/UpdateEmployee/ViewProfileStyle";
import "./../../AdminDashboard/Dashboard.scss";

function Profile() {
  const dispatch = useDispatch();
  const employeeCall = useSelector(
    (state) => state.addEmployee.employeeAllData
  );
  const { hasPermission } = usePermissions();
  const employeeId = employeeCall?.empId;

  const [selectedTab, setSelectedTab] = useState("personal");
  const [isLoading, setIsLoading] = useState(false);

  const tabOrder = [
    "personal",
    "contact",
    "education",
    "family",
    "experience",
    "financial",
  ];

  const saveNextButtonCallback = () => {
    handleNext();
  };

  const handleNext = () => {
    let index = tabOrder.indexOf(selectedTab);
    setSelectedTab(tabOrder[index + 1]);
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const renderTabContent = () => {
    if (hasPermission('Personal Details') && selectedTab === "personal") {
      return (
        <UpdatePersonal
          image={false}
          employeeCall={employeeCall}
          saveNextButtonCallback={saveNextButtonCallback}
        />
      );
    } else if (selectedTab === "contact") {
      return (
        <UpdateContact
          employeeCall={employeeCall}
          saveNextButtonCallback={saveNextButtonCallback}
        />
      );
    } else if (selectedTab === "education") {
      return (
        <UpdateEducation
          employeeCall={employeeCall}
          saveNextButtonCallback={saveNextButtonCallback}
        />
      );
    } else if (selectedTab === "family") {
      return (
        <UpdateFamily
          employeeCall={employeeCall}
          saveNextButtonCallback={saveNextButtonCallback}
        />
      );
    } else if (selectedTab === "experience") {
      return (
        <UpdateExperience
          employeeCall={employeeCall}
          saveNextButtonCallback={saveNextButtonCallback}
        />
      );
    }
    else if (selectedTab === "financial") {
      return <UpdateFinancial employeeCall={employeeCall} />;
    }
  };

  const memoizedemployeeDataReload = useMemo(() => {
    const employeeDataReload = async () => {
      try {
        setIsLoading(true);
        const response = await getEmployeeDetails()
        if (response.status === 200 || response.status === 201) {
          dispatch(setemployeeAllData(response.data.user));
          // dispatch(setemployeeAllLeaveData(response.data.leave));
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    };
    return employeeDataReload;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeId]);

  useEffect(() => {
    memoizedemployeeDataReload();
  }, [memoizedemployeeDataReload]);

  return (
    <ProfileMainBox>
      {isLoading ? (
        <LoadingBox>
          <Loader />
        </LoadingBox>
      ) : (
        <Grid item container spacing={2}>
          <Grid item container xs={12} md={3.5}>
            <MainBoxProfile>
              {hasPermission('Personal Details') && <PersonalDetailsButton
                dashboardprofilebuttons={DashboardProfileButtons}
                selectedtab={selectedTab}
                onClick={() => handleTabChange("personal")}
              >
                Personal Details
              </PersonalDetailsButton>}
              {hasPermission('Contact Details') && <ContactDetailsButton
                onClick={() => handleTabChange("contact")}
                dashboardprofilebuttons={DashboardProfileButtons}
                selectedtab={selectedTab}
              >
                Contact Details
              </ContactDetailsButton>}
              {hasPermission('Education Qualification') && <EducationQualificationButton
                onClick={() => handleTabChange("education")}
                dashboardprofilebuttons={DashboardProfileButtons}
                selectedtab={selectedTab}
              >
                Education Qualification
              </EducationQualificationButton>}
              {hasPermission('Family Details') && <FamilyDetailsButton
                onClick={() => handleTabChange("family")}
                dashboardprofilebuttons={DashboardProfileButtons}
                selectedtab={selectedTab}
              >
                Family Details
              </FamilyDetailsButton>}
              {hasPermission('Experience Details') && <ExperienceDetailsButton
                onClick={() => handleTabChange("experience")}
                dashboardprofilebuttons={DashboardProfileButtons}
                selectedtab={selectedTab}
              >
                Experience Details
              </ExperienceDetailsButton>}
              {hasPermission('Financial Details') && <FinancialDetailsButton
                onClick={() => handleTabChange("financial")}
                dashboardprofilebuttons={DashboardProfileButtons}
                selectedtab={selectedTab}
              >
                Financial Details
              </FinancialDetailsButton>}
              </MainBoxProfile>
          </Grid>
          <Grid item container xs={12} md={8.5}>
            <RenderTabBox>
              {renderTabContent()}
            </RenderTabBox>
          </Grid>
        </Grid>
      )}
    </ProfileMainBox>
  );
}
export default Profile;
