import { createSlice } from "@reduxjs/toolkit";

const storedTheme = window.localStorage.getItem("theme");
const initialTheme = storedTheme ? JSON.parse(storedTheme) : false;

const blackThemeSlice = createSlice({
  name: "theme",
  initialState: {
    isDarkTheme: initialTheme,
  },
  reducers: {
    toggleTheme: (state) => {
      state.isDarkTheme = !state.isDarkTheme;
      window.localStorage.setItem("theme", JSON.stringify(state.isDarkTheme));
    },
    setDarkTheme: (state) => {
      state.isDarkTheme = true;
      window.localStorage.removeItem("theme");
    },
    setLightTheme: (state) => {
      state.isDarkTheme = false;
      window.localStorage.removeItem("theme");
    },
  },
});

export const { toggleTheme, setDarkTheme, setLightTheme } =
  blackThemeSlice.actions;

export default blackThemeSlice.reducer;
