import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  CardContent,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import {
  Close as CloseIcon,
  CloudUpload as CloudUploadIcon,
  CloudDownload as CloudDownloadIcon,
  CloudSync as CloudSyncIcon,
} from "@mui/icons-material";

import {
  setProjectData,
  setProjectWorkingMemberName,
} from "../../Slices/adminDetailSlice";
import { setToast, setToastMsg, setToastType } from "../../Slices/toastSlice";
import { CLOUD_FUNCTIONS_ORIGIN } from "../../function-origin";
import { projectStatus } from "../Constant/constant";
import { renderAsterisk } from "../ReusableComponents/UtilityFunctions";
import { errorMessage } from "../../Services/axiosInstance";
import { getWorkFlow } from "../../Services/WorkFlowServices";
import { editProjects, upsertProjectDocument, upsertProjects } from "../../Services/ProjectManageServices";
import { getEmployee } from "../../Services/EmployeeManageServices";
import { AdditionalDetailsTypography, CloseBox, InputTextField, LeaderNameAutocomplete, MainBox, MainCard, MemberNameAutocomplete, StatusTextField, TechnologyStackTypography, WorkFlowNameAutocomplete } from "../../Styles/ProjectManagement/AddProjectStyle";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function AddProject({
  setIsLoading,
  setChange,
  change,
  onClose,
  isExistingData,
  setIsExistingData,
  existingData,
  setExistingData,
}) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.selectedActiveView.role);
  const projectData = useSelector((state) => state.adminData.projectData);
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const projectWorkingMemberName = useSelector(
    (state) => state.adminData.projectWorkingMemberName
  );
  const employeeDisplay = user === "employee";
  const adminLogin = user === "admin";

  const [selectedMemberNames, setSelectedMemberNames] = useState(
    projectWorkingMemberName || []
  );
  const [showUpdatedFileName, setShowUpdatedFileName] = useState("");
  const [projectMessage, setProjectMessage] = useState("");
  const [displayMessageError, setDisplayMessageError] = useState("");
  const [addProjectData, setAddProjectData] = useState({
    project: projectData ? projectData?.project : "",
    projectAliasName: projectData ? projectData?.projectAliasName : "",
    status: projectData ? projectData?.status : "",
    clientName: projectData ? projectData?.clientName : "",
    Description: projectData ? projectData?.Description : "",
    leaderName: null,
    frontend: projectData ? projectData?.frontend : "",
    backend: projectData ? projectData?.backend : "",
    datebase: projectData ? projectData?.datebase : "",
    workFlowId: null,
  });
  const [errors, setErrors] = useState("");
  const [selectedMemberNamesError, setSelectedMemberNamesError] = useState("");
  const [fileSelectError, setFileSelectError] = useState("");
  const [projectDocumentationFile, setProjectDocumentationFile] = useState({});
  const [docAvailable, setDocAvailable] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [workFlowList, setWorkFlowList] = useState([]);

  const EmployeesValueSetter = (event, newValue) => {
    setSelectedMemberNames(newValue);
    setDisplayMessageError("");
    setSelectedMemberNamesError("");
  };

  const leaderNameValueSetter = (event, newValue) => {
    setAddProjectData((prevState) => ({
      ...prevState,
      leaderName: newValue,
    }));
  };

  const WorkFlowValueSetter = (event, newValue) => {
    setAddProjectData((prevState) => ({
      ...prevState,
      workFlowId: newValue,
    }));
  };

  const setClose = () => {
    dispatch(setProjectData({}));
    dispatch(setProjectWorkingMemberName([]));
    setExistingData({});
    setIsExistingData(false);
    onClose();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProjectDocumentationFile(file);
      setFileSelectError("");
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDisplayMessageError("");

    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));

    const capitalizedValue =
      name === "status"
        ? value
        : value
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");

    setAddProjectData((prevState) => ({
      ...prevState,
      [name]: capitalizedValue,
    }));
  };

  const handleSubmit = async (e) => {
    const error = [];
    e.preventDefault();
    if (!addProjectData.project) {
      error.project = "Project can't be empty.";
    }
    if (!addProjectData.status) {
      error.status = "Provide status of project.";
    }
    if (!addProjectData.clientName) {
      error.clientName = "Client name can't be empty.";
    }
    if (addProjectData.leaderName.length === 0) {
      error.leaderName = "Select leader name.";
    }
    if (addProjectData?.workFlowId?.length === 0) {
      error.workFlowId = "Select workflow.";
    }
    if (!addProjectData.Description) {
      error.Description = "Add project description.";
    }
    if (selectedMemberNames.length === 0) {
      setSelectedMemberNamesError("Select team members.");
    }
    if (!addProjectData.frontend) {
      error.frontend = "Frontend field can't be empty.";
    }
    if (!addProjectData.backend) {
      error.backend = "Backend field can't be empty.";
    }
    if (!addProjectData.datebase) {
      error.datebase = "Datebase field can't be empty.";
    }

    setErrors(error);
    const noErrors = Object.keys(error).length === 0;

    if (isExistingData && noErrors && selectedMemberNames.length !== 0) {
      setIsLoading(true);
      try {
        setProjectMessage("Updated the Project...")
        setDisplayMessageError("");
        const postData = JSON.stringify({ projectData, selectedMemberNames });
        const response = await editProjects(existingData._id, postData)
        if (response.status === 200) {
          if (change === true) {
            setChange(false);
          } else {
            setChange(true);
          }
          setClose();
          setProjectMessage("");
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message ?? "Project updated."));
          dispatch(setToastType("success"));
        }
        return;
      } catch (error) {
        setIsLoading(false);
        setDisplayMessageError(errorMessage ?? "");
        setProjectMessage("");
        return;
      }
    } else if (isExistingData) {
      return;
    }

    if (!projectDocumentationFile.name && noErrors) {
      // setFileSelectError("Select Project Documentation File.");
      // return;
    } else if (projectDocumentationFile.name) {
      try {
        const formData = new FormData();
        formData.append("ProjectDocumentationFile", projectDocumentationFile);
        formData.append("FileName", addProjectData.project);
        const response = await upsertProjectDocument(addProjectData.project, formData)
        if (response.status === 200 || response.status === 201) {
          setProjectDocumentationFile({});
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message));
          dispatch(setToastType("success"));
        }
      } catch (error) {
        setDisplayMessageError("Error uploading project documentation:", error);
        return;
      }
    }

    if (noErrors && selectedMemberNamesError === "") {
      try {
        setProjectMessage("Adding the Project...")
        setDisplayMessageError("");
        const postData = JSON.stringify({ projectData, selectedMemberNames });
        const response = await upsertProjects(postData);
        if (response.status === 200) {
          if (change === true) {
            setChange(false);
          } else {
            setChange(true);
          }
          setClose();
          setProjectMessage("");
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message ?? "Project added."));
          dispatch(setToastType("success"));
        }
      } catch (error) {
        setDisplayMessageError(errorMessage ?? "")
        setProjectMessage("");
      }
    }
  };

  const fetchEmployeeName = async () => {
    try {
      const response = await getEmployee()
      if (response.status === 200 || response.status === 201) {
        setEmployeeList(response.data.namesData);
      }
      else {
        setEmployeeList([])
      }
    } catch (error) {
    }
  };

  const fetchWorkFlowName = async () => {
    const user = employeeDisplay ? "user" : adminLogin ? "admin" : "";
    if (user) {
      try {
        const response = await getWorkFlow(user)
        if (response.status === 200 || response.status === 201) {
          setWorkFlowList(response?.data?.workflows);
        }
        else {
          setWorkFlowList([])
        }
      } catch (error) {
      }
    }
  };

  const handleFileChangeAndUpload = async (event) => {
    const file = event.target.files[0];
    setShowUpdatedFileName(file);
    const fileNameFromPrevious = existingData.documentation.replace(
      /\.pdf$/,
      ""
    );
    if (file) {
      try {
        const formData = new FormData();
        formData.append("ProjectDocumentationFile", file);
        formData.append("FileName", fileNameFromPrevious);
        const response = await upsertProjectDocument(fileNameFromPrevious, formData);
        if (response.status === 200 || response.status === 201) {
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message));
          dispatch(setToastType("success"));
        }
      } catch (error) {
        setDisplayMessageError("Error uploading project documentation:", error);
        return;
      }
    }
  };

  const ViewDocumentOfProject = async () => {
    try {
      window.open(
        `${CLOUD_FUNCTIONS_ORIGIN}/admin/ProjectDocumentationFile/${existingData.documentation}`,
        "_blank",
        "noreferrer"
      );
    } catch (error) { }
  };

  useEffect(() => {
    if (isExistingData) {
      const checkApi = async () => {
        try {
          const response = await fetch(
            `${CLOUD_FUNCTIONS_ORIGIN}/admin/ProjectDocumentationFile/${existingData.documentation}`,
            { method: "HEAD" }
          );
          setDocAvailable(response.ok);
        } catch (error) {
          setDocAvailable(false);
        }
      };
      checkApi();
      setAddProjectData({
        project: existingData?.project,
        projectAliasName: existingData?.aliasName,
        status: existingData?.status,
        clientName: existingData?.clientName,
        Description: existingData?.Description,
        leaderName: existingData?.leaderName,
        frontend: existingData?.frontEnd,
        backend: existingData?.backEnd,
        datebase: existingData?.database,
        workFlowId: existingData?.workFlowId,
      });
      setSelectedMemberNames(existingData.memberOfDevTeam);
    } else {
      setSelectedMemberNames([]);
      setAddProjectData({
        project: "",
        projectAliasName: "",
        status: "",
        clientName: "",
        Description: "",
        leaderName: { firstName: "" },
        // workFlowId: { workFlowName: "" },
        workFlowId: null,
        frontend: "",
        backend: "",
        datebase: "",
      });
    }
  }, [existingData, isExistingData]);

  useEffect(() => {
    dispatch(setProjectWorkingMemberName(selectedMemberNames));
    setSelectedMemberNamesError("");
    dispatch(setProjectData(addProjectData));
  }, [selectedMemberNames, addProjectData, dispatch]);

  useEffect(() => {
    fetchEmployeeName();
    fetchWorkFlowName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ padding: "22px 4px 8px 10px", height: "container" }}>
      <MainBox>
        <Box sx={{ padding: "5px 0px 5px 18px" }}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {adminLogin
              ? isExistingData
                ? "Edit Project"
                : "Add Project"
              : "Project Details"}
          </Typography>
        </Box>
        <CloseBox>
          <IconButton onClick={setClose}>
            <CloseIcon color="primary" />
          </IconButton>
        </CloseBox>
      </MainBox>

      <Box sx={{ padding: { xs: "8px", sm: "8px 4px 8px 10px" }, marginRight: { xs: 0, sm: "8px" } }}>
        <MainCard>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <InputTextField
                  fullWidth
                  name="project"
                  value={addProjectData.project}
                  onChange={handleChange}
                  disabled={employeeDisplay ? true : false}
                  label={
                    <>
                      Project
                      {renderAsterisk()}
                    </>
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Typography color="error">{errors.project}</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputTextField
                  fullWidth
                  label={
                    employeeDisplay
                      ? "Project AliasName"
                      : "Project AliasName (Optional)"
                  }
                  name="projectAliasName"
                  value={addProjectData.projectAliasName}
                  onChange={handleChange}
                  disabled={employeeDisplay ? true : false}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <StatusTextField
                  fullWidth
                  select
                  employeedisplay={employeeDisplay ? "true" : "false"}
                  name="status"
                  value={addProjectData.status}
                  onChange={handleChange}
                  disabled={employeeDisplay ? true : false}
                  label={
                    <>
                      Status
                      {renderAsterisk()}
                    </>
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  {projectStatus?.map((status, index) => (
                    <MenuItem key={index} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </StatusTextField>
                <Typography color="error">{errors.status}</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputTextField
                  fullWidth
                  name="clientName"
                  value={addProjectData.clientName}
                  onChange={handleChange}
                  disabled={employeeDisplay ? true : false}
                  label={
                    <>
                      Client Name
                      {renderAsterisk()}
                    </>
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Typography color="error">{errors.clientName}</Typography>
              </Grid>

              {isExistingData ? (
                <>
                  {docAvailable ? (
                    <Grid
                      item xs={12}
                      sm={employeeDisplay ? 12 : 6}
                      sx={{ textAlign: "center" }}
                    >
                      <Tooltip
                        title="View Documentation"
                        placement="right"
                        arrow
                      >
                        <IconButton
                          color="success"
                          onClick={ViewDocumentOfProject}
                        >
                          <CloudDownloadIcon />
                        </IconButton>
                      </Tooltip>
                      <Typography color="primary">
                        {existingData.documentation}
                      </Typography>
                    </Grid>
                  ) : (
                    <></>
                  )}

                  {adminLogin ? (
                    <Grid
                      item
                      xs={12}
                      sm={docAvailable ? 6 : 12}
                      sx={{ textAlign: "center" }}
                    >
                      <Tooltip
                        title={
                          docAvailable
                            ? "ReUpload Documentation"
                            : "Upload Documentation"
                        }
                        placement="left"
                        arrow
                      >
                        <Button
                          component="label"
                          role={undefined}
                          variant="text"
                          tabIndex={-1}
                          color={docAvailable ? "warning" : "primary"}
                        >
                          {docAvailable ? (
                            <CloudSyncIcon />
                          ) : (
                            <CloudUploadIcon />
                          )}
                          <VisuallyHiddenInput
                            type="file"
                            onChange={handleFileChangeAndUpload}
                            accept="application/pdf"
                          />
                        </Button>
                      </Tooltip>
                      <Typography color="primary">
                        {showUpdatedFileName.name}
                      </Typography>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Grid item xs={12} sm={12} sx={{ textAlign: "center" }}>
                  <Tooltip title="Upload Documentation" placement="right" arrow>
                    <Button
                      component="label"
                      role={undefined}
                      variant="text"
                      tabIndex={-1}
                    >
                      <CloudUploadIcon />
                      <VisuallyHiddenInput
                        type="file"
                        onChange={handleFileChange}
                        accept="application/pdf"
                      />
                    </Button>
                  </Tooltip>
                  <Typography color="error">{fileSelectError}</Typography>
                  <Typography color="primary">
                    {projectDocumentationFile.name}
                  </Typography>
                </Grid>
              )}

              <Grid item xs={12} sm={12}>
                <InputTextField
                  fullWidth
                  multiline
                  maxRows={5}
                  name="Description"
                  value={addProjectData.Description}
                  onChange={handleChange}
                  disabled={employeeDisplay ? true : false}
                  label={
                    <>
                      Description
                      {renderAsterisk()}
                    </>
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Typography color="error">{errors.Description}</Typography>
              </Grid>

              <Grid item xs={12} sm={12} sx={{ textAlign: "center" }}>
                <AdditionalDetailsTypography
                  variant="h6"
                >
                  Additional Details
                </AdditionalDetailsTypography>
              </Grid>

              <Grid item xs={12} sm={12}>
                <LeaderNameAutocomplete
                  fullWidth
                  options={employeeList}
                  getOptionLabel={(option) => option.firstName}
                  renderInput={(params) => (
                    <TextField {...params} label="Leader Name" />
                  )}
                  value={addProjectData.leaderName}
                  name="leaderName"
                  onChange={leaderNameValueSetter}
                  readOnly={employeeDisplay ? true : false}
                  employeedisplay={employeeDisplay ? "true" : "false"}
                  adminlogin={adminLogin ? "true" : "false"}
                />
                <Typography color="error">{errors.leaderName}</Typography>
              </Grid>

              <Grid item xs={12} sm={12}>
                <MemberNameAutocomplete
                  fullWidth
                  multiple
                  options={employeeList.filter(
                    (option) => !selectedMemberNames.includes(option)
                  )}
                  getOptionLabel={(option) => option.firstName}
                  renderInput={(params) => (
                    <TextField {...params}
                      label={
                        <>
                          Team Members
                          {renderAsterisk()}
                        </>
                      }
                      InputLabelProps={{
                        shrink: true,
                      }} sx={{
                        "& fieldset legend > span": {
                          paddingLeft: "2px",
                          paddingRight: "0px",
                        },
                      }} />
                  )}
                  value={selectedMemberNames}
                  onChange={EmployeesValueSetter}
                  readOnly={employeeDisplay ? true : false}
                  employeedisplay={employeeDisplay ? "true" : "false"}
                />
                <Typography color="error">
                  {selectedMemberNamesError}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12}>
                <WorkFlowNameAutocomplete
                  fullWidth
                  options={workFlowList}
                  getOptionLabel={(option) => option.workFlowName}
                  renderInput={(params) => (
                    <TextField {...params} label="Work Flow" />
                  )}
                  name="workFlowId"
                  value={addProjectData.workFlowId || null}
                  onChange={WorkFlowValueSetter}
                />
                <Typography color="error">
                  {errors.workFlowId}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} sx={{ textAlign: "center" }}>
                <TechnologyStackTypography
                  variant="h6"
                >
                  Technology Stack
                </TechnologyStackTypography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputTextField
                  fullWidth
                  name="frontend"
                  value={addProjectData.frontend}
                  onChange={handleChange}
                  disabled={employeeDisplay ? true : false}
                  label={
                    <>
                      Front End
                      {renderAsterisk()}
                    </>
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Typography color="error">{errors.frontend}</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputTextField
                  fullWidth
                  name="backend"
                  value={addProjectData.backend}
                  onChange={handleChange}
                  disabled={employeeDisplay ? true : false}
                  label={
                    <>
                      Back End
                      {renderAsterisk()}
                    </>
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Typography color="error">{errors.backend}</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputTextField
                  fullWidth
                  name="datebase"
                  value={addProjectData.datebase}
                  onChange={handleChange}
                  disabled={employeeDisplay ? true : false}
                  label={
                    <>
                      Database
                      {renderAsterisk()}
                    </>
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Typography color="error">{errors.datebase}</Typography>
              </Grid>
            </Grid>

            <Box mt={2} sx={{
              display: "flex", justifyContent: "space-between",
              alignItems: "center",
            }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {projectMessage && (
                  <Typography
                    sx={{
                      mr: 2,
                      color: isDarkTheme ? "#e0e0e0" : "#5e6e82",
                    }}
                  >
                    {projectMessage}
                  </Typography>
                )}
                {displayMessageError && (
                  <Typography sx={{ mr: 2, color: "red" }}>
                    {displayMessageError}
                  </Typography>
                )}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Button onClick={setClose} sx={{ ml: 2, marginRight: "10px" }}>
                  Cancel
                </Button>
                {adminLogin ? (
                  isExistingData ? (
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={handleSubmit}
                    >
                      Update project
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                    >
                      Add project
                    </Button>
                  )
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          </CardContent>
        </MainCard>
      </Box>
    </Box>
  );
}

export default AddProject;
