import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Tooltip,
  Zoom,
  Typography,
} from "@mui/material";
import { GridToolbar, GridToolbarExport } from "@mui/x-data-grid";

import {
  tableBodyCell,
  tableHeadCell,
  tablePagination,
} from "../../ReusableComponents/CustomDesignMUI/CustomMUI";
import { numberArray } from "../../Constant/constant";
import { formatDate } from "../../ReusableComponents/UtilityFunctions";
import { setCalenderValue } from "../../../Slices/searchData";
import {
  AttendanceTabMainBox,
  DataGrids,
  NoRecordFoundTypography,
  VisibilityIcons,
  TableHeads,
  TableCells,
  LeaveTableCells,
} from "../../../Styles/EmployeeManagement/Employee/AttendenceStyle";
import "../../../assets/styles/DataGridStyles.css";

const AttendanceTable = ({
  allAttendenceData,
  allAttendenceDataEmployee,
  AttendenceData,
  working,
  employeeLogin,
  adminLogin,
  startDate,
  endDate,
  employeeName,
  attendanceManagement,
  employeesPresentData,
  attendanceManagementDaily,
  setGetData,
  selectedRows,
  setSelectedRows,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataGridHeight, setDataGridHeight] = useState(false);

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  function formatTime(seconds) {
    // eslint-disable-next-line use-isnan
    if (seconds === undefined || seconds === NaN) {
      return "00:00:00";
    } else {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      const formattedHours = String(hours).padStart(2, "0");
      const formattedMinutes = String(minutes).padStart(2, "0");
      const formattedSeconds = String(remainingSeconds).padStart(2, "0");
      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }
  }

  function extractTime(timestamp) {
    if (timestamp === 0) {
      return "Working...";
    }
    if (timestamp === undefined) {
      return "Absent";
    }
    const dateTime = new Date(timestamp);
    const time = dateTime.toLocaleTimeString([], {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    return time;
  }

  function extractTimeForAttendance(timestamp) {
    if (timestamp === 0) {
      return "Absent";
    }
    if (timestamp === null) {
      return "00:00:00";
    }
    const dateTime = new Date(timestamp);
    const time = dateTime.toLocaleTimeString([], {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    return time;
  }

  function dateFormat(dateTime) {
    const [day, month, year] = dateTime?.split("-");
    return `${day}/${month}/${year}`;
  }

  function calculateEndTime(total, startTime, breakTime) {
    const endTime = startTime + total + breakTime;
    return endTime;
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    window.localStorage.setItem("paginationNo", event.target.value);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const changeToParticularEmployee = (empId) => {
    navigate(`/attendance-management/${empId}`);
    const date = formatDate(new Date());
    dispatch(setCalenderValue(date));
  };

  const FormattedTaskData = allAttendenceDataEmployee?.map((data, index) => ({
    id: index + 1,
    Date: dateFormat(data.date),
    inTime: extractTime(data.dailytimer.TimerStart),
    outTime: extractTime(
      calculateEndTime(
        data?.dailytimer?.TotalTime,
        data?.dailytimer?.TimerStart,
        data?.dailytimer?.BreakTime
      )
    ),
    breakTime: formatTime(data.dailytimer.BreakTime / 1000, 9),
    workingTime: formatTime(data.dailytimer.TotalTime / 1000, 9),
  }));

  const column =
    employeeLogin || adminLogin
      ? [
        { field: "Date", headerName: "Date", flex: 1 },
        {
          field: "inTime",
          headerName: "Check In",
          flex: 1,
          renderCell: (params) => (
            <Typography
              sx={{
                textTransform:
                  params?.value === "Working..." ? "none" : "uppercase",
                fontSize: "0.9rem"
              }}
            >
              {params.value}
            </Typography>
          ),
        },
        {
          field: "outTime",
          headerName: "Check Out",
          flex: 1,
          renderCell: (params) => (
            <Typography
              sx={{
                textTransform:
                  params?.value === "Working..." ? "none" : "uppercase",
                fontSize: "0.9rem"
              }}
            >
              {params.value}
            </Typography>
          ),
        },
        { field: "breakTime", headerName: "Break Time", flex: 1 },
        { field: "workingTime", headerName: "Total Working Hours", flex: 1 },
      ]
      : attendanceManagement
        ? [
          { field: "empId", headerName: "EMP-ID", flex: 1 },
          { field: "employeeName", headerName: "Employee Name", flex: 1.5 },
          { field: "workingDays", headerName: "Working days", flex: 1 },
          { field: "presentDays", headerName: "Present Days", flex: 1 },
          { field: "leavesDays", headerName: "Absent Days", flex: 1 },
          {
            field: "Casual Leave",
            headerName: "Casual Leave",
            flex: 1,
          },
          {
            field: "Paid Leave",
            headerName: "Paid Leave",
            flex: 1,
          },
          {
            field: "Sick Leave",
            headerName: "Sick Leave",
            flex: 1,
          },
          {
            field: "Leave Without Pay (LWP)",
            headerName: "Leave Without Pay (LWP)",
            flex: 1,
            hide: true,
          },
          {
            field: "Religious Leave",
            headerName: "Religious Leave",
            flex: 1,
            hide: true,
          },
          {
            field: "UnPaid Leave",
            headerName: "UnPaid Leave",
            flex: 1,
            hide: true,
          },
          {
            field: "action",
            headerName: "Action",
            flex: 1,
            renderCell: (params) => (
              <Tooltip
                title="View Profile"
                arrow
                disableInteractive
                TransitionComponent={Zoom}
              >
                <VisibilityIcons
                  onClick={() => changeToParticularEmployee(params.row.empId)}
                />
              </Tooltip>
            ),
          },
        ]
        : [];

  const checkTodayRecord = (date) => {
    const currentDate = new Date();
    const currentDateString = currentDate.toISOString().split("T")[0];
    const compareDate = currentDateString.split("-").reverse().join("/");
    if (compareDate === date) {
      return true;
    } else {
      return false;
    }
  };

  const rows = attendanceManagement
    ? employeesPresentData
      .sort((a, b) => {
        const idA = parseInt(a.empId.slice(4), 10);
        const idB = parseInt(b.empId.slice(4), 10);
        return idA - idB;
      })
      .map((employee, index) => {
        return {
          id: index,
          empId: employee.empId,
          employeeName: employee.employeeName,
          workingDays: employee.workingdays,
          presentDays: employee.presentDays,
          leavesDays: employee.leavesDays,
          "Casual Leave": employee.leaveTypeCounts["Casual Leave"] || 0,
          "Paid Leave": employee.leaveTypeCounts["Paid Leave"] || 0,
          "Sick Leave": employee.leaveTypeCounts["Sick Leave"] || 0,
          "Leave Without Pay (LWP)":
            employee.leaveTypeCounts["Leave Without pay (LWP)"] || 0,
          "Religious Leave": employee.leaveTypeCounts["Religious Leave"] || 0,
          "UnPaid Leave": employee.leaveTypeCounts["UnPaid Leave"] || 0,
        };
      })
    : (employeeLogin || adminLogin) && AttendenceData
      ? Object.values(AttendenceData)
        .map((employee, index) => {
          return {
            id: index,
            Date: dateFormat(employee.date) || "N/A",
            inTime: extractTime(employee.dailytimer?.TimerStart) || "N/A",
            outTime:
              employee.dailytimer &&
                checkTodayRecord(dateFormat(employee.date)) &&
                working
                ? "Working..."
                : extractTime(
                  calculateEndTime(
                    employee.dailytimer.TotalTime,
                    employee.dailytimer.TimerStart,
                    employee.dailytimer.BreakTime
                  )
                ),
            breakTime: employee.dailytimer
              ? formatTime(employee.dailytimer.BreakTime / 1000, 9)
              : "N/A",
            workingTime: employee.dailytimer
              ? formatTime(employee.dailytimer.TotalTime / 1000, 9)
              : "N/A",
          };
        })
      : [];

  const handleRowSelectionChange = (newSelectionModel) => {
    const selectedIds = newSelectionModel;
    const selectedEmployeeIds = rows
      .filter((row) => selectedIds.includes(row.id))
      .map((row) => row.empId);

    const filteredData = employeesPresentData.filter((data) =>
      selectedEmployeeIds.includes(data.empId)
    );
    setSelectedRows(newSelectionModel);
    setGetData(filteredData);
  };

  useEffect(() => {
    const storedValue = parseInt(
      window.localStorage.getItem("paginationNo"),
      10
    );
    const initialValue = numberArray.includes(storedValue) ? storedValue : 10;
    setRowsPerPage(initialValue);
  }, []);

  return (
    <AttendanceTabMainBox>
      {attendanceManagementDaily ? (
        <TableContainer>
          <Table
            sx={{
              "@media (max-width: 600px)": {
                width: "280%",
              },
            }}
          >
            <TableHeads>
              <TableRow sx={{ textDecoration: "none" }}>
                <TableCell sx={tableHeadCell}>EMP-ID</TableCell>
                <TableCell sx={tableHeadCell}>Employee Name</TableCell>
                <TableCell sx={tableHeadCell}>Check In</TableCell>
                <TableCell sx={tableHeadCell}>Check Out</TableCell>
                <TableCell sx={tableHeadCell}>Break Time</TableCell>
                <TableCell sx={tableHeadCell}>Total Working Hours</TableCell>
              </TableRow>
            </TableHeads>
            <TableBody>
              {Object.values(allAttendenceData)?.length === 0 ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "var(--secondary-text-color)",
                    }}
                    colSpan={6}
                  >
                    No Records found.
                  </TableCell>
                </TableRow>
              ) : (
                Object.values(
                  allAttendenceData.sort((a, b) => {
                    const idA = parseInt(a.empId.slice(4), 10);
                    const idB = parseInt(b.empId.slice(4), 10);

                    return idA - idB;
                  })
                )
                  ?.slice(startIndex, endIndex)
                  ?.map((employee, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor:
                          index % 2 === 1 ? "var(--highlight-color)" : "",
                        background:
                          "var(--background-table-sidebar-card-color)",
                      }}
                    >
                      <TableCells tablebodycell={tableBodyCell}>
                        {employee.empId}
                      </TableCells>
                      <TableCells tablebodycell={tableBodyCell}>
                        {employee.name}
                      </TableCells>
                      {employee.leave === "" && (
                        <>
                          <TableCells
                            sx={{
                              textTransform:
                                extractTimeForAttendance(employee.checkIn) ===
                                  "Absent"
                                  ? "none"
                                  : "uppercase",
                            }}
                            tablebodycell={tableBodyCell}
                          >
                            {extractTimeForAttendance(employee.checkIn)}
                          </TableCells>
                          <TableCells
                            sx={{
                              textTransform:
                                extractTimeForAttendance(employee.checkOut) ===
                                  "Absent"
                                  ? "none"
                                  : "uppercase",
                            }}
                            tablebodycell={tableBodyCell}
                          >
                            {extractTimeForAttendance(employee.checkOut)}
                          </TableCells>
                        </>
                      )}
                      {employee.leave !== "" && (
                        <LeaveTableCells
                          tablebodycell={tableBodyCell}
                          colSpan={2}
                        >
                          Leave ({employee.leave})
                        </LeaveTableCells>
                      )}
                      <TableCell sx={tableBodyCell}>
                        {formatTime(employee.breakTime / 1000)}
                        {/* {formatTime(calculateBreakTime(employee.breakTime))} */}
                      </TableCell>
                      <TableCell sx={tableBodyCell}>
                        {formatTime(employee.totalWorking / 1000)}
                        {/* {formatTime(calculateTotalTime(employee.totalWorking) )} */}
                      </TableCell>
                    </TableRow>
                  ))
              )}
              {attendanceManagementDaily ? (
                <TablePagination
                  rowsPerPageOptions={numberArray}
                  count={allAttendenceData?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={tablePagination}
                />
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}

      {attendanceManagementDaily ? null : employeeLogin ||
        attendanceManagement ? (
        <Box
          sx={{ width: "100%", maxHeight: "fit-content", overflowX: "auto" }}
        >
          {rows.length === 0 ? (
            <Box sx={{ p: 2, textAlign: "center", color: isDarkTheme ? "#e0e0e0e3" : "#544f5a" }}>
              <Typography>No records found.</Typography>
            </Box>
          ) : (
            <Box
              sx={{
                "@media (max-width: 600px)": {
                  width: "300%",
                },
              }}
            >
              <DataGrids
                rows={rows}
                columns={column}
                rowHeight={43}
                dataGridHeight={dataGridHeight}
                checkboxSelection={!employeeLogin}
                disableRowSelectionOnClick
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  handleRowSelectionChange(newRowSelectionModel);
                }}
                rowSelectionModel={selectedRows}
                components={{
                  Toolbar: GridToolbar,
                }}
                slots={{
                  toolbar: GridToolbar,
                }}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      "Casual Leave": false,
                      "Paid Leave": false,
                      "Sick Leave": false,
                      "Leave Without Pay (LWP)": false,
                      "Religious Leave": false,
                      "UnPaid Leave": false,
                    },
                  },
                  pagination: {
                    paginationModel: {
                      pageSize:
                        parseInt(
                          window.localStorage.getItem("paginationNo"),
                          10
                        ) || 10,
                    },
                  },
                }}
                onRowCountChange={(params) => {
                  if (params === 0) {
                    setDataGridHeight(true);
                  } else {
                    setDataGridHeight(false);
                  }
                }}
                pageSizeOptions={numberArray}
                onPaginationModelChange={(pageSize) => {
                  window.localStorage.setItem(
                    "paginationNo",
                    pageSize.pageSize
                  );
                }}
                onPageSizeChange={(pageSize) => {
                  window.localStorage.setItem("paginationNo", pageSize);
                }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
                sx={{
                  "& .MuiDataGrid-cell": {
                    backgroundColor: (params) =>
                      params.rowIndex % 2 === 0
                        ? "var(--highlight-color)"
                        : "var(--background-table-sidebar-card-color)",
                  },
                }}
              />
            </Box>
          )}
        </Box>
      ) : null}

      {adminLogin && FormattedTaskData.length > 0 ? (
        <Box
          sx={{ width: "100%", maxHeight: "fit-content", overflowX: "auto" }}
        >
          <Box
            sx={{
              "@media (max-width: 600px)": {
                width: "290%",
              },
            }}
          >
            <DataGrids
              disableColumnMenu
              dataGridHeight={dataGridHeight}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              rows={FormattedTaskData}
              columns={column}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize:
                      parseInt(
                        window.localStorage.getItem("paginationNo"),
                        10
                      ) || 10,
                  },
                },
              }}
              onRowCountChange={(params) => {
                if (params === 0) {
                  setDataGridHeight(true);
                } else {
                  setDataGridHeight(false);
                }
              }}
              pageSizeOptions={numberArray}
              onPaginationModelChange={(pageSize) => {
                window.localStorage.setItem("paginationNo", pageSize.pageSize);
              }}
              onPageSizeChange={(pageSize) => {
                window.localStorage.setItem("paginationNo", pageSize);
              }}
              components={{
                Toolbar: () => (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <GridToolbarExport
                      csvOptions={{
                        fileName: `${employeeName}_${startDate}_to_${endDate}`,
                        delimiter: ",",
                      }}
                      printOptions={{
                        fileName: "custom_filename.pdf",
                      }}
                    />
                  </Box>
                ),
              }}

            />
          </Box>
        </Box>
      ) : (
        <NoRecordFoundTypography>
          {attendanceManagement || attendanceManagementDaily || employeeLogin
            ? null
            : "No records found."}
        </NoRecordFoundTypography>
      )}
    </AttendanceTabMainBox>
  );
};

export default AttendanceTable;
