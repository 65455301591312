import { Box, Button, Typography, styled } from "@mui/material";

export const MainBox = styled(Box)(() => ({
  margin: "0px 0px",
  overflowY: "auto",
  overflowX:"auto",
  height: "100%",
  borderRadius: "5px",
  marginBottom: "3%",
}));

export const LoadingBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "65vh",
}));

export const BoxZero = styled(Box)(() => ({
    display:"flex",
    gap:"6%",
    marginLeft:"10px",
    "@media (max-width: 600px)": {
      marginLeft:"0px"
    },
  }));

export const InnerBoxOne = styled(Box)(() => ({
  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
  borderRadius: "5px",
  background: "var(--background-table-sidebar-card-color)",
  padding: "15px",
  paddingBottom: "0px",
}));

export const InnerBoxTwo = styled(Box)(() => ({
  padding: "20px 20px 10px",
  borderBottom: "1px solid var(--table-border-color)",
  boxShadow: "0px 13px 10px -20px #111",
  display: "flex",
  alignItems: "flex-end"
}));

export const InnerBoxThree = styled(Box)(() => ({
  position: "absolute",
  top: "90px",
  right: "4px",
  background: "transparent",
  padding: "-10px",
  borderRadius: "50px",
  transform: "scale(0.7)",
  transformOrigin: "center",
  backdropFilter: "blur(30px)",
  border: "2px solid var(--secondary-text-color)",
}));

export const ChangePasswordButton = styled(Button)(() => ({
  marginLeft: "10px",
  color: "primary",
  fontWeight: "bold",
  textTransform: "none",
  "@media (max-width: 600px)": {
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    border: 'none !important',
    marginLeft: "50px"
  },
}));

export const InnerBoxFour = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "10px 15px 15px "
}));

export const FirstNameTypography = styled(Typography)(() => ({
  fontWeight: "300",
  fontSize: "30px",
  color: "var(--secondary-text-color)",
  display: "flex",
  alignItems: "center"
}));

export const RoleTypography = styled(Typography)(() => ({
  fontWeight: "300",
  fontSize: "20px",
  color: "var(--secondary-text-color)",
  display: "flex",
  alignItems: "center",
}));

export const TitleTypography = styled(Typography)(() => ({
  fontWeight: "300",
  fontSize: "20px",
  color: "var(--secondary-text-color)",
  display: "flex",
  alignItems: "center",
}));

export const InnerBoxFive = styled(Box)(() => ({
  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
  borderRadius: "5px",
  marginLeft: "5%",
  background: "var(--background-table-sidebar-card-color)",
  width:"100%",
  overflow:"auto",
  height:"100%",
  "@media (max-width: 600px)": {
    marginLeft: "0%",
  },
}));

export const InnerBoxSix = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignContent: "center",
  padding: "25px",
  boxShadow: "0px 13px 10px -20px #111",
  borderBottom: "1px solid var(--table-border-color)",
  background: "var(--primary-highlight-color)",
  borderRadius: "5px",
  "@media (max-width: 600px)": {
    padding: "10px 25px",
  },
}));

export const ProfileTitleTypography = styled(Typography)(() => ({
  fontWeight: "bold",
  fontSize: "25px",
  color: "var(--secondary-text-color)",
}));

export const InnerBoxSeven = styled(Box)(() => ({
  fontWeight: "bold",
  fontSize: "25px",
  alignItems: "center",
  color: "var(--secondary-text-color)",
}));
