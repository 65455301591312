import { Box, Typography, styled } from "@mui/material"
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export const MainBox = styled(Box)(() => ({
    margin: "0px 0px ",
}))

export const InnerBoxOne = styled(Box)(() => ({
    borderBottom: "1px solid var(--table-border-color)",
    marginBottom: "20px",
    display: "flex",
    justifyContent: "space-between",
}))

export const TitleTypography = styled(Typography)(() => ({
    color: "var(--primary-color)",
    fontWeight: "bold",
    marginLeft: "15px",
    marginBottom: "10px",
}))

export const InnerBoxThree = styled(Box)(() => ({
    position: "absolute",
    top: "90px",
    right: "4px",
    background: "transparent",
    padding: "-10px",
    borderRadius: "50px",
    transform: "scale(0.7)",
    transformOrigin: "center",
    backdropFilter: "blur(30px)",
    border: "2px solid var(--secondary-text-color)",
}));

export const StyledIcon = styled(FiberManualRecordIcon)(({ theme, isActive }) => ({
    color: isActive ? 'green' : 'red',
}));

export const LoadingBox = styled(Box)(() => ({
    height: "52vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}))