import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Tooltip,
  styled,
} from "@mui/material";
import {
  Close,
  UploadFile as UploadFileIcon,
  CloudDownload as CloudDownloadIcon,
  CloudUpload as CloudUploadIcon,
  PictureAsPdf as PictureAsPdfIcon,
} from "@mui/icons-material";

import {
  setToast,
  setToastMsg,
  setToastType,
} from "../../Slices/toastSlice";
import { CLOUD_FUNCTIONS_ORIGIN } from "../../function-origin";
import DialogComponent from "../ReusableComponents/DialogBoxComponents/DialogComponent";
import { errorMessage } from "../../Services/axiosInstance";
import { upsertCompanyPolicy, upsertLeavePolicy } from "../../Services/LeaveManageServices";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const UploadViewDialog = ({
  open,
  setOpenUpload_ViewDialog,
  // uploadDialog,
  // setUploadDialog,
  // downloadDialog,
  // setDownloadDialog,
  upload_ViewDialogName,
  // setUpload_ViewDialogName,
  fileName,
  // setFileName,
}) => {
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const dispatch = useDispatch();

  const [displayError, setDisplayError] = useState("");
  const [choosenFileToUpload, setChoosenFileToUpload] = useState({});
  const [choosenFileToUploadName, setChoosenFileToUploadName] = useState("");

  const onClose = () => {
    setChoosenFileToUploadName("");
    setChoosenFileToUpload({});
    setOpenUpload_ViewDialog(false);
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setChoosenFileToUpload(file);
      setChoosenFileToUploadName(file.name);
    }
  };

  const UploadCompanyPolicyAction = async () => {
    try {
      const formData = new FormData();
      formData.append("CompanyPolicyFile", choosenFileToUpload);
      formData.append("Type", fileName);

      const response = await upsertCompanyPolicy(formData);

      if (response.status === 200) {
        setChoosenFileToUploadName("");
        setChoosenFileToUpload({});
        setOpenUpload_ViewDialog(false);
        dispatch(setToast(true));
        dispatch(setToastMsg(response?.data?.message));
        dispatch(setToastType("success"));
      }
    } catch (error) {
      setDisplayError(errorMessage ?? "")
    }
  };

  const UploadLeavePolicyAction = async () => {
    try {
      const formData = new FormData();
      formData.append("LeavePolicyFile", choosenFileToUpload);
      formData.append("Type", fileName);

      const response = await upsertLeavePolicy(formData);
      if (response.status === 200) {
        setChoosenFileToUploadName("");
        setChoosenFileToUpload({});
        setOpenUpload_ViewDialog(false);
        dispatch(setToast(true));
        dispatch(setToastMsg(response?.data?.message));
        dispatch(setToastType("success"));
      }
    } catch (error) {
      setDisplayError(errorMessage ?? "")
    }
  };

  const ViewCompanyPolicyAction = async () => {
    try {
      window.open(
        `${CLOUD_FUNCTIONS_ORIGIN}/admin/CompanyPolicyFile/CompanyPolicy.pdf`,
        "_blank",
        "noreferrer"
      );
    } catch (error) {
      setDisplayError(error);
    }
  };

  const ViewLeavePolicyAction = async () => {
    try {
      window.open(
        `${CLOUD_FUNCTIONS_ORIGIN}/admin/LeavePolicyFile/LeavePolicy.pdf`,
        "_blank",
        "noreferrer"
      );
    } catch (error) {
      setDisplayError(error);
    }
  };

  const renderForm = () => {
    return (
      <>
        <Box sx={{ background: isDarkTheme ? "#121e2e" : "#ffffff" }}>
          <Box>
            <IconButton
              onClick={onClose}
              sx={{
                color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: "10px",
                right: "-5px",
                fontWeight: "bold",
                textTransform: "capitalize",
                marginRight: "15px",
                fontSize: "2px",
                "&:hover": {
                  background: "var(--highlight-color)",
                },
              }}
            >
              <Close />
            </IconButton>
          </Box>
          <Box
            sx={{
              marginTop: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "550px",
              height: "75px",
            }}
          >
            <Box>
              <DialogTitle sx={{ fontWeight: "bold" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background:
                        upload_ViewDialogName === "Upload"
                          ? "#508ac351"
                          : "#d3696922",
                      borderRadius: "1000px",
                      padding: "8px",
                      marginRight: "25px",
                    }}
                  >
                    {upload_ViewDialogName === "Upload" ? (
                      <UploadFileIcon
                        style={{ fontSize: "xx-large", fill: "#1976d2" }}
                      />
                    ) : null}
                    {upload_ViewDialogName === "Go To" ? (
                      <PictureAsPdfIcon
                        style={{ fontSize: "xx-large", fill: "#d32f2f" }}
                      />
                    ) : null}
                  </Box>
                  <Box>
                    <Box sx={{ color: isDarkTheme ? "#e0e0e0e3" : "#544f5a" }}>
                      {fileName}
                    </Box>
                  </Box>
                </Box>
              </DialogTitle>
            </Box>
          </Box>

          <DialogContent>
            <Box>
              {upload_ViewDialogName === "Upload" ? (
                <Box sx={{ textAlign: "center" }}>
                  <Tooltip title="Upload File" followCursor>
                    <Button
                      component="label"
                      role={undefined}
                      variant="text"
                      tabIndex={-1}
                    >
                      <CloudUploadIcon />
                      <VisuallyHiddenInput
                        type="file"
                        onChange={handleFileChange}
                        accept="application/pdf"
                      />
                    </Button>
                  </Tooltip>
                  {choosenFileToUploadName ? (
                    <>
                      <br />
                      <br />
                      <Typography
                        sx={{
                          color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82",
                        }}
                      >
                        {choosenFileToUploadName}
                      </Typography>
                    </>
                  ) : null}
                </Box>
              ) : null}

              {upload_ViewDialogName === "Go To" ? (
                <Box sx={{ textAlign: "center" }}>
                  <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudDownloadIcon />}
                    disabled
                  >
                    {fileName === "Company Policy"
                      ? "Company Policy.pdf"
                      : "Leave Policy.pdf"}
                  </Button>
                </Box>
              ) : null}
            </Box>
          </DialogContent>
          <Typography
            sx={{
              color: "red",
              paddingLeft: "38px",
              marginTop: "0px",
            }}
          >
            {displayError}
          </Typography>

          <DialogActions
            sx={{
              background: "var(--highlight-color)",
              boxShadow: "0px 2px 10px var(--dark-highlight-color)",
            }}
          >
            <Button
              onClick={onClose}
              sx={{
                color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82",
                textTransform: "capitalize",
                fontWeight: "bold",
                marginRight: "10px",
                borderRadius: "5px",
              }}
            >
              Cancel
            </Button>

            {upload_ViewDialogName === "Upload" ? (
              <Button
                onClick={() => {
                  if (fileName === "Company Policy") {
                    UploadCompanyPolicyAction();
                  }
                  if (fileName === "Leave Policy") {
                    UploadLeavePolicyAction();
                  }
                }}
                variant="contained"
                sx={{
                  borderRadius: "5px",
                  textTransform: "capitalize",
                }}
                component={Link}
                color="primary"
              >
                Upload
              </Button>
            ) : null}
            {upload_ViewDialogName === "Go To" ? (
              <Button
                onClick={() => {
                  if (fileName === "Company Policy") {
                    ViewCompanyPolicyAction();
                  }
                  if (fileName === "Leave Policy") {
                    ViewLeavePolicyAction();
                  }
                }}
                variant="contained"
                sx={{
                  borderRadius: "5px",
                  textTransform: "capitalize",
                }}
                component={Link}
                color="primary"
              >
                View
              </Button>
            ) : null}
          </DialogActions>
        </Box>
      </>)
  }

  useEffect(() => {
    setChoosenFileToUpload({});
    setChoosenFileToUploadName("");
  }, [open]);

  return (
    <Box>
      <DialogComponent
        open={open}
        onClose={onClose}
        maxWidth="md"
        renderChildern={renderForm}
      />
    </Box>
  );
};

export default UploadViewDialog;
