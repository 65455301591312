import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { 
  ListItem, 
  ListItemText, 
  ListItemAvatar, 
  Typography 
} from "@mui/material";
import { FestivalRounded as FestivalRoundedIcon, Event as EventIcon } from "@mui/icons-material";

import { getAllEvent, getAllFestival } from "../../Services/DashboardServices";
import { EventShowList, Avatars } from "../../Styles/AdminDashboard/CommonDashBoardStyle";

export default function EventShow({ setFestivalData, setEventData }) {
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const token = window.localStorage.getItem("Token");
  const user = useSelector((state) => state.selectedActiveView.role);
  const employeeLogin = user === "employee";
  const adminLogin = user === "admin";

  const [festivalName, setFestivalName] = useState("");
  const [festivalDate, setFestivalDate] = useState("");
  const [eventName, setEventName] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [displayError, setDisplayError] = useState("");

  const memoizedgetFestivalsData = useMemo(() => {
    const getFestivalsData = async () => {
      setDisplayError('');
      try {
        const response = await getAllFestival();
        if (response.status === 200 || response.status === 201) {
          const data = response.data.festivalRecord;
          setFestivalData(data);
          const nearestFestival = data.find(
            (festival) => new Date(festival.date) >= new Date()
          );
          setFestivalName(nearestFestival.festival);
          const fdate = new Date(nearestFestival.date);
          const fday = fdate.getDate();
          const fmonth = fdate.toLocaleString("default", { month: "long" });
          const fresult = `${fday} ${fmonth}`;
          setFestivalDate(fresult);
        }
        else {
          setFestivalName("")
          setFestivalDate("")
        }
      } catch (error) {
      }
    };
    return getFestivalsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const memoizedgetEventData = useMemo(() => {
    const getEventsData = async () => {
      try {
        const response = await getAllEvent();
        if (response.status === 200 || response.status === 201) {
          const data = response.data.eventRecord;
          setEventData(data);
          const nearestEvent = data.find(
            (eve) => new Date(eve.date) >= new Date()
          );
          setEventName(nearestEvent.eventTitle);
          const edate = new Date(nearestEvent.date);
          const eday = edate.getDate();
          const emonth = edate.toLocaleString("default", { month: "long" });
          const eresult = `${eday} ${emonth}`;
          setEventDate(eresult);
        }
        else {
          setEventName("")
          setEventDate("")
        }
      } catch (error) {
      }
    };
    return getEventsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (adminLogin) {
    memoizedgetFestivalsData();
    memoizedgetEventData();
    } else if (employeeLogin) {
      memoizedgetFestivalsData();
      memoizedgetEventData();
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EventShowList>
      {!festivalName && !eventName ? (
        <Typography sx={{ paddingBottom: "8px" }}>
          No Upcoming Events
        </Typography>
      ) : null}
      {new Date(festivalDate) <= new Date(eventDate) ? (
        <>
          {festivalName ? (
            <ListItem sx={{ paddingBottom: "0px" }}>
              <ListItemAvatar>
                <Avatars isdarktheme={isDarkTheme ? "true" : "false"}>
                  <FestivalRoundedIcon />
                </Avatars>
              </ListItemAvatar>
              <ListItemText primary={festivalName} secondary={festivalDate} />
            </ListItem>
          ) : null}
          {eventName ? (
            <ListItem>
              <ListItemAvatar>
                <Avatars isdarktheme={isDarkTheme ? "true" : "false"}>
                  <EventIcon />
                </Avatars>
              </ListItemAvatar>
              <ListItemText primary={eventName} secondary={eventDate} />
            </ListItem>
          ) : null}
        </>
      ) : (
        <>
          {eventName ? (
            <ListItem sx={{ paddingBottom: "0px", paddingTop: "0px" }}>
              <ListItemAvatar>
                <Avatars isdarktheme={isDarkTheme ? "true" : "false"}>
                  <EventIcon />
                </Avatars>
              </ListItemAvatar>
              <ListItemText primary={eventName} secondary={eventDate} />
            </ListItem>
          ) : null}
          {festivalName ? (
            <ListItem>
              <ListItemAvatar>
                <Avatars isdarktheme={isDarkTheme ? "true" : "false"} >
                  <FestivalRoundedIcon />
                </Avatars>
              </ListItemAvatar>
              <ListItemText primary={festivalName} secondary={festivalDate} />
            </ListItem>
          ) : null}
        </>
      )}
      <Typography sx={{ color: "red" }}> {displayError} </Typography>
    </EventShowList>
  );
}
