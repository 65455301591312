import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { WarningAmber as WarningAmberIcon, ArrowBack as ArrowBackIcon } from "@mui/icons-material";

import { setAllLeaveData } from "../../../Slices/leaveData";
import {
  setToast,
  setToastMsg,
  setToastType,
} from "../../../Slices/toastSlice";
import {
  setDetailLeave,
  setEditLeave,
  setisLeave,
} from "../../../Slices/selectedActive";
import { viewProfileTitle } from "../../ReusableComponents/CustomDesignMUI/CustomMUI";
import CustomDialogleave from "../../ReusableComponents/CustomDialogleave";
import {
  formatReverseDate,
  renderAsterisk,
} from "../../ReusableComponents/UtilityFunctions";
import ExitDialog from "../../DisplayEmployee/ExitDialog";
import Loader from "../../Loader/Loader";
import {
  editLeave,
  getLeaveBalanace,
} from "../../../Services/LeaveManageServices";
import { errorMessage } from "../../../Services/axiosInstance";
import {
  LoadingBox,
  UpdateLeaveMainBox,
  SaveButton,
  CancelButton,
  UpdateCustomFormControl,
  TitleBox,
  UpdateCustomTextField,
  UpdateLeaveFooterBox,
} from "../../../Styles/LeaveManagement/AddLeaveFormStyle";

function UpdateLeave({ backToView }) {
  const dispatch = useDispatch();
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const LeavesType = useSelector(
    (state) => state.employeeLeaveData.leavesTypes
  );
  const LeaveApp = useSelector((state) => state.selectedActiveView.leaveDetail);
  const user = useSelector((state) => state.selectedActiveView.role);
  const currentEmployee = useSelector((state) => state.addEmployee.current);

  const [LeaveApplication, setLeaveApplication] = useState(LeaveApp);
  const [error, setError] = useState([]);
  const [LeaveTime, setLeaveTime] = useState(false);
  const [warningArray, setWarningArray] = useState();
  const [openExitDialog, setOpenExitDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [displayError, setDisplayError] = useState("");
  const [LeaveTypeBalance, setLeaveTypeBalance] = useState(LeavesType);
  const [leaveData, setLeaveData] = useState({
    _id: LeaveApplication ? LeaveApplication._id : "",
    empId: LeaveApplication ? LeaveApplication.empId : "",
    employeeName: LeaveApplication ? LeaveApplication.employeeName : "",
    leaveType: LeaveApplication
      ? LeaveApplication.leaveType._id === undefined
        ? LeaveApplication.leaveType
        : LeaveApplication.leaveType._id
      : "",
    reason: LeaveApplication ? LeaveApplication.reason : "",
    startDate: LeaveApplication ? LeaveApplication.startDate : "",
    endDate: LeaveApplication ? LeaveApplication.endDate : "",
    leaveTime: LeaveApplication ? LeaveApplication.leaveTime : "",
    submitDate: LeaveApplication ? LeaveApplication.submitDate : "",
    duration: LeaveApplication ? LeaveApplication.duration : "",
  });

  const adminLogin = user === "admin";
  const EmployeeLogin = user === "employee";
  const EmployeeId = currentEmployee?.empId;

  const warning = [];
  const leaveTimeOptions = ["Full day", "Half day", "Early Leave(2hours max)"];
  const formattedStartDate = leaveData?.startDate
    ? new Date(leaveData.startDate).toISOString().split("T")[0]
    : "";
  const formattedEndDate = leaveData?.endDate
    ? new Date(leaveData.endDate).toISOString().split("T")[0]
    : "";
  const startDateObj = new Date(leaveData.startDate);
  const endDateObj = new Date(leaveData.endDate);
  const currentDateObj = new Date();
  const startDateUTC = Date.UTC(
    startDateObj.getFullYear(),
    startDateObj.getMonth(),
    startDateObj.getDate()
  );
  const endDateUTC = Date.UTC(
    endDateObj.getFullYear(),
    endDateObj.getMonth(),
    endDateObj.getDate()
  );
  const currentDateUTC = Date.UTC(
    currentDateObj.getFullYear(),
    currentDateObj.getMonth(),
    currentDateObj.getDate()
  );
  const oneDay = 24 * 60 * 60 * 1000;
  const leaveDuration = Math.floor((endDateUTC - startDateUTC) / oneDay) + 1;
  const daysForLeave = Math.floor((startDateUTC - currentDateUTC) / oneDay);

  const handleCloseCancelDialog = async () => {
    setOpenExitDialog(false);
  };

  const saveLeaveRecord = async () => {
    setDisplayError("");
    const error = [];
    if (!leaveData.employeeName) {
      error.employeeName = "employee name is required.";
    } else if (!leaveData.leaveType) {
      error.leaveType = "Type of leave is required.";
    } else if (!leaveData.startDate) {
      error.startDate = "Date is required.";
    } else if (!leaveData.endDate) {
      error.endDate = "Date is required.";
    } else if (leaveData.endDate < leaveData.startDate) {
      error.endDate = "Invalid Date.";
    } else if (!leaveData.reason) {
      error.reason = "Reason is required.";
    } else if (!leaveData.leaveTime && LeaveTime) {
      error.leaveTime = "leave time is required.";
    }
    setError(error);
    const noErrors = Object.keys(error).length === 0;
    if (noErrors) {
      if (leaveData.leaveType === "Sick Leave") {
        if (daysForLeave > 1) {
          warning.push("For sick leave advance leave request is not allowed.");
        }
      } else {
        if (leaveDuration === 1 && leaveData.leaveTime === "Half day") {
          if (daysForLeave < 4) {
            warning.push(
              "For half day leave, 3 days prior leave request is needed."
            );
          }
        } else if (
          leaveDuration === 1 &&
          leaveData.leaveTime === "Early Leave(2hours max)"
        ) {
          if (daysForLeave < 1) {
            warning.push(
              "For early leave, 1 day prior leave request is needed."
            );
          }
        } else if (
          (leaveDuration === 1 && leaveData.leaveTime === "Full day") ||
          leaveDuration === 2
        ) {
          if (daysForLeave < 8) {
            warning.push(
              "For 1-2 day leave, one week prior leave request is needed."
            );
          }
        } else if (leaveDuration < 6 && leaveDuration > 2) {
          if (daysForLeave < 16) {
            warning.push(
              "For 3-5 days leave, 15 days prior leave request is needed."
            );
          }
        } else if (leaveDuration > 6) {
          if (daysForLeave < 31) {
            warning.push(
              "For one week leave, one Month prior leave request is needed."
            );
          }
        }
      }
      setWarningArray(warning);
      if (warning.length) {
        setOpenDialog(true);
      } else {
        setIsLoading(true);

        try {
          const postData = JSON.stringify(leaveData);
          const response = await editLeave(postData);
          if (response.status === 201) {
            setIsLoading(false);
            const leave = response.data.leave;
            dispatch(setDetailLeave(leaveData));
            dispatch(setAllLeaveData(leave));
            dispatch(setisLeave(true));
            const typeOfLeave = LeaveTypeBalance?.filter(
              (state) => state._id === leaveData.leaveType
            );
            const data = {
              _id: leaveData ? leaveData._id : "",
              empId: leaveData ? leaveData.empId : "",
              employeeName: leaveData ? leaveData.employeeName : "",
              leaveType: {
                _id: leaveData
                  ? leaveData.leaveType._id === undefined
                    ? leaveData.leaveType
                    : leaveData.leaveType._id
                  : "",
                leaveType: typeOfLeave[0].leaveType,
              },
              reason: leaveData ? leaveData.reason : "",
              startDate: leaveData ? leaveData.startDate : "",
              endDate: leaveData ? leaveData.endDate : "",
              leaveTime: leaveData ? leaveData.leaveTime : "",
              submitDate: leaveData ? leaveData.submitDate : "",
              duration: leaveData ? leaveData?.duration : "",
            };
            dispatch(setToast(true));
            dispatch(setToastMsg(response.data.message));
            dispatch(setToastType("success"));
            backToView(data);
          }
        } catch (error) {
          setIsLoading(false);
          setDisplayError(errorMessage ?? "");
        }
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLeaveData((prevLeaveData) => ({
      ...prevLeaveData,
      [name]: value,
    }));
  };

  const checkLeaveTime = (endDate) => {
    if (endDate) {
      const isSameDate = leaveData.startDate === endDate;
      setLeaveTime(isSameDate);
    }
  };

  const handleSelectLeaveType = (event) => {
    setDisplayError("");
    const { value } = event.target;
    setLeaveData((prevLeaveData) => ({
      ...prevLeaveData,
      leaveType: value,
    }));
  };

  const handleSelectLeaveTime = (event) => {
    setDisplayError("");
    const { value } = event.target;
    setLeaveData((prevLeaveData) => ({
      ...prevLeaveData,
      leaveTime: value,
    }));
  };

  const onBackClick = () => {
    const leave = LeaveTypeBalance?.filter(
      (state) => state?._id === leaveData?.leaveType
    );
    setLeaveData((prevleaveData) => ({
      ...prevleaveData,
      leaveType: {
        ...prevleaveData.leaveType,
        leaveType: leave,
      },
    }));
    backToView(LeaveApp);
  };

  const reset = () => {
    setLeaveData({
      empId: leaveData.empId,
      employeeName: leaveData.employeeName,
      leaveType: leaveData.leaveType,
      reason: leaveData.reason,
      startDate: leaveData.startDate,
      endDate: leaveData.endDate,
      leaveTime: leaveData.leaveTime,
    });
    setError("");
  };

  const getleaveBalanaceData = async (data) => {
    if (data) {
      try {
        const employeeEmpId = adminLogin ? leaveData.empId : EmployeeId;
        const response = await getLeaveBalanace(data, employeeEmpId);
        if (response.status === 200 || response.status === 201) {
          const viewLeavesArray = response?.data?.viewLeavesArray;
          const leaveData = viewLeavesArray?.[0] || [];
          const leaveTypes = leaveData.map((item) => item?.leaveType);
          const balanceRow = leaveData.map((item) => item?.balance);
          const leaveBalanceMap = leaveTypes?.reduce(
            (acc, leaveType, index) => {
              acc[leaveType] = balanceRow[index];
              return acc;
            },
            {}
          );
          const updatedLeaveTypeBalance = LeaveTypeBalance?.map((item) => {
            const balance = leaveBalanceMap[item?.leaveType];
            return {
              ...item,
              balance: balance !== undefined ? balance : null,
            };
          });
          setLeaveTypeBalance(updatedLeaveTypeBalance);
        } else {
          setLeaveTypeBalance([]);
        }
      } catch (error) {
        console.error("Error fetching leave balance data:", error);
      }
    }
  };

  useEffect(() => {
    if ((adminLogin || EmployeeLogin) && leaveData?.empId !== "") {
      const date = formatReverseDate(new Date());
      getleaveBalanaceData(date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaveData]);

  useEffect(() => {
    dispatch(setEditLeave(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkLeaveTime(leaveData.endDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaveData.startDate, leaveData.endDate]);

  useEffect(() => {
    setLeaveApplication(LeaveApp);
  }, [LeaveApp]);

  return (
    <UpdateLeaveMainBox>
      {isLoading ? (
        <LoadingBox>
          <Loader />
        </LoadingBox>
      ) : (
        <Box>
          <Box>
            <TitleBox>
              <Typography sx={{ ...viewProfileTitle }}>
                <IconButton onClick={onBackClick}>
                  <ArrowBackIcon color="primary" />
                </IconButton>
                Update Leave
              </Typography>
            </TitleBox>
            <Box>
              <Box sx={{ padding: "0px 0px 0px 20px" }}>
                <Box sx={{ marginBottom: "15px" }}>
                  {adminLogin ? (
                    <>
                      <UpdateCustomTextField
                        disabled
                        value={leaveData.employeeName}
                        name="employeeName"
                        label={
                          <>
                            Employee Name
                            {renderAsterisk()}
                          </>
                        }
                      />

                      {error.employeeName && (
                        <Typography color="error">
                          {error.employeeName}
                        </Typography>
                      )}
                    </>
                  ) : null}

                  <UpdateCustomFormControl>
                    <InputLabel id="leaveType-label">
                      Leave Type{renderAsterisk()}
                    </InputLabel>
                    <Select
                      labelId="leaveType-label"
                      id="leaveType"
                      label={<>Leave Type{renderAsterisk()}</>}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        "& fieldset legend > span": {
                          paddingLeft: "2px",
                          paddingRight: "0px",
                        },
                      }}
                      value={leaveData.leaveType}
                      onChange={(event) => handleSelectLeaveType(event)}
                    >
                      {LeaveTypeBalance?.map((option) => (
                        <MenuItem key={option._id} value={option._id}>
                          {option?.balance
                            ? `${option?.leaveType} - ${option?.balance}`
                            : option?.leaveType}
                        </MenuItem>
                      ))}
                    </Select>
                    {error.leaveType && (
                      <Typography color="error">{error.leaveType}</Typography>
                    )}
                  </UpdateCustomFormControl>

                  <UpdateCustomTextField
                    isdarktheme={isDarkTheme ? "true" : "false"}
                    InputProps={{
                      startAdornment: <InputAdornment position="start" />,
                    }}
                    onChange={(event) => handleInputChange(event)}
                    value={formattedStartDate}
                    name="startDate"
                    label={<> From Date{renderAsterisk()}</>}
                    type="date"
                  />
                  {error.startDate && (
                    <Typography color="error">{error.startDate}</Typography>
                  )}
                  <UpdateCustomTextField
                    InputProps={{
                      startAdornment: <InputAdornment position="start" />,
                    }}
                    isdarktheme={isDarkTheme ? "true" : "false"}
                    onChange={(event) => {
                      handleInputChange(event);
                      checkLeaveTime(event.target.value);
                    }}
                    value={formattedEndDate}
                    name="endDate"
                    label={<> To Date{renderAsterisk()}</>}
                    type="date"
                  />
                  {error.endDate && (
                    <Typography color="error">{error.endDate}</Typography>
                  )}
                  {LeaveTime ? (
                    <>
                      <UpdateCustomFormControl>
                        <InputLabel id="leaveType-label">
                          Leave Duration{renderAsterisk()}
                        </InputLabel>
                        <Select
                          labelId="leaveType-label"
                          id="leaveTime"
                          label={
                            <>
                              Leave duration
                              {renderAsterisk()}
                            </>
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            "& fieldset legend > span": {
                              paddingLeft: "2px",
                              paddingRight: "0px",
                            },
                          }}
                          value={leaveData.leaveTime}
                          onChange={(event) => handleSelectLeaveTime(event)}
                        >
                          {leaveTimeOptions?.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                        {error.leaveTime && (
                          <Typography color="error">
                            {error.leaveTime}
                          </Typography>
                        )}
                      </UpdateCustomFormControl>
                    </>
                  ) : null}
                  <UpdateCustomTextField
                    name="reason"
                    label={<>Leave Reason{renderAsterisk()}</>}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={leaveData.reason}
                    onChange={(event) => handleInputChange(event)}
                    multiline
                    rows={4}
                  />
                  {error.reason && (
                    <Typography color="error">{error.reason}</Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <Typography sx={{ color: "red", marginTop: "20px" }}>
            {displayError}
          </Typography>
          <Box>
            <UpdateLeaveFooterBox>
              {" "}
              <CancelButton
                onClick={() => {
                  setOpenExitDialog(true);
                }}
              >
                Cancel
              </CancelButton>
              <ExitDialog
                onBackClick={onBackClick}
                open={openExitDialog}
                reset={reset}
                onClose={handleCloseCancelDialog}
              />
              <SaveButton variant="contained" onClick={saveLeaveRecord}>
                Update
              </SaveButton>
            </UpdateLeaveFooterBox>
            <CustomDialogleave
              open={openDialog}
              setOpenDialog={setOpenDialog}
              setIsLoading={setIsLoading}
              setDisplayError={setDisplayError}
              dialogHeading="Confirm Leave Request"
              leaveData={leaveData}
              dialogDescription={backToView}
              warning={warningArray}
              dialogIcon={
                <WarningAmberIcon
                  sx={{
                    color: "#DAA520",
                    fontSize: "30px",
                    backgroundColor: isDarkTheme ? "#121e2e" : "#ffffff",
                    borderBlockColor: "#ffffff",
                  }}
                />
              }
            />
          </Box>
        </Box>
      )}
    </UpdateLeaveMainBox>
  );
}

export default UpdateLeave;
