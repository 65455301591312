import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Close, DateRange as DateRangeIcon } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";

import DialogComponent from "../../ReusableComponents/DialogBoxComponents/DialogComponent";
import { MainBox, DOBTextField, CloseIconButton, InnerBoxOne, ConfirmDateRangeButton, CancelButton, ContentBox, DialogsActions } from "../../../Styles/EmployeeManagement/Employee/DateRangePickerDialogStyle";

const DateRangePickerDialog = ({
  open,
  setOpenDialog,
  dialogHeading,
  setDateRange,
}) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [error, setError] = useState({
    startDate: null,
    endDate: null,
  });
  let currentDate = new Date();
  currentDate.setHours(23, 59, 59, 59);

  const onClose = () => {
    setOpenDialog(false);
    setDateRange({});
    setStartDate(null);
    setEndDate(null);
    setError({ startDate: null, endDate: null });
  }

  const handleConfirmDateRange = () => {
    const errors = {};
    if (!startDate) {
      errors.startDate = "Start date is required.";
    }
    if (!endDate) {
      errors.endDate = "End date is required.";
    }
    if (Object.keys(errors).length > 0) {
      setError(errors);
    } else {
      setError({ startDate: null, endDate: null });
      setDateRange({ startDate, endDate });
      setStartDate(null);
      setEndDate(null);
      setOpenDialog(false);
    }
  };

  const renderForm = () => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <Box sx={{ background: isDarkTheme ? "#121e2e" : "#ffffff" }}>
          <Box>
            <CloseIconButton ismobile={isMobile ? "true" : "false"} onClick={onClose} isdarktheme={isDarkTheme ? "true" : "false"}>
              <Close />
            </CloseIconButton>
          </Box>
          <MainBox>
            <Box>
              <DialogTitle sx={{ fontWeight: "bold" }}>
                <InnerBoxOne>
                  <ContentBox>
                    <DateRangeIcon style={{ fontSize: "xx-large", fill: "#2c7be5" }} />
                  </ContentBox>
                  <Box>
                    <Box sx={{ color: isDarkTheme ? "#e0e0e0e3" : "#544f5a" }}>
                      {dialogHeading}
                    </Box>
                  </Box>
                </InnerBoxOne>
              </DialogTitle>
            </Box>
          </MainBox>
          <DialogContent sx={{ padding: isMobile ? "0px 24px 15px" : "20px 24px" }}>
            <Box sx={{ display: "flex", padding: isMobile ? "0px 24px" : "20px 24px", justifyContent: isMobile && "center", flexDirection: isMobile ? "column" : "row" }}>
              <Box>
                {isMobile ? <DOBTextField
                  label="Start Date"
                  isdarktheme={isDarkTheme ? "true" : "false"}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                      </InputAdornment>
                    ),
                  }}
                  name="startDate"
                  value={startDate}
                  onChange={(e) => {
                    setError({ ...error, startDate: null });
                    setStartDate(e.target.value)
                  }}
                  type="date"
                /> :
                  <DatePicker
                    label="Start Date"
                    sx={{
                      marginRight: "7px",
                      ".MuiInputLabel-root": {
                        color: isDarkTheme ? "#e0e0e0cc !important" : "#5e6e82 !important",
                      },
                      ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                        borderColor: isDarkTheme ? "#e0e0e0cc !important" : "#5e6e82 !important"
                      },
                      ".MuiOutlinedInput-input": {
                        color: isDarkTheme ? "#e0e0e0cc !important" : "#5e6e82 !important",
                      },
                    }}
                    value={startDate}
                    onChange={(date) => {
                      setError({ ...error, startDate: null });
                      setStartDate(date)
                    }}
                    slotProps={{
                      openPickerButton: {
                        color: "primary",
                      },
                    }}
                  />}
                <Typography color="error">{error.startDate}</Typography>
              </Box>
              <Box>
                {isMobile ? <DOBTextField
                  label="End Date"
                  isdarktheme={isDarkTheme ? "true" : "false"}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {/* Add your icon here if needed */}
                      </InputAdornment>
                    ),
                  }}
                  name="endDate"
                  value={endDate}
                  onChange={(e) => {
                    setError({ ...error, endDate: null });
                    setEndDate(e.target.value)
                  }}
                  type="date"
                /> : <DatePicker
                  sx={{
                    ".MuiInputLabel-root": {
                      color: isDarkTheme ? "#e0e0e0cc !important" : "#5e6e82 !important",
                    },
                    ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                      borderColor: isDarkTheme ? "#e0e0e0cc !important" : "#5e6e82 !important"
                    },
                    ".MuiOutlinedInput-input": {
                      color: isDarkTheme ? "#e0e0e0cc !important" : "#5e6e82 !important",
                    },
                  }}
                  label="End Date"
                  value={endDate}
                  onChange={(date) => {
                    setError({ ...error, endDate: null });
                    setEndDate(date)
                  }}
                  slotProps={{
                    openPickerButton: {
                      color: "primary",
                    },
                  }}
                />}
                <Typography color="error">{error.endDate}</Typography>
              </Box>
            </Box>
          </DialogContent>
          <DialogsActions>
            <CancelButton
              onClick={onClose}
              isdarktheme={isDarkTheme ? "true" : "false"}
            >
              Cancel
            </CancelButton>
            <ConfirmDateRangeButton
              onClick={handleConfirmDateRange}
              variant="contained"
              component={Link}
              color="primary"
            >
              Confirm Date Range
            </ConfirmDateRangeButton>
          </DialogsActions>
        </Box>
      </LocalizationProvider>
    )
  }

  return (
    <Box>
      <DialogComponent
        open={open}
        onClose={onClose}
        maxWidth="md"
        className="DateRangePickerDialog"
        renderChildern={renderForm}
      />
    </Box>
  );
};

export default DateRangePickerDialog;