import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Tooltip,
  Zoom,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from "@mui/icons-material";

import { setEditLeave } from "../../Slices/selectedActive";
import LeaveDataGrid from "../AdminDashboard/Tables/LeaveDataGrid";
import usePermissions from "../RolePermission/usePermissions";
import {
  MainBox,
  InnerBoxOne,
  UpcomingLeavesButton,
  OldLeavesButton,
  LeaveButtonBox,
  SliderBox,
  InnerSliderBox,
} from "../../Styles/LeaveManagement/LeaveManagementStyle";

const LeaveManagement = ({ EmpId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hasPermission } = usePermissions();
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);

  const [selectedButton, setSelectedButton] = useState("Upcoming Leaves");
  const isMobile = useMediaQuery("(max-width:600px)");
  const scrollRef = useRef(null);

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  const handleAddLeave = () => {
    navigate("/leave/add");
    dispatch(setEditLeave(false));
  };

  const getScrollAmount = () => {
    if (scrollRef.current) {
      return scrollRef.current.offsetWidth * 1.05;
    }
    return 700;
  };

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: -getScrollAmount(),
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: getScrollAmount(),
        behavior: "smooth",
      });
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <MainBox>
        {EmpId ? (
          <></>
        ) : (
          <InnerBoxOne bottom={0}>
            {isMobile ? (
              <>
                <LeaveButtonBox>
                  {hasPermission("Add Leave") && (
                    <Tooltip
                      title="Add Leave Details"
                      arrow
                      disableInteractive
                      TransitionComponent={Zoom}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddLeave}
                      >
                        + Add Leave
                      </Button>
                    </Tooltip>
                  )}
                </LeaveButtonBox>
                <SliderBox
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexGrow={1}
                  overflow="hidden"
                  position="relative"
                >
                  <IconButton onClick={scrollLeft} style={{ marginRight: 12,
                      color: isDarkTheme === "true" ? "#e0e0e0cc" : "#5e6e82",
                   }}>
                    <ChevronLeftIcon />
                  </IconButton>
                  <InnerSliderBox
                    ref={scrollRef}
                    display="flex"
                    flexDirection="row"
                    overflowX="auto"
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      flexShrink="0"
                      minWidth="100%"
                      justifyContent="center"
                    >
                      {hasPermission("Upcoming Leaves") && (
                        <UpcomingLeavesButton
                          onClick={() => handleButtonClick("Upcoming Leaves")}
                          selectedbutton={selectedButton}
                        >
                          Upcoming Leaves
                        </UpcomingLeavesButton>
                      )}
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      flexShrink="0"
                      minWidth="100%"
                      justifyContent="center"
                    >
                      {hasPermission("Old Leaves") && (
                        <OldLeavesButton
                          onClick={() => handleButtonClick("Old Leaves")}
                          selectedbutton={selectedButton}
                        >
                          Old Leaves
                        </OldLeavesButton>
                      )}
                    </Box>
                  </InnerSliderBox>
                  <IconButton onClick={scrollRight} style={{ marginLeft: 12,
                      color: isDarkTheme === "true" ? "#e0e0e0cc" : "#5e6e82",
                   }}>
                    <ChevronRightIcon />
                  </IconButton>
                </SliderBox>
              </>
            ) : (
              <>
                <Box>
                  {hasPermission("Upcoming Leaves") && (
                    <UpcomingLeavesButton
                      onClick={() => handleButtonClick("Upcoming Leaves")}
                      selectedbutton={selectedButton}
                    >
                      Upcoming Leaves
                    </UpcomingLeavesButton>
                  )}
                  {hasPermission("Old Leaves") && (
                    <OldLeavesButton
                      onClick={() => handleButtonClick("Old Leaves")}
                      selectedbutton={selectedButton}
                    >
                      Old Leaves
                    </OldLeavesButton>
                  )}
                </Box>
                {hasPermission("Add Leave") && (
                  <Box sx={{ paddingRight: "15px" }}>
                    <Tooltip
                      title="Add Leave Details"
                      arrow
                      disableInteractive
                      TransitionComponent={Zoom}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddLeave}
                      >
                        + Add Leave
                      </Button>
                    </Tooltip>
                  </Box>
                )}
              </>
            )}
          </InnerBoxOne>
        )}
        <Box
          sx={{
            width: "100%",
          }}
        >
          {EmpId ? <LeaveDataGrid EmpId={EmpId} /> : <></>}
          {!EmpId &&
          selectedButton === "Upcoming Leaves" &&
          hasPermission("Upcoming Leaves") ? (
            <LeaveDataGrid selectedButton={selectedButton} />
          ) : selectedButton === "Old Leaves" && hasPermission("Old Leaves") ? (
            <LeaveDataGrid selectedButton={selectedButton} />
          ) : (
            !EmpId && (
              <p>You do not have permission to view {selectedButton} data.</p>
            )
          )}
        </Box>
      </MainBox>
      <br />
    </Box>
  );
};

export default LeaveManagement;
