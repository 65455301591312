import dayjs from "dayjs";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardDoubleArrowDown as KeyboardDoubleArrowDownIcon,
  KeyboardDoubleArrowUp as KeyboardDoubleArrowUpIcon,
  DragHandle as DragHandleIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  Check as CheckIcon,
  Clear as ClearIcon,
  Bookmark as BookmarkIcon,
  FiberManualRecord as FiberManualRecordIcon,
  Bolt as BoltIcon
} from "@mui/icons-material";
import {
  TextField,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Box,
  Tooltip,
  tooltipClasses
} from "@mui/material";

import { taskTypeOptions } from "../Constant/constant";
import { ClearIconButton } from "../../Styles/TaskManagement/TaskSprintStyle";

export const formatDateSlashTo_ = (dateString) => {
  const formattedDate = dayjs(dateString, "DD/MM/YYYY").format("DD-MM-YYYY");
  return formattedDate;
};

export const formatTime = (seconds) => {
  if (seconds === 0) {
    return `00:00:00`;
  }
  if (seconds === undefined) {
    return `00:00:00`;
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60); // Round down to remove decimal part
  const remainingSeconds = Math.floor(seconds % 60);
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

export const formatTimeWithoutSeconds = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  return `${formattedHours}:${formattedMinutes}`;
};

export const formatDate = (date) => {
  if (!date) return "";
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};
export const formatReverseDate = (date) => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

export const extractTime = (timestamp) => {
  if (timestamp === 0) {
    return "Working...";
  }
  const dateTime = new Date(timestamp);
  const time = dateTime.toLocaleTimeString([], {
    hour12: true,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }); // Extract time part
  return time;
};

export const extractTimeWithZero = (timestamp) => {
  if (timestamp === 0) {
    return "00:00 Hrs";
  }
  const dateTime = new Date(timestamp);
  const time = dateTime.toLocaleTimeString([], {
    hour12: true,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }); // Extract time part
  return time;
};

export const extractTDate = (timestamp) => {
  const dateObj = new Date(timestamp); // Convert milliseconds to Date object
  const day = dateObj.getDate().toString().padStart(2, "0"); // Get day and pad with leading zero if necessary

  // Array of month names
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const monthIndex = dateObj.getMonth(); // Get month index (zero-based)
  const month = monthNames[monthIndex]; // Get month name from array

  const year = dateObj.getFullYear(); // Get full year
  const formattedDate = `${month} ${day} ${year}`; // Format date as dd-month-yyyy
  return formattedDate;
};
export const extractDate = (timestamp) => {
  const dateObj = new Date(timestamp); // Convert milliseconds to Date object
  const day = dateObj.getDate().toString().padStart(2, "0"); // Get day and pad with leading zero if necessary

  // Array of month names
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const monthIndex = dateObj.getMonth(); // Get month index (zero-based)
  const month = monthNames[monthIndex]; // Get month name from array

  const year = dateObj.getFullYear(); // Get full year
  const formattedDate = `${day}-${month}-${year}`; // Format date as dd-month-yyyy
  return formattedDate;
};

export const extractDTime = (timestamp) => {
  const dateObj = new Date(timestamp); // Convert milliseconds to Date object
  const hours = dateObj.getHours().toString().padStart(2, "0") % 12 || 12; // Get hours in 12-hour format
  const hour = hours.toString().padStart(2, "0"); // Get hours in 12-hour format
  const minutes = dateObj.getMinutes().toString().padStart(2, "0"); // Get minutes and pad with leading zero if necessary
  const amPm = dateObj.getHours() < 12 ? "AM" : "PM"; // Get AM/PM
  const formattedTime = `${hour}:${minutes} ${amPm}`; // Format time as HH:MM AM/PM
  return formattedTime;
};

export const extractDTimeWithOutAMPM = (timestamp) => {
  const dateObj = new Date(timestamp); // Convert milliseconds to Date object
  const hours = dateObj.getHours().toString().padStart(2, "0"); // Get hours in 12-hour format
  const hour = hours.toString().padStart(2, "0"); // Get hours in 12-hour format
  const minutes = dateObj.getMinutes().toString().padStart(2, "0"); // Get minutes and pad with leading zero if necessary
  const formattedTime = `${hour}:${minutes}`; // Format time as HH:MM
  return formattedTime;
};

export const formatBirthDayDate = (dateString) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const today = new Date();
  const birthday = new Date(dateString);

  const todayDay = today.getDate();
  const todayMonth = today.getMonth();

  const birthdayDay = birthday.getDate();
  const birthdayMonth = birthday.getMonth();

  if (todayDay === birthdayDay && todayMonth === birthdayMonth) {
    return "Today";
  } else if (todayDay + 1 === birthdayDay && todayMonth === birthdayMonth) {
    return "Tomorrow";
  } else {
    const day = String(birthdayDay).padStart(2, "0");
    const month = months[birthdayMonth];
    return `${day} ${month}`;
  }
};

export const dateWithMonthName = (dateString) => {
  if (dateString !== undefined) {
    const parts = dateString.split("-");
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Adjust month (0-indexed)
    const year = parseInt(parts[2], 10);
    const currentDate = new Date(day, month, year);
    const formattedDate = currentDate.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
    return formattedDate;
  }
  return;
};

export const capitalizeFirstLetter = (string) => {
  if (string && string.length > 0) {
    return string
      .split(" ")
      .map((word) => {
        if (word.length > 0) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        } else {
          return word;
        }
      })
      .join(" ");
  } else {
    return string;
  }
};

export function formatDateinUTC(date) {
  // Subtract 5 hours and 30 minutes to adjust to Indian Standard Time (IST)
  const indianTime = new Date(
    date.getTime() - 5 * 60 * 60 * 1000 - 30 * 60 * 1000
  );

  const day = indianTime.getUTCDate().toString().padStart(2, "0");
  const month = (indianTime.getUTCMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
  const year = indianTime.getUTCFullYear();

  return `${day}-${month}-${year}`;
}

export const getIcon = (name) => {
  switch (name) {
    case "Highest":
      return <KeyboardDoubleArrowUpIcon sx={{ color: "#E56717 !important" }} />;
    case "High":
      return <KeyboardArrowUpIcon sx={{ color: "#E56717" }} />;
    case "Medium":
      return <DragHandleIcon sx={{ color: "var(--totalHrs-color)" }} />;
    case "Low":
      return <KeyboardArrowDownIcon sx={{ color: "var(--primary-color)" }} />;
    case "Lowest":
      return (
        <KeyboardDoubleArrowDownIcon sx={{ color: "var(--primary-color)" }} />
      );
    default:
      // Handle cases where `name` does not match any of the above cases
      return null;
  }
};

export const sortingTask = (employeeTaskData) => {
  const statusOrder = [
    "To-Do",
    "In Progress",
    "Completed",
    "Hold",
    "Cancelled",
  ];

  const sortedData = employeeTaskData.sort((a, b) => {
    // Compare dueDate in descending order
    const dateA = a.dueDate ? new Date(a.dueDate) : new Date(0);
    const dateB = b.dueDate ? new Date(b.dueDate) : new Date(0);
    const dateComparison = dateB - dateA;
    if (dateComparison !== 0) return dateComparison;

    // If due dates are equal, compare status
    return statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
  });

  return sortedData;
};

const iconStyles = {
  Story: { color: "#ffffff", backgroundColor: "#63ba3c" },
  Tasks: { color: "#ffffff", backgroundColor: "#4bade8" },
  Bugs: { color: "#ffffff", backgroundColor: "#e5493a" },
  Epic: { color: "#ffffff", backgroundColor: "#904ee2" },
  Default: { color: "#ffffff", backgroundColor: "#E07B39" },
};

export const TypeCommon = ({
  formData,
  handleChange,
  typeOptions,
  textfieldSelectStyle,
  subTask,
  isDarkTheme,
  sprint,
}) => {
  return (
    <>
      <TextField
        select
        fullWidth
        label={textfieldSelectStyle ? "" : "Type"}
        name="type"
        value={formData?.type}
        onChange={handleChange}
        variant="outlined"
        sx={{
          "&.MuiFormControl-root": {
            width: subTask ? "4%" : "100%",
            maxWidth: subTask ? "4%" : "100%",
          },
          "& .MuiOutlinedInput-root": {
            width: "100%",
            border: subTask && "",
          },
          "& .MuiOutlinedInput-input": {
            padding: textfieldSelectStyle
              ? taskTypeOptions?.includes(formData.type)
                ? "7.5px 14px"
                : subTask
                ? "3.5px 9px"
                : "3.5px 14px"
              : sprint
              ? formData.type !== ""
                ? "5px 8px"
                : "9px 10px"
              : "12.5px 14px",
          },
          "& .MuiSelect-select": {
            fontWeight: 600,
          },
          "& .MuiSelect-select .MuiListItemText-root": {
            display: subTask ? "none" : "block",
            color: isDarkTheme ? "#e0e0e0e3" : "var(--primary-text-color)",
          },
          "& .MuiSelect-icon ": {
            display: subTask ? "none" : "block",
            color: isDarkTheme
              ? "#e0e0e0e3 !important"
              : "var(--primary-text-color)",
          },
          "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
            display: subTask ? "none" : "block",
          },
        }}
        InputLabelProps={{
          // shrink: sprint ? false : true,
          shrink: true,
        }}
        InputProps={{
          endAdornment:
            formData?.type && sprint ? (
              <ClearIconButton
                onClick={() => {
                  handleChange({ target: { name: "type", value: "" } });
                }}
                isDarkTheme={isDarkTheme}
              >
                <ClearIcon />
              </ClearIconButton>
            ) : null,
        }}
      >
        {typeOptions?.map((option) => {
          let IconComponent;
          let style;
          switch (option?.taskTypeName) {
            case "Story":
              IconComponent = BookmarkIcon;
              style = iconStyles.Story;
              break;
            case "Tasks":
              IconComponent = CheckIcon;
              style = iconStyles.Tasks;
              break;
            case "Bugs":
              IconComponent = FiberManualRecordIcon;
              style = iconStyles.Bugs;
              break;
            case "Epic":
              IconComponent = BoltIcon;
              style = iconStyles.Epic;
              break;
            default:
              IconComponent = AssignmentTurnedInIcon;
              style = iconStyles.Default;
          }
          return (
            <MenuItem key={option?._id} value={option?._id}>
              <ListItemIcon>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "9px",
                  }}
                >
                  <Avatar
                    sx={{
                      width: sprint ? 18 : 22,
                      height: sprint ? 18 : 22,
                      backgroundColor: style?.backgroundColor,
                      color: style?.color,
                    }}
                    variant="rounded"
                  >
                    <IconComponent sx={{ fontSize: sprint ? 14 : 16 }} />
                  </Avatar>
                </Box>
                <ListItemText primary={option?.taskTypeName} />
              </ListItemIcon>
            </MenuItem>
          );
        })}
      </TextField>
    </>
  );
};

export const TaskTypeCell = ({
  taskTypeName,
  height = 22,
  width = 22,
  fontSize = 16,
}) => {
  let IconComponent;
  let style;
  switch (taskTypeName) {
    case "Story":
      IconComponent = BookmarkIcon;
      style = iconStyles.Story;
      break;
    case "Tasks":
      IconComponent = CheckIcon;
      style = iconStyles.Tasks;
      break;
    case "Bugs":
      IconComponent = FiberManualRecordIcon;
      style = iconStyles.Bugs;
      break;
    case "Epic":
      IconComponent = BoltIcon;
      style = iconStyles.Epic;
      break;
    default:
      IconComponent = AssignmentTurnedInIcon;
      style = iconStyles.Default;
  }
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Tooltip title={taskTypeName} arrow>
        <Avatar
          sx={{
            width: width,
            height: height,
            backgroundColor: style?.backgroundColor,
            color: style?.color,
          }}
          variant="rounded"
        >
          <IconComponent sx={{ fontSize: fontSize }} />
        </Avatar>
      </Tooltip>
    </Box>
  );
};

export const renderAsterisk = () => {
  return <span style={{ color: "orange", fontSize: "20px" }}>*</span>;
};

export const filterEventsByDateAndPermission = (
  events,
  selectedButton,
  hasPermission
) => {
  const upcomingEvents = [];
  const completedEvents = [];
  events
    ?.sort((a, b) => new Date(a.date) - new Date(b.date))
    ?.forEach((event) => {
      const eventDate = new Date(event?.date);
      if (new Date(eventDate).setHours(23, 59, 59, 59) >= new Date()) {
        upcomingEvents?.push(event);
      } else {
        completedEvents?.push(event);
      }
    });
  if (
    hasPermission("Upcoming Events") &&
    selectedButton === "Upcoming Events"
  ) {
    return upcomingEvents;
  }
  if (
    hasPermission("Completed Events") &&
    selectedButton === "Completed Events"
  ) {
    return completedEvents;
  }
  return [];
};

const stringAvatar = (name = "") => {
  const [firstName = "", lastName = ""] = name.trim().split(" ");
  return {
    children: `${firstName[0] || ""}${lastName[0] || ""}`.toUpperCase(),
  };
};

const RenderEmployeeProfile = ({
  params,
  setSelectEmpId,
  selectEmpId,
  setDialogSelectEmp,
}) => {
  return (
    <Tooltip
      title={params?.FirstName}
      arrow
      placement="bottom"
      slotProps={{
        popper: {
          sx: {
            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
              {
                marginTop: "0px",
              },
            [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
              {
                marginBottom: "0px",
              },
            [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
              {
                marginLeft: "0px",
              },
            [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
              {
                marginRight: "0px",
              },
          },
        },
      }}
    >
      <Avatar
        sx={{
          background: "rgb(44, 123, 229)",
          color: "#FFFFFF",
          width: 32,
          height: 32,
          fontSize: "80%",
          marginLeft: "-6px",
          border: selectEmpId?.includes(params?.empId)
            ? "3px solid var(--secondary-color-H)"
            : "none",
        }}
        {...stringAvatar(params?.FirstName)}
        onClick={(event) => {
          event.stopPropagation();
          setSelectEmpId((prevData) => {
            if (prevData?.includes(params?.empId)) {
              return prevData?.filter((id) => id !== params?.empId);
            } else {
              return [...prevData, params?.empId];
            }
          });
          setDialogSelectEmp((prevData) => {
            if (prevData?.includes(params?.empId)) {
              return prevData?.filter((id) => id !== params?.empId);
            }
          });
        }}
      />
    </Tooltip>
  );
};

export const EmployeeAvatarGroup = ({
  employeeData = [],
  maxVisible,
  setSelectEmpId,
  selectEmpId,
  setEmpDialog,
  setDialogSelectEmp,
}) => {
  const visibleEmployees = employeeData?.slice(0, maxVisible);
  const remainingCount = employeeData?.length - maxVisible;
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {visibleEmployees?.map((employee, index) => (
        <RenderEmployeeProfile
          key={employee?._id || index}
          params={employee || "NA"}
          setSelectEmpId={setSelectEmpId}
          selectEmpId={selectEmpId}
          setDialogSelectEmp={setDialogSelectEmp}
        />
      ))}
      {remainingCount > 0 && (
        <Tooltip title={`+${remainingCount} more`} arrow>
          <Avatar
            sx={{
              background: "gray",
              color: "#fff",
              width: 32,
              height: 32,
              fontSize: "80%",
              marginLeft: "-6px",
              border: "1px solid gray",
            }}
            onClick={() => {
              setEmpDialog(true);
            }}
          >
            +{remainingCount}
          </Avatar>
        </Tooltip>
      )}
    </Box>
  );
};
