import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Checkbox,
  DialogContent,
  DialogTitle,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";
import {
  Close,
  AddHome as AddHomeIcon,
  PlaylistAddCircle as PlaylistAddCircleIcon,
  DeleteSweep as DeleteSweepIcon,
  EditNote as EditNoteIcon,
} from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";

import {
  setToast,
  setToastMsg,
  setToastType,
} from "../../Slices/toastSlice";
import { deprecateLeaveType } from '../../Slices/leaveData';
import { renderAsterisk } from "../ReusableComponents/UtilityFunctions";
import DialogComponent from "../ReusableComponents/DialogBoxComponents/DialogComponent";
import { deleteHoliday, editHoliday, upsertHoliday } from "../../Services/HolidayServices";
import { deleteLeaveType, editLeaveType, upsertLeaveType } from "../../Services/LeaveManageServices";
import { errorMessage } from "../../Services/axiosInstance";
import { MainBox, CustomTextField, HeaderBox, DateTextField, InnerBoxOne, CustomButton, CancelButton, CloseIconButton, DialogsActions, FormControlLabels, InnerBoxFive, InnerBoxFour, ErrorTypography, InnerBoxThree, InnerBoxTwo, TitleBox, CustomDatePicker } from "../../Styles/CompanyAndLeavePolicy/DialogOfCompanyAndLeavePolicyStyle"

const DialogOfCompanyAndLeavePolicy = ({
  open,
  setOpenDialogBox,
  buttonSelected,
  buttonName,
  selectedRecordId,
  FormattedLeavesData,
  FormattedFestivalsData,
  festivalEditRecord,
  leaveEditRecord,
  festivalDeleteRecord,
  leaveDeleteRecord,
  setFestivalEditRecord,
  setFestivalDeleteRecord,
  setLeaveEditRecord,
  setLeaveDeleteRecord,
  setLeavesData,
  setFestivalsData
}) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const dispatch = useDispatch();
  const selectedFestivalData = FormattedFestivalsData
    ? FormattedFestivalsData[selectedRecordId - 1]
    : null;
  const selectedLeaveData = FormattedLeavesData
    ? FormattedLeavesData[selectedRecordId - 1]
    : null;

  const [_id, set_id] = useState("");
  const [festival, setFestival] = useState("");
  const [festivalDate, setFestivalDate] = useState("");
  const [festivalDay, setFestivalDay] = useState("");
  const [leaveType, setLeaveType] = useState("");
  const [leaveNo, setLeaveNo] = useState("");
  const [enCash, setEnCash] = useState(false);
  const [deprecateLeave, setDeprecateLeave] = useState(false);
  const [displayError, setDisplayError] = useState("");
  const [error, setError] = useState([]);

  const dateAndDaySetter = (selectedDate) => {
    const formattedDate = dayjs(selectedDate).format("YYYY-MM-DD");
    const dayOfWeek = dayjs(selectedDate).format("dddd");
    setFestivalDate(formattedDate);
    setFestivalDay(dayOfWeek);
  };

  const resetForm = () => {
    setError([]);
    setDisplayError("");
    setOpenDialogBox(false);
  }

  const AddHolidayAction = async () => {
    const errors = [];
    if (!festival) {
      errors.festival = "Please Add Festival!";
      setError(errors);
      return;
    }
    if (!festivalDate) {
      errors.festivalDate = "Please Add Festival Date!";
      setError(errors);
      return;
    }
    try {
      const postData = JSON.stringify({ festival, festivalDate, festivalDay });
      const response = await upsertHoliday(postData)
      if (response.status === 200 || response.status === 201) {
        setFestivalsData(prevData => [...prevData, response?.data?.recordAdded]);
        resetHolidayTypeField();
        resetForm()
        dispatch(setToast(true));
        dispatch(setToastMsg(response.data.message ?? "Festival added."));
        dispatch(setToastType("success"));
      }
    } catch (error) {
      setDisplayError(errorMessage ?? "")
    }
  };

  const AddLeaveTypeAction = async () => {
    const errors = [];
    if (!leaveType) {
      errors.leaveType = "Please Add Leave Type!";
      setError(errors);
      return;
    }
    if (!leaveNo) {
      errors.leaveNo = "Please Add Number Of Leave!";
      setError(errors);
      return;
    }
    try {
      const postData = JSON.stringify({ leaveType, leaveNo, enCash, deprecateLeave });
      const response = await upsertLeaveType(postData);
      if (response.status === 200) {
        if (deprecateLeave) {
          dispatch(deprecateLeaveType(response.data._id));
        } else {
          setLeavesData(prevData => [...prevData, response?.data?.recordAdded]);
        }
        resetLeaveTypeField();
        resetForm()
        dispatch(setToast(true));
        dispatch(setToastMsg(response.data.message ?? "Leave Type added."));
        dispatch(setToastType("success"));
      }
    } catch (error) {
      setDisplayError(errorMessage ?? "")
    }
  };

  const EditHolidayAction = async () => {
    const errors = [];
    if (!festival) {
      errors.festival = "Please Add Festival!";
      setError(errors);
      return;
    }
    if (!festivalDate) {
      errors.festivalDate = "Please Add Festival Date!";
      setError(errors);
      return;
    }

    try {
      const postData = JSON.stringify({ _id, festival, festivalDate, festivalDay });
      const response = await editHoliday(postData);
      if (response.status === 200) {
        setFestivalsData(prevData => {
          const index = prevData?.findIndex(item => item?._id === response?.data?.recordUpdated?._id);
          if (index !== -1) {
            const updatedLeavesData = [...prevData];
            updatedLeavesData[index] = response?.data?.recordUpdated;
            return updatedLeavesData;
          } else {
            return prevData;
          }
        });
        resetHolidayTypeField();
        setFestivalEditRecord(false);
        resetForm()
        dispatch(setToast(true));
        dispatch(setToastMsg(response.data.message ?? "Festival Updated."));
        dispatch(setToastType("success"));
      }
    } catch (error) {
      setDisplayError(errorMessage ?? "")
    }
  };

  const EditLeaveTypeAction = async () => {
    const errors = [];
    if (!leaveType) {
      errors.leaveType = "Please Add Leave Type!";
      setError(errors);
      return;
    }
    if (!leaveNo) {
      errors.leaveNo = "Please Add Number Of Leave!";
      setError(errors);
      return;
    }

    try {
      const postData = JSON.stringify({ _id, leaveType, leaveNo, enCash, deprecateLeave });
      const response = await editLeaveType(postData);
      if (response.status === 200 || response.status === 201) {
        if (deprecateLeave) {
          dispatch(deprecateLeaveType(_id));
        } else {
          setLeavesData(prevData => {
            const index = prevData?.findIndex(item => item?._id === response?.data?.recordUpdated?._id);
            if (index !== -1) {
              const updatedLeavesData = [...prevData];
              updatedLeavesData[index] = response?.data?.recordUpdated;
              return updatedLeavesData;
            } else {
              return prevData;
            }
          });
        }
        resetLeaveTypeField();
        setLeaveEditRecord(false);
        resetForm();
        dispatch(setToast(true));
        dispatch(setToastMsg(response.data.message ?? "Leave record Updated."));
        dispatch(setToastType("success"));
      }
    } catch (error) {
      setDisplayError(errorMessage ?? "")
    }
  };

  const DeleteHolidayAction = async () => {
    try {
      const response = await deleteHoliday(_id);
      if (response.status === 200) {
        setFestivalsData(prevData => prevData?.filter(item => item?._id !== _id));
        resetHolidayTypeField();
        setFestivalDeleteRecord(false);
        resetForm();
        dispatch(setToast(true));
        dispatch(setToastMsg(response.data.message));
        dispatch(setToastType("success"));
      }
    } catch (error) {
      setDisplayError(errorMessage ?? "")
    }
  };

  const DeleteLeaveTypeAction = async () => {
    try {
      const response = await deleteLeaveType(_id)
      if (response.status === 200) {
        resetLeaveTypeField();
        setLeaveDeleteRecord(false);
        resetForm();
        dispatch(setToast(true));
        dispatch(setToastMsg(response.data.message));
        dispatch(setToastType("success"));
      }
    } catch (error) {
      setDisplayError(errorMessage ?? "")
    }
  };

  const resetLeaveTypeField = () => {
    set_id("");
    setLeaveType("");
    setLeaveNo("");
    setEnCash("");
  }

  const resetHolidayTypeField = () => {
    set_id("");
    setFestival("");
    setFestivalDate("");
    setFestivalDay("");
  }

  const onClose = () => {
    resetLeaveTypeField();
    resetHolidayTypeField();
    setDisplayError("");
    setError([]);
    if (buttonName === "Edit Leave Type") {
      setLeaveEditRecord(false);
    }
    if (buttonName === "Delete Leave Type") {
      setLeaveDeleteRecord(false);
    }
    if (buttonName === "Edit Holiday") {
      setFestivalEditRecord(false);
    }
    if (buttonName === "Delete Holiday") {
      setFestivalDeleteRecord(false);
    }
    setOpenDialogBox(false);
  }

  const renderForm = () => {
    return (<>
      <MainBox isdarktheme={isDarkTheme ? "true" : "false"}>
        <HeaderBox>
          <InnerBoxOne>
            <DialogTitle sx={{ fontWeight: "bold", padding: "0px 0px", }}>
              <InnerBoxTwo>
                <TitleBox>
                  {buttonName === "Add Holiday" ? (
                    <AddHomeIcon
                      color="success"
                      style={{ fontSize: "xx-large" }}
                    />
                  ) : null}
                  {buttonName === "Edit Holiday" ? (
                    <EditNoteIcon
                      color="warning"
                      style={{ fontSize: "xx-large" }}
                    />
                  ) : null}
                  {buttonName === "Delete Holiday" ? (
                    <DeleteSweepIcon
                      color="error"
                      style={{ fontSize: "xx-large" }}
                    />
                  ) : null}
                  {buttonName === "Add Leave Type" ? (
                    <PlaylistAddCircleIcon
                      color="success"
                      style={{ fontSize: "xx-large" }}
                    />
                  ) : null}
                  {buttonName === "Edit Leave Type" ? (
                    <EditNoteIcon
                      color="warning"
                      style={{ fontSize: "xx-large" }}
                    />
                  ) : null}
                  {buttonName === "Delete Leave Type" ? (
                    <DeleteSweepIcon
                      color="error"
                      style={{ fontSize: "xx-large" }}
                    />
                  ) : null}
                </TitleBox>
                <Box>
                  <Box sx={{ color: isDarkTheme ? "#e0e0e0e3" : "#544f5a" }}>
                    {buttonName}
                  </Box>
                </Box>
              </InnerBoxTwo>
            </DialogTitle>
          </InnerBoxOne>
          <Box>
            <CloseIconButton
              onClick={onClose}
              isdarktheme={isDarkTheme ? "true" : "false"}
              ismobile={isMobile ? "true" : "false"}
            >
              <Close />
            </CloseIconButton>
          </Box>
        </HeaderBox>
        <DialogContent sx={{ padding: isMobile ? "8px 2px" : "15px 2px 5px 2px" }}>
          <InnerBoxThree isdarktheme={isDarkTheme ? "true" : "false"}>
            {buttonSelected === "Add Holiday" ? (
              <>
                <CustomTextField
                  isdarktheme={isDarkTheme ? "true" : "false"}
                  fullWidth
                  name="festival"
                  value={festival}
                  onChange={(event) => {
                    setFestival(event.target.value);
                    setDisplayError("");
                    setError([]);
                  }}
                  variant="outlined"
                  disabled={festivalDeleteRecord}
                  label={
                    <>
                      Festival
                      {renderAsterisk()}
                    </>
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </>
            ) : null}
            {buttonSelected === "Add Leave Type" ? (
              <>
                <CustomTextField
                  isdarktheme={isDarkTheme ? "true" : "false"}
                  fullWidth
                  name="leaveType"
                  value={leaveType}
                  onChange={(event) => {
                    setLeaveType(event.target.value);
                    setDisplayError("");
                    setError([]);
                  }}
                  variant="outlined"
                  disabled={leaveDeleteRecord}
                  label={
                    <>
                      Leave Type
                      {renderAsterisk()}
                    </>
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    "& fieldset legend > span": {
                      paddingLeft: "2px",
                      paddingRight: "0px",
                    },
                  }}
                ></CustomTextField>
              </>
            ) : null}
          </InnerBoxThree>
          <ErrorTypography>
            {error.festival}
          </ErrorTypography>
          <ErrorTypography>
            {error.leaveType}
          </ErrorTypography>
          <InnerBoxFour isdarktheme={isDarkTheme ? "true" : "false"}>
            {buttonSelected === "Add Holiday" ? (
              <>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    '@media (min-width: 600px)': {
                      flexDirection: 'row',
                    },
                  }}
                >
                  <Box sx={{ width: "100%", '@media (min-width: 600px)': { width: "65%" } }}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="en-gb"
                    >
                      <DemoContainer components={["DatePicker"]}>
                        {
                          isMobile ? (
                            <DateTextField
                              isdarktheme={isDarkTheme ? "true" : "false"}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                  </InputAdornment>
                                ),
                              }}
                              name="festivalDate"
                              value={festivalDate ? dayjs(festivalDate).format("YYYY-MM-DD") : null}
                              onChange={(e) => {
                                dateAndDaySetter(e.target.value);
                                setDisplayError("");
                                setError([]);
                              }}
                              disabled={festivalDeleteRecord}
                              type="date"
                              label={
                                <>
                                  Festival Date
                                  {renderAsterisk()}
                                </>
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />)
                            : (<CustomDatePicker
                              isdarktheme={isDarkTheme ? "true" : "false"}
                              slotProps={{
                                openPickerButton: {
                                  color: "primary",
                                },
                              }}
                              name="festivalDate"
                              value={festivalDate ? dayjs(festivalDate) : null}
                              onChange={(date) => {
                                dateAndDaySetter(date);
                                setDisplayError("");
                                setError([]);
                              }}
                              disabled={festivalDeleteRecord}
                              label={
                                <>
                                  Festival Date
                                  {renderAsterisk()}
                                </>
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />)
                        }
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>
                  <Box sx={{ width: "100%", '@media (min-width: 600px)': { width: "60%", marginTop: "8px" } }}>
                    <CustomTextField
                      isdarktheme={isDarkTheme ? "true" : "false"}
                      fullWidth
                      label="Festival Day"
                      name="festivalDay"
                      value={festivalDay}
                      variant="outlined"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Box>
                </Box>
              </>
            ) : null}
            {buttonSelected === "Add Leave Type" ? (
              <>
                <CustomTextField
                  isdarktheme={isDarkTheme ? "true" : "false"}
                  fullWidth
                  type="number"
                  name="leaveNo"
                  value={leaveNo}
                  onChange={(event) => {
                    setLeaveNo(event.target.value);
                    setDisplayError("");
                    setError([]);
                  }}
                  variant="outlined"
                  disabled={leaveDeleteRecord}
                  label={
                    <>
                      No Of Leaves
                      {renderAsterisk()}
                    </>
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    marginTop: "15px"
                  }}
                ></CustomTextField>
              </>
            ) : null}
          </InnerBoxFour>
          <ErrorTypography>
            {error.leaveNo}
          </ErrorTypography>
          <InnerBoxFive isdarktheme={isDarkTheme ? "true" : "false"}>
            {buttonSelected === "Add Leave Type" ? (
              <>
                <Box sx={{ paddingTop: '10px' }}>
                  <FormControlLabels
                    control={<Checkbox checked={enCash} onChange={() => setEnCash(!enCash)} />}
                    label="enCash"
                  />
                </Box>
                <Box sx={{ paddingTop: '10px' }}>
                  <FormControlLabels
                    control={<Checkbox checked={deprecateLeave} onChange={() => setDeprecateLeave(!deprecateLeave)} />}
                    label="Deprecate Leave"
                  />
                </Box>
              </>
            ) : null}
          </InnerBoxFive>
          <ErrorTypography>
            {error.festivalDate}
          </ErrorTypography>
        </DialogContent>
        <ErrorTypography>
          {displayError}
        </ErrorTypography>
        <DialogsActions sx={{ padding: "8px 0px 0px 0px" }}>
          <CancelButton
            onClick={onClose}
            isdarktheme={isDarkTheme ? "true" : "false"}
          >
            Cancel
          </CancelButton>

          {buttonSelected === "Add Holiday" ? (
            <CustomButton
              onClick={() => {
                if (buttonName === "Add Holiday") {
                  AddHolidayAction();
                }
                if (buttonName === "Edit Holiday") {
                  EditHolidayAction();
                }
                if (buttonName === "Delete Holiday") {
                  DeleteHolidayAction();
                }
              }}
              variant="contained"
              component={Link}
              color={
                festivalEditRecord
                  ? "warning"
                  : festivalDeleteRecord
                    ? "error"
                    : "success"
              }
            >
              {buttonName}
            </CustomButton>
          ) : null}
          {buttonSelected === "Add Leave Type" ? (
            <CustomButton
              onClick={() => {
                if (buttonName === "Add Leave Type") {
                  AddLeaveTypeAction();
                }
                if (buttonName === "Edit Leave Type") {
                  EditLeaveTypeAction();
                }
                if (buttonName === "Delete Leave Type") {
                  DeleteLeaveTypeAction();
                }
              }}
              variant="contained"
              component={Link}
              color={
                leaveEditRecord
                  ? "warning"
                  : leaveDeleteRecord
                    ? "error"
                    : "success"
              }
            >
              {buttonName}
            </CustomButton>
          ) : null}
        </DialogsActions>
      </MainBox>
    </>)
  }

  useEffect(() => {
    if (festivalEditRecord || festivalDeleteRecord) {
      if (selectedFestivalData) {
        set_id(selectedFestivalData.festival_Id);
        setFestival(selectedFestivalData.festival);
        setFestivalDate(
          dayjs(
            selectedFestivalData.festivalDate.split("-").reverse().join("-")
          )
        );
        setFestivalDay(selectedFestivalData.festivalDay);
      } else {
        resetHolidayTypeField();
      }
    }
    if (leaveEditRecord || leaveDeleteRecord) {
      if (selectedLeaveData) {
        set_id(selectedLeaveData.leave_Id);
        setLeaveType(selectedLeaveData.leaveType);
        setLeaveNo(selectedLeaveData.leaveNo);
        setDeprecateLeave(selectedLeaveData.deprecateLeave);
        if (selectedLeaveData.enCash === "Yes") {
          setEnCash(true);
        }
        else {
          setEnCash(false);
        }
      } else {
        resetLeaveTypeField();
      }
    }
  }, [
    festivalEditRecord,
    leaveEditRecord,
    selectedFestivalData,
    selectedLeaveData,
    festivalDeleteRecord,
    leaveDeleteRecord,
  ]);

  return (
    <Box>
      <DialogComponent
        open={open}
        onClose={onClose}
        maxWidth="280px"
        renderChildern={renderForm}
        PaperProps={{
          sx: {
            marginLeft: isMobile ? "3%" : "12%",
            marginRight: isMobile ? "3%" : "10%",
            width: !isMobile && "430px",
            '@media (max-width: 600px)': {
              width: '100%',
            },
          }
        }}
      />
    </Box>
  );
};

export default DialogOfCompanyAndLeavePolicy;
