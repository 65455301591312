import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Typography,
  Box,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
} from "@mui/material";
import { Close, ConfirmationNumber as ConfirmationNumberIcon } from "@mui/icons-material";
import { format } from "date-fns";

import { formatTime } from "../../ReusableComponents/UtilityFunctions";
import DialogComponent from "../../ReusableComponents/DialogBoxComponents/DialogComponent";
import TimerDialogBox from "./TimerDialogBox";
import TicketDialog from "./TicketDialog";
import CircularProgressWithLabel from "./ProgressClock";
import {
  setToast,
  setToastMsg,
  setToastType,
} from "../../../Slices/toastSlice";
import { setSetAttendenceRecord } from "../../../Slices/employeeDetailSlice";
import {
  setCurrentWorkingTicketNo,
  setDisableStart,
  setElapsedTimes,
  setIsDefault,
  setIsLoading,
  setIsPlaying,
  setIsRunning,
  setIsStart,
  setShowDialogBox,
  setShowTicketDialogBox,
  setTaskList,
} from "../../../Slices/timerDetailsSlice";
import {
  upserTimerStart,
  editTicket,
  getTicketData,
} from "../../../Services/AttendanceManageServices";
import { errorMessage } from "../../../Services/axiosInstance";
import {
  TitleTypography,
  CloseIconButton,
  UpdateTaskBox,
  EditTicketMainBox,
  CustomButton,
  CancelButton,
  DialogsActions,
  ErrorTypography,
  ContentBox,
  InnerBoxOneDialog,
  OverTimeBox,
  InnerBoxTwo,
  BreakTimeBox,
  PunchOutButton,
  PunchInButton,
  InnerBoxOne,
  PunchInTypography,
  PunchInAtTypography,
  DateTypography,
  MainBox,
} from "../../../Styles/EmployeeManagement/Employee/TimerStyle";

const Timer = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.selectedActiveView.role);
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const showDialogBox = useSelector((state) => state.timerData.showDialogBox);
  const isRunning = useSelector((state) => state.timerData.isRunning);
  const isdefault = useSelector((state) => state.timerData.isDefault);
  const isPlaying = useSelector((state) => state.timerData.isPlaying);
  const totalBreakTime = useSelector((state) => state.timerData.totalBreakTime);
  const isStart = useSelector((state) => state.timerData.isStart);
  const disableStart = useSelector((state) => state.timerData.disableStart);
  const taskList = useSelector((state) => state.timerData.taskList);
  const showTicketDialogBox = useSelector(
    (state) => state.timerData.showTicketDialogBox
  );
  const currentWorkingTicketNo = useSelector(
    (state) => state.timerData.currentWorkingTicketNo
  );
  const employeeEmail = useSelector(
    (state) => state.addEmployee.current?.email
  );
  const adminEmail = useSelector((state) => state.adminData.adminData?.email);
  const elapsedTimes = useSelector((state) => state.timerData.elapsedTimes);
  const isRunningTask = useSelector((state) => state.timerData.isRunningTask);
  const peviousDate = window.localStorage.getItem("presentDay");
  const pesentDate = formatDate(new Date());
  const employeeLogin = user === "employee";
  const adminLogin = user === "admin";
  const start = window.localStorage.getItem("start") === "true";
  const cuDate = new Date();
  const formattedDate = format(cuDate, "dd MMM yyyy");

  const [showEditTicketDialogBox, setEditShowTicketDialogBox] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(elapsedTimes);
  const [selectedValueEditTicket, setSelectedValueEditTicket] = useState("");
  const [showCircularDialogBox, setshowcirculardialogbox] = useState("");
  const [displayError, setDisplayError] = useState("");

  const onCloseEditTicketDialog = () => {
    setEditShowTicketDialogBox(false);
  };

  function getEmail() {
    if (employeeLogin) {
      return employeeEmail;
    } else if (adminLogin) {
      return adminEmail;
    }
  }

  function getUser() {
    if (employeeLogin) {
      return "user";
    } else if (adminLogin) {
      return "admin";
    }
  }

  function extractTime(timestamp) {
    const dateTime = new Date(timestamp);
    const time = dateTime.toLocaleTimeString([], {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    return time;
  }
  const overTime = () => {
    if (elapsedTime > 28800) {
      const overtime = elapsedTime - 28800;
      return formatTime(overtime);
    }
    return `00:00:00`;
  };

  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const handleStart = async (selectedValue) => {
    const isTicketValid = taskList?.some(
      (task) => task.ticketNo === selectedValue
    );
    if (!isTicketValid) {
      dispatch(setToast(true));
      dispatch(setToastMsg("Selected task has been completed"));
      dispatch(setToastType("error"));
      return;
    }
    navigator.geolocation.getCurrentPosition(async (position) => {
      try {
        dispatch(setIsPlaying(true));
        dispatch(setDisableStart(true));
        let isFirstStart = false;
        if (window.localStorage.getItem("punchIn")) {
          isFirstStart = false;
        } else {
          isFirstStart = true;
        }
        dispatch(setSetAttendenceRecord(true));
        const presentDay = new Date();
        window.localStorage.setItem("presentDay", formatDate(presentDay));
        const currentTime = new Date().getTime();
        window.localStorage.setItem("punchIn", extractTime(currentTime));
        if (isFirstStart) {
          const initialElapsedTime = Math.floor(
            (new Date().getTime() - currentTime) / 1000
          );
          dispatch(setElapsedTimes(initialElapsedTime));
        }
        const currentDate = new Date();
        const date = formatDate(currentDate);
        const time = currentTime;
        const email = getEmail();
        const user = getUser();
        const currentWorkingTicketNo = selectedValue;
        const postData = JSON.stringify({
          email,
          time,
          date,
          currentWorkingTicketNo,
          CurrentLocation: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          },
        });
        const response = await upserTimerStart(user, postData);
        if (response.status === 200 || response.status === 201) {
          dispatch(setDisableStart(false));
          localStorage.setItem("start", true);
          dispatch(setIsLoading(false));
          dispatch(setIsStart(false));
          dispatch(setIsDefault(true));
          dispatch(setIsRunning(true));
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message ?? "Timer started."));
          dispatch(setToastType("success"));
          window.localStorage.setItem("TaskNo", currentWorkingTicketNo);
          const thatOneTask = taskList.find(
            (state) => state.ticketNo === currentWorkingTicketNo
          );
          window.localStorage.setItem("TaskDescription", thatOneTask.title);
        }
      } catch (error) {
        dispatch(setDisableStart(false));
        dispatch(setIsLoading(false));
        setDisplayError(errorMessage ?? "");
      }
    });
  };

  const ticketUpdate = async () => {
    if (!selectedValueEditTicket) {
      setDisplayError("");
    } else {
      try {
        const endTime = new Date().getTime();
        const OldTaskNo = window.localStorage.getItem("TaskNo");
        const postData = JSON.stringify({
          selectedValue: selectedValueEditTicket,
          OldTaskNo,
          endTime,
          isstart: isStart.toString(),
        });
        const response = await editTicket(postData);
        if (response.status === 200 || response.status === 201) {
          dispatch(setCurrentWorkingTicketNo(selectedValueEditTicket));
          window.localStorage.setItem("TaskNo", selectedValueEditTicket);
          const thatOneTask = taskList.find(
            (state) => state.ticketNo === selectedValueEditTicket
          );
          window.localStorage.setItem("TaskDescription", thatOneTask.title);
          onCloseEditTicketDialog();
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message ?? "Task Updated"));
          dispatch(setToastType("success"));
        } else {
          dispatch(setCurrentWorkingTicketNo(OldTaskNo));
        }
      } catch (error) {
        setDisplayError(errorMessage ?? "");
      }
    }
  };

  const renderEditTicketDialogBox = () => {
    return (
      <>
        <Box sx={{ background: isDarkTheme ? "#121e2e" : "#ffffff" }}>
          <Box>
            <CloseIconButton
              onClick={onCloseEditTicketDialog}
              isdarktheme={isDarkTheme ? "true" : "false"}
            >
              <Close />
            </CloseIconButton>
          </Box>
          <EditTicketMainBox>
            <Box>
              <DialogTitle sx={{ fontWeight: "bold" }}>
                <InnerBoxOneDialog>
                  <UpdateTaskBox>
                    <ConfirmationNumberIcon
                      style={{ fontSize: "xx-large", fill: "#7b1fa2" }}
                    />
                  </UpdateTaskBox>
                  <Box>
                    <Box sx={{ color: isDarkTheme ? "#e0e0e0e3" : "#544f5a" }}>
                      {"Update Your Task !"}
                    </Box>
                  </Box>
                </InnerBoxOneDialog>
              </DialogTitle>
            </Box>
          </EditTicketMainBox>
          <DialogContent>
            <ContentBox isdarktheme={isDarkTheme ? "true" : "false"}>
              <TextField
                fullWidth
                select
                label="Task"
                name="Tasks"
                value={selectedValueEditTicket}
                onChange={(event) =>
                  setSelectedValueEditTicket(event.target.value)
                }
                multiline
                variant="outlined"
              >
                {taskList?.map((option) => (
                  <MenuItem key={option.ticketNo} value={option.ticketNo}>
                    {option.ticketNo} {": "}
                    {option.title}
                  </MenuItem>
                ))}
              </TextField>
            </ContentBox>
            <ErrorTypography>{displayError}</ErrorTypography>
          </DialogContent>
          <DialogsActions>
            <CancelButton
              onClick={onCloseEditTicketDialog}
              isdarktheme={isDarkTheme ? "true" : "false"}
            >
              Cancel
            </CancelButton>
            <CustomButton
              onClick={() => ticketUpdate()}
              variant="contained"
              component={Link}
              color="secondary"
            >
              Update
            </CustomButton>
          </DialogsActions>
        </Box>
      </>
    );
  };

  const memoizedgetTask = useMemo(() => {
    const getTasks = async () => {
      try {
        const response = await getTicketData();
        if (response.status === 200 || response.status === 201) {
          dispatch(
            setTaskList(
              response.data.task.filter(
                (obj) =>
                  obj.status !== "Completed" && obj.status !== "Cancelled"
              )
            )
          );
        } else {
          dispatch(setTaskList([]));
        }
      } catch (error) { }
    };
    return getTasks;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pesentDate !== peviousDate && !isdefault) {
      window.localStorage.removeItem("presentDay");
      window.localStorage.removeItem("totalTime");
      window.localStorage.removeItem("punchIn");
      window.localStorage.removeItem("start");
      window.localStorage.removeItem("totalBreakTime");
    }

    let interval;
    if (isRunning) {
      interval = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [
    elapsedTime,
    isRunning,
    isdefault,
    start,
    pesentDate,
    peviousDate,
  ]);

  useEffect(() => {
    dispatch(
      setCurrentWorkingTicketNo(window.localStorage.getItem("TaskNo") || "")
    );
    memoizedgetTask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isdefault && !start) {
      setElapsedTime(window.localStorage.getItem("totalTime"));
    }
  }, [isdefault, start]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStart]);

  useEffect(() => {
    setElapsedTime(elapsedTimes);
  }, [elapsedTimes]);

  useEffect(() => {
    if (showCircularDialogBox === "true") {
      memoizedgetTask();
      setEditShowTicketDialogBox(true);
      setshowcirculardialogbox("");
    } else if (isRunningTask) {
      memoizedgetTask();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCircularDialogBox, isRunningTask]);

  useEffect(() => { }, [isPlaying, isStart]);

  useEffect(() => {
    setDisplayError("");
    setSelectedValueEditTicket(currentWorkingTicketNo);
  }, [showEditTicketDialogBox, currentWorkingTicketNo]);

  return (
    <Box>
      <Box>
        <TitleTypography>Timesheet</TitleTypography>
        <DateTypography>{formattedDate}</DateTypography>
      </Box>
      <MainBox>
        <PunchInAtTypography>Punch In At </PunchInAtTypography>
        <PunchInTypography>
          {window.localStorage.getItem("punchIn")}
        </PunchInTypography>
      </MainBox>

      <Box
        sx={{
          marginTop: "110px",
        }}
      >
        <CircularProgressWithLabel
          setshowcirculardialogbox={setshowcirculardialogbox}
          currentWorkingTicketNo={currentWorkingTicketNo}
          elapsedTime={elapsedTime}
          isstart={isStart.toString()}
        />
      </Box>
      {isStart ? (
        <>
          <InnerBoxOne>
            <PunchInButton
              disabled={disableStart}
              onClick={() => {
                memoizedgetTask();
                currentWorkingTicketNo
                  ? handleStart(currentWorkingTicketNo)
                  : dispatch(setShowTicketDialogBox(true));
              }}
            >
              Punch In
            </PunchInButton>
          </InnerBoxOne>
        </>
      ) : (
        <InnerBoxOne>
          <PunchOutButton onClick={() => dispatch(setShowDialogBox(true))}>
            Punch Out
          </PunchOutButton>
        </InnerBoxOne>
      )}
      <Typography sx={{ color: "red" }}>{displayError}</Typography>
      <InnerBoxTwo>
        <BreakTimeBox>
          <Typography sx={{ fontSize: "90%", fontWeight: "bold" }}>
            BreakTime
          </Typography>
          <Typography sx={{ fontSize: "90%" }}>
            {formatTime(
              // Math.floor(window.localStorage.getItem("totalBreakTime") / 1000)
              Math.floor(totalBreakTime / 1000)
            )}
          </Typography>
        </BreakTimeBox>
        <OverTimeBox>
          <Typography sx={{ fontSize: "90%", fontWeight: "bold" }}>
            OverTime
          </Typography>
          <Typography sx={{ fontSize: "90%" }}>{overTime()}</Typography>
        </OverTimeBox>
      </InnerBoxTwo>
      <TimerDialogBox open={showDialogBox} />
      <Box>
      </Box>
      <TicketDialog
        taskList={taskList}
        open={showTicketDialogBox}
        handleStart={handleStart}
      />
      <DialogComponent
        open={showEditTicketDialogBox}
        onClose={onCloseEditTicketDialog}
        maxWidth="md"
        renderChildern={renderEditTicketDialogBox}
      />
    </Box>
  );
};

export default Timer;
