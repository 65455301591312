import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, DialogContent, DialogTitle, IconButton, MenuItem, useMediaQuery } from "@mui/material";
import { Close, Done as DoneIcon, Close as CloseIcon, AssignmentInd as AssignmentIndIcon, GroupAdd as GroupAddIcon } from "@mui/icons-material";

import { setToast, setToastMsg, setToastType } from "../../Slices/toastSlice.js";
import Dialogcomponent from '../ReusableComponents/DialogBoxComponents/DialogComponent';
import { renderAsterisk } from "../ReusableComponents/UtilityFunctions.js";
import { errorMessage } from "../../Services/axiosInstance.js";
import { upsertAddGroup, editRole, upsertRole, getAllGroupData } from '../../Services/ManageRoleServices.js';
import { ErrorTypography, CloseIconButton, CancelButton, GroupNameBox, GroupNameTextField, InnerBoxFive, InnerBoxFour, InnerBoxSix, InnerBoxThree, InnerBoxTwo, LevelNameBox, LinkBox, UpsertRoleButton, CustomTextField, HeaderBox } from "../../Styles/ManageRoles/ManageRolesStyle.js";
import { DialogsActions } from "../../Styles/Authentication/LogOutStyle.js";

const RolesDialog = ({
  buttonSelected,
  open,
  setOpenDialogBox,
  openDialogName,
  selectedRecord,
  FormattedTaskData,
  setEmployeeData,
  setChange,
  change,
}) => {
  const dispatch = useDispatch();
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const isMobile = useMediaQuery("(max-width:600px)");
  const selectedData = FormattedTaskData.find(
    (state) => state.role === selectedRecord
  );

  const [roleName, setRoleName] = useState("");
  const [listOfGroup, setListOfGroup] = useState([]);
  const [group, setGroup] = useState("");
  const [groupName, setGroupName] = useState("");
  const [levelName, setLevelName] = useState("");
  const [id, setId] = useState("");
  const [displayError, setDisplayError] = useState("");
  const [selectedValueGroup, setSelectedValueGroup] = useState("");
  const [error, setError] = useState("");

  const addGroup = async () => {
    const errors = [];
    if (!groupName) {
      errors.groupName = "Add Group..";
      setError(errors);
      setDisplayError("");
    } else {
      try {
        const postData = JSON.stringify({ groupName });
        const response = await upsertAddGroup(postData);
        if (response.status === 200) {
          if (response.data.fail) {
            setDisplayError(response.data.message);
          } else {
            if (buttonSelected === "Add Group") {
              setGroupName("");
              setOpenDialogBox(false);
              dispatch(setToast(true));
              dispatch(setToastMsg(response.data.message ?? "Group Name Added."));
              dispatch(setToastType("success"));
            }
            if (buttonSelected === "Add Role") {
              setSelectedValueGroup(false);
              setGroupName("");
            }
            setError("");
            setDisplayError("");
          }
        }
      } catch (error) {
        setDisplayError(errorMessage ?? "")
      }
    }
  };

  const addRole = async () => {
    const errors = [];
    if (!roleName) {
      errors.roleName = "Add Role..";
      setError(errors);
    } else if (!group) {
      errors.group = "Select Group..";
      setError(errors);
    } else if (!levelName) {
      errors.levelName = "Select Level..";
      setError(errors);
    } else {
      if (buttonSelected === "Update Role") {
        try {
          const postData = JSON.stringify({ roleName, group, levelName, id });
          const response = await editRole(postData);
          if (response.status === 200) {
            const newRec = response.data.roleRecord
            setEmployeeData((prevData) => {
              const index = prevData?.findIndex((record) => record?._id === id);
              if (index !== -1) {
                const updatedData = [...prevData];
                updatedData[index] = newRec;
                return updatedData;
              }
              return prevData;
            });
            if (change === true) {
              setChange(false);
            } else {
              setChange(true);
            }
            setOpenDialogBox(false);
            dispatch(setToast(true));
            dispatch(setToastMsg(response.data.message ?? "Role updated."));
            dispatch(setToastType("success"));
          }
        } catch (error) {
          setDisplayError(errorMessage ?? "")
        }
      } else {
        try {
          const postData = JSON.stringify({ roleName, group, levelName });
          const response = await upsertRole(postData);
          if (response.status === 200) {
            setEmployeeData((prevData) => {
              return [response?.data?.newRole, ...prevData];
            });
            setRoleName("");
            setGroupName("");
            setGroup("");
            setLevelName("");
            setOpenDialogBox(false);
            if (change === true) {
              setChange(false);
            } else {
              setChange(true);
            }
            setOpenDialogBox(false);
            dispatch(setToast(true));
            dispatch(setToastMsg(response.data.message ?? "Role added."));
            dispatch(setToastType("success"));
          }
        } catch (error) {
          setDisplayError(errorMessage ?? "")
        }
      }
    }
  };

  const onClose = () => {
    setOpenDialogBox(false);
    setRoleName("");
    setGroupName("");
    setGroup("");
    setLevelName("");
    setError("");
    setSelectedValueGroup(false);
  }

  const renderForm = () => {
    return (<>
      <Box sx={{ background: isDarkTheme ? "#121e2e" : "#ffffff", padding: "13px" }}>
        <HeaderBox>
          <InnerBoxTwo>
            <DialogTitle sx={{ fontWeight: "bold", padding: "0px 0px", }}>
              <InnerBoxThree>
                <InnerBoxFour>
                  {buttonSelected === "Add Role" ||
                    buttonSelected === "Update Role" ? (
                    <AssignmentIndIcon
                      style={{ fontSize: "xx-large", fill: "#f4b73e" }}
                    />
                  ) : null}
                  {buttonSelected === "Add Group" ? (
                    <GroupAddIcon
                      style={{ fontSize: "xx-large", fill: "#f4b73e" }}
                    />
                  ) : null}
                </InnerBoxFour>
                <Box>
                  <Box sx={{ color: isDarkTheme ? "#e0e0e0e3" : "#544f5a" }}>
                    {buttonSelected}
                  </Box>
                </Box>
              </InnerBoxThree>
            </DialogTitle>
          </InnerBoxTwo>
          <Box>
            <CloseIconButton
              onClick={onClose}
              isdarktheme={isDarkTheme ? "true" : "false"}
              ismobile={isMobile ? "true" : "false"}
            >
              <Close />
            </CloseIconButton>
          </Box>
        </HeaderBox>
        <DialogContent sx={{ padding: isMobile ? "8px 2px" : "15px 2px 5px 2px" }}>
          <InnerBoxFive
            isdarktheme={isDarkTheme ? "true" : "false"}
          >
            {buttonSelected === "Add Role" ||
              buttonSelected === "Update Role" ? (
              <>
                <CustomTextField
                  fullWidth
                  name="roleName"
                  isdarktheme={isDarkTheme ? "true" : "false"}
                  value={roleName}
                  onChange={(event) => {
                    setRoleName(event.target.value);
                    setDisplayError("");
                    setError("");
                  }}
                  variant="outlined"
                  label={
                    <>
                      {openDialogName}
                      {renderAsterisk()}
                    </>
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </>
            ) : null}
            {buttonSelected === "Add Group" ? (
              <>
                <CustomTextField
                  fullWidth
                  isdarktheme={isDarkTheme ? "true" : "false"}
                  name="groupName"
                  value={groupName}
                  onChange={(event) => {
                    setGroupName(event.target.value);
                    setDisplayError("");
                    setError("");
                  }}
                  variant="outlined"
                  label={
                    <>
                      {openDialogName}
                      {renderAsterisk()}
                    </>
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                ></CustomTextField>
              </>
            ) : null}
          </InnerBoxFive>
          {buttonSelected === "Add Role" || buttonSelected === "Update Role" ? (
            <>
              <ErrorTypography>
                {error.roleName}
              </ErrorTypography>
            </>
          ) : null}

          {buttonSelected === "Add Group" ? (
            groupName ? (
              <ErrorTypography>
                {displayError}
              </ErrorTypography>
            ) : (
              <ErrorTypography>
                {error.groupName}
              </ErrorTypography>
            )
          ) : null}

          {buttonSelected === "Add Role" || buttonSelected === "Update Role" ? (
            <>
              <InnerBoxSix isdarktheme={isDarkTheme ? "true" : "false"}>
                <CustomTextField
                  fullWidth
                  select
                  isdarktheme={isDarkTheme ? "true" : "false"}
                  name="group"
                  value={group}
                  onChange={(event) => setGroup(event.target.value)}
                  variant="outlined"
                  label={
                    <>
                      Group Name
                      {renderAsterisk()}
                    </>
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    "& fieldset legend > span": {
                      paddingLeft: "2px",
                      paddingRight: "0px",
                    },
                  }}
                >
                  {listOfGroup?.map((item) => (
                    <MenuItem key={item.groupName} value={item.groupName}>
                      {item.groupName}
                    </MenuItem>
                  ))}
                </CustomTextField>
              </InnerBoxSix>
              <ErrorTypography>
                {error.group}
              </ErrorTypography>
              <LinkBox isdarktheme={isDarkTheme ? "true" : "false"}>
                <Link
                  style={{
                    color: "#2369d5",
                  }}
                  type="button"
                  underline="hover"
                  onClick={() => {
                    setSelectedValueGroup(true);
                  }}
                >
                  Create One
                </Link>
              </LinkBox>
              {selectedValueGroup ? (
                <GroupNameBox isdarktheme={isDarkTheme ? "true" : "false"}>
                  <GroupNameTextField
                    inputProps={{
                      style: {
                        padding: "20px",
                        marginTop: "5px",
                        marginBottom: "5px",
                      },
                    }}
                    fullWidth
                    isdarktheme={isDarkTheme ? "true" : "false"}
                    name="groupName"
                    value={groupName}
                    onChange={(event) => {
                      setGroupName(event.target.value);
                      setError("");
                      setDisplayError("");
                    }}
                    variant="outlined"
                    label={
                      <>
                        New Group Name
                        {renderAsterisk()}
                      </>
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  ></GroupNameTextField>
                  <IconButton
                    onClick={() => {
                      setGroupName("");
                      setGroup("");
                      setError("");
                      setSelectedValueGroup(false);
                    }}
                  >
                    <CloseIcon
                      color="error"
                    />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setGroup(groupName);
                      setGroupName(groupName);
                      addGroup();
                    }}
                  >
                    <DoneIcon color="success" />
                  </IconButton>
                </GroupNameBox>
              ) : null}
              {groupName ? (
                <ErrorTypography>
                  {displayError}
                </ErrorTypography>
              ) : (
                <ErrorTypography>
                  {error.groupName}
                </ErrorTypography>
              )}
              <LevelNameBox isdarktheme={isDarkTheme ? "true" : "false"}>
                <CustomTextField
                  fullWidth
                  select
                  name="levelName"
                  value={levelName}
                  isdarktheme={isDarkTheme ? "true" : "false"}
                  onChange={(event) => {
                    setLevelName(event.target.value);
                    setError("");
                  }}
                  variant="outlined"
                  label={
                    <>
                      Level Name
                      {renderAsterisk()}
                    </>
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  {[...Array(12).keys()].map((value) => (
                    <MenuItem key={value + 1} value={value + 1}>
                      Level {value + 1}
                    </MenuItem>
                  ))}
                </CustomTextField>
              </LevelNameBox>
              <ErrorTypography>
                {error.levelName}
              </ErrorTypography>
            </>
          ) : null}
        </DialogContent>
        <DialogsActions sx={{ padding: "8px 0px 0px 0px" }}>
          <CancelButton
            onClick={onClose}
            isdarktheme={isDarkTheme ? "true" : "false"}
          >
            Cancel
          </CancelButton>
          {buttonSelected === "Update Role" ? (
            <UpsertRoleButton
              onClick={() => {
                addRole();
              }}
              variant="contained"
              component={Link}
              color="success"
            >
              {buttonSelected}
            </UpsertRoleButton>
          ) : null}
          {buttonSelected === "Add Role" ? (
            <UpsertRoleButton
              onClick={() => {
                addRole();
              }}
              variant="contained"
              component={Link}
              color="success"
            >
              {buttonSelected}
            </UpsertRoleButton>
          ) : null}
          {buttonSelected === "Add Group" ? (
            <UpsertRoleButton
              onClick={() => {
                addGroup();
              }}
              variant="contained"
              component={Link}
              color="success"
            >
              {buttonSelected}
            </UpsertRoleButton>
          ) : null}
        </DialogsActions>
      </Box>
    </>)
  }

  const memoizedAdminFetchData = useMemo(() => {
    const groupSelectData = async () => {
      try {
        const response = await getAllGroupData()
        if (response.status === 200) {
          setListOfGroup(response.data.groups);
        }
        else {
          setListOfGroup([])
        }
      } catch (error) {
      }
    };
    return groupSelectData;
  }, []);

  useEffect(() => {
    memoizedAdminFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonSelected, groupName]);

  useEffect(() => {
    if (buttonSelected === "Update Role" && selectedData) {
      if (id !== selectedData.role_Id) {
        setGroup(selectedData.groupname);
        setLevelName(selectedData.levelName);
        setRoleName(selectedData.role);
        setId(selectedData.role_Id);
      }
    } else {
      setId("");
      setGroup("");
      setGroupName("");
      setLevelName("");
      setRoleName("");
    }
  }, [buttonSelected, selectedData, open, id]);

  return (
    <Box>
      <Dialogcomponent
        open={open}
        onClose={onClose}
        maxWidth="280px"
        renderChildern={renderForm}
        PaperProps={{
          sx: {
            marginLeft: isMobile ? "3%" : "12%",
            marginRight: isMobile ? "3%" : "10%",
            width: !isMobile && "450px",
            '@media (max-width: 600px)': {
              width: '100%',
            },
          }
        }}
      />
    </Box>
  );
};

export default RolesDialog;
