import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { setOnLeaveManagement } from "../../Slices/selectedActive";
import { resetEmployeeDetail } from "../../Slices/employeeDetailSlice";
import DialogComponent from "../ReusableComponents/DialogBoxComponents/DialogComponent";

const ExitDialog = ({ open, onClose, reset, onBackClick }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const OnLeaveManagement = useSelector(
    (state) => state.selectedActiveView.onLeaveManagement
  );

  function back() {
    if (OnLeaveManagement === "true") {
      onBackClick();
      dispatch(setOnLeaveManagement(""));
    } else {
      navigate("/dashboard");
    }
    onClose();
    reset();
    dispatch(resetEmployeeDetail());
  }

  const renderForm = () => {
    return (<>
      <DialogTitle
        id="approval-dialog-title"
        sx={{
          fontWeight: "bold",
          fontSize: "25px",
          color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82",
        }}
      >
        Confirm
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="approval-dialog-description"
          sx={{
            fontSize: "18px",
            fontWeight: "bold",
            color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82",
            display: "flex",
            alignContent: "center",
          }}
        >
          All unsaved data will be lost ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={back} sx={{ color: "gray" }}>
          Exit
        </Button>
        <Button onClick={onClose} variant="contained" color="primary">
          Stay
        </Button>
      </DialogActions>
    </>)
  }

  return (
    <DialogComponent
      sx={{
        ".MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper":
        {
          background: isDarkTheme
            ? "#121e2e !important"
            : "#ffffff !important",
        },
      }}
      open={open}
      onClose={onClose}
      arialabelledby="approval-dialog-title"
      ariadescribedby="approval-dialog-description"
      fullwidth="true"
      maxWidth="sm"
      renderChildern={renderForm}
    />
  );
};

export default ExitDialog;
