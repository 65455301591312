import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  formatDate,
  formatDateSlashTo_,
} from "../../ReusableComponents/UtilityFunctions";
import Graph from "../../ReusableComponents/Graph";
import "../../../App.css";

const EmployeeGraph = ({ EmpId }) => {
  const { employeeId } = useParams();
  const allEmployeeAttendence = useSelector(
    (state) => state.employeeData.allEmployeeAttendenceData
  );
  const selectedDate = useSelector((state) => state.searchData.calenderValue);
  const selectedEmpId = employeeId || EmpId;

  const [attendenceData, setAttendenceData] = useState([]);
  const formattedTodayDate = formatDate(new Date());
  const [date, setDate] = useState();

  useEffect(() => {
    if (selectedDate) {
      setDate(formatDateSlashTo_(selectedDate));
    } else {
      setDate(formatDateSlashTo_(formattedTodayDate));
    }
    const filteredDataByEmployee = allEmployeeAttendence?.filter(
      (item) => item?.empId === selectedEmpId
    );
    if (filteredDataByEmployee?.length !== 0) {
      const filteredDataBydate = filteredDataByEmployee[0]?.records;
      setAttendenceData(filteredDataBydate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEmpId, selectedDate, allEmployeeAttendence]);

  const getMondayDate = () => {
    const formattedDate = date?.split("-").reverse().join("-");
    const firstDay = new Date(formattedDate);
    const dayOfWeek = firstDay.getDay();
    const difference = dayOfWeek - 1;
    const mondayDate = new Date(firstDay);
    mondayDate.setDate(firstDay.getDate() - difference);
    return formatDate(mondayDate);
  };
  const graphValues = () => {
    if (attendenceData?.length === 0) {
      const defaultArray = [
        { label: "Mon", WorkHours: 0, BreakHours: 0 },
        { label: "Tue", WorkHours: 0, BreakHours: 0 },
        { label: "Wed", WorkHours: 0, BreakHours: 0 },
        { label: "Thu", WorkHours: 0, BreakHours: 0 },
        { label: "Fri", WorkHours: 0, BreakHours: 0 },
        { label: "Sat", WorkHours: 0, BreakHours: 0 },
      ];
      return defaultArray;
    } else {
      const dataArray = [];
      const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const formattedStartDate = getMondayDate();
      const formattedDate = formattedStartDate?.split("-").reverse().join("-");
      const startDate = new Date(formattedDate);
      const endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 6);
      const attendanceByDate = new Map();
      attendenceData.forEach((record) => {
        const formattedDate = record?.date?.split("-").reverse().join("-");
        const recordDate = new Date(formattedDate);
        if (
          (recordDate >= startDate &&
            recordDate <= endDate &&
            recordDate.getMonth() === startDate.getMonth() &&
            recordDate.getFullYear() === startDate.getFullYear()) ||
          (recordDate >= startDate &&
            recordDate <= endDate &&
            recordDate.getMonth() === endDate.getMonth() &&
            recordDate.getFullYear() === endDate.getFullYear())
        ) {
          const dayIndex = recordDate.getDay() - 1;
          const day = days[dayIndex];
          attendanceByDate.set(day, {
            WorkHours: record?.dailytimer?.TotalTime / (1000 * 60 * 60) || 0,
            BreakHours: record?.dailytimer?.BreakTime / (1000 * 60 * 60) || 0,
          });
        }
      });

      days.forEach((day) => {
        const record = attendanceByDate.get(day);
        dataArray.push({
          label: day,
          Working_Hours: record ? record.WorkHours : 0,
          Break_Hours: record ? record.BreakHours : 0,
        });
      });
      return dataArray;
    }
  };
  const data = graphValues();
  return <Graph data={data} />;
};
export default EmployeeGraph;
