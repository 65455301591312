import React, { useRef, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Backdrop,
  Box,
  Button,
  Grid,
  IconButton,
  Slide,
  DialogTitle,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Delete as DeleteIcon, Close, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from "@mui/icons-material";

import AddEventSlide from "./AddEventSlide";
import usePermissions from "../RolePermission/usePermissions.js";
import { filterEventsByDateAndPermission } from "../ReusableComponents/UtilityFunctions.js";
import DialogComponent from "../ReusableComponents/DialogBoxComponents/DialogComponent";
import {
  setToast,
  setToastMsg,
  setToastType,
} from "../../Slices/toastSlice.js";
import { errorMessage } from "../../Services/axiosInstance.js";
import {
  deleteEvent,
  getAllEvents,
} from "../../Services/EventManageServices.js";
import {
  MainBox,
  InnerBoxTwoDialog,
  InnerBoxOneDialog,
  SideBox,
  MainBoxDialog,
  CloseIconButton,
  EventDateBox,
  TextBox,
  InnerBoxTwo,
  EventTitleBox,
  ContentBox,
  InnerBoxFour,
  UpcomingEventsButton,
  InnerBoxOne,
  CompletedEventsButton,
  AddEventBox,
  NoDataAvailableTypography,
  SliderBox,
  InnerSliderBox,
  AddEventButtonBox,
  AddEventButton,
} from "../../Styles/ManageEvents/ManageEventsStyle";
import { DialogsActions } from "../../Styles/KnowledgeSharing/KnowledgeSharingStyle";
import { CancelButton } from "../../Styles/Authentication/LogOutStyle";
import {
  DeleteButton,
  ErrorTypography,
} from "../../Styles/KnowledgeSharing/KnowledgeSharingStyle";

export const ManageEvents = () => {
  const dispatch = useDispatch();
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const user = useSelector((state) => state.selectedActiveView.role);
  const { hasPermission } = usePermissions();
  const adminLogin = user === "admin";

  const [selectedButton, setSelectedButton] = useState("Upcoming Events");
  const [showForm, setShowForm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [_IdForDelete, set_IdForDelete] = useState("");
  const [_dataThatDelete, set_DataThatDelete] = useState({});
  const [isExistingEvent, setIsExistingEvent] = useState(false);
  const [existingEvent, setExistingEvent] = useState({});
  const [deleted, setDeleted] = useState(false);
  const [change, setChange] = useState(false);
  const [displayError, setDisplayError] = useState("");
  const [filterEvent, setFilterEvent] = useState([]);
  const isMobile = useMediaQuery("(max-width:600px)");
  const scrollRef = useRef(null);

  const toggleForm = () => {
    setIsExistingEvent(false);
    setExistingEvent({});
    setShowForm(!showForm);
  };

  const onClose = () => {
    setOpenDialog(false);
    set_IdForDelete("");
    set_DataThatDelete("");
  };

  const handleDeleteConfirm = async (idToDelete) => {
    try {
      const response = await deleteEvent(idToDelete);
      if (response.status === 200) {
        const filterData = filterEvent?.filter((item) => item?._id !== idToDelete);
        setFilterEvent(filterData)
        setDeleted(!deleted);
        setOpenDialog(false);
        set_IdForDelete("");
        set_DataThatDelete("");
        dispatch(setToast(true));
        dispatch(setToastMsg(response.data.message ?? "Event Record Deleted."));
        dispatch(setToastType("success"));
      }
    } catch (error) {
      setDisplayError(errorMessage ?? "");
      setOpenDialog(false);
      set_IdForDelete("");
      set_DataThatDelete("");
    }
  };

  const getScrollAmount = () => {
    if (scrollRef.current) {
      return scrollRef.current.offsetWidth * 1.05;
    }
    return 700;
  };

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: -getScrollAmount(),
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: getScrollAmount(),
        behavior: "smooth",
      });
    }
  };

  const renderChildern = () => {
    return (
      <>
        <Box sx={{ background: isDarkTheme ? "#121e2e" : "#ffffff" }}>
          <Box>
            <CloseIconButton
              onClick={onClose}
              isdarktheme={isDarkTheme ? "true" : "false"}
            >
              <Close />
            </CloseIconButton>
          </Box>
          <MainBoxDialog>
            <Box>
              <DialogTitle sx={{ fontWeight: "bold" }}>
                <InnerBoxOneDialog>
                  <InnerBoxTwoDialog
                    isdarktheme={isDarkTheme ? "true" : "false"}
                  />
                  <Box>
                    <Box sx={{ color: isDarkTheme ? "#e0e0e0e3" : "#544f5a" }}>
                      Confirm Delete Event
                    </Box>
                    <Typography
                      sx={{ color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82" }}
                    >
                      Are you sure you want to Delete{" "}
                      <span
                        style={{
                          textDecoration: "underline",
                        }}
                      >
                        {_dataThatDelete.eventTitle}
                      </span>{" "}
                      Event?
                    </Typography>
                  </Box>
                </InnerBoxOneDialog>
              </DialogTitle>
            </Box>
          </MainBoxDialog>
          <DialogsActions>
            <CancelButton
              onClick={onClose}
              isdarktheme={isDarkTheme ? "true" : "false"}
            >
              Cancel
            </CancelButton>
            <DeleteButton
              variant="contained"
              onClick={() => {
                handleDeleteConfirm(_IdForDelete);
              }}
              component={Link}
              color="error"
            >
              Delete
            </DeleteButton>
          </DialogsActions>
        </Box>
        <Box sx={{ background: isDarkTheme ? "#121e2e" : "#ffffff" }}>
          <ErrorTypography>{displayError}</ErrorTypography>
        </Box>
      </>
    );
  };

  const memoizedUserFetchEvent = useMemo(() => {
    const getEvent = async (e) => {
      setDisplayError("");
      try {
        const response = await getAllEvents();
        if (response.status === 200) {
          const filteredEvents = filterEventsByDateAndPermission(response?.data?.eventRecord, selectedButton, hasPermission);
          setFilterEvent(filteredEvents);
        } else {
          setFilterEvent([]);
        }
      } catch (error) {
      }
    };
    return getEvent;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedButton]);

  useEffect(() => {
    memoizedUserFetchEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedButton]);

  return (
    <Box sx={{ padding: "6px 4px 8px 7px" }}>
      {/* Box for items on the left */}
      {isMobile && adminLogin ? (
        <>
          <MainBox sx={{
            '@media (max-width: 600px)': {
              flexDirection: "column",
              alignItems: "flex-end",
            }
          }}>
            <AddEventButtonBox>
              {hasPermission("Add New Events") ? (
                <AddEventBox>
                  <AddEventButton
                    variant="contained"
                    color="primary"
                    onClick={toggleForm}
                  >
                    + Add New Event
                  </AddEventButton>
                </AddEventBox>
              ) : (
                <></>
              )}
            </AddEventButtonBox>
            <SliderBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexGrow={1}
              overflow="hidden"
              position="relative"
            >
              <IconButton onClick={scrollLeft} style={{
                marginRight: 12,
                color: isDarkTheme === "true" ? "#e0e0e0cc" : "#5e6e82",
              }}>
                <ChevronLeftIcon />
              </IconButton>
              <InnerSliderBox
                ref={scrollRef}
                display="flex"
                flexDirection="row"
                overflowX="auto"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  flexShrink="0"
                  minWidth="100%"
                  justifyContent="center"
                >
                  {hasPermission("Upcoming Events") && (
                    <UpcomingEventsButton
                      onClick={() => {
                        setSelectedButton("Upcoming Events");
                      }}
                      selectedbutton={selectedButton}
                    >
                      Upcoming Events
                    </UpcomingEventsButton>
                  )}
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  flexShrink="0"
                  minWidth="100%"
                  justifyContent="center"
                >
                  {hasPermission("Completed Events") && (
                    <CompletedEventsButton
                      onClick={() => {
                        setSelectedButton("Completed Events");
                      }}
                      selectedbutton={selectedButton}
                    >
                      Completed Events
                    </CompletedEventsButton>
                  )}
                </Box>
              </InnerSliderBox>
              <IconButton onClick={scrollRight} style={{
                marginLeft: 12,
                color: isDarkTheme === "true" ? "#e0e0e0cc" : "#5e6e82",
              }}>
                <ChevronRightIcon />
              </IconButton>
            </SliderBox>
          </MainBox>
        </>
      ) : (
        <>
          <MainBox>
            <Box sx={{ padding: "5px 0px 0px 18px", display: "flex" }}>
              <Box>
                {hasPermission("Upcoming Events") && (
                  <UpcomingEventsButton
                    onClick={() => {
                      setSelectedButton("Upcoming Events");
                    }}
                    selectedbutton={selectedButton}
                  >
                    Upcoming Events
                  </UpcomingEventsButton>
                )}
                {hasPermission("Completed Events") && (
                  <CompletedEventsButton
                    onClick={() => {
                      setSelectedButton("Completed Events");
                    }}
                    selectedbutton={selectedButton}
                  >
                    Completed Events
                  </CompletedEventsButton>
                )}
              </Box>
            </Box>

            {hasPermission("Add New Events") ? (
              <AddEventBox>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={toggleForm}
                >
                  + Add New Event
                </Button>
              </AddEventBox>
            ) : (
              <></>
            )}
          </MainBox>
        </>
      )}
      <Box sx={{ margin: "20px 2px 2px 10px", bottom: 2 }}>
        <Grid item container spacing={2}>
          {filterEvent.length === 0 ? (
            <Box sx={{ padding: "4%" }}>
              <NoDataAvailableTypography
                color="var(--secondary-text-color)"
                variant="body0"
              >
                No Events Available!!
              </NoDataAvailableTypography>
            </Box>
          ) : (
            filterEvent?.map((data, index) => (
              <Grid key={index} item lg={2} sm={3} xs={12}>
                <InnerBoxOne>
                  <InnerBoxTwo
                    onClick={() => {
                      setShowForm(true);
                      setIsExistingEvent(true);
                      setExistingEvent(data);
                    }}
                  >
                    {adminLogin ? (
                      <InnerBoxFour>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenDialog(true);
                            set_IdForDelete(data._id);
                            set_DataThatDelete(data);
                          }}
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      </InnerBoxFour>
                    ) : (
                      <></>
                    )}

                    <ContentBox>
                      <EventTitleBox>{data?.eventTitle}</EventTitleBox>
                      <EventDateBox>
                        {data?.date ===
                          new Date().toISOString().slice(0, 10) ? (
                          <TextBox>Today</TextBox>
                        ) : (
                          new Date(data?.date).toLocaleDateString("en-GB")
                        )}
                      </EventDateBox>
                    </ContentBox>
                  </InnerBoxTwo>
                </InnerBoxOne>
              </Grid>
            ))
          )}
        </Grid>
      </Box>
      <Typography sx={{ color: "red" }}>{displayError}</Typography>
      <Backdrop open={showForm} onClick={toggleForm} />
      <Slide direction="left" in={showForm} mountOnEnter unmountOnExit>
        <SideBox isdarktheme={isDarkTheme ? "true" : "false"}>
          <AddEventSlide
            onClose={toggleForm}
            isExistingEvent={isExistingEvent}
            setIsExistingEvent={setIsExistingEvent}
            existingEvent={existingEvent}
            setExistingEvent={setExistingEvent}
            selectedButton={selectedButton}
            setSelectedButton={setSelectedButton}
            change={change}
            setChange={setChange}
            setFilterEvent={setFilterEvent}
            filterEvent={filterEvent}
          />
        </SideBox>
      </Slide>
      <Box>
        <DialogComponent
          open={openDialog}
          onClose={onClose}
          maxWidth="md"
          renderChildern={renderChildern}
        />
      </Box>
    </Box>
  );
};
