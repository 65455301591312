import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Edit } from "@mui/icons-material";

import { setAllData } from "../../../Slices/UserDataSlice.js";
import { setemployeeAllData } from "../../../Slices/employeeDetailSlice.js";
import {
  viewEducationBox,
  viewProfileTitle,
  viewProfileSubtitle,
  viewExperiencePosition,
  viewEducationTitle,
} from "../../ReusableComponents/CustomDesignMUI/CustomMUI";
import { renderAsterisk } from "../../ReusableComponents/UtilityFunctions.js";
import { upsertExperience } from "../../../Services/EmployeeManageServices.js";
import { setToast, setToastMsg, setToastType } from "../../../Slices/toastSlice.js";
import { errorMessage } from "../../../Services/axiosInstance.js";
import { AddRecordBox, AddRecordButton, MainBox, TitleBox, InputTextField } from "../../../Styles/UpdateEmployee/UpdateExperienceStyle.js";
import { FooterBox } from "../../../Styles/UpdateEmployee/UpdatePersonalStyle.js";
import { CancelButton, SaveButton, SaveNextButton } from "../../../Styles/UpdateEmployee/UpdateContactStyle.js";

function UpdateExperience(props) {
  const { employeeCall, saveNextButtonCallback, setIsUpdated, isUpdated } = props;
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:600px)");
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const user = useSelector((state) => state.selectedActiveView.role);

  const [editEmployee, setEditEmployee] = useState(false);
  const [displayError, setDisplayError] = useState("");
  const [experienceDetailArray, setExperienceDetailArray] = useState(
    employeeCall?.Experience_Records || []
  );
  const [experienceDetailErrorsArray, setExperienceDetailErrorsArray] = useState([]);

  const employee_id = employeeCall?.empId;
  const adminLogin = user === "admin";

  const getEmptyExperienceDetail = () => ({
    Company_Name: "",
    Position: "",
    Start_Date: "",
    End_Date: "",
  });

  const getEmptyExperienceDetailErrors = () => ({
    Company_Name: "",
    Position: "",
    Start_Date: "",
    End_Date: "",
  });

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleInputChange1 = (index, event) => {
    const { name, value } = event.target;
    setExperienceDetailArray((prevArray) =>
      prevArray?.map((record, i) =>
        i === index ? { ...record, [name]: value } : record
      )
    );
  };

  const removeRecord = (index) => {
    setExperienceDetailArray((prevArray) =>
      prevArray.filter((_, i) => i !== index)
    );
    setExperienceDetailErrorsArray((prevErrorsArray) =>
      prevErrorsArray.filter((_, i) => i !== index)
    );
  };

  const addRecord = () => {
    if (validateAllForms()) {
      setExperienceDetailArray((prevArray) => [
        ...prevArray,
        getEmptyExperienceDetail(),
      ]);
      setExperienceDetailErrorsArray((prevErrorsArray) => [
        ...prevErrorsArray,
        getEmptyExperienceDetailErrors(),
      ]);
    }
  };

  const validateAllForms = () => {
    const allErrors = experienceDetailArray?.map((record, index) =>
      validateForm(index)
    );
    setExperienceDetailErrorsArray(allErrors);
    return allErrors.every((errors) =>
      Object.values(errors).every((error) => !error)
    );
  };

  const validateForm = (index) => {
    const record = experienceDetailArray[index];
    const errors = getEmptyExperienceDetailErrors();
    if (!record.Company_Name) {
      errors.Company_Name = "Company name is required";
    } else if (!/^[a-zA-Z\s]*$/.test(record.Company_Name)) {
      errors.Company_Name = "Company name should not contain numeric values";
    }

    if (!record.Position) {
      errors.Position = "Position is required";
    }

    if (!record.Start_Date) {
      errors.Start_Date = "Start date is required";
    }

    if (!record.End_Date) {
      errors.End_Date = "End date is required";
    }
    if (record.End_Date < record.Start_Date) {
      errors.End_Date = "invalid date";
    }

    return errors;
  };

  const saveAndNext = async () => {
    try {
      const postData = JSON.stringify({ employee_id, experienceDetailArray });
      const response = await upsertExperience(postData);
      if (response.status === 200) {
        dispatch(setAllData(response.data.user));
        dispatch(setemployeeAllData(response.data.employeeAllDetail));
        if (adminLogin) {
          if (isUpdated === true) {
            setIsUpdated(false)
          } else {
            setIsUpdated(true)
          }
        }
        dispatch(setToast(true));
        dispatch(setToastMsg(response.data.message));
        dispatch(setToastType("success"));
      }
    } catch (error) {
      setDisplayError(errorMessage ?? "")
    }
  };

  const calYears = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const yearsDiff = end.getFullYear() - start.getFullYear();
    const isSameMonthAndDay =
      end.getMonth() === start.getMonth() && end.getDate() >= start.getDate();
    if (isSameMonthAndDay) {
      return yearsDiff;
    } else {
      const monthsDiff = (end.getMonth() - start.getMonth() + 12) % 12;
      const decimalYears = monthsDiff / 12;
      return yearsDiff + decimalYears;
    }
  };

  return (
    <Box>
      <MainBox ismobile={isMobile?"true":"false"}>
        <TitleBox>
          <Box>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Experience Details
            </Typography>
          </Box>
          <Box>
            {editEmployee ? null : (
              <Box>
                <IconButton
                  onClick={() => setEditEmployee(true)}
                  disabled={editEmployee}
                  sx={{ color: "var( --third-color)" }}
                >
                  <Edit />
                </IconButton>
              </Box>
            )}
          </Box>
        </TitleBox>
        <hr />
        <AddRecordBox>
          <Typography sx={{ ...viewProfileTitle }}>
            Experience Records
          </Typography>
          {editEmployee ? (
            <AddRecordButton
              variant="outlined"
              onClick={() => addRecord()}
            >
              + Add Record
            </AddRecordButton>
          ) : null}
        </AddRecordBox>
        <Box>
          {editEmployee ? (
            <Box>
              {experienceDetailArray?.map((record, index) => (
                <Box key={index} sx={viewEducationBox}>
                  <Box sx={{ marginBottom: "15px" }}>
                    <InputTextField
                      name="Company_Name"
                      disabled={!editEmployee}
                      value={experienceDetailArray[index].Company_Name}
                      onChange={(event) => handleInputChange1(index, event)}
                      label={
                        <>
                          Company Name
                          {renderAsterisk()}
                        </>
                      }
                    />
                    {experienceDetailErrorsArray[index]?.Company_Name && (
                      <Typography color="error">
                        {experienceDetailErrorsArray[index].Company_Name}
                      </Typography>
                    )}
                    <InputTextField
                      name="Position"
                      disabled={!editEmployee}
                      value={experienceDetailArray[index].Position}
                      onChange={(event) => handleInputChange1(index, event)}
                      label={
                        <>
                          Position
                          {renderAsterisk()}
                        </>
                      }
                    />
                    {experienceDetailErrorsArray[index]?.Position && (
                      <Typography color="error">
                        {experienceDetailErrorsArray[index].Position}
                      </Typography>
                    )}
                    <InputTextField
                      InputProps={{
                        startAdornment: <InputAdornment position="start" />,
                      }}
                      isdarktheme={isDarkTheme ? "true" : "false"}
                      name="Start_Date"
                      value={experienceDetailArray[index].Start_Date}
                      disabled={!editEmployee}
                      type="date"
                      onChange={(event) => handleInputChange1(index, event)}
                      label={
                        <>
                          Start Date
                          {renderAsterisk()}
                        </>
                      }
                    />
                    {experienceDetailErrorsArray[index]?.Start_Date && (
                      <Typography color="error">
                        {experienceDetailErrorsArray[index].Start_Date}
                      </Typography>
                    )}
                    <InputTextField
                      InputProps={{
                        startAdornment: <InputAdornment position="start" />,
                      }}
                      isdarktheme={isDarkTheme ? "true" : "false"} name="End_Date"
                      value={experienceDetailArray[index].End_Date}
                      disabled={!editEmployee}
                      type="date"
                      onChange={(event) => handleInputChange1(index, event)}
                      label={
                        <>
                          End Date
                          {renderAsterisk()}
                        </>
                      }
                    />
                    {experienceDetailErrorsArray[index]?.End_Date && (
                      <Typography color="error">
                        {experienceDetailErrorsArray[index].End_Date}
                      </Typography>
                    )}
                  </Box>
                  <Button
                    variant="outlined"
                    color="error"
                    disabled={!editEmployee}
                    sx={{ fontWeight: "bold" }}
                    onClick={() => removeRecord(index)}
                  >
                    Remove
                  </Button>
                </Box>
              ))}
            </Box>
          ) : (
            <Box>
              {experienceDetailArray?.map((record, index) => (
                <Box key={index} sx={{ marginBottom: "25px" }}>
                  <Box sx={viewEducationBox}>
                    <Typography sx={viewEducationTitle}>
                      {experienceDetailArray[index].Company_Name}
                    </Typography>
                    <Typography sx={viewExperiencePosition}>
                      {experienceDetailArray[index].Position}
                    </Typography>
                    <Typography sx={viewProfileSubtitle}>
                      {formatDate(experienceDetailArray[index].Start_Date)} -{" "}
                      {formatDate(experienceDetailArray[index].End_Date)}
                    </Typography>

                    <Box style={{ display: "inline" }}>
                      <Typography sx={viewProfileSubtitle}>
                        Experience :{" "}
                        {calYears(
                          experienceDetailArray[index].Start_Date,
                          experienceDetailArray[index].End_Date
                        ).toFixed(2)}{" "}
                        Years
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>
        <Typography sx={{ color: "red" }}>{displayError}</Typography>
        <Box>
          {editEmployee ? (
            <FooterBox>
              <CancelButton
                onClick={() => {
                  setEditEmployee(false);
                  setExperienceDetailArray(
                    employeeCall?.Experience_Records || []
                  );
                }}
              >
                Cancel
              </CancelButton>
              <SaveButton
                onClick={() => {
                  if (validateAllForms()) {
                    if (saveAndNext()) setEditEmployee(false);
                  }
                }}
              >
                Save
              </SaveButton>
              <SaveNextButton
                variant="contained"
                onClick={() => {
                  if (validateAllForms()) {
                    if (saveAndNext()) {
                      setEditEmployee(false);
                      saveNextButtonCallback();
                    }
                  }
                }}
              >
                Save & Next
              </SaveNextButton>
            </FooterBox>
          ) : (
            <></>
          )}
        </Box>
      </MainBox>
    </Box>
  );
}

export default UpdateExperience;
