import { createSlice } from "@reduxjs/toolkit";

const employeeDataSlice = createSlice({
  name: "addEmployee",
  initialState: {
    personal: {
      id: "",
      firstName: "",
      department: "",
      designation: "",
      jobCategory: "",
      jobDoj: "",
      dob: "",
      bloodGroup: "",
      gender: "",
      reportingPerson: [],
      role: "",
      trainingEndDate: '',
      specializations: [],
      isProbation: false,
      isIntern : false
    },
    contact: {
      contactPersonalNumber: "",
      contactAdditionalNumber: "",
      relation: "",
      contactEmail: "",
      personalContactEmail: "",
      contactState: "",
      contactCity: "",
      contactResidental: "",
    },
    academicDetails: [],
    professionalDetails: [],
    family: [],
    experience: [],
    // job: {
    //   Date_Of_Join: "",
    //   Department: "",
    //   Designation: "",
    //   JobCategory: "",
    //   Responsibilities: "",
    // },
    financial: [],
    current: {},
    employeeAllData: [],
    // employeeAllLeaveData: [],
    employeeAllAttendenceData: [],
    setAttendenceStartRecord: false,
    // setStartTimer: false,
    knowledgeData: {},
    employeeAdded: "",
    employeeDelete: {
      value: "",
    },
    employeeIndex: {
      value: "",
      empId: "",
    }
  },
  reducers: {
    set_index: (state, action) => {
      state.employeeDelete.value = action.payload;
    },
    setEmployeeIndexShow: (state, action) => {
      state.employeeIndex.value = action.payload;
    },
    setEmpId: (state, action) => {
      state.employeeIndex.empId = action.payload;
    },
    setKnowledgeData: (state, action) => {
      state.knowledgeData = action.payload;
    },
    set_id: (state, action) => {
      state.personal.id = action.payload;
    },
    set_Firstname: (state, action) => {
      state.personal.firstName = action.payload;
    },
    set_Department: (state, action) => {
      state.personal.department = action.payload;
    },
    set_Designation: (state, action) => {
      state.personal.designation = action.payload;
    },
    set_jobCategory: (state, action) => {
      state.personal.jobCategory = action.payload;
    },
    set_jobDoj: (state, action) => {
      state.personal.jobDoj = action.payload;
    },
    set_Dob: (state, action) => {
      state.personal.dob = action.payload;
    },
    set_BloodGroup: (state, action) => {
      state.personal.bloodGroup = action.payload;
    },
    set_gender: (state, action) => {
      state.personal.gender = action.payload;
    },
    setReportingPerson: (state, action) => {
      state.personal.reportingPerson = action.payload;
    },
    setRole: (state, action) => {
      state.personal.role = action.payload;
    },
    setTrainingEndDate: (state, action) => {
      state.personal.trainingEndDate = action.payload;
    },
    setProbation: (state, action) => {
      state.personal.isProbation = action.payload;
    },
    setIntern: (state, action) => {
      state.personal.isIntern = action.payload;
    },
    setSpecialization: (state, action) => {
      state.personal.specializations = action.payload;
    },
    set_number1: (state, action) => {
      state.contact.contactPersonalNumber = action.payload;
    },
    set_number2: (state, action) => {
      state.contact.contactAdditionalNumber = action.payload;
    },
    set_relation: (state, action) => {
      state.contact.relation = action.payload;
    },
    set_email: (state, action) => {
      state.contact.contactEmail = action.payload;
    },
    set_personalEmail: (state, action) => {
      state.contact.personalContactEmail = action.payload;
    },
    set_state: (state, action) => {
      state.contact.contactState = action.payload;
    },
    set_city: (state, action) => {
      state.contact.contactCity = action.payload;
    },
    set_resident: (state, action) => {
      state.contact.contactResidental = action.payload;
    },
    set_academic: (state, action) => {
      state.academicDetails = action.payload;
    },
    set_professional: (state, action) => {
      state.professionalDetails = action.payload;
    },
    set_family: (state, action) => {
      state.family = action.payload;
    },
    set_experience: (state, action) => {
      state.experience = action.payload;
    },
    // set_Date_Of_Join: (state, action) => {
    //   state.job.Date_Of_Join = action.payload;
    // },
    // set_jobDepartment: (state, action) => {
    //   state.job.Department = action.payload;
    // },
    // set_jobDesignation: (state, action) => {
    //   state.job.Designation = action.payload;
    // },
    // set_JobCategory: (state, action) => {
    //   state.job.JobCategory = action.payload;
    // },
    // set_Responsibilities: (state, action) => {
    //   state.job.Responsibilities = action.payload;
    // },
    set_financial: (state, action) => {
      state.financial = action.payload;
    },
    setCurrentEmployee: (state, action) => {
      state.current = action.payload;
    },
    setemployeeAllData: (state, action) => {
      state.employeeAllData = action.payload;
    },
    // setemployeeAllLeaveData: (state, action) => {
    //   state.employeeAllLeaveData = action.payload;
    // },
    setemployeeAllAttendenceData: (state, action) => {
      state.employeeAllAttendenceData = action.payload;
    },
    setSetAttendenceRecord: (state, action) => {
      state.setAttendenceStartRecord = action.payload;
    },
    // setSetStartTimer: (state, action) => {
    //   state.setStartTimer = action.payload;
    // },
    setEmployeeAdded: (state, action) => {
      state.employeeAdded = action.payload;
    },

    // setAttendenceRecord
    resetEmployeeDetail: (state) => {
      state.personal.id = "";
      state.personal.firstName = "";
      state.personal.department = "";
      state.personal.designation = "";
      state.personal.jobCategory = "";
      state.personal.jobDoj = "";
      state.personal.dob = "";
      state.personal.bloodGroup = "";
      state.personal.gender = "";
      state.personal.reportingPerson = [];
      state.personal.role = "";
      state.personal.trainingEndDate = "";
      state.personal.isProbation = false;
      state.personal.isIntern = false;
      state.personal.specializations = [];
      state.contact.contactPersonalNumber = "";
      state.contact.contactAdditionalNumber = "";
      state.contact.relation = "";
      state.contact.contactEmail = "";
      state.contact.personalContactEmail = "";
      state.contact.contactState = "";
      state.contact.contactCity = "";
      state.contact.contactResidental = "";
      state.academicDetails = [];
      state.professionalDetails = [];
      state.family = [];
      state.experience = [];
      // state.job.Date_Of_Join = "";
      // state.job.Department = "";
      // state.job.Designation = "";
      // state.job.JobCategory = "";
      // state.job.Responsibilities = "";
      state.financial = [];
    },
    resetEmployeeReducer: (state) => {
      state.current = {};
      state.employeeAllData = [];
      // state.employeeAllLeaveData = [];
      state.employeeAllAttendenceData = [];
      state.setAttendenceStartRecord = false;
      // state.setStartTimer = false;
      state.knowledgeData = {};
      state.employeeAdded = "";
    },
  },
});

export const {
  setKnowledgeData,
  setEmployeeIndexShow,
  setEmpId,
  set_id,
  set_Firstname,
  set_Department,
  set_Designation,
  set_jobCategory,
  set_jobDoj,
  set_Dob,
  set_BloodGroup,
  set_gender,
  setReportingPerson,
  setRole,
  setTrainingEndDate,
  setProbation,
  setIntern,
  setSpecialization,
  setemployeeAllData,
  // setemployeeAllLeaveData,
  setemployeeAllAttendenceData,
  set_number1,
  set_number2,
  set_relation,
  set_email,
  set_personalEmail,
  set_state,
  set_city,
  set_resident,
  set_academic,
  set_professional,
  set_family,
  set_experience,
  // set_Date_Of_Join,
  // set_jobDepartment,
  // set_jobDesignation,
  // set_JobCategory,
  // set_Responsibilities,
  set_financial,
  resetEmployeeDetail,
  setSetAttendenceRecord,
  // setSetStartTimer,
  setEmployeeAdded,
  setCurrentEmployee,
  resetEmployeeReducer,
  set_index
} = employeeDataSlice.actions;

export default employeeDataSlice.reducer;
