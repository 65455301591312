import { AccordionDetails, AccordionSummary, Box, Button, DialogTitle, TableCell, TableHead, TextField, Typography, styled } from "@mui/material"
import VisibilityIcon from "@mui/icons-material/Visibility";

export const TableHeads = styled(TableHead)(() => ({
    background: "var(--primary-highlight-color)",
    height: "70px",
}))

export const NoDataFoundTypography = styled(Typography)(() => ({
    color: "var(--secondary-text-color)",
    display: "flex",
    justifyContent: "center",
}))

export const TableCells = styled(TableCell)(({ tableBodyCell }) => ({
    ...tableBodyCell,
    fontWeight: "500 !important",
}))

export const VisibilityIcons = styled(VisibilityIcon)(() => ({
    fontSize: "1.5rem",
    color: "var(--secondary-text-color)",
    cursor: "pointer",
}))

export const LoadingBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "65vh"
}))

export const AccordionSummarys = styled(AccordionSummary)(() => ({
    background: "var(--subTaskdataGrid-background-color)",
    color: "var(--primary-text-color)",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
}))

export const AccordionsDetails = styled(AccordionDetails)(() => ({
    backgroundColor: "var(--subTaskdataGrid-background-color)",
    color: "var(--primary-text-color)",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
}))

export const LeaveAccordianTableHeads = styled(TableHead)(() => ({
    background: "var(--primary-highlight-color)",
}))

export const ViewLeaveLoadingBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "75vh"
}))

export const MainBox = styled(Box)(() => ({
    margin: "10px 0px 15px 0px",
    padding: "20px",
    backgroundColor: "var(--background-table-sidebar-card-color)",
    borderRadius: "5px",
    alignItems: "center",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
    overflowY: "auto",
    "@media (max-width: 600px)": {
        padding: "10px",
        margin: "5px 0px",
    },
}))

export const InnerBoxOne = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 0px 15px 10px",
    color: "var(--primary-text-color)",
}))

export const CustomTypography = styled(Typography)(({ viewprofilesubtitle }) => ({
    marginBottom: "10px",
    ...viewprofilesubtitle
}))

export const WarningMainBox = styled(Box)(() => ({
    backgroundColor: "#d8c08426",
    margin: "20px 20px 0px 0px",
    borderTop: "1px solid #DAA520",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    borderRadius: "5px",
}))

export const WarningTypography = styled(Typography)(() => ({
    color: "#DAA520",
    fontWeight: "bold",
    fontSize: "16px",
    padding: "20px 20px 15px 20px",
    alignContent: "center",
    display: "flex",
}))


export const WarningDetailBox = styled(Box)(() => ({
    color: "#DAA520",
    fontSize: "15px",
    alignContent: "center",
    display: "flex",
    padding: "0px 20px 20px 20px",
}))


export const ErrorTypography = styled(Typography)(() => ({
    color: "red",
    marginTop: "20px"
}))


export const FooterBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "end",
    marginTop: "30px",
}))

export const CancelButton = styled(Button)(() => ({
    fontWeight: "bold",
    color: "gray",
}))

export const CustomButton = styled(Button)(({ theme }) => ({
    fontWeight: "bold",
    marginLeft: theme.spacing(1),
}));

export const DialogTitles = styled(DialogTitle)(({ isdarktheme }) => ({
    fontWeight: "bold",
    fontSize: "20px",
    color: isdarktheme === "true" ? "#e0e0e0e3" : "#544f5a",
}))

export const CustomTextField = styled(TextField)(() => ({
    marginTop: "5px",
    width: "500px",
    ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline ": {
        borderColor: "var(--secondary-text-color)",
    },
    "@media (max-width: 600px)": {
        maxWidth: "100%",
    },
    "& fieldset legend > span": {
        paddingLeft: "2px",
        paddingRight: "0px",
    },
}))