import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GridToolbar } from "@mui/x-data-grid";
import { Box, Button, Typography, IconButton, DialogContent, DialogTitle, Link, Tooltip, Zoom } from "@mui/material";
import { Edit as EditIcon, Close, Delete as DeleteIcon } from "@mui/icons-material";

import RolesDialog from "./RolesDialog";
import { numberArray } from "../Constant/constant";
import usePermissions from "../RolePermission/usePermissions";
import DialogComponent from '../ReusableComponents/DialogBoxComponents/DialogComponent';
import { deleteRole, getAllRolesData } from '../../Services/ManageRoleServices'
import { setToast, setToastMsg, setToastType } from "../../Slices/toastSlice";
import { InnerBoxOne, MainBox, ManageRoleDataGrid, TitleTypography, InnerMainBox, AddRoleButton } from "../../Styles/ManageRoles/ManageRolesStyle";
import { DeleteButton, MainBoxDialog } from "../../Styles/KnowledgeSharing/KnowledgeSharingStyle";
import { InnerBoxOneDialog, CancelButton, ErrorTypography, InnerBoxTwoDialog, CloseIconButton, DialogsActions } from "../../Styles/WorkFlow/AddWorkFlowDialogStyle"
import { errorMessage } from '../../Services/axiosInstance';
import "../../assets/styles/DataGridStyles.css";

function ManageRoles() {
	const dispatch = useDispatch();
	const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
	const { hasPermission } = usePermissions();

	const [openDialogBox, setOpenDialogBox] = useState(false);
	const [buttonSelected, setButtonSelected] = useState("");
	const [openDialogName, setOpenDialogName] = useState("");
	const [employeeData, setEmployeeData] = useState([]);
	const [selectedRecord, setSelectedRecord] = useState("");
	const [change, setChange] = useState(true);
	const [dataGridHeight, setDataGridHeight] = useState(false);
	const [roleDeleted, setRoleDeleted] = useState(false);
	const [openDialogDelete, setOpenDialogDelete] = useState(false);
	const [_IdForDelete, set_IdForDelete] = useState("");
	const [_dataThatDelete, set_DataThatDelete] = useState({});
	const [displayError, setDisplayError] = useState("");

	const FormattedTaskData = employeeData?.map((task, index) => ({
		id: index + 1,
		role_Id: task?._id,
		role: task?.role,
		groupname: task?.groupName.groupName,
		groupId: task?.groupName._id,
		levelName: task?.levelName,
	}));

	const column = [
		{ field: "role", headerName: "Role", flex: 1 },
		{ field: "groupname", headerName: "Group", flex: 1 },
		{ field: "levelName", headerName: "Level", flex: 1 },
		{
			field: "actions",
			headerName: "Actions",
			flex: 0.7,
			renderCell: (params) => (
				<Box sx={{ display: "flex", gap: "2px" }}>
					{hasPermission('EditRole') && <Tooltip
						title="Edit"
						placement="left"
						arrow
						TransitionComponent={Zoom}
					>
						<IconButton
							sx={{
								color: isDarkTheme ? "#e0e0e0e3" : "#544f5a",
							}}
							variant="contained"
							onClick={() => {
								setOpenDialogBox(true);
								setButtonSelected("Update Role");
								setOpenDialogName("Update Role");
								setSelectedRecord(params.row.role);
							}}
						>
							<EditIcon />
						</IconButton>
					</Tooltip>}

					{hasPermission('DeleteRole') && <Tooltip
						title="Delete"
						placement="right"
						arrow
						TransitionComponent={Zoom}
					>
						<IconButton
							sx={{
								color: isDarkTheme ? "#d94a38" : "#c62828",
							}}
							variant="contained"
							onClick={(e) => {
								e.stopPropagation();
								setOpenDialogDelete(true);
								set_IdForDelete(params?.row);
								set_DataThatDelete(params?.row);
							}
							}
						>
							<DeleteIcon />
						</IconButton>
					</Tooltip>}
				</Box>
			),
		},
	];

	const onClose = () => {
		set_IdForDelete("");
		setOpenDialogDelete(false);
		setDisplayError("")
	}

	const handleDelete = async (data) => {
		try {
			const response = await deleteRole(data?.role_Id)
			if (response.status === 200) {
				if (response.data.message === "Role deleted.") {
					const filterData = employeeData?.filter((record) => record?._id !== data?.role_Id);
					setEmployeeData(filterData);
					onClose();
					dispatch(setToast(true));
					dispatch(setToastMsg(response.data.message));
					dispatch(setToastType("success"));
					setRoleDeleted(!roleDeleted);
				} else {
					dispatch(setToast(true));
					dispatch(setToastMsg(response.data.message));
					dispatch(setToastType("warning"));
				}
			}
		} catch (error) {
			setDisplayError(errorMessage ?? "")
		}
	};

	const renderFormDelete = () => {
		return (
			<>
				<Box sx={{ background: isDarkTheme ? "#121e2e" : "#ffffff", padding: "8px" }}>
					<MainBoxDialog>
						<Box>
							<DialogTitle sx={{ fontWeight: "bold", padding: "0px 0px", fontSize: { xs: "1.2rem", sm: "1.5rem" } }}>
								<InnerBoxOneDialog>
									<Box sx={{ padding: "8px 5px", }}>
										<InnerBoxTwoDialog
											isdarktheme={isDarkTheme ? "true" : "false"}
										/>
									</Box>
									<Box>
										<Box
											sx={{
												fontSize: { xs: "1rem", sm: "1.2rem" },
												color: isDarkTheme ? "#e0e0e0e3" : "#544f5a",
											}}
										>
											Confirm Delete Status
										</Box>
									</Box>
								</InnerBoxOneDialog>
							</DialogTitle>
						</Box>
						<Box>
							<CloseIconButton
								onClick={onClose}
								isdarktheme={isDarkTheme ? "true" : "false"}
							>
								<Close />
							</CloseIconButton>
						</Box>
					</MainBoxDialog>
					<DialogContent sx={{ padding: "6px 4px 5px 7px" }}>
						<Typography
							sx={{
								color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82",
							}}
						>
							Are you sure you want to Delete{" "}
							<span
								style={{
									textDecoration: "underline",
								}}
							>
								{_dataThatDelete?.role}
							</span>{" "}
							Status?
						</Typography>
					</DialogContent>
					<DialogsActions displayError={displayError}>
						<Box sx={{ background: isDarkTheme ? "#121e2e" : "#ffffff" }}>
							<ErrorTypography sx={{ paddingLeft: "0px" }}>{displayError}</ErrorTypography>
						</Box>
						<Box>
							<CancelButton
								isdarktheme={isDarkTheme ? "true" : "false"}
								onClick={onClose}
							>
								Cancel
							</CancelButton>
							<DeleteButton
								variant="contained"
								onClick={() => {
									handleDelete(_IdForDelete);
								}}
								component={Link}
								color="error"
							>
								Delete
							</DeleteButton>
						</Box>
					</DialogsActions>
				</Box>

			</>
		);
	}

	const memoizedgetRolesData = useMemo(() => {
		const getRolesData = async () => {
			try {
				const response = await getAllRolesData()
				if (response.status === 200) {
					setEmployeeData(response.data.employeeRecord);
				}
				else {
					setEmployeeData([])
				}
			} catch (error) {
			}
		};
		return getRolesData;
	}, []);

	useEffect(() => {
		memoizedgetRolesData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Box>
			<MainBox bottom={0}
			>
				<TitleTypography variant="h6">
					Manage Roles
				</TitleTypography>
				<InnerMainBox>
					{hasPermission('Add Role') && <AddRoleButton
						variant="contained"
						color="primary"
						onClick={() => {
							setOpenDialogBox(true);
							setButtonSelected("Add Role");
							setOpenDialogName("Role Name");
						}}
					>
						+ Add Role
					</AddRoleButton>}

					{hasPermission('Add Group') && <Button
						variant="contained"
						color="primary"
						onClick={() => {
							setOpenDialogBox(true);
							setButtonSelected("Add Group");
							setOpenDialogName("Group Name");
						}}
						sx={{
							marginRight: "15px",
							'@media (max-width: 600px)': {
								display: "flex",
								justifyItems: "flex-end"
							},
						}}
					>
						+ Add Group
					</Button>}
				</InnerMainBox>
			</MainBox>
			<InnerBoxOne sx={{ width: "100%", overflowX: 'auto' }}>
				<Box
					sx={{
						'@media (max-width: 600px)': {
							width: '200%',
						},
					}}>
					<ManageRoleDataGrid
						disableColumnMenu
						rowHeight={43}
						slots={{ toolbar: GridToolbar }}
						slotProps={{
							toolbar: {
								showQuickFilter: true,
							},
						}}
						rows={FormattedTaskData}
						columns={column}
						initialState={{
							pagination: {
								paginationModel: {
									pageSize:
										parseInt(
											window.localStorage.getItem(
												"paginationNo"
											),
											10
										) || 10,
								},
							},
						}}
						onRowCountChange={(params) => {
							if (params === 0) {
								setDataGridHeight(true);
							} else {
								setDataGridHeight(false);
							}
						}}
						onPaginationModelChange={(pageSize) => {
							window.localStorage.setItem(
								"paginationNo",
								pageSize.pageSize
							);
						}}
						onPageSizeChange={(pageSize) => {
							window.localStorage.setItem("paginationNo", pageSize);
						}}
						pageSizeOptions={numberArray}
						components={{
							Toolbar: GridToolbar,
						}}
						dataGridHeight={dataGridHeight}
					/>
				</Box>
			</InnerBoxOne>
			<RolesDialog
				selectedRecord={selectedRecord}
				FormattedTaskData={FormattedTaskData}
				buttonSelected={buttonSelected}
				open={openDialogBox}
				setOpenDialogBox={setOpenDialogBox}
				openDialogName={openDialogName}
				setChange={setChange}
				change={change}
				setEmployeeData={setEmployeeData}
			/>
			<DialogComponent maxWidth="md" onClose={onClose} open={openDialogDelete} renderChildern={renderFormDelete} />
		</Box>
	);
}

export default ManageRoles;
