import {
  Autocomplete,
  Box,
  Button,
  Select,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import {
  commonStyle
} from "../../components/ReusableComponents/CustomDesignMUI/CustomMUI";

export const MainBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "-2px",
  color: "var(--secondary-text-color)",
}));

export const EmpIdTypography = styled(Typography)(() => ({
  fontWeight: "bold",
  fontSize: "15px",
  marginLeft: "9px",
  marginTop: "10px",
}));

export const FormBox = styled(Box)(() => ({
  marginTop: "30px",
  marginLeft: "9px",
}));

export const InputTextField = styled(TextField)(({ themechange }) => ({
  width: "97%",
  height: "55px",
  background: themechange === "true" ? "#142840" : "#ffffff",
  ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline ": {
    border: themechange === "true" ? " " : "#fffffff",
  },
  ...commonStyle
}));

export const GenderSelect = styled(Select)(({ themechange }) => ({
  width: "97%",
  height: "55px",
  background: themechange === "true" ? "#142840" : "#ffffff",
}));

export const CustomSelect = styled(Select)(
  ({ themechange, employeelogin }) => ({
    width: "97%",
    height: "55px",
    background:
      employeelogin === "true"
        ? themechange === "true"
          ? "#2d4663"
          : "#d5d5d5"
        : themechange === "true"
          ? "#142840"
          : "#ffffff",
  })
);

export const DOBTextField = styled(TextField)(({ themechange }) => ({
  width: "97%",
  height: "55px",
  color: themechange === "true" ? "white" : "black",
  background: themechange === "true" ? "#142840" : "#ffffff",
  '& input[type="date"]::-webkit-calendar-picker-indicator': {
    filter: themechange === "true" ? "invert(1)" : "none",
  },
}));

export const BloodGroupSelect = styled(Select)(({ themechange }) => ({
  width: "97%",
  height: "55px",
  background: themechange === "true" ? "#142840" : "#ffffff",
}));

export const DesignationTextField = styled(TextField)(
  ({ themechange, employeelogin, editemployee }) => ({
    width: "97%",
    height: "55px",
    background:
      employeelogin === "true"
        ? editemployee === "true"
          ? themechange === "true"
            ? "#2d4663"
            : "#d5d5d5"
          : themechange === "true"
            ? "#142840"
            : "#ffffff"
        : themechange === "true"
          ? "#142840"
          : "#ffffff",
    ...commonStyle
  }),
);

export const ReportingPersonAutocomplete = styled(Autocomplete)(() => ({
  ".cMuiButtonBase-root-MuiChip-root": {
    background: "var(--tag-background-color)",
  },
  ".MuiSvgIcon-root": {
    fill: "var(--tag-close-icon-color)",
  },
  ".MuiAutocomplete-tagSizeMedium": {
    color: "var(--primary-text-color)",
  },
  ".MuiAutocomplete-clearIndicator": {
    color: "var(--primary-text-color)",
  },
  width: "97%",
}));

export const DOBJoiningTextField = styled(TextField)(
  ({ themechange, employeelogin, editemployee }) => ({
    width: "97%",
    height: "55px",
    background:
      employeelogin === "true"
        ? editemployee === "true"
          ? themechange === "true"
            ? "#2d4663"
            : "#d5d5d5"
          : themechange === "true"
            ? "#142840"
            : "#ffffff"
        : themechange === "true"
          ? "#142840"
          : "#ffffff",
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
      filter: themechange === "true" ? "invert(1)" : "none",
    },
    ...commonStyle
  }),
);

export const TrainingEndDateTextField = styled(TextField)(
  ({ themechange }) => ({
    width: "97%",
    height: "55px",
    background: themechange === "true" ? "#142840" : "#ffffff",
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
      filter: themechange === "true" ? "invert(1)" : "none",
    },
  })
);

export const FooterBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "end",
  marginTop: "30px",
  padding: "5px",
}));

export const SaveButton = styled(Button)(() => ({
  fontWeight: "bold",
  color: "var(--primary-color)",
  textTransform: "capitalize",
  fontSize: "18px",
}));

export const SaveNextButton = styled(Button)(() => ({
  fontWeight: "bold",
  backgroundColor: "var(--secondary-color)",
  color: "#ffffff",
}));

export const MainBoxDialog = styled(Box)(({ isDarkTheme }) => ({
  color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "500px",
}));

export const InnerBoxOne = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "35px 0px",
}));

export const InnerBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "35px 0px",
}));

export const InnerBoxTwo = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "#ff000221",
  borderRadius: "1000px",
  padding: "8px",
  marginRight: "25px",
}));

export const CancelButton = styled(Button)(() => ({
  color: "var(--secondary-text-color)",
  textTransform: "capitalize",
  fontWeight: "bold",
  marginRight: "10px",
  borderRadius: "5px",
}));

export const ActiveButton = styled(Button)(() => ({
  borderRadius: "5px",
  textTransform: "capitalize",
}));

export const ErrorTypography = styled(Typography)(() => ({
  color: "red",
  marginTop: "20px",
}));

export const DateTextField = styled(TextField)(({ themechange }) => ({
  width: "97%",
  height: "55px",
  background: themechange === "true" ? "#142840" : "#ffffff",
  '& input[type="date"]::-webkit-calendar-picker-indicator': {
    filter: themechange === "true" ? "invert(1)" : "none",
  },
}));
