import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	Box, Button, IconButton, TextField, Typography,
	DialogContent,
	DialogTitle,
	useMediaQuery,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { Close,  Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon, Https as HttpsIcon } from "@mui/icons-material";

import { setAllData } from "../../../Slices/UserDataSlice.js";
import { setemployeeAllData } from "../../../Slices/employeeDetailSlice.js";
import { setToast, setToastMsg, setToastType } from "../../../Slices/toastSlice.js";
import {
	viewEducationBox,
	viewProfileTitle,
	viewEducationTitle,
	viewProfileSubtitle,
} from "../../ReusableComponents/CustomDesignMUI/CustomMUI";
import { renderAsterisk } from "../../ReusableComponents/UtilityFunctions.js";
import DialogComponent from '../../ReusableComponents/DialogBoxComponents/DialogComponent.js'
import { upsertCheckPassword, upsertFinancial } from "../../../Services/EmployeeManageServices.js";
import { errorMessage } from "../../../Services/axiosInstance.js";
import { AddRecordBox, ErrorTypography, CloseIconButton, MainBox, MainBoxDialog, TitleBox, AddRecordButton, InputTextField, SaveButton, InnerBoxOne, InnerBoxTwo, PasswordBox, PasswordTitleBox, CancelButtonDialog, ConfirmButtonDialog } from "../../../Styles/UpdateEmployee/UpdateFinancialStyle.js";
import { FooterBox } from "../../../Styles/UpdateEmployee/UpdatePersonalStyle.js";
import { CancelButton } from "../../../Styles/UpdateEmployee/UpdateContactStyle.js";
import { DialogsActions } from "../../../Styles/Authentication/LogOutStyle.js";

function UpdateFinancial(props) {
	const { employeeCall, setIsUpdated, isUpdated } = props;
	const dispatch = useDispatch();
	const isMobile = useMediaQuery("(max-width:600px)");
	const user = useSelector((state) => state.selectedActiveView.role);
	const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
	const adminLogin = user === "admin";
	// const employeeLogin = user === "employee";

	const [enteredPassword, setEnteredPassword] = useState("");
	const [displayError, setDisplayError] = useState("");
	const [callFrom, setCallFrom] = useState("");
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [showBankindDetails, setShowBankindDetails] = useState(false);
	const [editEmployee, setEditEmployee] = useState(false);
	const [financialDetailArray, setFinancialDetailArray] = useState(
		employeeCall?.Financial_Details || []
	);
	const [financialDetailErrorsArray, setFinancialDetailErrorsArray] = useState([]);
	const employee_id = employeeCall?.empId;

	const getEmptyRecordFinancialDetail = () => ({
		Bank_Name: "",
		Account_Number: "",
		Holder_Name: "",
		Ifsc_Code: "",
		Branch_Name: "",
	});

	const onCloseDialog = () => {
		setEnteredPassword("");
		setDisplayError("");
		setShowConfirmPassword(false);
	}

	const getEmptyErrorsFinancialDetailErrors = () => ({
		Bank_Name: "",
		Account_Number: "",
		Holder_Name: "",
		Ifsc_Code: "",
		Branch_Name: "",
	});

	const addRecord = () => {
		if (validateAllForms()) {
			setFinancialDetailArray((prevArray) => [
				...prevArray,
				getEmptyRecordFinancialDetail(),
			]);
			setFinancialDetailErrorsArray((prevErrorsArray) => [
				...prevErrorsArray,
				getEmptyErrorsFinancialDetailErrors(),
			]);
		}
	};

	const handleInputChange1 = (index, event) => {
		const { name, value } = event.target;
		setFinancialDetailArray((prevArray) =>
			prevArray?.map((record, i) =>
				i === index ? { ...record, [name]: value } : record
			)
		);
	};

	const removeRecord = (index) => {
		setFinancialDetailArray((prevArray) =>
			prevArray.filter((_, i) => i !== index)
		);
		setFinancialDetailErrorsArray((prevErrorsArray) =>
			prevErrorsArray.filter((_, i) => i !== index)
		);
	};

	const validateAllForms = () => {
		const allErrors = financialDetailArray?.map((record, index) =>
			validateForm(index)
		);
		setFinancialDetailErrorsArray(allErrors);
		return allErrors.every((errors) =>
			Object.values(errors).every((error) => !error)
		);
	};

	const validateForm = (index) => {
		const record = financialDetailArray[index];
		const errors = getEmptyErrorsFinancialDetailErrors();
		if (!record.Bank_Name) {
			errors.Bank_Name = "Bank name is required";
		} else if (!/^[a-zA-Z\s]*$/.test(record.Bank_Name)) {
			errors.Bank_Name = "Bank name should not contain numeric values";
		}
		if (!record.Account_Number) {
			errors.Account_Number = "Account no is required";
		} else if (!/^\d+$/.test(record.Account_Number)) {
			errors.Account_Number =
				"Account no. should contain only numeric values";
		}
		if (!record.Holder_Name) {
			errors.Holder_Name = "Account holder name is required";
		} else if (!/^[a-zA-Z\s]*$/.test(record.Holder_Name)) {
			errors.Holder_Name =
				"Account holder name should not contain numeric values";
		}
		if (!record.Ifsc_Code) {
			errors.Ifsc_Code = "IfscCode is required";
		}
		if (!record.Branch_Name) {
			errors.Branch_Name = "Branch name is required";
		} else if (!/^[a-zA-Z\s]*$/.test(record.Branch_Name)) {
			errors.Branch_Name =
				"Bank Branch name should not contain numeric values";
		}
		return errors;
	};

	const saveAndNext = async () => {
		try {
			const postData = JSON.stringify({ employee_id, financialDetailArray });
			const response = await upsertFinancial(postData)
			if (response.status === 200 || response.status === 201) {
				dispatch(setAllData(response.data.user));
				dispatch(setemployeeAllData(response.data.employeeAllDetail));
				if (adminLogin) {
					if (isUpdated === true) {
						setIsUpdated(false);
					} else {
						setIsUpdated(true);
					}
				}
				dispatch(setToast(true));
				dispatch(setToastMsg(response.data.message));
				dispatch(setToastType("success"));
			}
		} catch (error) {
			setDisplayError(errorMessage ?? "")
		}
	};

	const checkPassword = async () => {
		if (adminLogin) {
			if (callFrom === "Edit") {
				setEditEmployee(true);
			} else {
				setShowBankindDetails(true);
			}
			setShowConfirmPassword(false);
		}
		try {
			const postData = JSON.stringify({ enteredPassword });
			const response = await upsertCheckPassword(postData)
			if (response.status === 200 || response.status === 201) {
				if (callFrom === "Edit") {
					setEditEmployee(true);
				} else {
					setShowBankindDetails(true);
				}
				onCloseDialog();
				dispatch(setToast(true));
				dispatch(setToastMsg(response.data.message));
				dispatch(setToastType("success"));
			} else {
				setDisplayError("Wrong Password entered");
				setEnteredPassword("");
			}
		} catch (error) {
			setDisplayError(errorMessage ?? "Wrong Password entered");
			setEnteredPassword("");
		}
	};

	const renderForm = () => {
		return (
			<>
				<Box
					sx={{ background: isDarkTheme ? "#121e2e" : "#ffffff" }}
				>
					<Box>
						<CloseIconButton
							onClick={onCloseDialog}
							isdarktheme={isDarkTheme ? "true" : "false"}
						>
							<Close />
						</CloseIconButton>
					</Box>
					<MainBoxDialog>
						<Box>
							<DialogTitle sx={{ fontWeight: "bold" }}>
								<InnerBoxOne>
									<InnerBoxTwo>
										<HttpsIcon
											style={{
												fontSize: "xx-large",
												fill: "#2e7d32",
											}}
										/>
									</InnerBoxTwo>
									<Box>
										<PasswordTitleBox
											isdarktheme={isDarkTheme ? "true" : "false"}
										>
											{"Enter Your Password!"}
										</PasswordTitleBox>
									</Box>
								</InnerBoxOne>
							</DialogTitle>
						</Box>
					</MainBoxDialog>
					<DialogContent>
						<PasswordBox isdarktheme={isDarkTheme ? "true" : "false"}>
							<TextField
								fullWidth
								type="password"
								label="Password"
								name="Password"
								value={enteredPassword}
								onChange={(event) =>
									setEnteredPassword(
										event.target.value
									) && displayError("")
								}
								variant="outlined"
							></TextField>
						</PasswordBox>
						<ErrorTypography>
							{displayError}
						</ErrorTypography>
					</DialogContent>

					<DialogsActions>
						<CancelButtonDialog
							onClick={onCloseDialog}
							isdarktheme={isDarkTheme ? "true" : "false"}
						>
							Cancel
						</CancelButtonDialog>
						<ConfirmButtonDialog
							onClick={() => checkPassword()}
							variant="contained"
							component={Link}
							color="success"
						>
							Confirm
						</ConfirmButtonDialog>
					</DialogsActions>
				</Box>
			</>
		)
	}

	useEffect(() => {
		if (adminLogin) {
			if (callFrom === "Edit") {
				setEditEmployee(true);
			} else {
				setShowBankindDetails(true);
			}
			onCloseDialog()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showConfirmPassword]);

	return (
		<Box>
			<MainBox ismobile={isMobile?"true":"false"}>
				<TitleBox>
					<Box>
						<Typography variant="h6" sx={{ fontWeight: "bold" }}>
							Financial Details
						</Typography>
					</Box>
					<Box sx={{ display: "flex" }}>
						{editEmployee ? null : (
							<Box>
								<IconButton
									onClick={() => {
										setCallFrom("Edit");
										setShowConfirmPassword(true);
										if (
											financialDetailArray.length !== 0 &&
											adminLogin
										) {
											setEditEmployee(true);
										}
									}}
									disabled={editEmployee}
									sx={{ color: "var( --third-color)" }}
								>
									<Edit />
								</IconButton>
							</Box>
						)}

						{editEmployee ? null : !showBankindDetails ? (
							<Box>
								<IconButton
									onClick={() => {
										setCallFrom("Eye");
										setShowConfirmPassword(true);
									}}
									sx={{ color: "var( --third-color)" }}
								>
									<VisibilityIcon />
								</IconButton>
							</Box>
						) : (
							<Box>
								<IconButton
									onClick={() => setShowBankindDetails(false)}
									sx={{ color: "var( --third-color)" }}
								>
									<VisibilityOffIcon />
								</IconButton>
							</Box>
						)}
					</Box>
				</TitleBox>
				<hr />
				<AddRecordBox>
					<Typography sx={{ ...viewProfileTitle }}>
						Financial Records
					</Typography>
					{editEmployee ? (
						<AddRecordButton
							variant="outlined"
							onClick={() => addRecord()}
						>
							+ Add Record
						</AddRecordButton>
					) : null}
				</AddRecordBox>
				<Box>
					{editEmployee ? (
						<Box>
							{financialDetailArray?.map((record, index) => (
								<Box key={index} sx={viewEducationBox}>
									<Box sx={{ marginBottom: "15px" }}>
										<InputTextField
											name="Bank_Name"
											disabled={!editEmployee}
											value={
												financialDetailArray[index]
													.Bank_Name
											}
											onChange={(event) =>
												handleInputChange1(index, event)
											}
											label={
												<>
												  Bank Name
												  {renderAsterisk()}
												</>
											  }
										/>
										{financialDetailErrorsArray[index]
											?.Bank_Name && (
												<Typography color="error">
													{
														financialDetailErrorsArray[
															index
														].Bank_Name
													}
												</Typography>
											)}
										<InputTextField
											name="Account_Number"
											disabled={!editEmployee}
											value={
												financialDetailArray[index]
													.Account_Number
											}
											onChange={(event) =>
												handleInputChange1(index, event)
											}
											label={
												<>
												  Account Number
												  {renderAsterisk()}
												</>
											  }
										/>
										{financialDetailErrorsArray[index]
											?.Account_Number && (
												<Typography color="error">
													{
														financialDetailErrorsArray[
															index
														].Account_Number
													}
												</Typography>
											)}
										<InputTextField
											name="Holder_Name"
											disabled={!editEmployee}
											value={
												financialDetailArray[index]
													.Holder_Name
											}
											onChange={(event) =>
												handleInputChange1(index, event)
											}
											label={
												<>
												  Holders Name
												  {renderAsterisk()}
												</>
											  }
										/>
										{financialDetailErrorsArray[index]
											?.Holder_Name && (
												<Typography color="error">
													{
														financialDetailErrorsArray[
															index
														].Holder_Name
													}
												</Typography>
											)}
										<InputTextField
											name="Ifsc_Code"
											disabled={!editEmployee}
											value={
												financialDetailArray[index]
													.Ifsc_Code
											}
											onChange={(event) =>
												handleInputChange1(index, event)
											}
											label={
												<>
												 IFSC code
												  {renderAsterisk()}
												</>
											  }
										/>
										{financialDetailErrorsArray[index]
											?.Ifsc_Code && (
												<Typography color="error">
													{
														financialDetailErrorsArray[
															index
														].Ifsc_Code
													}
												</Typography>
											)}
										<InputTextField
											disabled={!editEmployee}
											name="Branch_Name"
											value={
												financialDetailArray[index]
													.Branch_Name
											}
											onChange={(event) =>
												handleInputChange1(index, event)
											}
											label={
												<>
												Branch Name
												  {renderAsterisk()}
												</>
											  }
										/>
										{financialDetailErrorsArray[index]
											?.Branch_Name && (
												<Typography color="error">
													{
														financialDetailErrorsArray[
															index
														].Branch_Name
													}
												</Typography>
											)}
									</Box>
									<Button
										variant="outlined"
										color="error"
										disabled={!editEmployee}
										sx={{ fontWeight: "bold" }}
										onClick={() => removeRecord(index)}
									>
										Remove
									</Button>
								</Box>
							))}
						</Box>
					) : (
						<Box>
							{financialDetailArray?.map((record, index) => (
								<Box key={index} sx={{ marginBottom: "25px" }}>
									<Box sx={viewEducationBox}>
										<Typography sx={viewEducationTitle}>
											Account Holder Name:{" "}
											{showBankindDetails
												? financialDetailArray[index]
													.Holder_Name
												: "*****"}
										</Typography>
										<Typography sx={viewEducationTitle}>
											Account Number:{" "}
											{showBankindDetails
												? financialDetailArray[index]
													.Account_Number
												: "*****"}
										</Typography>
										<Typography sx={viewProfileSubtitle}>
											Bank Name:{" "}
											{showBankindDetails
												? financialDetailArray[index]
													.Bank_Name
												: "*****"}
										</Typography>
										<Typography sx={viewProfileSubtitle}>
											IFSC Code:{" "}
											{showBankindDetails
												? financialDetailArray[index]
													.Ifsc_Code
												: "*****"}
										</Typography>
										<Typography sx={viewProfileSubtitle}>
											Branch Name:{" "}
											{showBankindDetails
												? financialDetailArray[index]
													.Branch_Name
												: "*****"}
										</Typography>
									</Box>
								</Box>
							))}
						</Box>
					)}
				</Box>
				<Box>
					{editEmployee ? (
						<FooterBox>
							<CancelButton
								onClick={() => {
									setEditEmployee(false);
									setFinancialDetailArray(
										employeeCall?.Financial_Details
									);
								}}
							>
								Cancel
							</CancelButton>
							<SaveButton
								variant="contained"
								onClick={() => {
									if (validateAllForms()) {
										if (saveAndNext()) {
											setEditEmployee(false);
										}
									}
								}}
							>
								Save
							</SaveButton>
						</FooterBox>
					) : (
						<Box></Box>
					)}
				</Box>
			</MainBox>
			<Box>
				<Box>
					<DialogComponent renderChildern={renderForm} maxWidth="md" onClose={onCloseDialog} open={showConfirmPassword} />
				</Box>
			</Box>
		</Box>
	);
}

export default UpdateFinancial;
