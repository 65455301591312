import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Grid, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { DashboardProfileButtons } from "../ReusableComponents/CustomDesignMUI/CustomMUI";
import UpdateContact from "../UpdateEmployee/UpdateContact/UpdateContact";
import UpdateEducation from "../UpdateEmployee/UpdateEducation/UpdateEducation";
import UpdateFamily from "../UpdateEmployee/UpdateFamily/UpdateFamily";
import UpdateExperience from "../UpdateEmployee/UpdateExperience/UpdateExperience";
import UpdatePersonal from "../UpdateEmployee/UpdatePersonal/UpdatePersonal";
import UpdateFinancial from "../UpdateEmployee/UpdateFinancial/UpdateFinancial";
import UpdateAttendance from "../UpdateEmployee/UpdateAttendance/UpdateAttendance";
import EmployeeEfficiency from "../TaskManagement/EmployeeEfficiency/EmployeeEfficiency";
import Loader from "../Loader/Loader";
import ShowEmployeeBalance from "../UpdateEmployee/ShowBalance/ShowEmployeeBalance";
import { getEmpDetails } from "../../Services/EmployeeManageServices";
import { LoadingBox, RenderTabBox, LeaveBalanceButton, TaskEfficiencyButton, MainBoxProfile, AttendanceDetailsButton, PersonalDetailsButton, ContactDetailsButton, EducationQualificationButton, FamilyDetailsButton, ExperienceDetailsButton, FinancialDetailsButton, DangerZoneButton } from "../../Styles/UpdateEmployee/ViewProfileStyle"
import DangarZone from "../DisplayEmployee/DangarZone";
import "./../AdminDashboard/Dashboard.scss";

const ViewProfile = () => {
  const navigate = useNavigate();
  const { employeeId } = useParams();
  const onAdminId = useSelector((state) => state.adminData.adminData?.empId);

  const [selectedTab, setSelectedTab] = useState("personal");
  const [showAttendanceDetails, setShowAttendanceDetails] = useState(false);
  const [showLeaveBalance, setShowLeaveBalance] = useState(false);
  const [showEfficiency, setShowEfficiency] = useState(false);
  const [employeeCall, setEmployeeCall] = useState([]);
  const [isUpdated, setIsUpdated] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const saveNextButtonCallback = () => {
    handleNext();
  };

  const tabOrder = [
    "personal",
    "contact",
    "education",
    "family",
    "experience",
    // "job",
    "financial",
    "attendance",
    "efficiency",
    "danger"
  ];

  const handleNext = () => {
    let index = tabOrder.indexOf(selectedTab);
    setSelectedTab(tabOrder[index + 1]);
  };

  const handleTabChange = (tab) => {
    // balance
    if (tab === "attendance") {
      setShowAttendanceDetails(true);
    } else if (tab === "balance") {
      setShowLeaveBalance(true);
    } else if (tab === "efficiency") {
      setShowEfficiency(true);
    } else {
      setSelectedTab(tab);
      setShowAttendanceDetails(false);
    }
  };

  const renderTabContent = () => {
    if (selectedTab === "personal") {
      return (
        <UpdatePersonal
          image={true}
          employeeCall={employeeCall}
          saveNextButtonCallback={saveNextButtonCallback}
          setIsUpdated={setIsUpdated}
          isUpdated={isUpdated}
        />
      );
    } else if (selectedTab === "contact") {
      return (
        <UpdateContact
          employeeCall={employeeCall}
          saveNextButtonCallback={saveNextButtonCallback}
          setIsUpdated={setIsUpdated}
          isUpdated={isUpdated}
        />
      );
    } else if (selectedTab === "education") {
      return (
        <UpdateEducation
          employeeCall={employeeCall}
          saveNextButtonCallback={saveNextButtonCallback}
          setIsUpdated={setIsUpdated}
          isUpdated={isUpdated}
        />
      );
    } else if (selectedTab === "family") {
      return (
        <UpdateFamily
          employeeCall={employeeCall}
          saveNextButtonCallback={saveNextButtonCallback}
          setIsUpdated={setIsUpdated}
          isUpdated={isUpdated}
        />
      );
    } else if (selectedTab === "experience") {
      return (
        <UpdateExperience
          employeeCall={employeeCall}
          saveNextButtonCallback={saveNextButtonCallback}
          setIsUpdated={setIsUpdated}
          isUpdated={isUpdated}
        />
      );
    }
    // else if (selectedTab === "job") {
    //   return (
    //     <UpdateJob
    //       employeeCall={employeeCall}
    //       saveNextButtonCallback={saveNextButtonCallback}
    //     />
    //   );
    // }
    else if (selectedTab === "financial") {
      return (
        <UpdateFinancial
          employeeCall={employeeCall}
          setIsUpdated={setIsUpdated}
          isUpdated={isUpdated}
        />
      );
    } else if (selectedTab === "attendance") {
      return <UpdateAttendance employeeCall={employeeCall} />;
    }
    else if (selectedTab === "danger") {
      return <DangarZone employeeCall={employeeCall} />;
    }
  };

  const handleBackButtonClick = () => {
    setSelectedTab("personal");
    setShowAttendanceDetails(false);
  };

  const handleBackLeaveBalance = () => {
    setSelectedTab("personal");
    setShowLeaveBalance(false);
  };

  const handleBackShowEfficiency = () => {
    setSelectedTab("personal");
    setShowEfficiency(false);
  };

  const onBackClick = () => {
    navigate(-1);
  };

  const memoizedgetData = useMemo(() => {
    const getData = async () => {
      setIsLoading(true);
      try {
        const response = await getEmpDetails(employeeId)
        if (response.status === 200 || response.status === 201) {
          setEmployeeCall(response.data.user);
          setIsLoading(false);
        }
        else {
          setEmployeeCall([]);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    };
    return getData;
  }, [employeeId]);

  useEffect(() => {
    memoizedgetData();
  }, [memoizedgetData, employeeId, isUpdated]);

  return (
    <>
      {isLoading ? (
        <LoadingBox>
          <Loader />
        </LoadingBox>
      ) :
        showLeaveBalance ?
          <ShowEmployeeBalance
            employeeName={employeeCall?.FirstName}
            employeeEmpId={employeeCall?.empId}
            handleBackLeaveBalance={handleBackLeaveBalance}
          />
          :
          showEfficiency ? (
            <EmployeeEfficiency
              employeeName={employeeCall?.FirstName}
              employeeEmpId={employeeCall?.empId}
              handleBackShowEfficiency={handleBackShowEfficiency}
            />
          ) : (
            <>
              {showAttendanceDetails ? (
                <UpdateAttendance
                  employeeEmpId={employeeCall?.empId}
                  onBackButtonClick={handleBackButtonClick}
                />
              ) : (
                <Box
                  sx={{
                    marginTop: "0px",
                  }}
                >
                  <Grid container item spacing={3}>
                    <Grid container item xs={12} md={3.5}>
                      <MainBoxProfile>
                        <Box sx={{ margin: "-10px 0px 5px -10px" }}>
                          <IconButton onClick={onBackClick}>
                            <ArrowBackIcon color="primary" />
                          </IconButton>
                        </Box>
                        <PersonalDetailsButton
                          onClick={() => handleTabChange("personal")}
                          dashboardprofilebuttons={DashboardProfileButtons}
                          selectedtab={selectedTab}
                        >
                          Personal Details
                        </PersonalDetailsButton>
                        <ContactDetailsButton
                          onClick={() => handleTabChange("contact")}
                          dashboardprofilebuttons={DashboardProfileButtons}
                          selectedtab={selectedTab}
                        >
                          Contact Details
                        </ContactDetailsButton>
                        <EducationQualificationButton
                          onClick={() => handleTabChange("education")}
                          dashboardprofilebuttons={DashboardProfileButtons}
                          selectedtab={selectedTab}
                        >
                          Education Qualification
                        </EducationQualificationButton>
                        <FamilyDetailsButton
                          onClick={() => handleTabChange("family")}
                          dashboardprofilebuttons={DashboardProfileButtons}
                          selectedtab={selectedTab}
                        >
                          Family Details
                        </FamilyDetailsButton>
                        <ExperienceDetailsButton
                          onClick={() => handleTabChange("experience")}
                          dashboardprofilebuttons={DashboardProfileButtons}
                          selectedtab={selectedTab}
                        >
                          Experience Details
                        </ExperienceDetailsButton>
                        {/* <Button
                  onClick={() => handleTabChange("job")}
                  sx={{
                    ...DashboardProfileButtons,
                    backgroundColor:
                      selectedTab === "job"
                        ? "var(--primary-highlight-color)"
                        : "var(--background-table-sidebar-card-color)",
                    color:
                      selectedTab === "job"
                        ? "var(--primary-color)"
                        : "var(--primary-text-color)",
                    fontWeight: selectedTab === "job" ? "bold" : "normal",
                    border: "none",
                  }}
                >
                  Job Details
                </Button> */}
                        <FinancialDetailsButton
                          onClick={() => handleTabChange("financial")}
                          dashboardprofilebuttons={DashboardProfileButtons}
                          selectedtab={selectedTab}
                        >
                          Financial Details
                        </FinancialDetailsButton>
                        <AttendanceDetailsButton
                          onClick={() => handleTabChange("attendance")}
                          dashboardprofilebuttons={DashboardProfileButtons}
                          selectedtab={selectedTab}
                        >
                          Attendance Details
                        </AttendanceDetailsButton>
                        <TaskEfficiencyButton
                          onClick={() => handleTabChange("efficiency")}
                          dashboardprofilebuttons={DashboardProfileButtons}
                          selectedtab={selectedTab}
                        >
                          Task Efficiency
                        </TaskEfficiencyButton>
                        <LeaveBalanceButton
                          onClick={() => handleTabChange("balance")}
                          dashboardprofilebuttons={DashboardProfileButtons}
                          selectedtab={selectedTab}
                        >
                          Leave Balance
                        </LeaveBalanceButton>
                        {employeeCall?.empId !== onAdminId ? <DangerZoneButton
                          onClick={() => handleTabChange("danger")}
                          dashboardprofilebuttons={DashboardProfileButtons}
                          selectedtab={selectedTab}
                        >
                          Danger Zone
                        </DangerZoneButton> : null}
                      </MainBoxProfile>
                    </Grid>
                    <Grid container item xs={12} md={8.5}>
                      <RenderTabBox>
                        {renderTabContent()}
                      </RenderTabBox>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </>
          )}
    </>
  );
};

export default ViewProfile;
