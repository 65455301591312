import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Draggable from "react-draggable";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  AccountTree as AccountTreeIcon,
  Close as CloseIcon,
  EditCalendar as EditCalendarIcon,
  HelpOutline as HelpOutlineIcon,
  Assignment as AssignmentIcon,
  AssignmentInd as AssignmentIndIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  Done as DoneIcon,
  Clear as ClearIcon,
  FormatListBulleted as FormatListBulletedIcon,
  ContentPasteSearch as ContentPasteSearchIcon,
  AccessTimeFilled as AccessTimeFilledIcon,
  Category as CategoryIcon,
  Flag as FlagIcon,
  AccessTime as AccessTimeIcon,
  LocalOffer as LocalOfferIcon,
  AccessAlarms as AccessAlarmsIcon,
  SlowMotionVideo as SlowMotionVideoIcon,
  ChatBubble as ChatBubbleIcon
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  CardContent,
  Grid,
  IconButton,
  MenuItem,
  Slide,
  TextField,
  Tooltip,
  Typography,
  styled,
  Paper,
  InputAdornment,
  Collapse,
  Chip,
  useMediaQuery,
} from "@mui/material";

import {
  setToast,
  setToastMsg,
  setToastType,
} from "../../Slices/toastSlice.js";
import { setUpdatedTask } from "../../Slices/taskSlice.js";
import {
  setComplateTaskCount,
  setIsSetDefaultRow,
} from "../../Slices/selectedActive";
import {
  TypeCommon,
  dateWithMonthName,
  extractDTime,
  extractTDate,
  getIcon,
} from "../ReusableComponents/UtilityFunctions.js";
import {
  priorityArray,
  taskStatusList,
  taskTypeOptions,
} from "../Constant/constant.js";
import EmployeeProfile from "./EmplopyeeProfile.js";
import Loader from "../Loader/Loader.js";
import DetailTaskCommentSection from "./DetailTaskCommentSection.js";
import DialogComponent from "../ReusableComponents/DialogBoxComponents/DialogComponent.js";
import SubTask from "./SubTask.js";
import {
  editApprovedStatus,
  editTask,
  editTaskDescription,
  editTaskPlannedHours,
  getTaskById,
  upsertComment,
  addTags,
  getTaskType,
} from "../../Services/TaskManageServices.js";
import { fetchCategories } from "../../Services/TaskManageServices.js";
import { errorMessage } from "../../Services/axiosInstance.js";
import {
  LoadingBox,
  MainBox,
  SideBox,
  ActualHourBox,
  PriorityTextField,
  ClearIcons,
  DoneIcons,
  TicketNoBox,
  TaskTitleTextField,
  MainCard,
  MainGrid,
  CloseBox,
  CreatedOnBox,
  InnerBoxOne,
  InnerBoxTwo,
  EditDueDateBox,
  TitleBox,
  ApprovedBox,
  TypographyActivity,
  DueDateBox,
  CollapseTypography,
  SubTaskTypography,
} from "../../Styles/TaskManagement/DetailTaskStyle";
import { setIsRunningTask } from "../../Slices/timerDetailsSlice.js";
import {
  LinearsProgress,
  SubTaskBox,
} from "../../Styles/TaskManagement/SubTaskStyle.js";

const StyledDatePicker = styled(DatePicker)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
  },
});

const PaperComponent = (props) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
};

const TaskDialogBox = (props) => {
  const { open, setOpenDialog, taskId, viewer, unapprovedTask } = props;
  const isMobile = useMediaQuery("(max-width:600px)");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const user = useSelector((state) => state.selectedActiveView.role);
  const allData = useSelector((state) => state.taskData.employeesNames);
  const isSetDefaultRow = useSelector(
    (state) => state.selectedActiveView.isSetDefaultRow
  );
  const complateTaskCount = useSelector(
    (state) => state.selectedActiveView.complateTaskCount
  );

  const currentEmployeeLogin = user === "employee";
  const adminLogin = user === "admin";
  let currentDate = dayjs().endOf("day");
  
  const [showForm, setShowForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [change, setChange] = useState(false);
  const [comment, setComment] = useState("");
  const [commentError, setCommentError] = useState("");
  const [name, setUser] = useState("");
  const [errorPlannedHours, setErrorPlannedHours] = useState([]);
  const [description, setDescription] = useState("");
  const [showDescriptionEdit, setShowDescriptionEdit] = useState(false);
  const [showHoursEdit, setShowHoursEdit] = useState(false);
  const [taskData, setTaskData] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [parentTaskList, setParentTaskList] = useState([]);
  const [reversedHistory, setReversedHistory] = useState([]);
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [sprints, setSprints] = useState([]);
  const [displayMessageError, setDisplayMessageError] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [statusList, setStatusList] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [isOpenMobile, setIsOpenMobile] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [formData, setFormData] = useState({
    _id: "",
    assignToEmp: "",
    assignTo: "",
    assignBy: "",
    assignByEmp: "",
    status: "",
    statusValue: 0,
    statusColor: "",
    actualHours: "",
    billableHours: "",
    priority: "",
    category: "",
    type: "",
    taskType: "",
    parentTaskId: "",
    dueDate: "",
    plannedHours: "",
    title: "",
    tags: [],
    sprint: "",
  });
  const [error, setError] = useState({
    description: "",
    actualHours: "",
  });
  const [tempTitle, setTempTitle] = useState(formData.title);

  const modules = {
    toolbar: [
      [
        { header: "1" },
        { header: "2" },
        { font: [] },
        { size: [] },
        { color: [] },
        { background: [] },
        "bold",
        "italic",
        "underline",
        // "strike",
        // "blockquote",
        "link",
        "code-block",
        { list: "ordered" },
        { list: "bullet" },
        // { indent: "-1" },
        { indent: "+1" },
        // { script: "sub" },
        // { script: "super" },
      ],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "color",
    "background",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "link",
    "code-block",
    "list",
    "bullet",
    "indent",
    "script",
  ];

  // const onClose = () => {
  //   dispatch(
  //     setComplateTaskCount({ totalCompletedTasks: 0, totalCount: 0, task: 0 })
  //   );
  //   setOpenDialog(false);
  //   dispatch(setIsSetDefaultRow(false));
  //   navigate({
  //     pathname: window.location.pathname,
  //     search: "",
  //   });
  // };
  const onClose = () => {
    dispatch(
      setComplateTaskCount({ totalCompletedTasks: 0, totalCount: 0, task: 0 })
    );
    setOpenDialog(false);
    dispatch(setIsSetDefaultRow(false));

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete("tNo");
    navigate({
      pathname: window.location.pathname,
      search: searchParams.toString(),
    });
  };

  const handleBoxClick = () => {
    setIsEditing(true);
  };

  const handleResetDescription = (e) => {
    setDescription(taskData?.description || "");
    setShowDescriptionEdit(false);
  };

  const handleResetHours = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      plannedHours: taskData?.plannedHours,
    }));
    setShowHoursEdit(false);
  };

  const handleActualHoursChange = (event) => {
    const value = event.target.value;
    setShowHoursEdit(true);
    setErrorPlannedHours([]);
    generateSuggestions(value);
    handleChange({ target: { name: event.target.name, value } });
  };

  const generateSuggestions = (input) => {
    let options = [];
    const hoursMatch = input.match(/(\d+)\s*(h|hours)/i);
    const minutesMatch = input.match(/(\d+)\s*(m|minutes)/i);

    let hours = hoursMatch ? parseInt(hoursMatch[1], 10) : 0;
    let minutes = minutesMatch ? parseInt(minutesMatch[1], 10) : 0;

    if (!isNaN(input) && input.trim() !== "") {
      const numericValue = parseInt(input, 10);
      options.push(`${numericValue} hour${numericValue > 1 ? "s" : ""}`);
    }

    if (minutes > 0) {
      options.push(`${minutes} minute${minutes > 1 ? "s" : ""}`);
    }

    if (hours > 0 || minutes > 0) {
      options.push(
        `${hours} hour${hours > 1 ? "s" : ""} ${minutes} minute${minutes > 1 ? "s" : ""
        }`
      );
    }

    setSuggestions(options);
  };

  const handleSuggestionClick = (suggestion) => {
    const currentInput = formData.plannedHours;
    const hoursMatch = currentInput.match(/(\d+)\s*(h|hours)/i);
    const minutesMatch = suggestion.match(/(\d+)\s*(m|minutes)/i);

    let hours = hoursMatch ? parseInt(hoursMatch[1], 10) : 0;
    let minutes = minutesMatch ? parseInt(minutesMatch[1], 10) : 0;

    const isHourSuggestion = suggestion.toLowerCase().includes("hour");
    const isMinuteSuggestion = suggestion.toLowerCase().includes("minute");

    if (isHourSuggestion) {
      hours = parseInt(suggestion) || hours;
    } else if (isMinuteSuggestion) {
      minutes = parseInt(suggestion) || minutes;
    }

    setFormData((prevData) => ({
      ...prevData,
      plannedHours: `${hours} hour${hours > 1 ? "s" : ""} ${minutes} minute${minutes > 1 ? "s" : ""
        }`,
    }));

    setSuggestions([]);
  };

  const handleDateChange = (newValue) => {
    const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
    setFormData((prevData) => ({
      ...prevData,
      dueDate: formattedDate,
    }));
  };

  const getStatusBackgroundColor = (status) => {
    switch (status) {
      case "In Progress":
        return "var(--pending-color)";
      case "Cancelled":
        return "var(--reject-color)";
      case "Completed":
        return "var(--approved-color)";
      case "Hold":
        return "var(--cancel-color)";
      case "To-Do":
        return "var(--primary-color)";
      default:
        return "var(--primary-color)";
    }
  };

  const textfieldSelectStyle = {
    ".MuiOutlinedInput-input": {
      color: isDarkTheme ? "#e0e0e0cc !important" : "#5e6e82 !important",
      padding: "10px 0px 5px 10px",
      borderColor: "white",
    },
    fontSize: "6px",
    ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: isDarkTheme ? "#e0e0e0cc" : "#5e6e82",
    },
    svg: {
      fill: isDarkTheme ? "#e0e0e0cc" : "#5e6e82",
    },
    color: isDarkTheme ? "#e0e0e0cc !important" : "#484848",
  };

  const textfieldstyle = {
    ".MuiOutlinedInput-input": {
      color: isDarkTheme ? "#e0e0e0cc !important" : "#5e6e82 !important",
      padding: "10px 0px 5px 10px",
      borderColor: "white",
    },
    fontSize: "6px",
  };

  const linkStyle = {
    color: isDarkTheme ? "#e6e6e6" : "black",
    textDecoration: "none",
    fontWeight: "bold",
  };

  const boxStyleGrid = {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "0px 0px 3px 0px",
    color: isDarkTheme ? "#e0e0e0cc" : "#484848",
    fontWeight: "bold",
  };

  const cardStyle = {
    backgroundColor: isDarkTheme ? "#142840" : "#ffffff",
    ".MuiCardContent-root": {
      padding: "10px 0px 10px 8px",
    },
  };

  const toggleForm = (user) => {
    setShowForm(!showForm);
    setUser(user);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const localTaskNo = localStorage.getItem("TaskNo");
    const localStart = localStorage.getItem("start") === "true";
    const isFinal = statusList?.find((item) => item?._id === value)?.isFinal;

    if (
      formData._id !== "" &&
      localStart &&
      taskData?.ticketNo === localTaskNo &&
      isFinal
    ) {
      dispatch(setToast(true));
      dispatch(setToastMsg("Timer is Running on this Task"));
      dispatch(setToastType("error"));
      return;
    } else if (
      formData?._id !== "" &&
      taskData?.ticketNo === localTaskNo &&
      isFinal
    ) {
      dispatch(setIsRunningTask(true));
    }

    setFormData((prevData) => {
      const newStatus =
        name === "statusValue"
          ? statusList.find((item) => item?._id === value)?.status
          : name === "status"
            ? value
            : prevData?.status;
      const color =
        name === "statusValue"
          ? statusList.find((item) => item?._id === value)?.color
          : prevData?.statusColor;
      return {
        ...prevData,
        [name]: value,
        status: newStatus,
        statusColor: color,
      };
    });
  };

  const handleSelect = (event, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      assignTo: event.target.value,
    }));
    if (value) {
      const selectedEmployee = allData?.find(
        (employee) => employee.firstName === event.target.value
      );
      if (selectedEmployee) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          assignToEmp: selectedEmployee.empId,
        }));
      }
    }
  };

  const saveDescription = async () => {
    try {
      setDisplayMessageError("");
      const getUser = currentEmployeeLogin
        ? "user"
        : adminLogin
          ? "admin"
          : null;
      const id = formData._id;
      const oldValue = taskData?.description;
      const postData = JSON.stringify({ description, oldValue, id });
      const response = await editTaskDescription(getUser, postData);
      if (response.status === 200 || response.status === 201) {
        setShowDescriptionEdit(false);
        if (change === true) {
          setChange(false);
        } else {
          setChange(true);
        }
        dispatch(setToast(true));
        dispatch(setToastMsg(response.data.msg));
        dispatch(setToastType("success"));
      }
      if (response.status === 202) {
      }
    } catch (error) {
      setDisplayMessageError(errorMessage ?? "");
    }
  };

  const savePlannedHours = async () => {
    try {
      setDisplayMessageError("");
      const getUser = currentEmployeeLogin
        ? "user"
        : adminLogin
          ? "admin"
          : null;
      const id = formData._id;
      const oldValue = taskData?.plannedHours;
      const newValue = formData?.plannedHours;
      const postData = JSON.stringify({ newValue, oldValue, id });
      const response = await editTaskPlannedHours(getUser, postData);
      if (response.status === 200 || response.status === 201) {
        setShowHoursEdit(false);
        if (change === true) {
          setChange(false);
        } else {
          setChange(true);
        }
        dispatch(setToast(true));
        dispatch(setToastMsg(response.data.msg));
        dispatch(setToastType("success"));
      }
      if (response.status === 202) {
      }
    } catch (error) {
      setDisplayMessageError(errorMessage ?? "");
    }
  };

  const SaveComment = async (e) => {
    if (comment) {
      const id = taskData?._id;
      try {
        const getUser = currentEmployeeLogin
          ? "user"
          : adminLogin
            ? "admin"
            : null;
        const postData = JSON.stringify({ id, comment });
        const response = await upsertComment(getUser, postData);
        if (response.status === 200 || response.status === 201) {
          setComment("");
          if (change === true) {
            setChange(false);
          } else {
            setChange(true);
          }
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message));
          dispatch(setToastType("success"));
        }
      } catch (error) {
        setCommentError(errorMessage ?? "");
      }
    } else {
      setCommentError("Comment cant be empty");
    }
  };

  const handleSubmit = async (name) => {
    const errors = [];
    if (name === "description") {
      if (!description) {
        errors.description = "Description is required";
      }
      setError({
        description: errors.description,
      });
    } else if (name === "hours") {
      if (!formData.plannedHours) {
        errors.plannedHours = "Planned hours is required";
      } else {
        const hoursMinutesRegex = /^(\d+):(\d{2})$/;
        const hoursKeywordRegex = /(\d+)\s*(h|hours)/i;
        const minutesKeywordRegex = /(\d+)\s*(m|minutes)/i;

        let hours = 0;
        let minutes = 0;

        if (hoursMinutesRegex.test(formData.plannedHours)) {
          const [, hh, mm] = formData.plannedHours.match(hoursMinutesRegex);
          hours = parseInt(hh, 10);
          minutes = parseInt(mm, 10);
        } else {
          if (hoursKeywordRegex.test(formData.plannedHours)) {
            hours = parseInt(
              formData.plannedHours.match(hoursKeywordRegex)[1],
              10
            );
          } else if (!isNaN(formData.plannedHours)) {
            hours = parseInt(formData.plannedHours, 10);
          }

          if (minutesKeywordRegex.test(formData.plannedHours)) {
            minutes = parseInt(
              formData.plannedHours.match(minutesKeywordRegex)[1],
              10
            );
          }
        }

        const totalMinutes = hours * 60 + minutes;
        const formattedHours = String(Math.floor(totalMinutes / 60)).padStart(
          2,
          "0"
        );
        const formattedMinutes = String(totalMinutes % 60).padStart(2, "0");
        formData.plannedHours = `${formattedHours}:${formattedMinutes}`;

        if (formData.plannedHours === "00:00") {
          errors.plannedHours = "Planned hours cannot be zero";
        }
      }
      setErrorPlannedHours(errors);
    }

    const noErrors = Object.keys(errors).length === 0;
    if (noErrors) {
      if (name === "description") {
        saveDescription();
      } else if (name === "hours") savePlannedHours();
    }
    return errors;
  };

  const changeTaskApproved = async () => {
    if (unapprovedTask) {
      setIsLoading(true);
      try {
        const getUser = currentEmployeeLogin
          ? "user"
          : adminLogin
            ? "admin"
            : null;
        const id = taskData._id;
        const postData = JSON.stringify({ id });
        const response = await editApprovedStatus(getUser, postData);
        if (response.status === 200 || response.status === 201) {
          setIsLoading(false);
          if (change === true) {
            setChange(false);
          } else {
            setChange(true);
          }
          dispatch(setToast(true));
          dispatch(
            setToastMsg(response.data.msg ?? "approved status updated in task.")
          );
          dispatch(setToastType("success"));
        }
      } catch (error) {
        setIsLoading(false);
        setDisplayMessageError(errorMessage ?? "");
      }
    }
  };

  const handleAddTag = async (tag) => {
    const tagTitle = typeof tag === "string" ? tag : tag.title;
    const trimmedTag = tagTitle.trim();

    if (!trimmedTag) {
      setError({ tag: "Tag cannot be empty" });
      return;
    }

    if (tags.some((t) => t.title === trimmedTag)) {
      setInputValue("");
      setError({ tag: "This tag already exists" });
      return;
    }

    try {
      const existingTag = options.find((t) => t.title === trimmedTag);
      if (existingTag) {
        const updatedTags = [...tags, existingTag];
        setTags(updatedTags);
        setFormData((prevData) => ({
          ...prevData,
          tags: updatedTags.map((t) => t._id),
        }));
        setInputValue("");
        setError({ tag: null });
        return;
      }

      const randomColor = `#${Math.floor(Math.random() * 16777215)
        .toString(16)
        .padStart(6, "0")}`;
      const postData = { title: trimmedTag, color: randomColor };
      const response = await addTags(postData);

      if (response.status === 200 || response.status === 201) {
        const newTag = await response.data;
        const updatedTags = [...tags, newTag];
        setTags(updatedTags);
        setFormData((prevData) => ({
          ...prevData,
          tags: updatedTags.map((t) => t._id),
        }));
        setInputValue("");
        setError({ tag: null });
        setOptions((prevOptions) => [...prevOptions, newTag]);
        return;
      } else if (response.status === 400) {
        setError({ tag: "Invalid tag submission" });
      } else {
        setError("Failed to add tag");
      }
    } catch (error) {
      setError({ tag: "Error adding tag to the database" });
    }
  };

  const handleDeleteTag = (tagToDelete) => () => {
    const updatedTags = tags.filter((tag) => tag._id !== tagToDelete._id);
    setTags(updatedTags);
    setFormData((prevData) => ({
      ...prevData,
      tags: updatedTags.map((tag) => tag._id),
    }));
  };

  const handleTitleChange = (e) => {
    setTempTitle(e.target.value);
  };

  const saveTitleLocally = () => {
    const newTitle = `${tempTitle}`;
    setFormData((prevData) => ({
      ...prevData,
      title: newTitle,
    }));
    setIsEditing(false);
  };

  const cancelEdit = () => {
    setTempTitle(formData.title);
    setIsEditing(false);
  };

  const handleSideToggle = () => {
    setIsOpen((prev) => !prev);
  };

  const handleSideToggleMobile = () => {
    setIsOpenMobile((prev) => !prev);
  };

  const renderActivity = () => {
    return (
      <>
        <DetailTaskCommentSection
          handleSideToggleMobile={handleSideToggleMobile}
          cardStyle={cardStyle}
          taskData={taskData}
          reversedHistory={reversedHistory}
          linkStyle={linkStyle}
          toggleForm={toggleForm}
          commentError={commentError}
          setCommentError={setCommentError}
          SaveComment={SaveComment}
          viewer={viewer}
          comment={comment}
          setComment={setComment}
          tags={tags}
          typeOptions={typeOptions}
        />
      </>
    );
  };

  const CustomLabel = ({ tooltipText, isDarkTheme }) => {
    return (
      <>
        <Tooltip
          placement="top"
          title={
            <Typography
              sx={{
                fontSize: "14px",
              }}
            >
              {tooltipText}
            </Typography>
          }
          arrow
        >
          <ActualHourBox isdarktheme={isDarkTheme ? "true" : "false"}>
            <HelpOutlineIcon
              sx={{
                fontSize: "16px",
              }}
              color="primary"
            />
          </ActualHourBox>
        </Tooltip>
      </>
    );
  };

  const renderForm = () => {
    return (
      <>
        <MainBox isdarktheme={isDarkTheme ? "true" : "false"}>
          {isLoading ? (
            <LoadingBox>
              <Loader />
            </LoadingBox>
          ) : (
            <>
              {isMobile ? (
                <InnerBoxOne
                  ismobile={isMobile ? "true" : "false"}
                  id="draggable-dialog-title"
                  isdarktheme={isDarkTheme ? "true" : "false"}
                >
                  <InnerBoxTwo
                    ismobile={isMobile ? "true" : "false"}
                    isdarktheme={isDarkTheme ? "true" : "false"}
                  >
                    <TitleBox isdarktheme={isDarkTheme ? "true" : "false"}>
                      <Box sx={{ display: "flex" }}>{taskData?.ticketNo}</Box>
                      {unapprovedTask || taskData?.approved !== null ? (
                        <ApprovedBox>
                          {taskData.approved ? (
                            <Button
                              variant="contained"
                              color="success"
                              sx={{ padding: "1px 5px" }}
                            >
                              Approved
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="warning"
                              sx={{ padding: "1px 5px" }}
                              onClick={() => changeTaskApproved()}
                            >
                              unApproved
                            </Button>
                          )}
                        </ApprovedBox>
                      ) : null}
                    </TitleBox>
                    <CloseBox>
                      <IconButton onClick={onClose}>
                        <CloseIcon color="primary" />
                      </IconButton>
                    </CloseBox>
                  </InnerBoxTwo>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "space-between",
                      marginLeft: "5px",
                      paddingBottom: "2px",
                    }}
                  >
                    <Box sx={{ flexDirection: "column" }}>
                      <DueDateBox ismobile={isMobile ? "true" : "false"}>
                        <Box>
                          DueDate:&nbsp;
                          {dateWithMonthName(formData?.dueDate)}
                        </Box>
                      </DueDateBox>
                      <Tooltip
                        title="Edit Due Date"
                        placement="bottom"
                        arrow
                        sx={{ marginTop: "-10px" }}
                      >
                        <EditDueDateBox ismobile={isMobile ? "true" : "false"}>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="en-gb"
                          >
                            <StyledDatePicker
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  border: "1px solid gray",
                                  width: "70%",
                                  "& .MuiInputBase-input": {
                                    padding: "3px 5px",
                                  },
                                },
                                ".MuiSvgIcon-root": {
                                  height: "16px",
                                },
                              }}
                              slots={{
                                openPickerIcon: EditCalendarIcon,
                              }}
                              slotProps={{
                                openPickerButton: {
                                  color: "primary",
                                },
                              }}
                              minDate={currentDate}
                              onChange={(date) => handleDateChange(date)}
                              value={dayjs(formData?.dueDate)}
                            />
                          </LocalizationProvider>
                        </EditDueDateBox>
                      </Tooltip>
                      <Box>
                        <Tooltip
                          title={`assigned by ${formData?.assignBy
                            } at ${extractDTime(taskData?.timestamp)}`}
                          placement="bottom"
                          arrow
                          sx={{ marginTop: "-10px" }}
                        >
                          <CreatedOnBox
                            ismobile={isMobile ? "true" : "false"}
                            isdarktheme={isDarkTheme ? "true" : "false"}
                          >
                            Created on {extractTDate(taskData?.timestamp)}&nbsp;
                          </CreatedOnBox>
                        </Tooltip>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginRight: "6px",
                      }}
                    >
                      <TypographyActivity onClick={handleSideToggleMobile}>
                        Activity
                      </TypographyActivity>
                    </Box>
                    {isOpenMobile && (
                      <DialogComponent
                        open={isOpenMobile}
                        onClose={handleSideToggleMobile}
                        renderChildern={renderActivity}
                        maxWidth="3000px"
                        PaperComponent={""}
                        PaperProps={{
                          sx: {
                            backgroundColor: isDarkTheme
                              ? "#0b1727"
                              : "#ffffff",
                            marginLeft: "3%",
                            marginRight: "3%",
                            "@media (max-width: 600px)": {
                              width: "100%",
                            },
                            overflow: "auto",
                            height: "auto",
                            boxShadow: "none !important",
                            zIndex: 1100,
                          },
                        }}
                      />
                    )}
                  </Box>
                </InnerBoxOne>
              ) : (
                <InnerBoxOne
                  id="draggable-dialog-title"
                  isdarktheme={isDarkTheme ? "true" : "false"}
                >
                  <InnerBoxTwo isdarktheme={isDarkTheme ? "true" : "false"}>
                    <TitleBox isdarktheme={isDarkTheme ? "true" : "false"}>
                      <Box sx={{ display: "flex" }}>{taskData?.ticketNo}</Box>
                      {unapprovedTask || taskData?.approved !== null ? (
                        <ApprovedBox>
                          {taskData.approved ? (
                            <Button
                              variant="contained"
                              color="success"
                              sx={{ padding: "1px 5px" }}
                            >
                              Approved
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="warning"
                              sx={{ padding: "1px 5px" }}
                              onClick={() => changeTaskApproved()}
                            >
                              unApproved
                            </Button>
                          )}
                        </ApprovedBox>
                      ) : null}
                    </TitleBox>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <DueDateBox>
                        <Box>
                          DueDate:&nbsp;
                          {dateWithMonthName(formData?.dueDate)}
                        </Box>

                        <Tooltip
                          title="Edit Due Date"
                          placement="bottom"
                          arrow
                          sx={{ marginTop: "-10px" }}
                        >
                          <EditDueDateBox>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale="en-gb"
                            >
                              <StyledDatePicker
                                sx={{
                                  ".MuiInputBase-input": {
                                    display: "none",
                                  },
                                  ".MuiSvgIcon-root": {
                                    height: "16px",
                                  },
                                }}
                                slots={{
                                  openPickerIcon: EditCalendarIcon,
                                }}
                                slotProps={{
                                  openPickerButton: {
                                    color: "primary",
                                  },
                                }}
                                minDate={currentDate}
                                onChange={(date) => {
                                  handleDateChange(date);
                                }}
                                value={dayjs(formData?.dueDate)}
                              />
                            </LocalizationProvider>
                          </EditDueDateBox>
                        </Tooltip>
                      </DueDateBox>
                      <Box>
                        <Tooltip
                          title={`assign by  ${formData?.assignBy
                            } at ${extractDTime(taskData?.timestamp)}`}
                          placement="bottom"
                          arrow
                          sx={{ marginTop: "-10px" }}
                        >
                          <CreatedOnBox
                            isdarktheme={isDarkTheme ? "true" : "false"}
                          >
                            Created on {extractTDate(taskData?.timestamp)}
                            &nbsp;
                          </CreatedOnBox>
                        </Tooltip>
                      </Box>
                    </Box>
                  </InnerBoxTwo>
                  <CloseBox>
                    <IconButton onClick={onClose}>
                      <CloseIcon color="primary" />
                    </IconButton>
                  </CloseBox>
                </InnerBoxOne>
              )}
              <MainGrid container spacing={1}>
                <Grid
                  sx={{ overflowX: "auto" }}
                  item
                  sm={12}
                  lg={isOpen ? 7.3 : 11.3}
                >
                  <MainCard isMobile={isMobile} cardstyle={cardStyle}>
                    <CardContent>
                      <Grid container spacing={2} sx={{ width: "100%" }}>
                        <Grid item xs={12}>
                          {isEditing ? (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <TaskTitleTextField
                                value={tempTitle}
                                onChange={handleTitleChange}
                                autoFocus
                                multiline={true}
                                fullWidth
                                variant="outlined"
                                size="small"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment
                                      position="start"
                                      sx={{
                                        color: isDarkTheme
                                          ? "#e0e0e0e3"
                                          : "#544f5a",
                                      }}
                                    >
                                      {/* {taskData?.ticketNo}: */}
                                    </InputAdornment>
                                  ),
                                  style: {
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    paddingLeft: "10px",
                                    color: isDarkTheme ? "#e6e6e6" : "black",
                                  },
                                }}
                                isdarktheme={isDarkTheme ? "true" : "false"}
                              />

                              <IconButton onClick={saveTitleLocally}>
                                <DoneIcon style={{ color: "green" }} />
                              </IconButton>

                              <IconButton onClick={cancelEdit}>
                                <ClearIcon style={{ color: "red" }} />
                              </IconButton>
                            </Box>
                          ) : (
                            <TicketNoBox
                              isdarktheme={isDarkTheme ? "true" : "false"}
                              onClick={handleBoxClick}
                            >
                              {formData?.title}
                            </TicketNoBox>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              padding: isMobile
                                ? "0px 0px 8px 4px"
                                : "0px 0px 8px 6px",
                            }}
                          >
                            <Box
                              sx={{
                                position: "relative",
                                display: "flex",
                                alignItems: "center",
                                marginLeft: "6px",
                              }}
                            >
                              <ReactQuill
                                className={isDarkTheme ? "ReactQuillDark" : ""}
                                style={{
                                  color: isDarkTheme ? "#e0e0e0" : "#5e6e82",
                                  flex: 1,
                                  ".qlSnow .qlEditor": {
                                    minHeight: "100px !important",
                                  },
                                  ".qlSnow .qlFill": {
                                    fill: "red !important",
                                  },
                                }}
                                theme="snow"
                                value={description}
                                onChange={(content, delta, source, editor) => {
                                  setDescription(editor.getHTML());
                                  setShowDescriptionEdit(true);
                                }}
                                modules={modules}
                                formats={formats}
                                placeholder="Description..."
                              />
                            </Box>
                            <Typography color="error">
                              {error.description}
                            </Typography>
                          </Box>
                          {showDescriptionEdit && (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                // marginTop: "12%",
                                marginRight: "10px",
                                flexGrow: 1,
                              }}
                            >
                              <IconButton
                                sx={{ padding: "0px" }}
                                onClick={() => handleSubmit("description")}
                              >
                                <DoneIcon
                                  sx={{
                                    paddingLeft: "10px",
                                    color: "var(--start-color)",
                                    marginTop: "0px",
                                    position: "relative",
                                  }}
                                />
                              </IconButton>
                              <IconButton
                                sx={{ padding: "0px" }}
                                onClick={handleResetDescription}
                              >
                                <ClearIcon
                                  sx={{
                                    paddingRight: "10px",
                                    paddingLeft: "10px",
                                    color: "var(--late-owl-point-color)",
                                    marginTop: "0px",
                                    position: "relative",
                                  }}
                                />
                              </IconButton>
                            </Box>
                          )}
                        </Grid>

                        <Grid container spacing={2} sx={{ marginLeft: "1px" }}>
                          <Grid item xs={12} sm={isOpen ? 6 : 4}>
                            <Box
                              sx={{
                                margin: isOpen
                                  ? isMobile
                                    ? "8px 2px 0px 6px"
                                    : "8px 10px 0px 10px"
                                  : "8px 5px 0px 5px",
                              }}
                            >
                              <Box sx={boxStyleGrid}>
                                <CategoryIcon
                                  sx={{
                                    // fill: "orange",
                                    marginRight: "5px",
                                    fontSize: "17px",
                                    marginTop: "2px",
                                  }}
                                />
                                Task category
                              </Box>

                              <TextField
                                fullWidth
                                select
                                name="category"
                                value={formData.category}
                                onChange={handleChange}
                                sx={textfieldSelectStyle}
                              >
                                {categories?.map((option) => (
                                  <MenuItem
                                    key={option._id}
                                    value={option.name}
                                  >
                                    {option.name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={isOpen ? 6 : 4}>
                            <Box
                              sx={{
                                margin: isOpen
                                  ? isMobile
                                    ? "8px 2px 0px 6px"
                                    : "8px 10px 0px 10px"
                                  : "8px 5px 0px 5px",
                              }}
                            >
                              <Box sx={boxStyleGrid}>
                                <FormatListBulletedIcon
                                  sx={{
                                    marginRight: "5px",
                                    fontSize: "18px",
                                    marginTop: "2px",
                                  }}
                                />
                                Task Type
                              </Box>
                              <TextField
                                fullWidth
                                select
                                name="taskType"
                                value={formData?.taskType}
                                onChange={handleChange}
                                sx={textfieldSelectStyle}
                              >
                                {taskTypeOptions?.map((option) => (
                                  <MenuItem key={option} value={option}>
                                    {option}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={isOpen ? 6 : 4}>
                            <Box
                              sx={{
                                margin: isOpen
                                  ? isMobile
                                    ? "8px 2px 0px 5px"
                                    : "8px 10px 0px 10px"
                                  : "8px 5px 0px 5px",
                              }}
                            >
                              <Box sx={boxStyleGrid}>
                                <AccountTreeIcon
                                  sx={{
                                    // fill: "orange",
                                    marginRight: "5px",
                                    fontSize: "17px",
                                    marginTop: "2px",
                                  }}
                                />{" "}
                                Project
                              </Box>
                              <TextField
                                fullWidth
                                disabled
                                name="plannedHours"
                                value={taskData?.project}
                                sx={textfieldstyle}
                                variant="outlined"
                              ></TextField>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={isOpen ? 6 : 4}>
                            <Box
                              sx={{
                                margin: isOpen
                                  ? isMobile
                                    ? "8px 2px 0px 5px"
                                    : "8px 10px 0px 10px"
                                  : "8px 5px 0px 5px",
                              }}
                            >
                              <Box sx={boxStyleGrid}>
                                <AccessTimeFilledIcon
                                  sx={{
                                    marginRight: "5px",
                                    fontSize: "18px",
                                    marginTop: "2px",
                                  }}
                                />
                                Planned Hours
                                <CustomLabel
                                  tooltipText="Enter the planned hours in (HH:mm) format"
                                  isDarkTheme={isDarkTheme}
                                />
                              </Box>
                              <Autocomplete
                                freeSolo
                                options={suggestions}
                                filterOptions={(options, { inputValue }) => {
                                  const regex = /^(\d+\s?(h|hour|hours)\s?)?(\d+\s?(m|minute|minutes))?$/i;
                                  return options.filter((option) =>
                                    regex.test(inputValue)
                                  );
                                }}
                                value={formData.plannedHours || ""}
                                onInputChange={(event, newInputValue) => {
                                  handleActualHoursChange({
                                    target: {
                                      name: "plannedHours",
                                      value: newInputValue || "",
                                    },
                                  });
                                  setShowHoursEdit(
                                    newInputValue !== formData.plannedHours
                                  );
                                }}
                                onChange={(event, selectedValue) => {
                                  if (selectedValue) {
                                    handleSuggestionClick(selectedValue);
                                    setShowHoursEdit(true);
                                  }
                                }}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    padding: "0px 0px 0px 5px"
                                  },
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    name="plannedHours"
                                    sx={textfieldstyle}
                                    variant="outlined"
                                    error={!!errorPlannedHours.plannedHours}
                                    helperText={errorPlannedHours.plannedHours}
                                    autoComplete="off"
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: showHoursEdit ? (
                                        <>
                                          <IconButton
                                            onClick={() =>
                                              handleSubmit("hours")
                                            }
                                          >
                                            <DoneIcons sx={{ paddingRight: "unset" }} />
                                          </IconButton>
                                          <IconButton
                                            onClick={handleResetHours}
                                          >
                                            <ClearIcons />
                                          </IconButton>
                                        </>
                                      ) : null,
                                      style: {
                                        overflow: "auto",
                                      },
                                    }}
                                  />
                                )}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={isOpen ? 6 : 4}>
                            <Box
                              sx={{
                                margin: isOpen
                                  ? isMobile
                                    ? "8px 2px 0px 6px"
                                    : "8px 10px 0px 10px"
                                  : "8px 5px 0px 5px",
                              }}
                            >
                              <Box sx={boxStyleGrid}>
                                <AssignmentIcon
                                  sx={{
                                    marginRight: "5px",
                                    fontSize: "18px",
                                    marginTop: "2px",
                                  }}
                                />
                                Type
                              </Box>
                              <TypeCommon
                                isDarkTheme={isDarkTheme}
                                formData={formData}
                                typeOptions={typeOptions}
                                handleChange={handleChange}
                                textfieldSelectStyle={textfieldSelectStyle}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={isOpen ? 6 : 4}>
                            <Box
                              sx={{
                                margin: isOpen
                                  ? isMobile
                                    ? "8px 2px 0px 6px"
                                    : "8px 10px 0px 10px"
                                  : "8px 5px 0px 5px",
                              }}
                            >
                              <Box sx={boxStyleGrid}>
                                <AssignmentTurnedInIcon
                                  sx={{
                                    marginRight: "5px",
                                    fontSize: "18px",
                                    marginTop: "2px",
                                  }}
                                />
                                Parent Task (Optional)
                                {
                                  <CustomLabel
                                    tooltipText="Select a parent task if this is a subtask. Leave blank if not applicable."
                                    isDarkTheme={isDarkTheme}
                                  />
                                }
                              </Box>
                              <TextField
                                fullWidth
                                select
                                name="parentTaskId"
                                value={formData?.parentTaskId}
                                onChange={handleChange}
                                sx={textfieldSelectStyle}
                              >
                                {parentTaskList?.map((option) => (
                                  <MenuItem
                                    key={option?._id}
                                    value={option?._id}
                                  >
                                    {`${option?.ticketNo} : ${option?.title?.length > 30
                                      ? option?.title?.slice(0, 30) + "..."
                                      : option?.title
                                      }`}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={isOpen ? 6 : 4}>
                            <Box
                              sx={{
                                margin: isOpen
                                  ? isMobile
                                    ? "8px 2px 0px 5px"
                                    : "8px 10px 0px 10px"
                                  : "8px 5px 0px 5px",
                              }}
                            >
                              <Box sx={boxStyleGrid}>
                                <AssignmentIndIcon
                                  sx={{
                                    marginRight: "5px",
                                    fontSize: "18px",
                                    marginTop: "2px",
                                  }}
                                />
                                Assign To
                              </Box>
                              <TextField
                                fullWidth
                                select
                                name="assignToName"
                                sx={textfieldSelectStyle}
                                value={formData?.assignTo}
                                onChange={(event, newValue) =>
                                  handleSelect(event, newValue)
                                }
                                variant="outlined"
                              >
                                {allData?.map((option) => (
                                  <MenuItem
                                    key={option.firstName}
                                    value={option.firstName}
                                  >
                                    {option.firstName}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={isOpen ? 6 : 4}>
                            <Box
                              sx={{
                                margin: isOpen
                                  ? isMobile
                                    ? "8px 2px 0px 5px"
                                    : "8px 10px 0px 10px"
                                  : "8px 5px 0px 5px",
                              }}
                            >
                              <Box sx={boxStyleGrid}>
                                <AccessTimeIcon
                                  sx={{
                                    marginRight: "5px",
                                    fontSize: "18px",
                                    marginTop: "2px",
                                  }}
                                />
                                Actual Hours{" "}
                                {
                                  <CustomLabel
                                    tooltipText="Enter the actual hours in (HH:mm) format"
                                    isDarkTheme={isDarkTheme}
                                  />
                                }
                              </Box>
                              <TextField
                                fullWidth
                                disabled
                                sx={textfieldstyle}
                                name="actualHours"
                                value={formData?.actualHours || "00:00"}
                              />
                              <Typography color="error">
                                {error?.actualHours}
                              </Typography>
                              <Typography color="error">
                                {taskData?.totalActualHours !== "0:00"
                                  ? `${taskData?.totalActualHours} Total actual hours for all subtasks`
                                  : ""}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={isOpen ? 6 : 4}>
                            <Box
                              sx={{
                                margin: isOpen
                                  ? isMobile
                                    ? "8px 2px 0px 5px"
                                    : "8px 10px 0px 10px"
                                  : "8px 5px 0px 5px",
                              }}
                            >
                              <Box sx={boxStyleGrid}>
                                <ContentPasteSearchIcon
                                  sx={{
                                    marginRight: "5px",
                                    fontSize: "18px",
                                    marginTop: "2px",
                                  }}
                                />
                                Status
                              </Box>
                              <TextField
                                fullWidth
                                select
                                sx={{
                                  ".MuiSelect-select": {
                                    height: "30px",
                                    textTransform: "capitalize",
                                    minHeight: "0.5rem",
                                    background: formData?.statusValue
                                      ? formData?.statusColor
                                      : getStatusBackgroundColor(
                                        formData?.status
                                      ),
                                    color: "white !important",
                                    padding: "8px 0px 0px 10px",
                                  },
                                  ".MuiOutlinedInput-input": {
                                    color: "white !important",
                                  },
                                }}
                                name={
                                  formData?.statusValue
                                    ? "statusValue"
                                    : "status"
                                }
                                value={
                                  formData?.statusValue
                                    ? formData?.statusValue || 1
                                    : formData?.status
                                }
                                onChange={handleChange}
                              >
                                {formData?.statusValue
                                  ? statusList?.map((option) => (
                                    <MenuItem
                                      key={option._id}
                                      value={option._id}
                                    >
                                      {option.status}
                                    </MenuItem>
                                  ))
                                  : taskStatusList?.map((option) => (
                                    <MenuItem key={option} value={option}>
                                      {option}
                                    </MenuItem>
                                  ))}
                              </TextField>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={isOpen ? 6 : 4}>
                            <Box
                              sx={{
                                margin: isOpen
                                  ? isMobile
                                    ? "8px 2px 0px 5px"
                                    : "8px 10px 0px 10px"
                                  : "8px 5px 0px 5px",
                              }}
                            >
                              <Box sx={boxStyleGrid}>
                                <FlagIcon
                                  sx={{
                                    marginRight: "5px",
                                    fontSize: "18px",
                                    marginTop: "2px",
                                  }}
                                />
                                Priority
                              </Box>
                              <PriorityTextField
                                fullWidth
                                select
                                name="priority"
                                value={formData?.priority}
                                onChange={handleChange}
                                sx={{
                                  ".MuiOutlinedInput-input": {
                                    color: isDarkTheme
                                      ? "#e0e0e0cc !important"
                                      : "#5e6e82 !important",
                                  },
                                }}
                              >
                                {priorityArray?.map((option) => (
                                  <MenuItem key={option} value={option}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        height: "25px",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          marginRight: "8px",
                                        }}
                                      >
                                        {getIcon(option)}
                                      </Typography>
                                      <Typography>{option}</Typography>
                                    </Box>
                                  </MenuItem>
                                ))}
                              </PriorityTextField>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={isOpen ? 6 : 4}>
                            <Box
                              sx={{
                                margin: isOpen
                                  ? isMobile
                                    ? "8px 2px 0px 5px"
                                    : "8px 10px 0px 10px"
                                  : "8px 5px 0px 5px",
                              }}
                            >
                              <Box sx={boxStyleGrid}>
                                <SlowMotionVideoIcon
                                  sx={{
                                    marginRight: "5px",
                                    fontSize: "18px",
                                    marginTop: "2px",
                                  }}
                                />
                                Sprint (Optional)
                              </Box>
                              <TextField
                                fullWidth
                                select
                                name="sprint"
                                sx={textfieldSelectStyle}
                                value={formData?.sprint || ""}
                                onChange={handleChange}
                                variant="outlined"
                              >
                                {sprints?.map((option) => (
                                  <MenuItem
                                    key={option?._id}
                                    value={option?._id}
                                    disabled={option?.isCompleted}
                                  >
                                    {option?.name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={isOpen ? 6 : 4}>
                            <Box
                              sx={{
                                margin: isOpen
                                  ? isMobile
                                    ? "8px 2px 0px 5px"
                                    : "8px 10px 0px 10px"
                                  : "8px 5px 0px 5px",
                              }}
                            >
                              <Box sx={boxStyleGrid}>
                                <AccessAlarmsIcon
                                  sx={{
                                    marginRight: "5px",
                                    fontSize: "18px",
                                    marginTop: "2px",
                                  }}
                                />
                                Billable Hours (Optional){" "}
                                {
                                  <CustomLabel
                                    tooltipText="Enter the billable hours in (HH:mm) format"
                                    isDarkTheme={isDarkTheme}
                                  />
                                }
                              </Box>
                              <TextField
                                fullWidth
                                disabled
                                sx={textfieldstyle}
                                name="billableHours"
                                value={formData?.billableHours || "00:00"}
                              />
                              <Typography color="error">
                                {error?.billableHours}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={isOpen ? 6 : 4}>
                            <Box
                              sx={{
                                margin: isOpen
                                  ? isMobile
                                    ? "8px 2px 0px 5px"
                                    : "8px 10px 0px 10px"
                                  : "8px 5px 0px 5px",
                              }}
                            >
                              <Box sx={boxStyleGrid}>
                                <LocalOfferIcon
                                  sx={{
                                    marginRight: "5px",
                                    fontSize: "18px",
                                    marginTop: "2px",
                                  }}
                                />
                                Tags (Optional)
                              </Box>
                              <Autocomplete
                                freeSolo
                                options={options}
                                value={null}
                                inputValue={inputValue}
                                onInputChange={(event, newValue) => {
                                  setInputValue(newValue || "");
                                }}
                                getOptionLabel={(option) => {
                                  if (typeof option === "string") return option;
                                  return option?.title || "";
                                }}
                                clearIcon={inputValue && <ClearIcon />}
                                onChange={(event, newValue) => {
                                  if (
                                    event.type !== "keydown" &&
                                    newValue &&
                                    !tags.some(
                                      (t) =>
                                        t._id ===
                                        (typeof newValue === "string"
                                          ? newValue
                                          : newValue._id)
                                    )
                                  ) {
                                    handleAddTag(newValue);
                                  }
                                }}
                                sx={{
                                  ".MuiAutocomplete-inputRoot .MuiOutlinedInput-input":
                                  {
                                    padding: "0px",
                                  },
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    variant="outlined"
                                    helperText={error.tag}
                                    error={!!error.tag}
                                    placeholder="Press enter to add tags"
                                    onKeyDown={(event) => {
                                      if (event.key === "Enter") {
                                        event.preventDefault();
                                        handleAddTag(inputValue);
                                      }
                                    }}
                                    sx={{
                                      ".MuiOutlinedInput-input": {
                                        color: isDarkTheme
                                          ? "#e0e0e0cc !important"
                                          : "#5e6e82 !important",
                                        padding: "6px 14px",
                                      },
                                      ".MuiOutlinedInput-root": {
                                        minHeight: "10px",
                                      },
                                    }}
                                  />
                                )}
                              />
                              <Box mt={2}>
                                {tags.map((tag) => (
                                  <Chip
                                    key={tag._id}
                                    label={tag.title}
                                    onDelete={handleDeleteTag(tag)}
                                    style={{
                                      margin: "4px",
                                      backgroundColor: tag.color,
                                      color: "#fff",
                                      height: "25px",
                                      fontSize: "12px",
                                    }}
                                  />
                                ))}
                              </Box>
                            </Box>
                          </Grid>
                          {/* {taskData?.subTask?.length > 0 ? */}
                          <Grid item xs={12}>
                            <Box
                              sx={{
                                padding: isMobile
                                  ? "0px 0px 8px 4px"
                                  : "0px 0px 8px 6px",
                              }}
                            >
                              <SubTaskBox>
                                <SubTaskTypography variant="h6">
                                  Sub-Task
                                </SubTaskTypography>
                                <LinearsProgress
                                  variant="determinate"
                                  value={
                                    complateTaskCount?.totalCount &&
                                      complateTaskCount?.task
                                      ? (complateTaskCount.totalCount /
                                        complateTaskCount.task) *
                                      100
                                      : 0
                                  }
                                />
                                {taskData?.subTask?.length > 0 ? <Typography sx={{ color: isDarkTheme ? "#e0e0e0cc" : "#484848" }} variant="h6">
                                  {complateTaskCount?.totalCount !== 0 ? complateTaskCount?.totalCompletedTasks : `0/${taskData?.subTask?.length}`}
                                </Typography> : ""}
                              </SubTaskBox>
                              <SubTask
                                parentTaskId={taskData?._id}
                                projectId={taskData?.projectDetails?.id}
                                allData={allData}
                                categories={categories}
                                statusList={statusList}
                                typeOptions={typeOptions}
                                taskData={taskData?.subTask ?? []}
                              />
                            </Box>
                          </Grid>
                          {/* : <></>} */}
                        </Grid>
                      </Grid>
                      <Box mt={2}>
                        <Typography sx={{ color: "red" }}>
                          {displayMessageError}
                        </Typography>
                      </Box>
                    </CardContent>
                  </MainCard>
                </Grid>
                <Grid
                  item
                  sm={12}
                  lg={4}
                  sx={{
                    height: "100vh",
                    position: "relative",
                    overflow: "auto",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Collapse in={isOpen} sx={{ flex: 1, overflow: "auto" }}>
                      <DetailTaskCommentSection
                        cardStyle={cardStyle}
                        taskData={taskData}
                        reversedHistory={reversedHistory}
                        linkStyle={linkStyle}
                        toggleForm={toggleForm}
                        commentError={commentError}
                        setCommentError={setCommentError}
                        SaveComment={SaveComment}
                        viewer={viewer}
                        comment={comment}
                        setComment={setComment}
                        tags={tags}
                        typeOptions={typeOptions}
                      />
                    </Collapse>
                    <Collapse
                      in={!isOpen}
                      sx={{
                        position: "absolute",
                        bottom: "2%",
                        right: "2%",
                        zIndex: 1000,
                      }}
                    >
                      <CollapseTypography variant="body2">
                        Activity
                      </CollapseTypography>
                    </Collapse>
                  </Box>
                </Grid>
                {!isMobile && (
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    lg={0.7}
                    sx={{
                      height: "100vh",
                      position: "relative",
                      display: "flex",
                    }}
                  >
                    <IconButton
                      onClick={handleSideToggle}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        position: "fixed",
                        top: "15%",
                        right: "2.2%",
                        transform: "translateY(-50%)",
                        zIndex: 1000,
                        backgroundColor: "transparent",
                        borderRadius: "0",
                        boxShadow: "none",
                        padding: "0px",
                      }}
                    >
                      <ChatBubbleIcon color="primary" />
                    </IconButton>
                    <Typography
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        position: "fixed",
                        top: "19%",
                        right: "1%",
                        transform: "translateY(-50%)",
                        zIndex: 1000,
                        color: isDarkTheme ? "white" : "black",
                      }}
                    >
                      Activity
                    </Typography>
                  </Grid>
                )}
              </MainGrid>
              <Slide direction="left" in={showForm} mountOnEnter unmountOnExit>
                <SideBox isdarktheme={isDarkTheme ? "true" : "false"}>
                  <EmployeeProfile onClose={toggleForm} name={name} />
                </SideBox>
              </Slide>
            </>
          )}
        </MainBox>
      </>
    );
  };

  const memoizedsaveTask = useMemo(() => {
    const saveTask = async () => {
      if (formData._id !== "") {
        try {
          setDisplayMessageError("");
          const getUser = currentEmployeeLogin
            ? "user"
            : adminLogin
              ? "admin"
              : null;
          const postData = JSON.stringify({ formData });
          const response = await editTask(getUser, postData);

          if (response.status === 200 || response.status === 201) {
            setChange((prev) => !prev);
            dispatch(setToast(true));
            dispatch(setToastMsg(response.data.msg));
            dispatch(setToastType("success"));
          }
          if (response.status === 202) {
          }
        } catch (error) {
          setDisplayMessageError(errorMessage ?? "");
        }
      }
    };
    return saveTask;
  }, [formData, adminLogin, currentEmployeeLogin, dispatch]);

  const memoizedgetTaskDetails = useMemo(() => {
    const getTaskDetails = async (e) => {
      if (taskId || isSetDefaultRow) {
        try {
          const response = await getTaskById(taskId);
          if (response.status === 200 || response.status === 201) {
            setIsLoading(false);
            const data = response.data.task;
            const history = data?.history?.slice().reverse();
            const statusList = data?.workFlow?.statusData || [];
            const tagsList = data?.tagsList || [];
            const taskTags = data?.tags.map(
              (tagTitle) =>
                tagsList.find((tag) => tag.title === tagTitle) || {
                  title: tagTitle,
                  color: "#ccc",
                }
            );
            const sprintName = data?.sprintsList?.find(
              (item) => item?._id === data?.sprint
            )?.name;
            const updatedData = {
              ...data,
              status: data?.statusValue
                ? statusList?.find((item) => item?._id === data?.statusValue)
                  ?.status
                : data?.status,
              statusColor:
                statusList?.find((item) => item?._id === data?.statusValue)
                  ?.color || "",
              tagList: taskTags,
              sprint: sprintName ?? "",
            };
            setTaskData({ ...data, tagIdes: taskTags.map((tag) => tag._id) });
            setSprints(data?.sprintsList);
            setStatusList(statusList);
            setParentTaskList(data?.parentTaskList);
            setParentTaskList(data?.parentTaskList);
            setOptions(tagsList);
            setTags(taskTags);
            dispatch(setUpdatedTask(updatedData));
            setReversedHistory(history);
            setDescription(data?.description);
            setFormData({
              _id: data?._id || "",
              assignToEmp: data?.empId || "",
              assignTo: data?.name || "",
              assignBy: data?.assignBy.empId || "",
              assignByEmp: data?.assignBy.name || "",
              status: data?.statusValue
                ? statusList?.find((item) => item?._id === data?.statusValue)
                  ?.status
                : data?.status,
              statusValue: data?.statusValue || 0,
              statusColor:
                statusList?.find((item) => item?._id === data?.statusValue)
                  ?.color || "",
              actualHours: data?.actualHours || "00:00",
              billableHours: data?.billableHours || "00:00",
              priority: data?.priority || "",
              category: data?.category || "",
              type: data?.type || "",
              taskType: data?.taskType || "",
              parentTaskId: data?.parentTaskId || "",
              dueDate: data?.dueDate || "",
              plannedHours: data?.plannedHours || "",
              title: data?.title || "",
              tags: taskTags.map((tag) => tag._id),
              sprint: data?.sprint || "",
            });
          } else {
            setTaskData([]);
            setStatusList([]);
            setReversedHistory([]);
            setDescription("");
          }
        } catch (error) {
          setIsLoading(false);
          setStatusList([]);
        }
      }
    };
    return getTaskDetails;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId, isSetDefaultRow]);

  const memoizedGetCategories = useMemo(() => {
    const getCategories = async () => {
      if (taskId) {
        try {
          const response = await fetchCategories();
          const categories = response.data;
          const taskCategory = categories.filter((category) => category.name === "Task");
          const othersCategory = categories.filter((category) => category.name === "Others");
          const remainingCategories = categories.filter(
            (category) => category.name !== "Task" && category.name !== "Others"
          );
          const filteredCategories = [...taskCategory, ...remainingCategories, ...othersCategory];
          setCategories(filteredCategories);
        } catch (error) {
          console.error("Error fetching categories:", error);
        }
      }
    };
    return getCategories;
  }, [taskId]);

  const memoizedGetTypeOptions = useMemo(() => {
    const getTaskTypesData = async () => {
      if (taskId) {
        try {
          const response = await getTaskType();
          if (response.status === 200) {
            setTypeOptions(response?.data?.taskTypes);
          } else {
            setTypeOptions([]);
          }
        } catch (error) {
          console.error("Error fetching TaskType:", error);
        }
      }
    };
    return getTaskTypesData;
  }, [taskId]);

  useEffect(() => {
    if (!isMobile) {
      setIsOpenMobile(false);
    }
  }, [isMobile]);

  useEffect(() => {
    setTempTitle(formData.title);
  }, [formData?.title]);

  // const fetchTaskDetails = useCallback(() => {
  //   if (taskId !== "" && isSetDefaultRow) {
  //     memoizedgetTaskDetails();
  //   } else if (taskId !== "") {
  //     memoizedgetTaskDetails();
  //     memoizedGetCategories();
  //     memoizedGetTypeOptions();
  //   }
  //   // eslint-disable-next-line
  // }, [
  //   memoizedgetTaskDetails,
  //   memoizedGetCategories,
  //   memoizedGetTypeOptions,
  //   taskId,
  //   isSetDefaultRow,
  //   change,
  //   open,
  // ]);

  // Use a single dependency array to minimize re-runs
  const fetchTaskDetails = useCallback(() => {
    // if (!open) return;
    
    if (taskId !== "" && isSetDefaultRow) {
      memoizedgetTaskDetails();
    } else if (taskId !== "") {
      memoizedgetTaskDetails();
      if (open) {
        memoizedGetCategories();
        memoizedGetTypeOptions();
      }
    }
    // eslint-disable-next-line
  }, [
    memoizedgetTaskDetails,
    memoizedGetCategories,
    memoizedGetTypeOptions,
    taskId,
    isSetDefaultRow,
    open,
  ]);

  useEffect(() => {
    fetchTaskDetails();
  }, [fetchTaskDetails]);

  useEffect(() => { }, [complateTaskCount]);

  useEffect(() => {
    setIsLoading(true);
    setDescription("");
    setFormData({
      _id: "",
      assignToEmp: "",
      assignTo: "",
      assignBy: "",
      assignByEmp: "",
      status: "",
      statusValue: "",
      statusColor: "",
      actualHours: "",
      billableHours: "",
      priority: "",
      category: "",
      type: "",
      taskType: "",
      parentTaskId: "",
      dueDate: "",
      tags: [],
      sprint: "",
    });
  }, [open]);

  useEffect(() => {
    const isFormDataNonEmpty = Object.values(formData).some(
      (value) => !(value === "" || (Array.isArray(value) && value.length === 0))
    );
    const areTagsEqual = () => {
      if (!Array.isArray(formData?.tags) || !Array.isArray(taskData?.tagIdes)) {
        return false;
      }
      if (formData?.tags?.length !== taskData?.tagIdes?.length) {
        return true;
      }
      if (formData?.tags?.length === 0 && taskData?.tagIdes?.length === 0) {
        return false;
      }
      return false;
    };
    const areSprint =
      formData.sprint !== (taskData.sprint || "") ? true : false;
    if (isFormDataNonEmpty || Object.keys(taskData)?.length > 0) {
      if (
        formData.title !== taskData.title ||
        formData.status !== taskData.status ||
        formData.statusValue !== taskData.statusValue ||
        formData.assignTo !== taskData.name ||
        formData.priority !== taskData.priority ||
        formData.category !== taskData.category ||
        formData.type !== taskData.type ||
        formData.taskType !== taskData.taskType ||
        formData.parentTaskId !== taskData.parentTaskId ||
        formData.dueDate !== taskData.dueDate ||
        areTagsEqual() ||
        areSprint
      ) {
        memoizedsaveTask();
      }
    }
    // eslint-disable-next-line
  }, [
    formData.title,
    formData?.status,
    formData?.statusValue,
    formData?.assignTo,
    formData?.priority,
    formData?.category,
    formData?.type,
    formData?.parentTaskId,
    formData?.taskType,
    formData?.dueDate,
    formData?.tags,
    formData?.sprint,
    memoizedsaveTask,
  ]);

  return (
    <DialogComponent
      open={open}
      onClose={onClose}
      maxWidth="3000px"
      PaperComponent={isMobile || isOpenMobile ? "" : PaperComponent}
      PaperProps={{
        sx: {
          backgroundColor: isDarkTheme ? "#0b1727" : "#ffffff",
          marginTop: !isOpenMobile && !isMobile ? "3%" : "",
          marginBottom: !isOpenMobile && !isMobile ? "auto" : "",
          marginLeft: isMobile ? "3%" : "12%",
          marginRight: isMobile ? "3%" : "10%",
          borderRadius: !isOpenMobile && !isMobile ? "10px" : "",
          overflow: !isOpenMobile && !isMobile ? "hidden" : "",
          position: !isOpenMobile && !isMobile ? "fixed" : "",
          width: !isOpenMobile && !isMobile ? "80vw" : "",
          "@media (max-width: 600px)": {
            width: "100%",
          },
          height: "auto",
          boxShadow: "none !important",
          zIndex: 1100,
        },
      }}
      renderChildern={renderForm}
    />
  );
};

export default TaskDialogBox;
