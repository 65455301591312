import { Box, Button, IconButton, TextField, Typography, styled } from "@mui/material"
import {
    commonStyle
} from "../../components/ReusableComponents/CustomDesignMUI/CustomMUI";

export const MainBox = styled(Box)(({ ismobile }) => ({
    margin: ismobile !== "true" & "0px 35px",
    overflowY: "auto",
    marginTop: "10px",
    marginLeft: "9px",
}))

export const TitleBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
}))

export const AddRecordBox = styled(Box)(() => ({
    marginTop: "20px",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
}))

export const AddRecordButton = styled(Button)(() => ({
    color: "var(--primary-color)",
    fontWeight: "bold",
}))

export const InputTextField = styled(TextField)(() => ({
    paddingBottom: "10px",
    width: "80%",
    marginTop: "10px",
    ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline ":
    {
        borderColor:
            "var(--secondary-text-color)",
    },
    ...commonStyle
}))

export const SaveButton = styled(Button)(() => ({
    fontWeight: "bold",
    backgroundColor: "var(--secondary-color)",
    color: "#ffffff",
}))

export const MainBoxDialog = styled(Box)(() => ({
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "550px",
    height: "75px",
}))

export const InnerBoxOne = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}))

export const InnerBoxTwo = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#00ff1115",
    borderRadius: "1000px",
    padding: "8px",
    marginRight: "25px",
}))

export const PasswordTitleBox = styled(Box)(({ isdarktheme }) => ({
    color: isdarktheme === "true"
        ? "#e0e0e0e3"
        : "#544f5a",
}))

export const PasswordBox = styled(Box)(({ isdarktheme }) => ({
    display: "flex",
    justifyContent: "left",
    marginTop: "-15px",
    paddingLeft: "35px",
    marginRight: "25px",
    color: isdarktheme === "true"
        ? "#e0e0e0cc"
        : "#5e6e82",
}))

export const ErrorTypography = styled(Typography)(() => ({
    color: "red",
    paddingLeft: "48px",
    marginTop: "6px",
}))

export const CancelButtonDialog = styled(Button)(({ isdarktheme }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    textTransform: "capitalize",
    fontWeight: "bold",
    marginRight: "10px",
    borderRadius: "5px",
}))

export const ConfirmButtonDialog = styled(Button)(() => ({
    borderRadius: "5px",
    textTransform: "capitalize",
}))

export const CloseIconButton = styled(IconButton)(({ isdarktheme }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "10px",
    right: "-5px",
    fontWeight: "bold",
    textTransform: "capitalize",
    marginRight: "15px",
    fontSize: "2px",
    "&:hover": {
        background: "var(--highlight-color)",
    },
}))