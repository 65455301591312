
import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DataGrid, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";

import TaskDialogBox from "./DetailTask";
import { numberArray } from "../Constant/constant";
import SubTaskDefaultRow from "./SubTaskDefaultRow";
import { setComplateTaskCount } from "../../Slices/selectedActive";
import { TaskTypeCell, getIcon } from "../ReusableComponents/UtilityFunctions";
import { StatusBox } from "../../Styles/TaskManagement/TaskManagementStyle";
import "../../assets/styles/DataGridStyles.css";

const SubTask = ({ taskData, typeOptions, projectId, parentTaskId, categories, statusList, allData }) => {
    const apiRef = useGridApiRef();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.selectedActiveView.role);
    const employee = useSelector((state) => state.addEmployee.current?.empId);
    const admin = useSelector((state) => state.adminData.adminData?.empId);
    const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
    const updatedTask = useSelector((state) => state.taskData.updatedTask);
    const complateTaskCount = useSelector((state) => state.selectedActiveView.complateTaskCount);
    const currentEmployeeLogin = user === "employee";
    const adminLogin = user === "admin";

    const [openDialog, setOpenDialog] = useState(false);
    const [taskId, setTaskId] = useState("");
    const [dataGridHeight, setDataGridHeight] = useState(false);
    const [change, setChange] = useState(false);
    const [viewer, setViewer] = useState("");
    const [rows, setRows] = useState([]);

    function handleDetailClick(id) {
        setOpenDialog(true);
        setTaskId(id);
    }

    const getStatusBackgroundColor = (status) => {
        switch (status) {
            case "In Progress":
                return "var(--pending-color)";
            case "Cancelled":
                return "var(--reject-color)";
            case "Completed":
                return "var(--approved-color)";
            case "Hold":
                return "var(--cancel-color)";
            case "To-Do":
                return "var(--primary-color)";
            default:
                return "var(--primary-color)";
        }
    };

    const getTotalTaskComplated = (taskData) => {
        if (!taskData?.length) return;
        let finalCount = 0;
        let completedCount = 0;
        taskData?.forEach((subTask) => {
            if (subTask?.statusValue !== 0 || subTask?.statusValue !== "0") {
                const matchingStatus = statusList?.find(
                    (status) => status?._id?.toString() === subTask?.statusValue?.toString()
                );
                if (matchingStatus?.isFinal) {
                    finalCount++;
                }
            }
            if ((subTask?.statusValue === 0 || subTask?.statusValue === "0") && subTask?.status === "Completed") {
                completedCount++;
            }
        });
        const totalCount = finalCount + completedCount;
        const totalCompletedTasks = `${totalCount}/${taskData?.length}`;
        if (taskData?.length > 0 && totalCount) {
            dispatch(setComplateTaskCount({ totalCompletedTasks, totalCount, task: taskData?.length }))
        }
    }

    const formatDateToDDMMYYYY = (date) => {
        if (date) {
            const parts = date?.split("-");
            if (parts?.length === 3) {
                return parts?.reverse()?.join("-");
            }
        }
        return '';
    };

    const column = useMemo(
        () => [
            {
                field: 'type',
                headerName: 'Type',
                flex: 0.8,
                editable: true,
                renderCell: (params) => {
                    if (params.row.isNew) {
                        return params.value;
                    }
                    const option = typeOptions.find(
                        (opt) => opt?._id === params.value || opt?.taskTypeName === params.value
                    );
                    if (!option) return null;
                    return (
                        <TaskTypeCell
                            taskTypeName={option?.taskTypeName}
                        />
                    );
                },
                renderEditCell: (params) => {
                    if (params?.row?.isNew) {
                        return params.value;
                    }
                    return null;
                },
                colSpan: (value, row) => {
                    if (row.isNew) {
                        return 12;
                    }
                    return 1;
                },
            },
            { field: "ticketNo", headerName: "Ticket", flex: 1 },
            {
                field: 'title',
                headerName: 'Title',
                flex: 2.5,
                renderCell: (params) => {
                    return params.value;
                },
            },
            // {
            //     field: "tags",
            //     headerName: "Tags",
            //     flex: 2.5,
            //     renderCell: (params) => formatTags(params.value),
            // },
            { field: "assignTo", headerName: "Name", flex: 2 },
            {
                field: "date", headerName: "Due Date", flex: 1.5,
            },
            { field: "category", headerName: "Category", flex: 2 },
            { field: "plannedHours", headerName: "Planned Hours", flex: 1 },
            { field: "actualHours", headerName: "Actual Hours", flex: 1 },
            {
                field: "priority",
                headerName: "Priority",
                flex: 1.5,
                renderCell: (params) => (
                    <Box sx={{ display: "flex", height: "25px" }}>
                        <Typography
                            sx={{
                                marginRight: "2px",
                                justifyContent: "flex-start",
                            }}
                        >
                            {getIcon(params.value)}
                        </Typography>
                        <Typography
                            sx={{
                                color: isDarkTheme ? "#e0e0e0e3 !important" : "var(--secondary-text-color)  !important",
                            }}
                        >
                            {params.value}
                        </Typography>
                    </Box>
                ),
            },
            {
                field: "status",
                headerName: "Status",
                sortable: true,
                flex: 1.6,
                renderCell: (params) => {
                    const { statusValue, statusColor } = params.row;
                    return (
                        <StatusBox
                            backgroundcolor={statusValue && statusValue !== "0"
                                ? statusColor
                                : getStatusBackgroundColor(params.value)}
                        >
                            {params.value}
                        </StatusBox>
                    );
                },
            },
        ],
        [typeOptions, isDarkTheme]
    );

    useEffect(() => {
        if (currentEmployeeLogin) {
            setViewer(employee);
        } else if (adminLogin) {
            setViewer(admin);
        }
        // eslint-disable-next-line 
    }, [
        change,
        admin,
        employee,
    ]);

    useEffect(() => {
        const newRow = {
            id: "newRow-" + Math.random(),
            type: <SubTaskDefaultRow
                parentTaskId={parentTaskId}
                projectId={projectId}
                allData={allData}
                typeOptions={typeOptions}
                categories={categories}
                statusList={statusList}
            />,
            title: "",
            assignTo: '',
            dueDate: '',
            priority: '',
            status: '',
            tags: [],
            isNew: true,
        };

        if (taskData?.length > 0) {
            const data = taskData?.map(({ __v, ...rest }, index) => {
                return {
                    id: rest?._id || "task-" + index,
                    record_id: rest?._id,
                    timestamp: rest?.timestamp,
                    date: formatDateToDDMMYYYY(rest?.dueDate),
                    ticketNo: rest?.ticketNo,
                    name: rest?.name,
                    history: rest?.history,
                    assignBy: rest?.assignBy?.name,
                    assignByEmp: rest?.assignBy?.empId,
                    assignTo: rest?.name,
                    assignToEmp: rest?.empId,
                    title: rest?.title,
                    project: rest?.project,
                    priority: rest?.priority,
                    status: rest?.status,
                    statusValue: rest?.statusValue,
                    statusColor: rest?.statusColor,
                    category: rest?.category,
                    type: rest?.type,
                    description: rest?.description,
                    plannedHours: rest?.plannedHours,
                    actualHours: rest?.actualHours,
                    tags: rest?.tagList?.filter((tag) => tag && tag) || [],
                }
            });
            setRows([...data, newRow]);
            getTotalTaskComplated(taskData);
        } else {
            setRows([newRow]);
        }
        // eslint-disable-next-line 
    }, [taskData]);

    const handleProcessRowUpdate = async (newRow) => {
        const updatedRows = rows?.map((row) =>
            row?.id === newRow?.id ? { ...newRow } : row
        );
        setRows(updatedRows);
        return newRow;
    };

    useEffect(() => {
        if (updatedTask && Object.keys(updatedTask)?.length > 0 && updatedTask?._id) {
            setRows((prevData) => {
                const matchesCurrentFilter = prevData?.some(task => task?.id === updatedTask?._id);
                if (matchesCurrentFilter) {
                    const index = prevData?.findIndex((task) => task?.id === updatedTask?._id);
                    if (index !== -1) {
                        const updatedData = [...prevData];
                        updatedData[index] = { ...updatedTask, assignTo: updatedTask?.name, id: updatedTask?._id, date: formatDateToDDMMYYYY(updatedTask?.dueDate), };
                        return updatedData;
                    } else {
                        return [{ ...updatedTask, assignTo: updatedTask?.name, id: updatedTask?._id, date: formatDateToDDMMYYYY(updatedTask?.dueDate) }, ...(prevData || [])];
                    }
                }
                return prevData;
            });
            const filteredRows = rows?.filter((row) => !row.isNew);
            getTotalTaskComplated(filteredRows);
        }
    // eslint-disable-next-line 
    }, [updatedTask]);

    useEffect(() => {
    }, [complateTaskCount])

    return (<Box
        sx={{
            width: "100%",
            overflowX: "auto",
            maxHeight: "fit-content",
        }}
    >
        <Box
            sx={{
                '@media (max-width: 600px)': {
                    width: "320%",
                },
            }}
        >
            <DataGrid
                apiRef={apiRef}
                disableColumnMenu
                rowHeight={43}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
                aria-selected="false"
                editMode="cell"
                onCellClick={(params) => {
                    if (params?.row?.isNew) {
                        apiRef.current.startRowEditMode({ id: params?.row?.id });
                    }
                }}
                experimentalFeatures={{ newEditingApi: true }}
                rows={rows}
                columns={column}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            "ticketNo": false,
                            "plannedHours": false,
                            "category": false,
                            "actualHours": false
                        },
                    },
                    pagination: {
                        paginationModel: {
                            pageSize:
                                parseInt(window.localStorage.getItem("paginationNo"), 10) || 10,
                        },
                    },
                }}
                disableSelectionOnClick
                getRowId={(row) => row?.id}
                processRowUpdate={handleProcessRowUpdate}
                onRowCountChange={(params) => {
                    if (params === 0) {
                        setDataGridHeight(true);
                    } else {
                        setDataGridHeight(false);
                    }
                }}
                onRowClick={(params) => {
                    if (params?.row?.isNew) {
                        return;
                    }
                    handleDetailClick(params.row.ticketNo);
                }}
                onPaginationModelChange={(pageSize) => {
                    window.localStorage.setItem("paginationNo", pageSize.pageSize);
                }}
                onPageSizeChange={(pageSize) => {
                    window.localStorage.setItem("paginationNo", pageSize);
                }}
                pageSizeOptions={numberArray}
                components={{
                    Toolbar: GridToolbar,
                }}
                sx={{
                    height: dataGridHeight ? "230px" : "fit-content",
                    ".MuiDataGrid-filler": {
                        height: "0px !important",
                        display: "none",
                    },
                    color: isDarkTheme ? "#e0e0e0e3" : "var(--primary-text-color)",
                    textTransform: "capitalize",
                    ".MuiDataGrid-columnHeaderTitle": {
                        textTransform: "none !important",
                        color: isDarkTheme ? "#e0e0e0e3" : "var(--primary-text-color)",
                    },
                    backgroundColor: isDarkTheme ? "#19314ed8" : "var(--subAccordian-background-color)",
                    ".MuiDataGrid-cell ": {
                        cursor: "pointer",
                        alignContent: "center",
                    },
                    ".MuiDataGrid-cell:focus": {
                        outline: "none",
                    },
                    "& .MuiDataGrid-columnSeparator": {
                        color: isDarkTheme ? "#fff" : "inherit",
                    },
                    "& .MuiTablePagination-root, & .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows, & .MuiTablePagination-actions, & .MuiTablePagination-input, & .MuiDataGrid-selectedRowCount": {
                        color: isDarkTheme ? "#e0e0e0e3 !important" : "var(--secondary-text-color)",
                    },
                    "& .MuiSelect-icon": {
                        fill: isDarkTheme ? "#e0e0e0e3 !important" : "var(--secondary-text-color)"
                    },
                    ".MuiDataGrid-columnHeader": {
                        height: "48px !important",
                    },
                    ".MuiInput-underline:before": {
                        borderBottom: isDarkTheme ? "1px solid #e0e0e0e3 !important" : "1px solid var(--primary-text-color) !important",
                    },
                    ".MuiSvgIcon-fontSizeSmall": {
                        fill: isDarkTheme ? "#e0e0e0e3 !important" : "var(--primary-text-color) !important",
                    },
                    ".MuiInputBase-input.MuiInput-input.MuiInputBase-inputTypeSearch.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd":
                    {
                        color: isDarkTheme ? "#e0e0e0e3 !important" : "var(--primary-text-color) !important",
                    },
                    ".MuiDataGrid-overlay": {
                        color: isDarkTheme ? "#e0e0e0e3 !important" : "var(--primary-text-color) !important",
                        backgroundColor: isDarkTheme ? "#19314ed8" : "var(--subAccordian-background-color)",
                    },
                    ".MuiDataGrid-columnHeaders": {
                        backgroundColor: isDarkTheme ? "#142840 !important" : "#fff"
                    },
                    ".MuiDataGrid-columnHeader:focus": {
                        outline: "none",
                    },
                    ".MuiDataGrid-actionsCell": { type: "actions" },
                    ".MuiDataGrid-cell--editing": {
                        backgroundColor: isDarkTheme ? "#19314ed8 !important" : "var(--subAccordian-background-color)",
                    },
                    "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                        outlineOffset: "0px !important",
                    },
                    ".MuiDataGrid-scrollbar": {
                        height: "auto !important",
                    },
                    "--DataGrid-containerBackground": "var(--primary-highlight-color)"
                }}
            />
        </Box>
        <TaskDialogBox
            unapprovedTask={false}
            open={openDialog}
            setOpenDialog={setOpenDialog}
            taskId={taskId}
            viewer={viewer}
            change={change}
            setChange={setChange}
        />
    </Box>)
}
export default SubTask