import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, TableContainer, Table, TableCell, TableBody, Checkbox, Button, Typography } from "@mui/material";
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

import Loader from "../Loader/Loader";
import { empTaskTableHeadCell, empTaskTableBodyCell } from "../ReusableComponents/CustomDesignMUI/CustomMUI";
import { getAllRolesData } from "../../Services/ManageRoleServices";
import { setToast, setToastMsg, setToastType } from "../../Slices/toastSlice";
import { addRoleByPermissions, getAllPermissionsData, getRoleByPermissionsData } from "../../Services/ConfigurationServices"
import { errorMessage } from "../../Services/axiosInstance";
import { MainBox, TitleTypography, StickyTableCell, CustomTableCell, SaveButton, FooterBox, LoadingBox, InnerBoxOne, TableHeads, TableRows, IconButtons } from "../../Styles/Setting/SettingPageStyle"
import { setEditSettings } from "../../Slices/selectedActive";

export const SettingPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);

  const [getRoleData, setGetRoleData] = useState([]);
  const [getPermissionData, setGetPermissionData] = useState([]);
  const [getRoleByPermissionData, setGetRoleByPermissionData] = useState([]);
  const [rowNumber, setRowNumber] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [checkedStates, setCheckedStates] = useState([]);
  const [formData, setFormData] = useState([]);
  const [displayError, setDisplayError] = useState("");

  const getRolesData = async () => {
    setIsLoading(true);
    try {
      const response = await getAllRolesData();
      if (response.status === 200) {
        setIsLoading(false);
        const roleOrder = {
          'CEO': 0,
          'HR': 1,
          'Business Development Executive': 2,
        };
        const orderedRoles = response?.data?.employeeRecord?.filter(role => roleOrder.hasOwnProperty(role.role));
        orderedRoles.sort((a, b) => roleOrder[a.role] - roleOrder[b.role]);
        orderedRoles.sort((a, b) => a._id - b._id);
        const remainingRoles = response?.data?.employeeRecord?.filter(role => !roleOrder.hasOwnProperty(role.role));
        remainingRoles.sort((a, b) => a._id - b._id);
        setGetRoleData([...orderedRoles, ...remainingRoles]);
      } else {
        setGetRoleData([]);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getPermissionsData = async () => {
    setIsLoading(true);
    try {
      const response = await getAllPermissionsData();
      if (response.status === 200) {
        setIsLoading(false);
        setGetPermissionData(response?.data?.AllPermissions);
      } else {
        setGetPermissionData([]);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getRoleByPermission = async () => {
    setIsLoading(true);
    try {
      const response = await getRoleByPermissionsData();
      if (response.status === 200) {
        setIsLoading(false);
        setGetRoleByPermissionData(response?.data?.getRoleByPermissions);
      } else {
        setGetRoleByPermissionData([]);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleChange = (index, roleId, permissionId) => (event) => {
    event.stopPropagation();
    const newCheckedStates = [...checkedStates];
    newCheckedStates[index] = event.target.checked;
    setCheckedStates(newCheckedStates);
    const obj = { roleId, permissionId };

    if (event.target.checked) {
      if (!formData.some(item => item.roleId === roleId && item.permissionId === permissionId)) {
        setFormData(prevFormData => [...prevFormData, obj]);
      }
    } else {
      setFormData(prevFormData =>
        prevFormData?.filter(item => !(item.roleId === roleId && item.permissionId === permissionId))
      );
    }
  };

  const saveRoleByPermission = async (event) => {
    try {
      event.preventDefault();
      setDisplayError("");
      const postData = JSON.stringify(formData);
      const response = await addRoleByPermissions(postData);
      if (response.status === 200) {
        dispatch(setEditSettings(true));
        dispatch(setToast(true));
        dispatch(setToastMsg(response?.data?.message ?? "RoleByPermissions Added Successfully."));
        dispatch(setToastType("success"));
        // getRolesData();
        // getPermissionsData();
        // getRoleByPermission();
        // Delay before setting edit settings to false
        setTimeout(() => {
          dispatch(setEditSettings(false));
        }, 3000); // 3 seconds delay
      }
    } catch (error) {
      setDisplayError(errorMessage ?? "")
      dispatch(setEditSettings(false));
    }
  }

  useEffect(() => {
    //set getRoleByPermissionData value in formData set
    const rolePermissionObjects = getRoleByPermissionData?.map(item => ({
      roleId: item?.roleId,
      permissionId: item?.permissionId,
    }));
    setFormData(rolePermissionObjects);

    if (getRoleByPermissionData && getRoleData && getPermissionData) {
      const initialCheckedStates = getPermissionData?.flatMap(permission => (
        getRoleData?.map(role => {
          return getRoleByPermissionData?.some(rolePermission =>
            rolePermission?.roleId === role?._id &&
            rolePermission?.permissionId === permission?._id
          );
        })
      ));
      setCheckedStates(initialCheckedStates);
    }
  }, [getRoleByPermissionData, getRoleData, getPermissionData])

  useEffect(() => {
    getRolesData();
    getPermissionsData();
    getRoleByPermission();
  }, [])

  return (
    <Box>
      {isLoading ? (
        <LoadingBox>
          <Loader />
        </LoadingBox>
      ) : (
        <Box>
          <MainBox bottom={0}>
            <TitleTypography variant="h6">
              Settings
            </TitleTypography>
          </MainBox>
          <InnerBoxOne>
            <TableContainer style={{ maxHeight: 'calc(100vh - 310px)' }} sx={{ overflow: "auto", position: 'relative' }}>
              <Table>
                <TableHeads isdarktheme={isDarkTheme ? "true" : "false"}>
                  <TableCell sx={{ ...empTaskTableHeadCell }}></TableCell>
                  <TableCell sx={{
                    position: 'sticky',
                    left: 30,
                    backgroundColor: isDarkTheme ? "var(--primary-highlight-color)" : "#ffffff"
                  }}></TableCell>
                  {getRoleData?.map((item) => {
                    return (
                      <TableCell sx={{ ...empTaskTableHeadCell }} align={'left'} key={item?._id}>{item?.role}</TableCell>
                    )
                  })}
                </TableHeads>
                <TableBody>
                  {getPermissionData?.map((row, rowIndex) => {
                    if (!row?.parentId) {
                      return (
                        <React.Fragment key={rowIndex}>
                          <TableRows sx={{
                            backgroundColor:
                              rowIndex % 2 === 1 ? "var(--highlight-color)" : "",
                            background:
                              "var(--background-table-sidebar-card-color)",
                          }} key={rowIndex}>
                            <StickyTableCell isdarktheme={isDarkTheme ? "true" : "false"} onClick={() => {
                              if (!row.parentId) {
                                if ((rowIndex + 1) === rowNumber) {
                                  setRowNumber(null);
                                } else {
                                  setRowNumber(rowIndex + 1);
                                }
                              }
                            }} emptasktablebodycell={empTaskTableBodyCell}>
                              <IconButtons>
                                {row?.isParent && !row?.parentId ? (rowNumber === (rowIndex + 1) ?
                                  <ExpandLessIcon sx={{ color: 'var(--secondary-text-color)' }} /> :
                                  <ExpandMoreIcon sx={{ color: 'var(--secondary-text-color)' }} />) : ""}
                              </IconButtons>
                            </StickyTableCell>
                            <CustomTableCell isdarktheme={isDarkTheme ? "true" : "false"}>
                              {row?.pageName}
                            </CustomTableCell>
                            {getRoleData?.map((item, i) => {
                              const index = rowIndex * getRoleData.length + i;
                              const Check = checkedStates[index];
                              return (
                                <TableCell key={i} sx={{ ...empTaskTableBodyCell, width: '10px' }}>
                                  <Checkbox
                                    sx={{ color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82" }}
                                    onChange={handleChange(index, item?._id, row?._id)}
                                    checked={Check ?? false}
                                  />
                                </TableCell>
                              )
                            })
                            }
                          </TableRows>
                          {getPermissionData?.map((childRow, childIndex) => {
                            if (childRow?.parentId && childRow?.parentId === row._id && rowNumber === (rowIndex + 1)) {
                              return (
                                <TableRows sx={{
                                  backgroundColor:
                                    childIndex % 2 === 1 ? "var(--highlight-color)" : "",
                                  background:
                                    "var(--background-table-sidebar-card-color)",
                                }} key={`child_permission_${childIndex}`}>
                                  <TableCell sx={{
                                    backgroundColor: isDarkTheme ? "var(--primary-highlight-color)" : '#ffffff', position: 'sticky',
                                    width: '10px',
                                    padding: "5px",
                                    left: 0,
                                    top: 65,
                                    zIndex: 100,
                                  }}>
                                  </TableCell>
                                  <CustomTableCell isdarktheme={isDarkTheme ? "true" : "false"}>
                                    {childRow?.pageName}
                                  </CustomTableCell>
                                  {getRoleData?.map((item, i) => {
                                    const index = childIndex * getRoleData.length + i;
                                    const Check = checkedStates[index];

                                    return (<TableCell key={i} sx={{ ...empTaskTableBodyCell, width: '10px' }}>
                                      <Checkbox
                                        sx={{ color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82" }}
                                        onChange={handleChange(index, item?._id, childRow?._id)}
                                        checked={Check ?? false}
                                      />
                                    </TableCell>)
                                  }
                                  )}
                                </TableRows>
                              );
                            }
                            return null; // Render nothing if conditions don't match
                          })}
                        </React.Fragment>
                      )
                    }
                    return null;
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </InnerBoxOne>
          <FooterBox>
            <Typography sx={{ color: "red" }}>{displayError}</Typography>{" "}
            <Box>
              <Button
                sx={{
                  fontWeight: "bold",
                  color: "gray",
                }}
                onClick={() => { navigate("/dashboard") }}
              >
                Cancel
              </Button>
              <SaveButton
                variant="contained"
                onClick={saveRoleByPermission}
              >
                Save
              </SaveButton>
            </Box>
          </FooterBox>
        </Box>)}
    </Box>
  );
};
