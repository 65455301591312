import React from "react";
import { useSelector } from "react-redux";

import { formatDate } from "../../ReusableComponents/UtilityFunctions";
import Graph from "../../ReusableComponents/Graph";
import "../../../App.css";

const BarChart = () => {
  const attendenceData = useSelector(
    (state) => state.addEmployee.employeeAllAttendenceData
  );

  const getMondayDate = () => {
    const currentDate = new Date();
    const dayOfWeek = currentDate.getDay();
    const difference = dayOfWeek - 1;
    const mondayDate = new Date(currentDate);
    mondayDate.setDate(currentDate.getDate() - difference);
    return formatDate(mondayDate);
  };
  const graphValues = () => {
    const dataArray = [];
    const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const formattedStartDate = getMondayDate();
    const formattedDate = formattedStartDate.split("-").reverse().join("-");
    const startDate = new Date(formattedDate);
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6);
    const attendanceByDate = new Map();
    attendenceData.forEach((record) => {
      const formattedDate = record.date.split("-").reverse().join("-");
      const recordDate = new Date(formattedDate);
      if (
        (recordDate >= startDate &&
          recordDate <= endDate &&
          recordDate.getMonth() === startDate.getMonth() &&
          recordDate.getFullYear() === startDate.getFullYear()) ||
        (recordDate >= startDate &&
          endDate.getMonth() !== startDate.getMonth() &&
          recordDate <= endDate &&
          recordDate.getMonth() === endDate.getMonth() &&
          recordDate.getFullYear() === endDate.getFullYear())
      ) {
        const dayIndex = recordDate.getDay() - 1;
        const day = days[dayIndex];
        attendanceByDate.set(day, {
          WorkHours: record.dailytimer.TotalTime / (1000 * 60 * 60) || 0,
          BreakHours: record.dailytimer.BreakTime / (1000 * 60 * 60) || 0,
        });
      }
    });

    days.forEach((day) => {
      const record = attendanceByDate.get(day);
      dataArray.push({
        label: day,
        Working_Hours: record ? record.WorkHours : 0,
        Break_Hours: record ? record.BreakHours : 0,
      });
    });
    return dataArray;
  };
  const data = graphValues();
  return <Graph data={data} />;
};

export default BarChart;
