import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Edit } from "@mui/icons-material";

import { setAllData } from "../../../Slices/UserDataSlice.js";
import { setAllLeaveData } from "../../../Slices/leaveData.js";
import { setemployeeAllData } from "../../../Slices/employeeDetailSlice.js";
import { renderAsterisk } from "../../ReusableComponents/UtilityFunctions.js";
import { viewProfileSubtitle } from "../../ReusableComponents/CustomDesignMUI/CustomMUI";
import { upsertContact } from "../../../Services/EmployeeManageServices.js";
import { errorMessage } from "../../../Services/axiosInstance.js";
import { setToast, setToastMsg, setToastType } from "../../../Slices/toastSlice.js";
import { CancelButton, CustomTextField, EditBox, ErrorTypography, InnerBoxOne, InputTextField, MainBox, SaveButton, SaveNextButton } from "../../../Styles/UpdateEmployee/UpdateContactStyle.js";
import { FooterBox } from "../../../Styles/UpdateEmployee/UpdatePersonalStyle.js";

function UpdateContact(props) {
  const { employeeCall, saveNextButtonCallback, isUpdated, setIsUpdated } = props;
  const dispatch = useDispatch();
  const themeChange = useSelector((state) => state.theme.isDarkTheme);
  const user = useSelector((state) => state.selectedActiveView.role);
  // const EmployeeLogin = user === "employee";
  const adminLogin = user === "admin";
  const employee_id = employeeCall?.empId;

  const [editEmployee, setEditEmployee] = useState(false);
  const [displayError, setDisplayError] = useState("");
  const [errors, setErrors] = useState({});
  const [employeeContactDetails, setEmployeeContactDetails] = useState({
    id: employeeCall?._id,
    contactPersonalNumber: employeeCall?.Contact_Number,
    contactAdditionalNumber: employeeCall?.Contact_Number2,
    relation: employeeCall?.Relation,
    contactEmail: employeeCall?.Email,
    personalContactEmail: employeeCall?.PersonalEmail,
    contactState: employeeCall?.State,
    contactCity: employeeCall?.City,
    contactResidental: employeeCall?.Residental_Address,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEmployeeContactDetails((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!employeeContactDetails.contactPersonalNumber) {
      newErrors.contactPersonalNumber = "Phone number is required";
    } else if (
      !/^[6-9]\d{9}$/.test(employeeContactDetails.contactPersonalNumber)
    ) {
      newErrors.contactPersonalNumber = "Phone number is not valid";
    }
    if (!employeeContactDetails.relation) {
      newErrors.relation = "Relation is required";
    } else if (!/^[A-Za-z]+$/.test(employeeContactDetails.relation)) {
      newErrors.relation = "Relation should contain only alphabetic characters";
    }
    if (!employeeContactDetails.contactAdditionalNumber) {
      newErrors.contactAdditionalNumber = "Phone number is required";
    } else if (
      !/^[6-9]\d{9}$/.test(employeeContactDetails.contactAdditionalNumber)
    ) {
      newErrors.contactAdditionalNumber = "Phone number is not valid";
    } else if (
      employeeContactDetails.contactAdditionalNumber ===
      employeeContactDetails.contactPersonalNumber
    ) {
      newErrors.contactAdditionalNumber =
        "Phone number and Emergency Number should not be same";
    }
    if (!employeeContactDetails.contactEmail) {
      newErrors.contactEmail = "Email is required";
    } else if (
      !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(
        employeeContactDetails.contactEmail
      )
    ) {
      newErrors.contactEmail = "Invalid email format";
    }
    if (!employeeContactDetails.personalContactEmail) {
      newErrors.personalContactEmail = "Email is required";
    } else if (
      !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(
        employeeContactDetails.personalContactEmail
      )
    ) {
      newErrors.personalContactEmail = "Invalid email format";
    }
    if (!employeeContactDetails.contactState) {
      newErrors.contactState = "State is required";
    }
    if (!employeeContactDetails.contactCity) {
      newErrors.contactCity = "City is required";
    }
    if (!employeeContactDetails.contactResidental) {
      newErrors.contactResidental = "Residental address is required";
    }
    setErrors(newErrors);
    const noErrors = Object.keys(newErrors).length === 0;
    if (noErrors) {
      return true;
    }
    return Object.keys(newErrors).length === 0;
  };

  const saveAndNext = async () => {
    setDisplayError("");
    try {
      const postData = JSON.stringify({ employee_id, employeeContactDetails });
      const response = await upsertContact(postData)

      if (response.status === 201 || response.status === 200) {
        dispatch(setAllData(response.data.user));
        dispatch(setAllLeaveData(response.data.leave));
        dispatch(setemployeeAllData(response.data.employeeAllDetail));
        setEditEmployee(false);
        if (adminLogin) {
          if (isUpdated === true) {
            setIsUpdated(false)
          } else {
            setIsUpdated(true)
          }
        }
        dispatch(setToast(true));
        dispatch(setToastMsg(response.data.message));
        dispatch(setToastType("success"));
      }
    } catch (error) {
      setDisplayError(errorMessage ?? "")
    }
  };

  return (
    <Box>
      <MainBox>
        <InnerBoxOne>
          <Box>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Contact Details
            </Typography>
          </Box>
          {editEmployee ? null : (
            <Box>
              <EditBox>
                <IconButton
                  disabled={editEmployee}
                  onClick={() => setEditEmployee(true)}
                  sx={{ color: "var( --third-color)" }}
                >
                  <Edit />
                </IconButton>
              </EditBox>
            </Box>
          )}
        </InnerBoxOne>
        <hr />
        <Box sx={{ marginBottom: "25px", paddingTop: "20px" }}>
          <Box>
            <Typography sx={viewProfileSubtitle}>Phone Number{renderAsterisk()}</Typography>
            <InputTextField
              themechange={themeChange ? "true" : "false"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+91</InputAdornment>
                ),
              }}
              name="contactPersonalNumber"
              value={employeeContactDetails.contactPersonalNumber}
              disabled={!editEmployee}
              onChange={handleInputChange}
            />
            <Typography sx={{ color: "red" }}>
              {errors.contactPersonalNumber}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ marginBottom: "25px" }}>
          <Box>
            <Typography sx={viewProfileSubtitle}>Emergency Number{renderAsterisk()}</Typography>
            <InputTextField
              themechange={themeChange ? "true" : "false"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+91</InputAdornment>
                ),
              }}
              name="contactAdditionalNumber"
              value={employeeContactDetails.contactAdditionalNumber}
              disabled={!editEmployee}
              onChange={handleInputChange}
            />
            <Typography sx={{ color: "red" }}>
              {errors.contactAdditionalNumber}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ marginBottom: "25px" }}>
          <Box>
            <Typography sx={viewProfileSubtitle}>Emergency Relation{renderAsterisk()}</Typography>
            <InputTextField
              themechange={themeChange ? "true" : "false"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
              disabled={!editEmployee}
              name="relation"
              value={employeeContactDetails?.relation}
              onChange={handleInputChange}
            />
            <Typography sx={{ color: "red" }}>{errors.relation}</Typography>
          </Box>
        </Box>
        <Box sx={{ marginBottom: "25px" }}>
          <Box>
            <Typography sx={viewProfileSubtitle}>E-mail Address{renderAsterisk()}</Typography>
            <CustomTextField
              themechange={themeChange ? "true" : "false"}
              name="contactEmail"
              value={employeeContactDetails.contactEmail}
              disabled={!editEmployee}
              onChange={handleInputChange}
            />
            <Typography sx={{ color: "red" }}>{errors.contactEmail}</Typography>
          </Box>
        </Box>
        <Box sx={{ marginBottom: "25px" }}>
          <Box>
            <Typography sx={viewProfileSubtitle}>
              Personal E-mail Address{renderAsterisk()}
            </Typography>
            <CustomTextField
              themechange={themeChange ? "true" : "false"}
              name="personalContactEmail"
              value={employeeContactDetails.personalContactEmail}
              disabled={!editEmployee}
              onChange={handleInputChange}
            />
            <Typography sx={{ color: "red" }}>
              {errors.personalContactEmail}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ marginBottom: "25px" }}>
          <Box>
            <Typography sx={viewProfileSubtitle}>State of Residence{renderAsterisk()}</Typography>
            <CustomTextField
              themechange={themeChange ? "true" : "false"}
              name="contactState"
              value={employeeContactDetails.contactState}
              disabled={!editEmployee}
              onChange={handleInputChange}
            />
            <Typography sx={{ color: "red" }}>{errors.contactState}</Typography>
          </Box>
        </Box>
        <Box sx={{ marginBottom: "25px" }}>
          <Box>
            <Typography sx={viewProfileSubtitle}>City{renderAsterisk()}</Typography>
            <CustomTextField
              themechange={themeChange ? "true" : "false"}
              name="contactCity"
              value={employeeContactDetails.contactCity}
              disabled={!editEmployee}
              onChange={handleInputChange}
            />
            <Typography sx={{ color: "red" }}>{errors.contactCity}</Typography>
          </Box>
        </Box>
        <Box sx={{ marginBottom: "21px" }}>
          <Box>
            <Typography sx={viewProfileSubtitle}>
              Residential Address{renderAsterisk()}
            </Typography>
            <CustomTextField
              themechange={themeChange ? "true" : "false"}
              name="contactResidental"
              value={employeeContactDetails.contactResidental}
              disabled={!editEmployee}
              onChange={handleInputChange}
            />
            <Typography sx={{ color: "red" }}>
              {errors.contactResidental}
            </Typography>
          </Box>
        </Box>
        <ErrorTypography>
          {displayError}
        </ErrorTypography>
        <Box>
          {editEmployee ? (
            <FooterBox>
              <CancelButton
                onClick={() => {
                  setEditEmployee(false);
                  setEmployeeContactDetails({
                    id: employeeCall._id,
                    contactPersonalNumber: employeeCall.Contact_Number,
                    contactAdditionalNumber: employeeCall.Contact_Number2,
                    contactEmail: employeeCall.Email,
                    personalContactEmail: employeeCall.PersonalEmail,
                    contactState: employeeCall.State,
                    contactCity: employeeCall.City,
                    contactResidental: employeeCall.Residental_Address,
                  });
                }}
              >
                Cancel
              </CancelButton>
              <SaveButton
                onClick={() => {
                  if (validateForm()) {
                    if (saveAndNext());
                  }
                }}
              >
                Save
              </SaveButton>
              <SaveNextButton
                variant="contained"
                onClick={() => {
                  if (validateForm()) {
                    if (saveAndNext()) {
                      saveNextButtonCallback();
                    }
                  }
                }}
              >
                Save & Next
              </SaveNextButton>
            </FooterBox>
          ) : null}
        </Box>
      </MainBox>
    </Box>
  );
}

export default UpdateContact;
