import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Box,
  Tooltip,
  Button,
  Typography,
  FormControl,
  TextField,
  useMediaQuery,
  InputAdornment,
  DialogTitle,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { InsertChart as InsertChartIcon, ListAlt as ListAltIcon, Search as SearchIcon, ArrowBack as ArrowBackIcon, Close } from "@mui/icons-material";

import TaskDialogBox from "./DetailTask";
import { numberArray } from "../Constant/constant";
import {
  TaskTypeCell,
  TypeCommon,
  getIcon,
  sortingTask,
  EmployeeAvatarGroup,
} from "../ReusableComponents/UtilityFunctions";
import BoardView from "./BoardView";
import DialogComponent from "../ReusableComponents/DialogBoxComponents/DialogComponent";
import {
  empTaskTableBodyCell,
  empTaskTableHeadCell,
} from "../ReusableComponents/CustomDesignMUI/CustomMUI";
import { getSprintTasks } from "../../Services/TaskManageServices";
import {
  AdditionalCountChip,
  StatusBox,
  VisibleTagsChip,
} from "../../Styles/TaskManagement/TaskManagementStyle";
import {
  FilterBox,
  HeaderBox,
  CloseIconButton,
  MainBoxDialog,
  TitleTypography,
  BackButton,
} from "../../Styles/TaskManagement/TaskSprintStyle";
import {
  TableContainers,
  CustomTableCells,
  TableCells,
  Tables,
  TableHeads,
} from "../../Styles/CompanyAndLeavePolicy/LeaveAssignCardStyle";
import { CancelButton } from "../../Styles/TaskManagement/TaskTypeStyle";
import "../../assets/styles/DataGridStyles.css";

export default function AllSprints() {
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");
  const isShowTask = useSelector((state) => state.taskData.isShowTask);
  const user = useSelector((state) => state.selectedActiveView.role);
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const employee = useSelector((state) => state.addEmployee.current?.empId);
  const admin = useSelector((state) => state.adminData.adminData?.empId);
  const updatedTask = useSelector((state) => state.taskData.updatedTask);
  const employeeLogin = useSelector(
    (state) => state.selectedActiveView.role === "employee"
  );
  const getTaskTypeList = useSelector(
    (state) => state?.selectedActiveView?.getTaskTypeList
  );
  const currentEmployeeId = useSelector(
    (state) => state.addEmployee.current?.empId
  );
  const empId = employeeLogin ? currentEmployeeId : "";
  const currentEmployeeLogin = user === "employee";
  const adminLogin = user === "admin";

  const [viewer, setViewer] = useState("");
  const [view, setView] = useState("list");
  const [column, setColumn] = useState([]);
  const [taskId, setTaskId] = useState("");
  const [searchTitle, setSearchTitle] = useState("");
  const [selectedType, setSelectedType] = useState({ type: "" });
  const [openDialog, setOpenDialog] = useState(false);
  const [dataGridHeight, setDataGridHeight] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [selectEmpId, setSelectEmpId] = useState([]);
  const [empDialog, setEmpDialog] = useState(false);
  const [searchEmp, setSearchEmp] = useState("");
  const [dialogSelectEmp, setDialogSelectEmp] = useState([]);

  const sprintId = new URLSearchParams(location?.search).get("sprintId");

  const onBack = () => {
    navigate(-1);
  };

  function handleDetailClick(id) {
    setOpenDialog(true);
    setTaskId(id);
    navigate(`?sprintId=${sprintId}&tNo=${id}`, { replace: true });
  }

  const sortByFirstName = (data) => {
    return (
      data
        ?.slice()
        ?.sort((a, b) => a?.FirstName?.localeCompare(b?.FirstName)) ?? []
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedType((prevData) => {
      return {
        ...prevData,
        [name]: value,
      };
    });
  };

  const onCloseEmployeeDialog = () => {
    setEmpDialog(false);
    setSelectEmpId((prevData) => {
      const updatedData = prevData?.filter(
        (id) => !dialogSelectEmp?.includes(id)
      );
      return updatedData;
    });
  };

  const handleRowSelect = (empId) => {
    setSelectEmpId((prev) =>
      prev?.includes(empId)
        ? prev?.filter((id) => id !== empId)
        : [...prev, empId]
    );
    setDialogSelectEmp((prev) =>
      Array?.isArray(prev)
        ? prev?.includes(empId)
          ? prev?.filter((id) => id !== empId)
          : [...prev, empId]
        : [empId]
    );
  };

  const fetchSprintTasks = async () => {
    if (!sprintId) return;
    try {
      const response = await getSprintTasks(sprintId, empId);
      const taskData = response?.data?.tasks || [];
      setFilteredData(sortingTask(taskData));
      setTasks(sortingTask(taskData));
      setEmployeeData(sortByFirstName(response?.data?.empData));
      setTypeOptions(response?.data?.taskTypes);
    } catch (error) {
      console.error("Error fetching sprint tasks:", error);
    }
  };

  const getStatusBackgroundColor = (status) => {
    switch (status) {
      case "In Progress":
        return "var(--pending-color)";
      case "Cancelled":
        return "var(--reject-color)";
      case "Completed":
        return "var(--approved-color)";
      case "Hold":
        return "var(--cancel-color)";
      case "To-Do":
        return "var(--primary-color)";
      default:
        return "var(--primary-color)";
    }
  };

  const formatTags = (tags) => {
    if (!tags || tags?.length === 0) return "";
    const maxVisibleTags = 2;
    const visibleTags = tags?.slice(0, maxVisibleTags);
    const additionalCount = tags?.length - maxVisibleTags;
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {visibleTags?.map((tag, index) => (
          <VisibleTagsChip key={index} label={tag?.title} color={tag?.color} />
        ))}
        {additionalCount > 0 && (
          <Tooltip
            title={tags
              ?.slice(maxVisibleTags)
              ?.map((tag) => tag?.title)
              .join(", ")}
          >
            <AdditionalCountChip label={`+${additionalCount}`} />
          </Tooltip>
        )}
      </div>
    );
  };

  const FormattedTaskData = filteredData?.map((task, index) => ({
    id: index + 1,
    record_id: task?._id,
    timestamp: task?.timestamp,
    date: task?.dueDate?.split("-").reverse().join("-"),
    ticketNo: task?.ticketNo,
    history: task?.history,
    assignBy: task?.assignBy?.name,
    assignByEmp: task?.assignBy?.empId,
    assignTo: task?.name,
    assignToEmp: task?.empId,
    title: task?.title,
    project: task?.project,
    priority: task?.priority,
    status: task?.status,
    statusValue: task?.statusValue,
    statusColor: task?.statusColor,
    category: task?.category,
    type: task?.type,
    description: task?.description,
    plannedHours: task?.plannedHours,
    actualHours: task?.actualHours,
    sprint: task?.sprint,
    tags: task?.tagList?.filter((tag) => tag && tag).map((tag) => tag) || [],
  }));

  const employeeColumns = useMemo(
    () =>
      [
        {
          field: "type",
          headerName: "Type",
          flex: 0.5,
          renderCell: (params) => {
            if (params?.row?.isNew) {
              return params.value;
            }
            const option = getTaskTypeList?.find(
              (opt) =>
                opt?._id === params.value || opt?.taskTypeName === params.value
            );
            if (!option) return null;
            return <TaskTypeCell taskTypeName={option?.taskTypeName} />;
          },
        },
        { field: "ticketNo", headerName: "Ticket", flex: 1 },
        { field: "title", headerName: "Title", flex: 3 },
        {
          field: "tags",
          headerName: "Tags",
          flex: 3,
          renderCell: (params) => formatTags(params.value),
        },
        { field: "date", headerName: "Due Date", flex: 1.2 },
        { field: "sprint", headerName: "Sprints", flex: 1.2 },
        {
          field: "priority",
          headerName: "Priority",
          flex: 1.5,
          renderCell: (params) => (
            <Box sx={{ display: "flex", height: "25px" }}>
              <Typography sx={{ marginRight: "8px" }}>
                {getIcon(params.value)}
              </Typography>
              <Typography
                sx={{
                  color: "var(--secondary-text-color) !important",
                }}
              >
                {params.value}
              </Typography>
            </Box>
          ),
        },
        {
          field: "status",
          headerName: "Status",
          flex: 1.2,
          sortable: true,
          renderCell: (params) => {
            const { statusValue, statusColor } = params.row;
            return (
              <StatusBox
                backgroundcolor={
                  statusValue && statusValue !== "0"
                    ? statusColor
                    : getStatusBackgroundColor(params.value)
                }
              >
                {params.value}
              </StatusBox>
            );
          },
        },
      ].filter((column) => column !== null),
    [getTaskTypeList]
  );

  const adminColumns = useMemo(
    () => [
      {
        field: "type",
        headerName: "Type",
        flex: 0.5,
        renderCell: (params) => {
          if (params?.row?.isNew) {
            return params.value;
          }
          const option = getTaskTypeList?.find(
            (opt) =>
              opt?._id === params.value || opt?.taskTypeName === params.value
          );
          if (!option) return null;
          return <TaskTypeCell taskTypeName={option?.taskTypeName} />;
        },
      },
      { field: "ticketNo", headerName: "Ticket", flex: 1 },
      { field: "title", headerName: "Title", flex: 2.2 },
      {
        field: "tags",
        headerName: "Tags",
        flex: 3.1,
        renderCell: (params) => formatTags(params.value),
      },
      { field: "date", headerName: "Due Date", flex: 1.5 },
      { field: "plannedHours", headerName: "Planned Hours", flex: 1.5 },
      { field: "actualHours", headerName: "Actual Hours", flex: 1.5 },
      { field: "sprint", headerName: "Sprints", flex: 1.5 },
      {
        field: "priority",
        headerName: "Priority",
        flex: 1.5,
        renderCell: (params) => (
          <Box sx={{ display: "flex", height: "25px" }}>
            <Typography
              sx={{
                marginRight: "2px",
                justifyContent: "flex-start",
              }}
            >
              {getIcon(params.value)}
            </Typography>
            <Typography
              sx={{
                color: "var(--secondary-text-color) !important",
              }}
            >
              {params.value}
            </Typography>
          </Box>
        ),
      },
      {
        field: "status",
        headerName: "Status",
        sortable: true,
        flex: 1.5,
        renderCell: (params) => {
          const { statusValue, statusColor } = params.row;
          return (
            <StatusBox
              backgroundcolor={
                statusValue && statusValue !== "0"
                  ? statusColor
                  : getStatusBackgroundColor(params.value)
              }
            >
              {params.value}
            </StatusBox>
          );
        },
      },
    ],
    [getTaskTypeList]
  );

  const renderChildern = () => {
    const limitedEmployeeData = employeeData?.slice(isMobile ? 9 : 4);
    const filteredEmployeeData = searchEmp?.trim()
      ? limitedEmployeeData?.filter((employee) =>
          employee?.FirstName?.toLowerCase()?.includes(searchEmp?.toLowerCase())
        )
      : limitedEmployeeData;
    return (
      <Box
        sx={{
          background: isDarkTheme ? "#121e2e" : "#ffffff",
          padding: "20px",
        }}
      >
        <MainBoxDialog>
          <DialogTitle
            sx={{
              color: isDarkTheme ? "#e0e0e0e3;" : "#544f5a",
              padding: "0px 0px",
            }}
          >
            {/* <InnerBoxOneDialog> */}
            {/* <Box sx={{ padding: "8px 5px" }}>
                  <InnerBoxTwoDialog
                    isdarktheme={isDarkTheme ? "true" : "false"}
                  />
                </Box> */}
            <Box
              sx={{
                fontSize: { xs: "1.2rem", sm: "1.2rem" },
                color: isDarkTheme ? "#e0e0e0e3" : "#544f5a",
              }}
            >
              Select Employees
            </Box>
            {/* </InnerBoxOneDialog> */}
          </DialogTitle>
          <Box>
            <CloseIconButton
              onClick={onCloseEmployeeDialog}
              isdarktheme={isDarkTheme ? "true" : "false"}
            >
              <Close />
            </CloseIconButton>
          </Box>
        </MainBoxDialog>
        <DialogContent sx={{ padding: "0px" }}>
          <Box sx={{ mb: 1, mt: 1 }}>
            <TextField
              size="small"
              variant="outlined"
              label="Search"
              value={searchEmp}
              onChange={(e) => setSearchEmp(e.target.value)}
              sx={{ width: isMobile ? "160px" : "150px", mr: 1 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon
                      sx={{
                        fontSize: "17px",
                        color: "var(--primary-text-color)",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <TableContainers sx={{ mb: 2 }}>
            <Tables
              sprint={"sprint"}
              isdarktheme={isDarkTheme ? "true" : "false"}
            >
              <TableHeads>
                <TableRow sx={{ textDecoration: "none" }}>
                  <TableCell padding="checkbox">
                    {/* <Checkbox
                      checked={allSelected}
                      onChange={handleSelectAll}
                      inputProps={{ "aria-label": "select all employees" }}
                    /> */}
                  </TableCell>
                  <TableCells
                    emptasktableheadcell={empTaskTableHeadCell}
                    isdarktheme={isDarkTheme ? "true" : "false"}
                  >
                    Employee Name
                  </TableCells>
                </TableRow>
              </TableHeads>
              <TableBody>
                {filteredEmployeeData?.map((employee, index) => (
                  <TableRow
                    sx={{
                      maxHeight: "20px",
                      backgroundColor:
                        index % 2 === 1 ? "var(--highlight-color)" : "",
                      background: isDarkTheme ? "#142840" : "#ffffff",
                    }}
                    key={employee?.empId}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectEmpId?.includes(employee?.empId)}
                        onChange={() => handleRowSelect(employee?.empId)}
                        inputProps={{
                          "aria-label": `select ${employee?.FirstName}`,
                        }}
                        sx={{
                          color: isDarkTheme
                            ? "#e0e0e0e3"
                            : "var(--primary-text-color) !important",
                          "&.Mui-checked": {
                            color: "var(--primary-color) !important",
                          },
                        }}
                      />
                    </TableCell>
                    <CustomTableCells
                      emptasktablebodycell={empTaskTableBodyCell}
                      isdarktheme={isDarkTheme ? "true" : "false"}
                    >
                      {employee?.FirstName}
                    </CustomTableCells>
                  </TableRow>
                ))}
              </TableBody>
            </Tables>
          </TableContainers>
        </DialogContent>
        <DialogActions sx={{ padding: "10px 0px 0px 8px" }}>
          <CancelButton onClick={onCloseEmployeeDialog} color="secondary">
            Cancel
          </CancelButton>
          <Button variant="contained" onClick={() => setEmpDialog(false)}>
            {"OK"}
          </Button>
        </DialogActions>
      </Box>
    );
  };

  useEffect(() => {
    if (!tasks || tasks.length === 0) return;
    let newFilterData = tasks;
    if (searchTitle?.trim()) {
      const normalizedSearchTitle = searchTitle?.trim()?.toLowerCase();
      newFilterData = newFilterData?.filter(
        (task) =>
          task?.title?.toLowerCase()?.includes(normalizedSearchTitle) ||
          task?.ticketNo?.toLowerCase()?.includes(normalizedSearchTitle)
      );
    }
    if (selectedType?.type?.trim()) {
      newFilterData = newFilterData?.filter(
        (task) => task?.type === selectedType.type
      );
    }
    if (selectEmpId?.length > 0) {
      newFilterData = newFilterData?.filter((task) =>
        selectEmpId?.includes(task?.empId)
      );
    }
    setFilteredData(newFilterData);
    setColumns((prevData) => {
      return prevData?.map((workflow) => {
        const updatedStatuses = workflow?.statuses?.map((status) => {
          const filteredTasks = newFilterData?.filter(
            (task) => task?.statusValue === status?.id
          );
          return {
            ...status,
            tasks: filteredTasks,
          };
        });
        return {
          ...workflow,
          statuses: updatedStatuses,
        };
      });
    });
  }, [tasks, searchTitle, selectedType, selectEmpId]);

  useEffect(() => {
    if (!empDialog && selectEmpId?.length > 0) {
      setEmployeeData((prevData) => {
        const matchedRecords = prevData?.filter((item) =>
          selectEmpId.includes(item?.empId)
        );
        const unmatchedRecords = prevData?.filter(
          (item) => !selectEmpId.includes(item?.empId)
        );
        const combinedRecords = [...matchedRecords, ...unmatchedRecords];
        return combinedRecords;
      });
    } else {
      setEmployeeData((prevData) => sortByFirstName(prevData));
    }
  }, [selectEmpId, empDialog]);

  useEffect(() => {
    if (
      updatedTask &&
      Object.keys(updatedTask).length > 0 &&
      updatedTask?._id
    ) {
      setColumns((prevData) =>
        prevData?.map((workflow) => ({
          ...workflow,
          statuses: workflow?.statuses?.map((status) => {
            const index = status?.tasks?.findIndex(
              (task) => task?._id === updatedTask?._id
            );
            if (index !== -1) {
              const updatedTasks = [...status?.tasks];
              updatedTasks[index] = updatedTask;
              return {
                ...status,
                tasks: updatedTasks,
              };
            }
            return { ...status };
          }),
        }))
      );

      setFilteredData((prevData) => {
        const index = prevData?.findIndex(
          (task) => task?._id === updatedTask?._id
        );
        if (index !== -1) {
          const updatedTasks = [...prevData];
          updatedTasks[index] = updatedTask;
          return updatedTasks;
        } else {
          return [updatedTask, ...(prevData || [])];
        }
      });
    }
  }, [updatedTask]);

  useEffect(() => {
    if (currentEmployeeLogin) {
      setViewer(employee);
      setColumn(employeeColumns);
    } else if (adminLogin) {
      setViewer(admin);
      setColumn(adminColumns);
    }
  }, [
    admin,
    adminColumns,
    adminLogin,
    currentEmployeeLogin,
    employee,
    employeeColumns,
  ]);

  useEffect(() => {
    fetchSprintTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sprintId, empId, isShowTask]);

  return (
    <Box sx={{ width: "100%", maxHeight: "fit-content", overflowX: "auto" }}>
      <Box
        sx={{
          "@media (max-width: 600px)": {
            width: "330%",
          },
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "8px 0px",
            }}
          >
            <BackButton
              variant="text"
              startIcon={<ArrowBackIcon />}
              onClick={onBack}
              sx={{
                minWidth: 'unset',
                padding: "0px",
                marginRight: "10px",
                justifyContent: "flex-start",
                color: "var(--primary-color)",
              }}
            ></BackButton>
            <TitleTypography variant="h6">
              {view.charAt(0).toUpperCase() + view.slice(1)} View
            </TitleTypography>
          </Box>
          <HeaderBox>
            <FilterBox ismobile={isMobile}>
              <Box>
                <TextField
                  size="small"
                  variant="outlined"
                  label="Search"
                  value={searchTitle}
                  onChange={(e) => setSearchTitle(e.target.value)}
                  sx={{ width: isMobile ? "160px" : "200px", mr: 1 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon
                          sx={{
                            fontSize: "18px",
                            color: "var(--primary-text-color)",
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormControl
                  size="small"
                  sx={{ minWidth: isMobile ? 150 : 170 }}
                >
                  <TypeCommon
                    sprint={"sprint"}
                    isDarkTheme={isDarkTheme}
                    formData={selectedType}
                    typeOptions={typeOptions}
                    handleChange={handleChange}
                  />
                </FormControl>
              </Box>
              <Box
                sx={{ ml: isMobile ? "6px" : 2, marginTop: isMobile ? 1 : 0 }}
              >
                <EmployeeAvatarGroup
                  setEmpDialog={setEmpDialog}
                  selectEmpId={selectEmpId}
                  setSelectEmpId={setSelectEmpId}
                  employeeData={employeeData}
                  maxVisible={isMobile ? 9 : 4}
                  setEmployeeData={setEmployeeData}
                  setDialogSelectEmp={setDialogSelectEmp}
                />
              </Box>
            </FilterBox>
            <Box>
              <Button
                variant={view === "list" ? "contained" : "outlined"}
                onClick={() => setView("list")}
                sx={{ mr: 1, minWidth: "0px" }}
              >
                <ListAltIcon width="18px" />
              </Button>
              <Button
                variant={view === "board" ? "contained" : "outlined"}
                onClick={() => setView("board")}
                sx={{ minWidth: "0px" }}
              >
                <InsertChartIcon width="18px" />
              </Button>
            </Box>
          </HeaderBox>
          {view === "list" ? (
            <DataGrid
              disableColumnMenu
              rowHeight={43}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              aria-selected="false"
              rows={FormattedTaskData}
              columns={column}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize:
                      parseInt(
                        window.localStorage.getItem("paginationNo"),
                        10
                      ) || 10,
                  },
                },
                columns: {
                  columnVisibilityModel: {
                    sprint: false,
                  },
                },
              }}
              onRowCountChange={(params) => {
                if (params === 0) {
                  setDataGridHeight(true);
                } else {
                  setDataGridHeight(false);
                }
              }}
              onRowClick={(params) => {
                handleDetailClick(params.row.ticketNo);
              }}
              onPaginationModelChange={(pageSize) => {
                window.localStorage.setItem("paginationNo", pageSize.pageSize);
              }}
              onPageSizeChange={(pageSize) => {
                window.localStorage.setItem("paginationNo", pageSize);
              }}
              pageSizeOptions={numberArray}
              components={{
                Toolbar: GridToolbar,
              }}
              sx={{
                height: dataGridHeight ? "230px" : "fit-content",
                ".MuiDataGrid-filler": {
                  height: "0px !important",
                  display: "none",
                },
                color: "var(--primary-text-color)",
                textTransform: "capitalize",
                ".MuiDataGrid-columnHeaderTitle": {
                  textTransform: "none !important",
                },
                backgroundColor: "var(--subAccordian-background-color)",
                ".MuiDataGrid-cell ": {
                  cursor: "pointer",
                  alignContent: "center",
                },
                ".MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                ".MuiDataGrid-columnHeader": {
                  height: "48px !important",
                },
                ".MuiInput-underline:before": {
                  borderBottom:
                    "1px solid var(--primary-text-color) !important",
                },
                ".MuiSvgIcon-fontSizeSmall": {
                  fill: "var(--primary-text-color) !important",
                },
                ".MuiInputBase-input.MuiInput-input.MuiInputBase-inputTypeSearch.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd":
                  {
                    color: "var(--primary-text-color) !important",
                  },
                ".MuiDataGrid-overlay": {
                  color: "var(--primary-text-color) !important",
                  backgroundColor: "var(--subAccordian-background-color)",
                },
                ".MuiDataGrid-columnHeader:focus": {
                  outline: "none",
                },
                ".MuiDataGrid-actionsCell": { type: "actions" },
                "--DataGrid-containerBackground":
                  "var(--primary-highlight-color)",
              }}
            />
          ) : (
            <>
              <BoardView
                filteredData={filteredData}
                tasks={tasks}
                setTasks={setTasks}
                setFilteredData={setFilteredData}
                columns={columns}
                setColumns={setColumns}
                typeOptions={typeOptions}
              />
            </>
          )}
        </Box>
      </Box>
      <TaskDialogBox
        open={openDialog}
        setOpenDialog={setOpenDialog}
        taskId={taskId}
        viewer={viewer}
        onClose={() => handleDetailClick()}
      />
      <DialogComponent
        open={empDialog}
        onClose={() => setEmpDialog(false)}
        maxWidth="280px"
        PaperProps={{
          sx: {
            width: !isMobile && "380px",
            height: "auto",
            marginTop: !isMobile && "3%",
            marginBottom: !isMobile && "auto",
            marginLeft: isMobile ? "3%" : "12%",
            marginRight: isMobile ? "3%" : "10%",
            borderRadius: !isMobile && "10px",
            "@media (max-width: 600px)": {
              width: "100%",
            },
          },
        }}
        renderChildern={renderChildern}
      />
    </Box>
  );
}
