import React, { useState, useMemo, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { useSelector } from 'react-redux';

import { DashboardProfileButtons } from "./CustomDesignMUI/CustomMUI";
import DisplayPersonal from "../DisplayEmployee/DisplayPersonal/DisplayPersonal";
import DisplayContact from "../DisplayEmployee/DisplayContact/DisplayContact";
import DisplayEducation from "../DisplayEmployee/DisplayEducation/DisplayEducation";
import DisplayFamily from "../DisplayEmployee/DisplayFamily/DisplayFamily";
import DisplayExperience from "../DisplayEmployee/DisplayExperience/DisplayExperience";
import DisplayFinancial from "../DisplayEmployee/DisplayFinancial/DisplayFinancial";
import { MainBoxProfile, RenderTabBox, DangerZoneButton, PersonalDetailsButton, ContactDetailsButton, EducationQualificationButton, FamilyDetailsButton, ExperienceDetailsButton, FinancialDetailsButton } from "../../Styles/UpdateEmployee/ViewProfileStyle";
import DangarZone from "../DisplayEmployee/DangarZone";
import { getEmpDetails } from "../../Services/EmployeeManageServices";

const AddEmpForm = () => {
  const empId = useSelector(
    (state) => state.addEmployee.employeeDelete.value
  );
  const [selectedTab, setSelectedTab] = useState("personal");
  const [employeeCall, setEmployeeCall] = useState([]);

  const tabOrder = [
    "personal",
    "contact",
    "education",
    "family",
    "experience",
    // "job",
    "financial",
    "danger"
  ];

  const saveNextButtonCallback = (activeTabData) => {
    handleNext();
  };

  const handleNext = () => {
    let index = tabOrder.indexOf(selectedTab);
    setSelectedTab(tabOrder[index + 1]);
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const previousButtonCallback = () => {
    handlePrevious();
  };

  const handlePrevious = () => {
    let index = tabOrder.indexOf(selectedTab);
    setSelectedTab(tabOrder[index - 1]);
  };

  const renderTabContent = () => {
    if (selectedTab === "personal") {
      return (
        <DisplayPersonal saveNextButtonCallback={saveNextButtonCallback} />
      );
    } else if (selectedTab === "contact") {
      return (
        <DisplayContact
          saveNextButtonCallback={saveNextButtonCallback}
          previousButtonCallback={previousButtonCallback}
        />
      );
    } else if (selectedTab === "education") {
      return (
        <DisplayEducation
          saveNextButtonCallback={saveNextButtonCallback}
          previousButtonCallback={previousButtonCallback}
        />
      );
    } else if (selectedTab === "family") {
      return (
        <DisplayFamily
          saveNextButtonCallback={saveNextButtonCallback}
          previousButtonCallback={previousButtonCallback}
        />
      );
    } else if (selectedTab === "experience") {
      return (
        <DisplayExperience
          saveNextButtonCallback={saveNextButtonCallback}
          previousButtonCallback={previousButtonCallback}
        />
      );
    }
    // else if (selectedTab === "job") {
    //   return (
    //     <DisplayJob
    //       saveNextButtonCallback={saveNextButtonCallback}
    //       previousButtonCallback={previousButtonCallback}
    //     />
    //   );
    // }
    else if (selectedTab === "financial") {
      return (
        <DisplayFinancial
          saveNextButtonCallback={saveNextButtonCallback}
          previousButtonCallback={previousButtonCallback}
        />
      );
    }
    else if (selectedTab === "danger") {
      return (<DangarZone
        employeeCall={employeeCall}
      />)
    }
  };

  const memoizedgetData = useMemo(() => {
    const getData = async () => {
      try {
        const response = await getEmpDetails(empId)
        if (response.status === 200 || response.status === 201) {
          setEmployeeCall(response.data.user);
        }
        else {
          setEmployeeCall([]);
        }
      } catch (error) {
      }
    };
    return getData;
  }, [empId]);

  useEffect(() => {
    memoizedgetData();
  }, [memoizedgetData, empId]);

  return (
    <>
      <Box
        sx={{
          margin: "0px 0px",
        }}
      ></Box>
      <Box
        sx={{
          marginTop: "0px",
        }}
      >
        <Grid container item spacing={3}>
          <Grid container item xs={12} md={3.5}>
            <MainBoxProfile>
              <PersonalDetailsButton
                onClick={() => handleTabChange("personal")}
                dashboardprofilebuttons={DashboardProfileButtons}
                selectedtab={selectedTab}
              >
                Personal Details
              </PersonalDetailsButton>
              <ContactDetailsButton
                onClick={() => handleTabChange("contact")}
                dashboardprofilebuttons={DashboardProfileButtons}
                selectedtab={selectedTab}
              >
                Contact Details
              </ContactDetailsButton>
              <EducationQualificationButton
                onClick={() => handleTabChange("education")}
                dashboardprofilebuttons={DashboardProfileButtons}
                selectedtab={selectedTab}
              >
                Education Qualification
              </EducationQualificationButton>
              <FamilyDetailsButton
                onClick={() => handleTabChange("family")}
                dashboardprofilebuttons={DashboardProfileButtons}
                selectedtab={selectedTab}
              >
                Family Details
              </FamilyDetailsButton>
              <ExperienceDetailsButton
                onClick={() => handleTabChange("experience")}
                dashboardprofilebuttons={DashboardProfileButtons}
                selectedtab={selectedTab}
              >
                Experience Details
              </ExperienceDetailsButton>
              {/* <Button
                onClick={() => handleTabChange("job")}
                sx={{
                  ...DashboardProfileButtons,
                  backgroundColor:
                    selectedTab === "job"
                      ? "var(--primary-highlight-color)"
                      : "var(--background-table-sidebar-card-color)",
                  color:
                    selectedTab === "job"
                      ? "var(--primary-color)"
                      : "var(--primary-text-color)",
                  fontWeight: selectedTab === "job" ? "bold" : "normal",
                  border: "none",
                }}
              >
                Job Details
              </Button> */}
              <FinancialDetailsButton
                onClick={() => handleTabChange("financial")}
                dashboardprofilebuttons={DashboardProfileButtons}
                selectedtab={selectedTab}
              >
                Financial Details
              </FinancialDetailsButton>
              <DangerZoneButton
                onClick={() => handleTabChange("danger")}
                dashboardprofilebuttons={DashboardProfileButtons}
                selectedtab={selectedTab}
              >
                Danger Zone
              </DangerZoneButton>
            </MainBoxProfile>
          </Grid>
          <Grid container item xs={12} md={8.5}>
            <RenderTabBox>
              {renderTabContent()}
            </RenderTabBox>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AddEmpForm;
