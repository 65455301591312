import { Box, TextField, styled } from "@mui/material"
import {
  commonStyle
} from "../../components/ReusableComponents/CustomDesignMUI/CustomMUI";

export const MainBox = styled(Box)(({ ismobile }) => ({
  margin: ismobile !== "true" && "0px 35px",
  overflowY: "auto",
  marginTop: "10px",
  marginLeft: "9px",
}))

export const TitleBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}))

export const AddRecordBox = styled(Box)(() => ({
  marginTop: "20px",
  marginBottom: "10px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}))

export const InputTextField = styled(TextField)(({ isdarktheme }) => ({
  paddingBottom: "10px",
  width: "80%",
  marginTop: "10px",
  ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline ":
  {
    borderColor: "var(--secondary-text-color)",
  },
  '& input[type="date"]::-webkit-calendar-picker-indicator': {
    filter: isdarktheme === "true" ? "invert(1)" : "none",
  },
  ...commonStyle
}))

export const PhoneTextField = styled(TextField)(() => ({
  width: "80%",
  paddingBottom: "10px",
  marginTop: "10px",
  ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline ":
  {
    borderColor: "var(--secondary-text-color)",
  },
  ".MuiTypography-root": {
    color: "var(--secondary-text-color) !important",
  },
  ...commonStyle
}))