import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { AccessTime as AccessTimeIcon, PlayCircleOutline as PlayCircleOutlineIcon, HourglassEmpty as HourglassEmptyIcon, Devices as DevicesIcon } from "@mui/icons-material";

import { UpdateStatCompBox } from "../../ReusableComponents/CustomDesignMUI/CustomMUI";
import EmployeeProgress from "./EmployeeProgress";
import { formatTime } from "../../ReusableComponents/UtilityFunctions";
import { BreakTimeBox, CheckInBox, CheckOutBox, MainGrid, WorkingHrsBox } from "../../../Styles/UpdateEmployee/StatisticsCompStyle";

const StatComp = ({ EmpId }) => {
	const { employeeId } = useParams();
	const isMobile = useMediaQuery("(max-width:600px)");
	const allEmployeeAttendence = useSelector(
		(state) => state.employeeData.allEmployeeAttendenceData
	);
	const selectedEmpId = employeeId || EmpId;

	const [attendenceData, setAttendenceData] = useState([
		{
			averageBreakTime: 0,
			averageInTime: 0,
			averageOutTime: 0,
			averageTotalTime: 0,
		},
	]);

	function convertTo12HourFormat(time) {
		const [hours, minutes, seconds] = time?.split(":").map(Number);
		if (time === "00:00:00") {
			return `00:00:00 Hrs`;
		}
		let formattedHours = hours === "00" ? hours : hours % 12 || 12;
		const ampm = hours < 12 ? "AM" : "PM";

		const formattedMinutes = String(minutes).padStart(2, "0");
		const formattedSeconds = String(seconds).padStart(2, "0");

		return `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
	}

	useEffect(() => {
		const filteredDataByEmployee = allEmployeeAttendence?.filter(
			(item) => item?.empId === selectedEmpId
		);
		if (filteredDataByEmployee?.length !== 0) {
			setAttendenceData(filteredDataByEmployee);
		}
	}, [selectedEmpId, allEmployeeAttendence]);

	return (
		<Box>
			<Grid item container md={12} spacing={2}>
				<Grid item md={6} xs={12}>
					<Box>
						<EmployeeProgress EmpId={EmpId} />
					</Box>
				</Grid>
				<MainGrid
					ismobile={isMobile ? "true" : "false"}
					item
					md={6}
				>
					<CheckInBox updatestatcompbox={UpdateStatCompBox}>
						<AccessTimeIcon sx={{ color: "var(--secondary-color)" }} />
						<Box sx={{ marginLeft: "10px" }}>
							<Typography sx={{ fontWeight: "bold", fontSize: "13px" }}>
								Average CheckIn Time
							</Typography>
							<Typography sx={{ fontSize: "13px" }}>
								{convertTo12HourFormat(
									attendenceData[0]?.averageInTime ||
									"00:00:00"
								)}
							</Typography>
						</Box>
					</CheckInBox>
					<CheckOutBox updatestatcompbox={UpdateStatCompBox}>
						<PlayCircleOutlineIcon
							sx={{ color: "var(--reject-color)" }}
						/>
						<Box sx={{ marginLeft: "10px" }}>
							<Typography sx={{ fontWeight: "bold", fontSize: "13px" }}>
								Average CheckOut Time
							</Typography>
							<Typography sx={{ fontSize: "13px" }}>
								{convertTo12HourFormat(
									attendenceData[0]?.averageOutTime ||
									"00:00:00"
								)}
							</Typography>
						</Box>
					</CheckOutBox>
					<BreakTimeBox updatestatcompbox={UpdateStatCompBox}>
						<HourglassEmptyIcon
							sx={{ color: "var(--totalHrs-color)" }}
						/>
						<Box sx={{ marginLeft: "10px" }}>
							<Typography
								sx={{ fontWeight: "bold", fontSize: "13px" }}
							>
								Average Break Time
							</Typography>
							<Typography sx={{ fontSize: "13px" }}>
								{formatTime(
									Math.floor(
										(attendenceData[0]?.averageBreakTime ||
											0) / 1000
									)
								) || 0}{" "}
								Hrs
							</Typography>
						</Box>
					</BreakTimeBox>
					<WorkingHrsBox updatestatcompbox={UpdateStatCompBox}>
						<DevicesIcon
							sx={{ color: "var(--avgWorkingHrs-color)" }}
						/>
						<Box sx={{ marginLeft: "10px" }}>
							<Typography
								sx={{ fontWeight: "bold", fontSize: "13px" }}
							>
								Average Working hrs
							</Typography>
							<Typography sx={{ fontSize: "13px" }}>
								{formatTime(
									Math.floor(
										(attendenceData[0]?.averageTotalTime ||
											0) / 1000
									)
								)}{" "}
								Hrs
							</Typography>
						</Box>
					</WorkingHrsBox>
				</MainGrid>
			</Grid>
		</Box>
	);
};

export default StatComp;
