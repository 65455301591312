import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography, IconButton, Tooltip, Zoom, DialogContent, DialogTitle, Link, } from '@mui/material';
import {
    GridToolbar,
} from "@mui/x-data-grid";
import { Close, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

import {
    setToast,
    setToastMsg,
    setToastType,
} from "../../Slices/toastSlice";
import { errorMessage } from '../../Services/axiosInstance';
import { numberArray } from "../Constant/constant";
import Loader from '../Loader/Loader';
import DialogComponent from '../ReusableComponents/DialogBoxComponents/DialogComponent';
import AddWorkFlowDialog from './AddWorkFlowDialog';
import { deleteWorkFlow, getAllWorkFlow } from '../../Services/WorkFlowServices';
import { LoadingBox, MainBox, TitleTypography, InnerBoxOne, AddWorkFlowBox, DataGrids } from "../../Styles/WorkFlow/WorkFlowStyle"
import { DeleteButton, MainBoxDialog } from "../../Styles/KnowledgeSharing/KnowledgeSharingStyle";
import { InnerBoxOneDialog, CancelButton, ErrorTypography, InnerBoxTwoDialog, CloseIconButton, DialogsActions } from "../../Styles/WorkFlow/AddWorkFlowDialogStyle"

function WorkFlow() {
    const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [getWorkFlowData, setGetWorkFlowData] = useState([]);
    const [dataGridHeight, setDataGridHeight] = useState(false);
    const [openDialogBox, setOpenDialogBox] = useState(false);
    const [editId, setEditId] = useState("");
    const [openDialogDelete, setOpenDialogDelete] = useState(false);
    const [_IdForDelete, set_IdForDelete] = useState("");
    const [_dataThatDelete, set_DataThatDelete] = useState({});
    const [displayError, setDisplayError] = useState("");

    const onClose = () => {
        set_IdForDelete("");
        setOpenDialogDelete(false);
        setDisplayError("")
    }

    const getAllWorlFlowData = async () => {
        setIsLoading(true);
        try {
            const response = await getAllWorkFlow();
            if (response.status === 200) {
                setIsLoading(false);
                setGetWorkFlowData(response?.data?.workflows);
            } else {
                setGetWorkFlowData([]);
            }
        } catch (error) {
            setIsLoading(false);
        }
    };

    const deleteWorkFlowData = async (workFlow) => {
        try {
            const response = await deleteWorkFlow(workFlow?.workFlowId)
            if (response.status === 200 || response.status === 201) {
                const id = response?.data?.workFlowId
                setGetWorkFlowData(prevData => prevData?.filter(workflow => workflow?._id !== id));
                onClose();
                dispatch(setToast(true));
                dispatch(setToastMsg(response.data.message ?? "WorkFlow deleted."));
                dispatch(setToastType("success"));
            }
        }
        catch (error) {
            setDisplayError(errorMessage ?? "")
        }
    }

    const FormattedWorkFlowData = getWorkFlowData?.map((item, index) => ({
        id: index + 1,
        workFlowName: item?.workFlowName,
        workFlowId: item?._id,
    }));

    const columns = [
        { field: "id", headerName: "No.", flex: 1 },
        { field: "workFlowName", headerName: "Workflow Name", flex: 1 },
        {
            field: "actions",
            headerName: "Actions",
            flex: 0.7,
            renderCell: (params) => (
                <Box sx={{ display: "flex", gap: "2px" }}>
                    <Tooltip
                        title="Edit"
                        placement="bottom"
                        arrow
                        TransitionComponent={Zoom}
                    >
                        <IconButton
                            sx={{
                                color: isDarkTheme ? "#e0e0e0e3" : "#544f5a",
                            }}
                            variant="contained"
                            onClick={() => {
                                setEditId(params?.row?.workFlowId)
                                setOpenDialogBox(true)
                            }}
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title="Delete"
                        placement="bottom"
                        arrow
                        TransitionComponent={Zoom}
                    >
                        <IconButton
                            sx={{
                                color: isDarkTheme ? "#d94a38" : "#c62828",
                            }}
                            variant="contained"
                            onClick={(e) => {
                                e.stopPropagation();
                                setOpenDialogDelete(true);
                                set_IdForDelete(params?.row);
                                set_DataThatDelete(params?.row);
                            }
                            }
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Box >
            ),
        },
    ]

    const renderFormDelete = () => {
        return (
            <>
                <Box sx={{ background: isDarkTheme ? "#121e2e" : "#ffffff", padding: "8px" }}>
                    <MainBoxDialog>
                        <Box>
                            <DialogTitle sx={{ fontWeight: "bold", padding: "0px 0px", fontSize: { xs: "1.2rem", sm: "1.5rem" } }}>
                                <InnerBoxOneDialog>
                                    <Box sx={{ padding: "8px 5px" }}>
                                        <InnerBoxTwoDialog
                                            isdarktheme={isDarkTheme ? "true" : "false"}
                                        />
                                    </Box>
                                    <Box>
                                        <Box
                                            sx={{
                                                fontSize: { xs: "1rem", sm: "1.2rem" },
                                                color: isDarkTheme ? "#e0e0e0e3" : "#544f5a",
                                            }}
                                        >
                                            Confirm Delete Status
                                        </Box>
                                    </Box>
                                </InnerBoxOneDialog>
                            </DialogTitle>
                        </Box>
                        <Box>
                            <CloseIconButton
                                onClick={onClose}
                                isdarktheme={isDarkTheme ? "true" : "false"}
                            >
                                <Close />
                            </CloseIconButton>
                        </Box>
                    </MainBoxDialog>
                    <DialogContent sx={{ padding: "6px 4px 5px 7px" }}>
                        <Typography
                            sx={{
                                color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82",
                            }}
                        >
                            Are you sure you want to Delete{" "}
                            <span
                                style={{
                                    textDecoration: "underline",
                                }}
                            >
                                {_dataThatDelete?.workFlowName}
                            </span>{" "}
                            Status?
                        </Typography>
                    </DialogContent>
                    <DialogsActions displayError={displayError}>
                        <Box sx={{ background: isDarkTheme ? "#121e2e" : "#ffffff" }}>
                            <ErrorTypography>{displayError}</ErrorTypography>
                        </Box>
                        <Box>
                            <CancelButton
                                isdarktheme={isDarkTheme ? "true" : "false"}
                                onClick={onClose}
                            >
                                Cancel
                            </CancelButton>
                            <DeleteButton
                                variant="contained"
                                onClick={() => {
                                    deleteWorkFlowData(_IdForDelete);
                                }}
                                component={Link}
                                color="error"
                            >
                                Delete
                            </DeleteButton>
                        </Box>
                    </DialogsActions>
                </Box>

            </>
        );
    }

    useEffect(() => {
        getAllWorlFlowData()
    }, []);

    return (
        <Box>
            {isLoading ? (
                <LoadingBox>
                    <Loader />
                </LoadingBox>
            ) : (
                <Box>
                    <MainBox bottom={0}>
                        <TitleTypography variant="h6">
                            Workflow
                        </TitleTypography>
                        <AddWorkFlowBox>
                            <Tooltip
                                title="Add New WorkFlow"
                                arrow
                                disableInteractive
                                TransitionComponent={Zoom}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        setOpenDialogBox(true)
                                    }}
                                >
                                    + Add WorkFlow
                                </Button>
                            </Tooltip>
                        </AddWorkFlowBox>
                    </MainBox>
                    <InnerBoxOne>
                        <Box style={{ maxHeight: "fit-content", width: '100%', overflowX: "auto" }}>
                            <Box sx={{
                                '@media (max-width: 600px)': {
                                    width: '170%',
                                },
                            }}>
                                <DataGrids
                                    disableColumnMenu
                                    rowHeight={43} 
                                    slots={{ toolbar: GridToolbar }}
                                    slotProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                        },
                                    }}
                                    aria-selected="false"
                                    rows={FormattedWorkFlowData}
                                    getRowId={(row) => row.id}
                                    columns={columns}
                                    initialState={{
                                        columns: {
                                            columnVisibilityModel: {
                                                startDate: false,
                                            },
                                        },
                                        pagination: {
                                            paginationModel: {
                                                pageSize:
                                                    parseInt(window.localStorage.getItem("paginationNo"), 10) ||
                                                    10,
                                            },
                                        },
                                    }}
                                    onRowCountChange={(params) => {
                                        if (params === 0) {
                                            setDataGridHeight(true);
                                        } else {
                                            setDataGridHeight(false);
                                        }
                                    }}
                                    onPaginationModelChange={(pageSize) => {
                                        window.localStorage.setItem("paginationNo", pageSize.pageSize);
                                    }}
                                    onPageSizeChange={(pageSize) => {
                                        window.localStorage.setItem("paginationNo", pageSize);
                                    }}
                                    pageSizeOptions={numberArray}
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}
                                    dataGridHeight={dataGridHeight}
                                />
                            </Box>
                        </Box>
                    </InnerBoxOne>
                </Box>)
            }
            <AddWorkFlowDialog
                editId={editId}
                setEditId={setEditId}
                open={openDialogBox}
                setOpenDialogBox={setOpenDialogBox}
                setGetWorkFlowData={setGetWorkFlowData}
            />
            <DialogComponent maxWidth="md" onClose={onClose} open={openDialogDelete} renderChildern={renderFormDelete} />
        </Box >
    )
}

export default WorkFlow