import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Zoom,
  styled,
} from "@mui/material";
import { Close as CloseIcon, UploadFile as UploadFileIcon, Link as LinkIcon, FilePresent as FilePresentIcon } from "@mui/icons-material";

import {
  setToast,
  setToastMsg,
  setToastType,
} from "../../Slices/toastSlice";
import { CLOUD_FUNCTIONS_ORIGIN } from "../../function-origin";
import { setKnowledgeData } from "../../Slices/employeeDetailSlice";
import Loader from "../Loader/Loader";
import { errorMessage } from "../../Services/axiosInstance";
import { editKnowledge, upsertKnowledge, upsertKnowledgeFile } from "../../Services/KnowledgeSharingServices";
import { InnerBoxOne, InnerBoxThree, InnerBoxTwo, InputTextField, LoadingBox, MainBox, MainCard } from "../../Styles/KnowledgeSharing/AddKnowledgeStyle";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function AddKnowledge({
  setChange,
  change,
  onClose,
  isExistingKnowledge,
  setIsExistingKnowledge,
  existingKnowledge,
  setExistingKnowledge,
  selectedButton,
  setSelectedButton,
}) {
  const dispatch = useDispatch();
  const knowledgeData = useSelector((state) => state.addEmployee.knowledgeData);

  const [showFileName, setShowFileName] = useState("");
  const [knowledgeFile, setKnowledgeFile] = useState({});
  const [knowledgeFileAvailable, setKnowledgeFileAvailable] = useState(false);
  const [displayMessageError, setDisplayMessageError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [addKnowledgeData, setAddKnowledgeData] = useState({
    Title: knowledgeData ? knowledgeData?.Title : "",
    Description: knowledgeData ? knowledgeData?.Description : "",
    RefLink: knowledgeData ? knowledgeData?.RefLink : "",
  });
  const [errors, setErrors] = useState("");

  const setClose = () => {
    setAddKnowledgeData({})
    // dispatch(setKnowledgeData({}));
    setIsExistingKnowledge(false);
    setExistingKnowledge({});
    onClose();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setKnowledgeFile(file);
      setShowFileName(file.name);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDisplayMessageError("");

    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));

    setAddKnowledgeData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    dispatch(setKnowledgeData(addKnowledgeData));
  };
  const handleSubmit = async (e) => {
    const error = [];
    e.preventDefault();
    if (!addKnowledgeData.Title) {
      error.title = "Title can't be empty.";
    }
    if (!addKnowledgeData.Description) {
      error.description = "Description can't be empty.";
    }
    setErrors(error);
    const noErrors = Object.keys(error).length === 0;

    if (!noErrors) {
      return;
    }

    // Edit project Api Call
    if (isExistingKnowledge && noErrors) {
      try {
        setIsLoading(true);
        setDisplayMessageError("");
        const postData = JSON.stringify({ knowledgeData });
        const response = await editKnowledge(existingKnowledge._id, postData)
        if (response.status === 200 || response.status === 201) {
          handleFileUpload();
          setIsLoading(false);
          if (change === true) {
            setChange(false);
          } else {
            setChange(true);
          }
          setClose();
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message ?? "Knowledge updated."));
          dispatch(setToastType("success"));
        }
        return;
      } catch (error) {
        setIsLoading(false);
        setDisplayMessageError(errorMessage ?? "");
        return;
      }
    } else if (isExistingKnowledge) {
      return;
    }

    if (noErrors) {
      try {
        setIsLoading(true);
        setDisplayMessageError("");
        const postData = JSON.stringify({ knowledgeData });
        const response = await upsertKnowledge(postData);
        if (response.status === 200 || response.status === 201) {
          handleFileUpload();
          if (change === true) {
            setChange(false);
          } else {
            setChange(true);
          }
          setSelectedButton("My Shared Knowledge");
          setClose();
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message ?? "Knowledge added."));
          dispatch(setToastType("success"));
        }
      } catch (error) {
        setDisplayMessageError(errorMessage ?? "")
      }
    }
  };

  const handleFileUpload = async () => {
    if (Object.keys(knowledgeFile).length === 0 && showFileName) {
      try {
        let knowledgeFileName = isExistingKnowledge
          ? existingKnowledge.RefFileName
          : addKnowledgeData.Title + ".pdf";
        const formData = new FormData();
        formData.append("KnowledgeFile", knowledgeFile);
        formData.append("FileName", showFileName);

        const response = await upsertKnowledgeFile(knowledgeFileName, formData);
        if (response.status === 200 || response.status === 201) {
          setIsLoading(false);
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message));
          dispatch(setToastType("success"));
        }
      } catch (error) {
        setIsLoading(false);
        setDisplayMessageError("Error uploading knowledge file:", error);
        return;
      }
    } else {
      return;
    }
  };

  const ViewKnowledgeFile = async () => {
    try {
      window.open(
        `${CLOUD_FUNCTIONS_ORIGIN}/user/KnowledgeFiles/${existingKnowledge.RefFileName}`,
        "_blank",
        "noreferrer"
      );
    } catch (error) { }
  };

  useEffect(() => {
    if (isExistingKnowledge) {
      setAddKnowledgeData({
        Title: existingKnowledge?.Title,
        Description: existingKnowledge?.Description,
        RefLink: existingKnowledge?.RefLink,
      });
      fetch(
        `${CLOUD_FUNCTIONS_ORIGIN}/user/KnowledgeFiles/${existingKnowledge.RefFileName}`
      )
        .then((response) =>
          response.ok
            ? setKnowledgeFileAvailable(true)
            : setKnowledgeFileAvailable(false)
        )
        .catch((error) => {
          setKnowledgeFileAvailable(false);
        });
    }
  }, [isExistingKnowledge, existingKnowledge]);

  return (
    <Box>
      {isLoading ? (
        <LoadingBox
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "96vh",
          }}
        >
          <Loader />
        </LoadingBox>
      ) : (
        <MainBox>
          <InnerBoxOne>
            <Box sx={{ padding: "5px 0px 5px 18px" }}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {selectedButton === "Public Knowledge"
                  ? isExistingKnowledge
                    ? "Knowledge Details"
                    : "Add Knowledge"
                  : isExistingKnowledge
                    ? "Edit Knowledge"
                    : "Add Knowledge"}
              </Typography>
            </Box>
            <InnerBoxTwo>
              <IconButton onClick={setClose}>
                <CloseIcon color="primary" />
              </IconButton>
            </InnerBoxTwo>
          </InnerBoxOne>

          <Box sx={{ padding: "8px 4px 8px 10px", marginRight: "8px" }}>
            <MainCard>
              <CardContent>
                <Grid item container spacing={2}>
                  <Grid item sm={12}>
                    <InputTextField
                      fullWidth
                      label="Title"
                      name="Title"
                      value={addKnowledgeData.Title || ""}
                      onChange={handleChange}
                      disabled={
                        selectedButton === "Public Knowledge" &&
                        isExistingKnowledge
                      }
                    />
                    <Typography color="error">{errors.title}</Typography>
                  </Grid>

                  <Grid item sm={12}>
                    <InputTextField
                      fullWidth
                      multiline
                      maxRows={12}
                      label="Description"
                      name="Description"
                      value={addKnowledgeData.Description || ""}
                      onChange={handleChange}
                      disabled={
                        selectedButton === "Public Knowledge" &&
                        isExistingKnowledge
                      }
                    />
                    <Typography color="error">{errors.description}</Typography>
                  </Grid>

                  {selectedButton === "Public Knowledge" &&
                    isExistingKnowledge ? (
                    addKnowledgeData.RefLink === "" ? (
                      <></>
                    ) : (
                      <Grid item sm={12} sx={{ textAlign: "center" }}>
                        <Tooltip
                          arrow
                          TransitionComponent={Zoom}
                          title="Reference Link"
                          placement="right"
                        >
                          <Button
                            size="large"
                            color="warning"
                            startIcon={<LinkIcon />}
                            variant="outlined"
                            href={addKnowledgeData.RefLink}
                            target="_blank"
                          >
                            Link
                          </Button>
                        </Tooltip>
                      </Grid>
                    )
                  ) : (
                    <Grid item sm={12}>
                      <InputTextField
                        fullWidth
                        label="Reference Link"
                        name="RefLink"
                        value={addKnowledgeData.RefLink || ""}
                        onChange={handleChange}
                      />
                    </Grid>
                  )}

                  {selectedButton === "Public Knowledge" &&
                    isExistingKnowledge ? (
                    knowledgeFileAvailable ? (
                      <Grid item sm={12} sx={{ textAlign: "center" }}>
                        <Tooltip
                          title="Reference Document"
                          placement="right"
                          arrow
                        >
                          <IconButton
                            color="success"
                            onClick={ViewKnowledgeFile}
                          >
                            <FilePresentIcon sx={{ fontSize: "40px" }} />
                          </IconButton>
                        </Tooltip>
                        <Typography
                          sx={{ color: "var(--secondary-text-color)" }}
                        >
                          {existingKnowledge.RefFileName}
                        </Typography>
                      </Grid>
                    ) : (
                      <></>
                    )
                  ) : isExistingKnowledge ? (
                    <>
                      {knowledgeFileAvailable ? (
                        <Grid item sm={6} sx={{ textAlign: "center" }}>
                          <Tooltip
                            title="Reference Document"
                            placement="left"
                            arrow
                          >
                            <IconButton
                              color="success"
                              onClick={ViewKnowledgeFile}
                            >
                              <FilePresentIcon sx={{ fontSize: "40px" }} />
                            </IconButton>
                          </Tooltip>
                          <Typography
                            sx={{ color: "var(--secondary-text-color)" }}
                          >
                            {existingKnowledge.RefFileName}
                          </Typography>
                        </Grid>
                      ) : (
                        <></>
                      )}
                      <Grid
                        item
                        sm={knowledgeFileAvailable ? 6 : 12}
                        sx={{ textAlign: "center" }}
                      >
                        <Tooltip
                          title={
                            knowledgeFileAvailable
                              ? "Change Reference Document"
                              : "Upload Reference Document"
                          }
                          placement="right"
                          arrow
                        >
                          <Button
                            color={
                              knowledgeFileAvailable ? "warning" : "primary"
                            }
                            component="label"
                            role={undefined}
                            variant="text"
                            tabIndex={-1}
                          >
                            <UploadFileIcon sx={{ fontSize: "40px" }} />
                            <VisuallyHiddenInput
                              type="file"
                              onChange={handleFileChange}
                              accept="application/pdf"
                            />
                          </Button>
                        </Tooltip>
                        <Typography color="primary">{showFileName}</Typography>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item sm={12} sx={{ textAlign: "center" }}>
                        <Tooltip
                          title="Upload Reference Document"
                          placement="right"
                          arrow
                        >
                          <Button
                            color="primary"
                            component="label"
                            role={undefined}
                            variant="text"
                            tabIndex={-1}
                          >
                            <UploadFileIcon />
                            <VisuallyHiddenInput
                              type="file"
                              onChange={handleFileChange}
                              accept="application/pdf"
                            />
                          </Button>
                        </Tooltip>
                        <Typography color="primary">{showFileName}</Typography>
                      </Grid>
                    </>
                  )}
                </Grid>

                <InnerBoxThree
                  mt={2}
                >
                  <Button
                    onClick={setClose}
                    sx={{ ml: 2, marginRight: "10px" }}
                  >
                    Cancel
                  </Button>

                  {selectedButton === "Public Knowledge" &&
                    isExistingKnowledge ? (
                    <></>
                  ) :
                    <Button
                      variant="contained"
                      color={isExistingKnowledge ? "warning" : "primary"}
                      onClick={handleSubmit}
                    >
                      {isExistingKnowledge ? 'Update Knowledge' : 'Add Knowledge'}
                    </Button>}
                </InnerBoxThree>
                <Box mt={2}>
                  <Typography sx={{ color: "red" }}>
                    {displayMessageError}
                  </Typography>
                </Box>
              </CardContent>
            </MainCard>
          </Box>
        </MainBox>
      )}
    </Box>
  );
}

export default AddKnowledge;
