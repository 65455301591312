import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
	Box,
	Typography,
	IconButton,
	Grid,
	CardContent,
	Button,
	Zoom,
	Tooltip,
	useMediaQuery
} from "@mui/material";
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import dayjs from "dayjs";

import { setCalenderValue } from "../../../Slices/searchData";
import {
	formatDate,
	formatDateSlashTo_,
} from "../../ReusableComponents/UtilityFunctions";
import EmployeeActivity from "./EmployeeActivity";
import EmployeeGraph from "./EmployeeGraph";
import StatComp from "./StatComp";
import EditTimer from "./EditTimer";
import DateRangePickerDialog from "../../EmployeeManagement/Employee/DateRangePickerDialog";
import AttendanceTable from "../../AdminDashboard/Tables/AttendanceTable";
import MonthSlider from "./MonthSlider";
import { getAttendenceWithFilter } from "../../../Services/AttendanceManageServices";
import { MainBox, InnerBoxOne, LeftSideBox, InnerSliderBox, CommanTabBox, InnerBoxFour, RightSideBox, InnerBoxThree, StatisticsButton, AttendanceSheetButton, CalendarTypography, CalendarBox, CustomCard, Last30DayButton, MonthlyButton, MonthBox, SliderBox } from '../../../Styles/UpdateEmployee/UpdateAttendanceStyle'
import '../../../assets/styles/DateCalender.css';

const UpdateAttendance = ({ onBackButtonClick, employeeEmpId, EmpId }) => {
	const dispatch = useDispatch();
	const scrollRef = useRef(null);
	const navigate = useNavigate();
	const { employeeId } = useParams();
	const allEmployeeAttendence = useSelector(
		(state) => state.employeeData.allEmployeeAttendenceData
	);
	const isMobile = useMediaQuery("(max-width:600px)");
	const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
	const selectedEmp = useSelector((state) => state.addEmployee.employeeIndex?.empId);
	const selectedEmpId = selectedEmp || employeeId || EmpId;
	const selectedDate = useSelector((state) => state.searchData.calenderValue);
	const currentDate = formatDate(new Date());

	const [date, setDate] = useState(currentDate);
	const [month, setMonth] = useState(new Date());
	const [empId, setEmpId] = useState(selectedEmpId);
	const [record, setRecord] = useState([]);
	const [employeeAttendanceAllData, setEmployeeAttendanceAllData] = useState([]);
	const [employeeAttendancedata, setEmployeeAttendancedata] = useState([]);
	const [working, setWorking] = useState(false);
	const [selectedButton, setSelectedButton] = useState("Statistics");
	const [employeesNameArray, setEmployeesNameArray] = useState([]);
	const [dateButton, setDateButton] = useState("Last30");
	const [value, setValue] = useState(dayjs(new Date()));
	const [show, setShow] = useState(true);
	const [dateRange, setDateRange] = useState({});
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [exportAttendanceStartDate, setExportAttendanceStartDate] = useState("");
	const [exportAttendanceEndDate, setExportAttendanceEndDate] = useState("");
	const [dateRangeDialog, setDateRangeDialog] = useState(false);
	const [employeeName, setEmployeeName] = useState("");

	function change() {
		setShow(true);
	}

	const handleButtonClick = (buttonName) => {
		setSelectedButton(buttonName);
	};

	const handleDateChange = (newValue) => {
		setValue(newValue);
		const formattedDate = formatDate(newValue.$d);
		dispatch(setCalenderValue(formattedDate));
	};

	const dateButtonHandler = (name) => {
		setDateButton(name);
		if (name === "Last30") {
			last30DaysDate();
		}
	};

	const handleBack = () => {
		if (onBackButtonClick) {
			onBackButtonClick();
		} else {
			navigate(-1);
		}
	};

	const getScrollAmount = () => {
		if (scrollRef.current) {
			return scrollRef.current.offsetWidth * 1.05;
		}
		return 700;
	};

	const scrollLeft = () => {
		if (scrollRef.current) {
			scrollRef.current.scrollBy({
				left: -getScrollAmount(),
				behavior: "smooth",
			});
		}
	};

	const scrollRight = () => {
		if (scrollRef.current) {
			scrollRef.current.scrollBy({
				left: getScrollAmount(),
				behavior: "smooth",
			});
		}
	};

	const last30DaysDate = () => {
		const currentDate = new Date();
		const startDate = new Date(currentDate);
		startDate.setDate(startDate.getDate() - 30);
		const startDateFormatted = dayjs(startDate).format("DD-MM-YYYY");
		const endDateFormatted = dayjs(currentDate).format("DD-MM-YYYY");
		setExportAttendanceStartDate(startDateFormatted);
		setExportAttendanceEndDate(endDateFormatted);
	};

	const selectedMonth = () => {
		const dateObject = new Date(month);
		const currentDate = new Date();

		if (dateObject > currentDate) {
			const startDateFormatted = dayjs(currentDate).startOf('month').format("DD-MM-YYYY");
			const endDateFormatted = dayjs(currentDate).endOf('month').format("DD-MM-YYYY");
			setEmployeesNameArray([])
			setEmployeeAttendancedata([])

			setExportAttendanceStartDate(startDateFormatted);
			setExportAttendanceEndDate(endDateFormatted);
		} else {
			const selectedMonth = dateObject.getMonth();
			const selectedYear = dateObject.getFullYear();

			const startOfMonth = new Date(selectedYear, selectedMonth, 1);
			const endOfMonth = new Date(selectedYear, selectedMonth + 1, 0);

			const startDateFormatted = dayjs(startOfMonth).format("DD-MM-YYYY");
			const endDateFormatted = dayjs(endOfMonth).format("DD-MM-YYYY");

			setExportAttendanceStartDate(startDateFormatted);
			setExportAttendanceEndDate(endDateFormatted);
		}
	};

	const memoizedEmployeeFetchData = useMemo(() => {
		const getEmployeeData = async () => {
			if (exportAttendanceStartDate !== "") {
				try {
					const empId = selectedEmpId;
					const response = await getAttendenceWithFilter(empId, exportAttendanceStartDate, exportAttendanceEndDate);
					if (response.status === 200 || response.status === 201) {
						const data = response.data.attendanceData;
						setEmployeesNameArray(response.data.employeesNameArray);
						const work = response.data.work;
						setEmployeeName(response.data.employeeName);
						setEmployeeAttendanceAllData(data);
						// if (work === "false") {
						// 	setWorking(false);
						// } else {
						// 	setWorking(true);
						// }
						setWorking(work === "false" ? false : true);
						setEmployeeAttendancedata(data);
					}
					else {
						setEmployeesNameArray([])
						setEmployeeName("")
						setEmployeeAttendanceAllData([])
					}
				} catch (error) {
				}
			}
		};
		return getEmployeeData;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [exportAttendanceStartDate, selectedEmpId]);

	useEffect(() => {
		memoizedEmployeeFetchData();
	}, [memoizedEmployeeFetchData]);

	useEffect(() => {
		if (
			exportAttendanceStartDate === "" &&
			exportAttendanceEndDate === ""
		) {
			last30DaysDate();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		selectedMonth();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [month]);

	useEffect(() => {
		if (startDate && endDate) {
			const filteredRecords = employeeAttendanceAllData.filter(
				(record) => {
					const recordDate = dayjs(record.date, "DD-MM-YYYY");
					const start = dayjs(startDate, "DD-MM-YYYY");
					const end = dayjs(endDate, "DD-MM-YYYY");
					return (
						recordDate.isSame(start, "day") ||
						recordDate.isSame(end, "day") ||
						(recordDate.isAfter(start, "day") &&
							recordDate.isBefore(end, "day"))
					);
				}
			);
			setEmployeeAttendancedata(filteredRecords);
		} else {
			const filteredRecords = employeeAttendanceAllData.filter(
				(record) => {
					const recordDate = dayjs(record.date, "DD-MM-YYYY");
					const start = dayjs(startDate, "DD-MM-YYYY");
					const end = dayjs(endDate, "DD-MM-YYYY");
					return (
						recordDate.isSame(start, "day") ||
						recordDate.isSame(end, "day") ||
						(recordDate.isAfter(start, "day") &&
							recordDate.isBefore(end, "day"))
					);
				}
			);
			setEmployeeAttendancedata(filteredRecords);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startDate, endDate]);

	useEffect(() => {
		if (Object.keys(dateRange).length === 0) {
			setStartDate(dayjs().startOf("month").format("DD/MM/YYYY"));
			setEndDate(dayjs().endOf("month").format("DD/MM/YYYY"));
		}
	}, [dateRange]);

	useEffect(() => {
		setDate(selectedDate);
		setEmpId(selectedEmpId);
		const filteredDataByEmployee = allEmployeeAttendence?.filter(
			(item) => item?.empId === empId
		);
		if (filteredDataByEmployee?.length !== 0) {
			const filteredDataBydate =
				filteredDataByEmployee[0]?.records?.filter(
					(item) => item?.date === formatDateSlashTo_(date)
				);
			const adminShowData = filteredDataBydate[0]?.dailytimer?.timer;
			setRecord(adminShowData);
			if (filteredDataBydate?.length === 0) {
				setRecord([]);
			} else {
				setRecord(adminShowData);
			}
		}
	}, [selectedDate, selectedEmpId, date, empId, allEmployeeAttendence]);

	useEffect(() => {
		if (EmpId) {
			setValue(dayjs(new Date()));
		}
	}, [EmpId]);

	useEffect(() => {
		if (
			!(
				Object.keys(dateRange).length === 0 &&
				dateRange.constructor === Object
			)
		) {
			const startDateFormatted = dayjs(dateRange.startDate).format(
				"DD/MM/YYYY"
			);
			const endDateFormatted = dayjs(dateRange.endDate).format(
				"DD/MM/YYYY"
			);
			setStartDate(startDateFormatted);
			setEndDate(endDateFormatted);
		}
	}, [dateRange]);

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<Box
				sx={{
					p: 2,
					"@media (max-width: 600px)": {
						p: 0,
					},
				}}
			>
				<MainBox>
					{show ? (
						<>
							{!EmpId && (
								<>
									<InnerBoxOne ismobile={isMobile ? "true" : "false"}>
										<LeftSideBox>
											<IconButton sx={{ padding: isMobile ? "2px" : "8px" }} onClick={handleBack}>
												<ArrowBackIcon color="primary" />
											</IconButton>
											<Typography variant={isMobile ? "subtitle1" : "h6"} sx={{ fontWeight: "bold" }}>
												Attendance Details (
												{employeeEmpId ? employeeEmpId : employeeId}:{" "}
												{employeeName})
											</Typography>
										</LeftSideBox>
										<RightSideBox>
											<Tooltip
												title="Add New Details"
												arrow
												disableInteractive
												TransitionComponent={Zoom}
											>
												<Button
													sx={{ padding: isMobile ? "3px 8px" : "6px 16px", marginTop: isMobile && "4px" }}
													color="primary"
													variant="contained"
													onClick={() => setShow(false)}
												>
													Update timer
												</Button>
											</Tooltip>
										</RightSideBox>
									</InnerBoxOne>
									<InnerBoxThree>
										{isMobile ? <SliderBox>
											<IconButton onClick={scrollLeft} style={{
												marginRight: 12,
												color: isDarkTheme === "true" ? "#e0e0e0cc" : "#5e6e82",
											}}>
												<ChevronLeftIcon />
											</IconButton>
											<InnerSliderBox
												ref={scrollRef}
											>
												<CommanTabBox>
													<StatisticsButton
														onClick={() => handleButtonClick("Statistics")}
														selectedbutton={selectedButton}
													>
														Statistics
													</StatisticsButton>
												</CommanTabBox>
												<CommanTabBox>
													<AttendanceSheetButton
														onClick={() => handleButtonClick("Attendance Sheet")}
														selectedbutton={selectedButton}
													>
														Attendance Sheet
													</AttendanceSheetButton>
												</CommanTabBox>
											</InnerSliderBox>
											<IconButton onClick={scrollRight} style={{
												marginLeft: 12,
												color: isDarkTheme === "true" ? "#e0e0e0cc" : "#5e6e82",
											}}>
												<ChevronRightIcon />
											</IconButton>
										</SliderBox>
											:
											(<>
												<StatisticsButton
													onClick={() => handleButtonClick("Statistics")}
													selectedbutton={selectedButton}
												>
													Statistics
												</StatisticsButton>
												<AttendanceSheetButton
													onClick={() => handleButtonClick("Attendance Sheet")}
													selectedbutton={selectedButton}
												>
													Attendance Sheet
												</AttendanceSheetButton>
											</>)
										}
									</InnerBoxThree>
								</>
							)}
							<Box sx={{ mt: 2 }}>
								<Grid container spacing={2}>
									{selectedButton === "Statistics" && (
										<>
											<Grid item xs={12} md={8}>
												<Grid container spacing={2}>
													<Grid item xs={12} md={6}>
														<CustomCard>
															<CardContent>
																<CalendarTypography>
																	Calendar
																</CalendarTypography>
																<CalendarBox>
																	<DateCalendar
																		value={value}
																		onChange={handleDateChange}
																	/>
																</CalendarBox>
															</CardContent>
														</CustomCard>
													</Grid>
													<Grid item xs={12} md={6}>
														<CustomCard>
															<CardContent>
																<EmployeeGraph EmpId={EmpId} />
															</CardContent>
														</CustomCard>
													</Grid>
													<Grid item xs={12} sx={{ pt: 2 }}>
														<CustomCard>
															<CardContent>
																<StatComp EmpId={EmpId} />
															</CardContent>
														</CustomCard>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12} md={4}>
												<CustomCard>
													<CardContent>
														<EmployeeActivity
															EmpId={EmpId}
															employeesNameArray={employeesNameArray}
														/>
													</CardContent>
												</CustomCard>
											</Grid>
										</>
									)}
									{selectedButton === "Attendance Sheet" && (
										<Grid item xs={12}>
											<CustomCard>
												<InnerBoxFour>
													<Box sx={{ p: 1 }}>
														<Last30DayButton
															variant="contained"
															onClick={() => dateButtonHandler("Last30")}
															datebutton={dateButton}
														>
															Last 30 Days
														</Last30DayButton>
														<MonthlyButton
															variant="contained"
															onClick={() => dateButtonHandler("Monthly")}
															datebutton={dateButton}
														>
															Monthly
														</MonthlyButton>
													</Box>
												</InnerBoxFour>
												{dateButton === "Monthly" && (
													<MonthBox>
														<MonthSlider setMonth={setMonth} month={month} />
													</MonthBox>
												)}
												<Box sx={{ mx: 1, mb: 2 }}>
													<AttendanceTable
														allAttendenceDataEmployee={employeeAttendancedata}
														working={working}
														employeeLogin={false}
														adminLogin={true}
														startDate={exportAttendanceStartDate}
														endDate={exportAttendanceEndDate}
														employeeName={employeeName}
														attendanceManagement={false}
													/>
												</Box>
											</CustomCard>
										</Grid>
									)}
								</Grid>
							</Box>
						</>
					) : (
						<EditTimer
							change={change}
							record={record}
							empId={empId}
							presentDate={selectedDate}
						/>
					)}
				</MainBox>
				<DateRangePickerDialog
					open={dateRangeDialog}
					setOpenDialog={setDateRangeDialog}
					dialogHeading={"Date Range"}
					setDateRange={setDateRange}
				/>
			</Box>
		</LocalizationProvider>
	);
};

export default UpdateAttendance;
