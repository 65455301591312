export const InputLable = {
  typography: "subtitle2",
  fontWeight: "bold",
  padding: "20px 0 0 0",
  color: "var(--primary-color)",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
};

export const InputField = {
  width: "100%",
  borderRadius: "5px",
  marginBottom: "20px",
};

export const InputFieldChangePassword = {
  width: "100%",
  borderRadius: "5px",
};

export const visibilityIconStyle = {
  ".MuiSvgIcon-root": {
    color: "var(--secondary-text-color)",
  }
};

export const InputFieldProps = (isDarkMode) => {
  return {
    fontSize: "14px",
    caretColor: isDarkMode ? "#b6babf" : "#000",
    "&:-webkit-autofill": {
      WebkitBoxShadow: `0 0 0 1000px ${
        isDarkMode ? "var(--darkTheme-before-login-sideblock-bg)" : "var(--lightTheme-before-login-bg)"
      } inset`,
      WebkitTextFillColor: isDarkMode ? "var(--darkTheme-inputfield-color)" : "var(--lightTheme-inputfield-color)",
      transition: "background-color 5000s ease-in-out 0s",
    },
  };
};

export const errorMessageDesign = {
  color: "red",
  fontSize: "12px",
};

export const DashboardProfileButtons = {
  marginTop: "10px",
  textTransform: "capitalize",
  display: "flex",
  alignItems: "left",
  justifyContent: "left",
  padding: "15px 20px",
  width: "100%",
  border: "none",
  borderRadius: "5px",
  "&:hover": {
    background: "var(--primary-highlight-color)",
    color: "var(--primary-color)",
    fontWeight: "bold",
  },
};

export const viewProfileTitle = {
  fontWeight: "bold",
  fontSize: "18px",
  "@media (max-width: 600px)": {
    fontSize: "16px",
    marginTop: "25px"
},
};

export const viewProfileSubtitle = {
  fontSize: "16px",
  color: "var(--secondary-text-color)",
};

export const viewEducationBox = {
  height: "auto",
  maxWidth: "100%",
  backgroundColor: "var(--primary-highlight-color)",
  borderRadius: "5px",
  padding: "15px",
  marginBottom: "10px",
};

export const viewEducationTitle = {
  fontWeight: "bold",
  fontSize: "16px",
  color: "var(--text-gray-color)",
};

export const viewLeaveDetail = {
  fontWeight: "bold",
  fontSize: "16px",
  color: "var(--secondary-text-color)",
  marginBottom: "10px",
};
export const tablePagination = {
  fontWeight: "bold",
  fontSize: "16px",
  color: "var(--secondary-text-color)",
  marginBottom: "10px",
  justifyItems: "flex-end",
  svg: {
    fill: "var(--secondary-text-color)",
  },
};

export const viewExperiencePosition = {
  fontWeight: "bold",
  fontSize: "13px",
};

// FOR TABLES
export const tableHeadCell = {
  color: "var(--primary-text-color)",
  fontSize: "18px",
  fontWeight: "8px",
  textAlign: 'left',
  paddingTop: "8px ",
  paddingBottom: "8px ",
  letterSpacing: "1px",
};

export const tableHeadCellNew = {
  color: "var(--primary-text-color)",
  fontSize: "18px",
  fontWeight: "8px",
  textAlign: 'center',
  paddingTop: "8px ",
  paddingBottom: "8px ",
  letterSpacing: "1px",
};

// FOR EMP TASK TABLES
export const empTaskTableHeadCell = {
  color: "var(--primary-text-color)",
  fontSize: "16px",
  fontWeight: "bold",
  paddingTop: "8px ",
  paddingBottom: "8px ",
  letterSpacing: "1px",
};

export const tableBodyCell = {
  color: "var(--primary-text-color)",
  fontSize: "15px",
  textAlign: 'left',
};
export const tableBodyCellNew = {
  color: "var(--primary-text-color)",
  fontSize: "15px",
  textAlign: 'center',
};

export const empTaskTableBodyCell = {
  color: "var(--primary-text-color)",
  overflow: 'hidden',
  maxWidth: '50px',
  textOverflow: "ellipsis",
  paddingTop: "8px ",
  paddingBottom: "8px ",
  fontSize: "15px",
};

export const tableMainHead = {
  fontWeight: "bold",
  color: "var(--primary-text-color)",
};

export const progessBarBox = {
  color: "var(--secondary-text-color)",
  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14)",
  borderRadius: "5px",
  padding: "10px",
  marginTop: "15px",
};

export const progessBarTitleBox = {
  display: "flex",
  justifyContent: "space-between",
  alignContent: "center",
};

export const progessBarTitle = {
  fontSize: "13px",
  fontWeight: "bold",
};

export const ActivityTitle = {
  fontSize: "14px",
  fontWeight: "bold",
  color: "var(--secondary-text-color)",
};

export const UpdateStatCompBox = {
  marginTop: "15px",
  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14)",
  borderRadius: "5px",
  padding: "7.5px",
  width: "100%",
  color: "var(--secondary-text-color)",
  display: "flex",
  alignItems: "center",
};

export const sucessButton = {
  marginLeft: "0px 10px",
  backgroundColor: "var(--secondary-color)",
  color: '#fff',
  "&:hover": { color: "none", backgroundColor: "var(--secondary-color-H)", }
}

export const commonStyle = {
  "& fieldset legend > span": {
    paddingLeft: "2px",
    paddingRight: "0px",
  },
}