import { Box, TextField, Typography, styled } from "@mui/material"
import { Circle as CircleIcon, Send as SendIcon } from "@mui/icons-material";

export const ActivityBox = styled(Box)(() => ({
    color: "var(--primary-color)",
    borderBottom: "2px solid var(--table-border-color)",
    boxShadow: "0px 13px 10px -20px #111",
    paddingRight: "10px",
    paddingLeft: "10px",
}))

export const MainBox = styled(Box)(() => ({
    paddingTop: "5px",
    height: "63vh",
    maxHeight: "63vh",
    width: "100%",
}))

export const InnerBoxOne = styled(Box)(() => ({
    height: "100%",
    overflowY: "auto",
    paddingLeft: "10px",
}))

export const TimeStampTypography = styled(Typography)(({ isdarktheme }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    textAlign: "center",
}))

export const InnerBoxTwo = styled(Box)(() => ({
    display: "inline-flex",
    margin: "3px 0px 3px 0px",
}))

export const CircleIcons = styled(CircleIcon)(({ isdarktheme }) => ({
    fontSize: "10px",
    marginTop: "7.5px",
    paddingRight: "4px",
    fill: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
}))

export const InnerBoxThree = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
}))

export const InnerBoxFour = styled(Box)(({ isdarktheme }) => ({
    width: "84%",
    paddingBottom: "4px",
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
}))

export const TimestampTypography = styled(Typography)(() => ({
    paddingRight: "4px",
    textAlign: "right",
    fontSize: "12px",
    color: "#A0A0A0",
    fontWeight: "bold",
}))

export const NoActivityTypography = styled(Typography)(({ isdarktheme }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    padding: "5px",
}))

export const NoActivityCircleIcons = styled(CircleIcon)(({ isdarktheme }) => ({
    fontSize: "12px",
    marginTop: "9px",
    paddingRight: "6px",
    fill: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
}))

export const CommentMainBox = styled(Box)(() => ({
    bottom: 0,
    marginTop: "5px",
    paddingLeft: "10px",
    paddingRight: "10px",
}))

export const CommentTextField = styled(TextField)(({ isdarktheme }) => ({
    ".MuiOutlinedInput-input":
    {
        color: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82 !important",
    },
    ".MuiOutlinedInput-root":
    {
        padding: "2px 14px",
    },
}))

export const SendIcons = styled(SendIcon)(({ isdarktheme }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#484848",
    marginTop: "0px",
    position: "relative",
    paddingRight: "initial",
}))
