import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";

import SideBar from "../ReusableComponents/SideBarComponents/SideBar";
import CustomToast from "../ReusableComponents/CustomToast";
import Header from "../ReusableComponents/Header";
import { Footer } from "../ReusableComponents/Footer";
import Loader from "../Loader/Loader";
import TimerDialogBox from "../EmployeeManagement/Employee/TimerDialogBox";
import Notification from "../../components/Notification/Notification"
import TicketDialog from "../EmployeeManagement/Employee/TicketDialog";
import {
  setAllData,
  setAllEmployeeAttendenceData,
  setProjectsName,
} from "../../Slices/UserDataSlice";
import { setAdminData, setRoles } from "../../Slices/adminDetailSlice";
import { setAllLeaveData, setLeavesTypes } from "../../Slices/leaveData";
import {
  setCurrentEmployee,
  setSetAttendenceRecord,
  setemployeeAllData,
} from "../../Slices/employeeDetailSlice";
import { setEmployeeName, setGetNotificationCount, setGetRoleByPermission, setGetTaskTypeList, setRole } from "../../Slices/selectedActive";
import { setEmployeesNames } from "../../Slices/taskSlice";
import { setToast, setToastMsg, setToastType } from "../../Slices/toastSlice";
import { setDisableStart, setElapsedTimes, setIsDefault, setIsPlaying, setIsRunning, setIsStart, setTaskList } from "../../Slices/timerDetailsSlice";
import {
  getDataEmployee,
  getEmployeesData,
  getRoles,
} from "../../Services/DashboardServices";
import { getTicketData, upserTimerStart } from "../../Services/AttendanceManageServices";
import { LoadingBox } from "../../Styles/AdminDashboard/DashBoardStyle"
import { MainBox, InnerBoxOne, ChildrenBox, FooterBox } from "../../Styles/AdminDashboard/DashBoardStyle"
import "./Dashboard.scss";

function DashBoard({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.selectedActiveView.role);
  const editSettings = useSelector((state) => state.selectedActiveView.editSettings);
  const showDialogBox = useSelector((state) => state.timerData.showDialogBox);
  const employeeEmail = useSelector((state) => state.addEmployee.current?.email);
  const adminEmail = useSelector((state) => state.adminData.adminData?.email);
  const showToast = useSelector((state) => state.toast.on);
  const showToastMsg = useSelector((state) => state.toast.msg);
  const showToastType = useSelector((state) => state.toast.type);
  const adminId = useSelector((state) => state.adminData.adminData.empId);
  const employeeDetail = useSelector((state) => state.addEmployee.current);
  const taskList = useSelector((state) => state.timerData.taskList);
  const showTicketDialogBox = useSelector((state) => state.timerData.showTicketDialogBox);
  const isPlaying = useSelector((state) => state.timerData.isPlaying);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const employeeDisplay = user === "employee";
  const adminLogin = user === "admin";
  const token = window.localStorage.getItem("Token");

  const [employeeId, setEmployeeId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [displayError, setDisplayError] = useState("");
  const [isOpen, setIsOpen] = useState(isMobile ? false : true);

  const handleSidebarShow = () => {
    setIsOpen(!isOpen);
  }

  function getEmail() {
    if (employeeDisplay) {
      return employeeEmail;
    } else if (adminLogin) {
      return adminEmail;
    }
  }

  function getUser() {
    if (employeeDisplay) {
      return "user";
    } else if (adminLogin) {
      return "admin";
    }
  }

  function extractTime(timestamp) {
    const dateTime = new Date(timestamp);
    const time = dateTime.toLocaleTimeString([], {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    return time;
  }

  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const handleStart = async (selectedValue) => {
    navigator.geolocation.getCurrentPosition(async (position) => {
      try {
        dispatch(setIsPlaying(true));
        dispatch(setDisableStart(true));
        let isFirstStart = false;
        if (window.localStorage.getItem("punchIn")) {
          isFirstStart = false;
        } else {
          isFirstStart = true;
        }
        dispatch(setSetAttendenceRecord(true));
        const presentDay = new Date();
        window.localStorage.setItem("presentDay", formatDate(presentDay));
        const currentTime = new Date().getTime();
        window.localStorage.setItem("punchIn", extractTime(currentTime));
        if (isFirstStart) {
          const initialElapsedTime = Math.floor(
            (new Date().getTime() - currentTime) / 1000
          );
          dispatch(setElapsedTimes(initialElapsedTime));
        }
        const currentDate = new Date();
        const date = formatDate(currentDate);
        const time = currentTime;
        const email = getEmail();
        const user = getUser();
        const currentWorkingTicketNo = selectedValue;
        const postData = JSON.stringify({
          email, time, date, currentWorkingTicketNo, CurrentLocation: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          },
        })
        const response = await upserTimerStart(user, postData)
        if (response.status === 200 || response.status === 201) {
          dispatch(setDisableStart(false));
          localStorage.setItem("start", true);
          dispatch(setIsStart(false));
          dispatch(setIsDefault(true));
          dispatch(setIsRunning(true));
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message ?? "Timer started."));
          dispatch(setToastType("success"));
          window.localStorage.setItem("TaskNo", selectedValue);
          const thatOneTask = taskList?.find(
            (state) => state?.ticketNo === currentWorkingTicketNo
          );
          window.localStorage.setItem("TaskDescription", thatOneTask?.title);
        }
      } catch (error) {
        dispatch(setDisableStart(false));
      }
    });
  };

  const memoizedgetData = useMemo(() => {
    const getData = async () => {
      if (adminId === undefined || adminId === null) {
        setIsLoading(true);
        try {
          const response = await getDataEmployee();
          if (response.status === 200) {
            const leaveData = response.data.leave;
            const alluserData = response.data.User;
            const empIdsWithName = response.data.empIdsWithName;
            const allAttendenceData = response.data.employeeAttendenceData;
            const projects = response.data.projects;
            const roles = response.data.roles;
            const data = response.data.leavesTypeArray;
            const adminDetails = response.data.adminDetails;
            setIsLoading(false);
            if (response?.data?.ticketNo !== null) {
              window.localStorage.setItem("TaskNo", response.data.ticketNo);
              window.localStorage.setItem(
                "TaskDescription",
                response.data.taskDescription
              );
            }
            dispatch(setAdminData(adminDetails));
            dispatch(setLeavesTypes(data));
            dispatch(setRoles(roles));
            dispatch(setAllData(alluserData));
            dispatch(setAllLeaveData(leaveData));
            dispatch(setProjectsName(projects));
            dispatch(setAllEmployeeAttendenceData(allAttendenceData));
            dispatch(setEmployeesNames(empIdsWithName));
          }
        } catch (error) {
          setIsLoading(false);
        }
      }
    };
    return getData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, dispatch]);

  const memoizedgetRole = useMemo(() => {
    const getRole = async () => {
      setIsLoading(true);
      try {
        const response = await getRoles();
        if (response.status === 200) {
          setIsLoading(false);
          const employeePersonal = response.data.checkToken;
          const getRoleByPermission = response?.data?.getRoleByPermission
          dispatch(setGetRoleByPermission(getRoleByPermission));
          dispatch(setGetTaskTypeList(response?.data?.taskTypeList));
          dispatch(setEmployeeName(employeePersonal?.firstName))
          dispatch(setGetNotificationCount(response?.data?.notificationCount))
          setEmployeeId(employeePersonal.empId);
          dispatch(setRole(response.data.role));
          dispatch(setCurrentEmployee(response.data.employeePersonal));
          dispatch(setemployeeAllData(response.data.employee));
        } else if (response.status === 201) {
          setIsLoading(false);
          const getRoleByPermission = response?.data?.getRoleByPermission
          dispatch(setEmployeeName(response?.data?.admin?.firstName))
          dispatch(setGetRoleByPermission(getRoleByPermission));
          dispatch(setGetTaskTypeList(response?.data?.taskTypeList));
          dispatch(setGetNotificationCount(response?.data?.notificationCount))
          dispatch(setAdminData(response.data.admin));
          dispatch(setRole(response.data.role));
        } else {
          setEmployeeId("");
        }
      } catch (error) {
        setIsLoading(false);
      }
    };
    return getRole;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, dispatch, token]);

  const memoizedgetEmployeeData = useMemo(() => {
    const getEmployeeData = async () => {
      setDisplayError("");
      if (employeeDetail?.empId === undefined) {
        setIsLoading(true);
        try {
          const response = await getEmployeesData();
          if (response.status === 200) {
            setIsLoading(false);
            if (response.data.task !== null) {
              window.localStorage.setItem("TaskNo", response.data.task);
              window.localStorage.setItem(
                "TaskDescription",
                response.data.taskDescription
              );
            }
            dispatch(setCurrentEmployee(response.data.employeesPersonal[0]));
            dispatch(setLeavesTypes(response.data.leavesTypeArray));
            dispatch(setRoles(response.data.roles));
            dispatch(setAllData(response.data.User));
            dispatch(setemployeeAllData(response.data.user));
            // dispatch(setemployeeAllLeaveData(response.data.leave));
            dispatch(setProjectsName(response.data.projects));
            dispatch(setRole(response.data.role));
            dispatch(setEmployeesNames(response.data.namesData));
          }
        } catch (error) {
          setIsLoading(false);
        }
      }
    };
    return getEmployeeData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, dispatch, token, employeeId]);

  const memoizedgetTask = useMemo(() => {
    const getTasks = async () => {
      try {
        const response = await getTicketData()
        if (response.status === 200 || response.status === 201) {
          dispatch(setTaskList(
            response.data.task.filter(
              (obj) => obj.status !== "Completed" && obj.status !== "Cancelled"
            )
          ));
        }
        else {
          dispatch(setTaskList([]))
        }
      } catch (error) {
      }
    };
    return getTasks;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (adminLogin) {
      memoizedgetData();
    } else if (employeeDisplay) {
      memoizedgetEmployeeData();
    } else {
      memoizedgetRole();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    adminLogin,
    memoizedgetRole,
    employeeDisplay,
    memoizedgetEmployeeData
  ]);

  useEffect(() => {
    if (isPlaying) {
      memoizedgetTask();
    }
  }, [memoizedgetTask, isPlaying])

  useEffect(() => {
    if (isMobile) {
      setIsOpen(false)
    }
    else {
      setIsOpen(true)
    }
  }, [isMobile])

  useEffect(() => {
    if (editSettings) {
      memoizedgetRole();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editSettings])

  return (
    <Box>
      {isLoading ? (
        <LoadingBox>
          <Loader />
        </LoadingBox>
      ) : (
        <Box>
          <Grid container item sx={{ height: "100vh", overflow: "hidden" }}>
            <SideBar isOpen={isOpen} handleSidebarShow={handleSidebarShow} setIsOpen={setIsOpen} />
            <Grid item xs={12} md={isOpen ? 9.5 : 11.5}>
              <MainBox>
                <Header handleStart={handleStart} handleSidebarShow={handleSidebarShow} isOpen={isOpen} setIsOpen={setIsOpen} />
                <InnerBoxOne>
                  <ChildrenBox>
                    {children}
                  </ChildrenBox>
                  <Typography sx={{ color: "red" }}>{displayError}</Typography>
                  <FooterBox>
                    <Footer />
                  </FooterBox>
                </InnerBoxOne>
              </MainBox>
            </Grid>
          </Grid>
          <Notification />
          {showToast ? (
            <CustomToast toastType={showToastType} message={showToastMsg} />
          ) : null}
          {showDialogBox ? <TimerDialogBox
            open={showDialogBox}
          /> : null}
          {showTicketDialogBox ? <TicketDialog
            taskList={taskList}
            open={showTicketDialogBox}
            handleStart={handleStart}
          /> : null}
        </Box>
      )}
    </Box>
  );
}

export default DashBoard;