import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  InputAdornment,
  Typography,
} from "@mui/material";

import {
  setToast,
  setToastMsg,
  setToastType,
} from "../../../Slices/toastSlice";
import {
  viewEducationBox,
  viewProfileTitle,
} from "../../ReusableComponents/CustomDesignMUI/CustomMUI";
import { setAllData } from "../../../Slices/UserDataSlice";
import { setAllLeaveData } from "../../../Slices/leaveData";
import {
  resetEmployeeDetail,
  setEmployeeAdded,
  set_experience,
} from "../../../Slices/employeeDetailSlice";
import ExitDialog from "../ExitDialog";
import { renderAsterisk } from "../../ReusableComponents/UtilityFunctions.js";
import { errorMessage } from "../../../Services/axiosInstance";
import { upsertExperience } from "../../../Services/EmployeeManageServices";
import { MainBox, TitleBox, AddRecordBox, AddRecordButton, InputTextField } from "../../../Styles/UpdateEmployee/UpdateExperienceStyle";
import { FooterBox } from "../../../Styles/UpdateEmployee/UpdatePersonalStyle";
import { CancelButton, SaveButton, SaveNextButton } from "../../../Styles/UpdateEmployee/UpdateContactStyle.js";

const DisplayExperience = (props) => {
  const { saveNextButtonCallback, previousButtonCallback } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const employee_id = useSelector((state) => state.addEmployee.personal.id);
  const experience = useSelector((state) => state.addEmployee.experience);

  const [openExitDialog, setOpenExitDialog] = useState(false);
  const [displayError, setDisplayError] = useState("");
  const [displaySuccess, setDisplaySuccess] = useState("");
  const [experienceDetailArray, setExperienceDetailArray] = useState(
    experience ? experience : []
  );
  const [experienceDetailErrorsArray, setExperienceDetailErrorsArray] =
    useState([]);

  const reset = () => {
    nullRecord();
  };

  const nullRecord = () => {
    setExperienceDetailArray(experience || []);
    setExperienceDetailErrorsArray([]);
  };

  const handleInputChange1 = (index, event) => {
    const { name, value } = event.target;
    setExperienceDetailArray((prevArray) =>
      prevArray?.map((record, i) =>
        i === index ? { ...record, [name]: value } : record
      )
    );
  };

  const removeRecord = (index) => {
    setExperienceDetailArray((prevArray) =>
      prevArray.filter((_, i) => i !== index)
    );
    setExperienceDetailErrorsArray((prevErrorsArray) =>
      prevErrorsArray.filter((_, i) => i !== index)
    );
  };

  const handleCloseCancelDialog = async () => {
    setOpenExitDialog(false);
  };

  const getEmptyExperienceRecord = () => ({
    Company_Name: "",
    Position: "",
    Start_Date: "",
    End_Date: "",
  });

  const getEmptyExperienceErrors = () => ({
    Company_Name: "",
    Position: "",
    Start_Date: "",
    End_Date: "",
  });

  const addRecord = () => {
    if (validateAllForms()) {
      setExperienceDetailArray((prevArray) => [
        ...prevArray,
        getEmptyExperienceRecord(),
      ]);
      setExperienceDetailErrorsArray((prevErrorsArray) => [
        ...prevErrorsArray,
        getEmptyExperienceErrors(),
      ]);
    }
  };

  const validateAllForms = () => {
    const allErrors = experienceDetailArray?.map((record, index) =>
      validateForm(index)
    );
    setExperienceDetailErrorsArray(allErrors);
    return allErrors.every((errors) =>
      Object.values(errors).every((error) => !error)
    );
  };

  const validateForm = (index) => {
    const record = experienceDetailArray[index];
    const errors = getEmptyExperienceErrors();

    if (!record.Company_Name) {
      errors.Company_Name = "Company name is required";
    } else if (!/^[a-zA-Z\s]*$/.test(record.Company_Name)) {
      errors.Company_Name = "Company name should not contain numeric values";
    }

    if (!record.Position) {
      errors.Position = "Position is required";
    }

    if (!record.Start_Date) {
      errors.Start_Date = "Start date is required";
    }
    if (!record.End_Date) {
      errors.End_Date = "End date is required";
    } else if (record.Start_Date > record.End_Date) {
      errors.End_Date = "Invalid date.";
    }
    return errors;
  };

  const save = async () => {
    setDisplayError("");
    if (displaySuccess === "employee added.") {
    } else {
      try {
        const postData = JSON.stringify({ employee_id, experienceDetailArray });
        const response = await upsertExperience(postData)
        if (response.status === 200) {
          dispatch(setAllData(response.data.user));
          setDisplaySuccess("")
          navigate("/dashboard");
          dispatch(resetEmployeeDetail());
          dispatch(setAllLeaveData(response.data.leave));
          dispatch(set_experience(experienceDetailArray));
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message ?? "Employee data updated successfully."));
          dispatch(setToastType("success"));
        }
      } catch (error) {
        setDisplayError(errorMessage ?? "")
      }
    }
  };

  const saveAndNext = async () => {
    setDisplayError("");
    if (displaySuccess === "employee added.") {
      saveNextButtonCallback();
    } else {
      try {
        const postData = JSON.stringify({ employee_id, experienceDetailArray });
        const response = await upsertExperience(postData)
        if (response.status === 200) {
          dispatch(setAllData(response.data.user));
          dispatch(setAllLeaveData(response.data.leave));
          dispatch(set_experience(experienceDetailArray));
          saveNextButtonCallback();
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message ?? "Employee data updated successfully."));
          dispatch(setToastType("success"));
        }
      } catch (error) {
        setDisplayError(errorMessage ?? "")
      }
    }
  };

  return (
    <MainBox>
      <TitleBox>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Experience Details
          </Typography>
        </Box>
      </TitleBox>
      <hr />
      <AddRecordBox>
        <Typography sx={{ ...viewProfileTitle }}>Experience Records</Typography>
        <AddRecordButton
          variant="outlined"
          onClick={() => addRecord()}
        >
          + Add Record
        </AddRecordButton>
      </AddRecordBox>
      <Box>
        {experienceDetailArray?.map((record, index) => (
          <Box key={index} sx={viewEducationBox}>
            <Box sx={{ marginBottom: "15px" }}>
              <InputTextField
                name="Company_Name"
                value={experienceDetailArray[index].Company_Name}
                onChange={(event) => handleInputChange1(index, event)}
                label={
                  <>
                    Company Name
                    {renderAsterisk()}
                  </>
                }
              />
              {experienceDetailErrorsArray[index]?.Company_Name && (
                <Typography color="error">
                  {experienceDetailErrorsArray[index].Company_Name}
                </Typography>
              )}
              <InputTextField
                name="Position"
                value={experienceDetailArray[index].Position}
                onChange={(event) => handleInputChange1(index, event)}
                label={
                  <>
                   Position
                    {renderAsterisk()}
                  </>
                }
              />
              {experienceDetailErrorsArray[index]?.Position && (
                <Typography color="error">
                  {experienceDetailErrorsArray[index].Position}
                </Typography>
              )}
              <InputTextField
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                }}
                isdarktheme={isDarkTheme ? "true" : "false"}
                name="Start_Date"
                value={experienceDetailArray[index].Start_Date}
                type="date"
                onChange={(event) => handleInputChange1(index, event)}
                label={
                  <>
                   Start Date
                    {renderAsterisk()}
                  </>
                }
              />
              {experienceDetailErrorsArray[index]?.Start_Date && (
                <Typography color="error">
                  {experienceDetailErrorsArray[index].Start_Date}
                </Typography>
              )}
              <InputTextField
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                }}
                isdarktheme={isDarkTheme ? "true" : "false"}
                name="End_Date"
                value={experienceDetailArray[index].End_Date}
                type="date"
                onChange={(event) => handleInputChange1(index, event)}
                label={
                  <>
                   End Date
                    {renderAsterisk()}
                  </>
                }
              />
              {experienceDetailErrorsArray[index]?.End_Date && (
                <Typography color="error">
                  {experienceDetailErrorsArray[index].End_Date}
                </Typography>
              )}
            </Box>
            <Button
              variant="outlined"
              color="error"
              sx={{ fontWeight: "bold" }}
              onClick={() => removeRecord(index)}
            >
              Remove
            </Button>
          </Box>
        ))}
      </Box>
      <Typography sx={{ color: "red", marginTop: "20px" }}>
        {displayError}
      </Typography>
      <Typography sx={{ color: "green", marginTop: "20px" }}>
        {displaySuccess}
      </Typography>
      <Box>
        <FooterBox>
          <Button
            sx={{
              fontWeight: "bold",
              color: "gray",
            }}
            onClick={() => {
              previousButtonCallback();
            }}
          >
            Back
          </Button>
          <CancelButton
            onClick={() => {
              setOpenExitDialog(true);
              dispatch(setEmployeeAdded(""));
            }}
          >
            Cancel
          </CancelButton>
          <ExitDialog
            open={openExitDialog}
            reset={reset}
            onClose={handleCloseCancelDialog}
          />
          <SaveButton
            onClick={() => {
              if (validateAllForms()) {
                save();
                dispatch(setEmployeeAdded(""));
              }
            }}
          >
            Save
          </SaveButton>
          <SaveNextButton
            variant="contained"
            onClick={() => {
              if (validateAllForms()) {
                saveAndNext();
              }
            }}
          >
            Save & Next
          </SaveNextButton>
        </FooterBox>
      </Box>
    </MainBox>
  );
};

export default DisplayExperience;
