import { Badge, Box, Typography, styled } from "@mui/material";
import { DarkMode as DarkModeIcon, Logout as LogoutIcon } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";

export const LoadingBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "90vh",
}));


export const Badges = styled(Badge)(() => ({
  '& .MuiBadge-dot': {
    backgroundColor: '#c71b1b'
  },
  '.MuiBadge-badge': {
    backgroundColor: '#c71b1b',
    color: 'white',
    right: 3,
    top: 3,
    border: '2px solid white',
    height: "75%",
    "@media (max-width: 600px)": {
      top: 0,
    }
  },
}));

export const MainBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));


export const ShowTimerBox = styled(Box)(({ ismobile }) => ({
  fontSize: ismobile === "true" ? "13px" : "1.15em",
  fontFamily: "Jost, sans-serif",
  color: "var(--secondary-text-color)",
  fontWeight: "500",
}));


export const PunchInPunchOutBox = styled(Box)(({ ismobile }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: ismobile === "true" ? "15px" : "25px",
  width: ismobile === "true" ? "15px" : "25px",
  border: "1px solid #ccc",
  borderRadius: "50%"
}));

export const TimerBox = styled(Box)(({ ismobile }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: ismobile === "true" ? "6px" : "6px",
  paddingLeft: ismobile === "true" ? "8px" : "11px",
  marginRight: "3px",
  marginBottom: !ismobile && "3px",
  border: "1px solid #ccc",
  backgroundColor: "var(--primary-highlight-color)",
  borderRadius: "30px",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)"
}));

export const ContentRightBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: " var(--secondary-text-color)",
  borderRadius: "50%",
  width: "30px",
  height: "30px",
  padding: "0",
  marginTop: "15px",
  cursor: "pointer",
}))

export const ContentLeftBox = styled(Box)(({ isdarktheme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: isdarktheme === "true" ? "#1a283b" : "white",
  borderRadius: "50%",
  width: "30px",
  height: "30px",
  padding: "0",
  right: 0,
  position: "fixed",
  left: "19.8%",
  cursor: "pointer",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)"
}))

export const ProfileBox = styled(Box)(() => ({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "5px",
  bottom: "2%",
}))

export const InnerBoxOne = styled(Box)(() => ({
  display: " flex",
  flexDirection: " column",
  justifyContent: " space-between",
  height: " calc(100vh - 69px)",
  overflow: "auto",
}));

export const ChildrenBox = styled(Box)(() => ({
  margin: "25px 25px",
}));

export const FooterBox = styled(Box)(() => ({
  boxShadow: "0 13px 10px 6px #111",
  borderTop: "1px solid var(--table-border-color)",
  padding: "5px",
  "@media (max-width: 600px)": {
    padding: "10px",
    marginBottom: "15px"
  },
}));

export const FooterMainBox = styled(Box)(() => ({
  display: " flex",
  textAlign: " center",
  alignItems: " center",
  justifyContent: " center",
  flexDirection: " column",
  marginLeft: " 5%",
}));

export const PrivacyPolicyBox = styled(Box)(() => ({
  "& a": {
    color: "rgb(44, 123, 229) !important",
    textDecoration: "underline !important",
  },
  "& a:visited": {
    color: "rgb(44, 123, 229) !important",
  },
  marginBottom: "0.5%",
}));

export const HeaderMainBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "3px 20px 0px 24px",
  borderBottom: "1px solid var(--table-border-color)",
  "@media (max-width: 600px)": {
    padding: "3px 27px 0px 25px",
  }
}));

export const TextTypography = styled(Typography)(() => ({
  fontSize: "14px",
  paddingLeft: "1.5px",
  color: "var(--secondary-color)",
  display: "none",
}));

export const PipeTypography = styled(Typography)((isDarkTheme) => ({
  display: "flex",
  alignItems: "center",
  margin: "0 5px",
  color:
    isDarkTheme === "true"
      ? "var(--secondary-text-color)"
      : "var(--primary-text-color)",
}));

export const SpaceTypography = styled(Typography)((isDarkTheme) => ({
  display: "flex",
  alignItems: "center",
  letterSpacing: "3px",
  fontSize: "1.2em",
  marginLeft: "2%",
  fontWeight: "bold",
  textAlign: "center",
  color:
    isDarkTheme === "true"
      ? "var(--secondary-text-color)"
      : "var(--primary-text-color)",
}));

export const HeaderInnerBoxOne = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const DarkModeIcons = styled(DarkModeIcon)(() => ({
  color: "var(--secondary-text-color)",
  cursor: "pointer",
}));

export const LogoutIcons = styled(LogoutIcon)(() => ({
  color: "var(--secondary-text-color)",
  cursor: "pointer",
  fontSize: "24px",
}));

export const EmpTaskLoadingBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "55vh",
}));

export const EmpTaskMainBox = styled(Box)(() => ({
  '@media (max-width: 600px)': {
    width: '350%',
  },
  maxHeight: "fit-content",
}));

export const DataGrids = styled(DataGrid)(({ dataGridHeight }) => ({
  height: dataGridHeight ? "230px" : "fit-content",
  ".MuiDataGrid-filler": {
    height: "0px !important",
    display: "none",
  },
  color: "var(--primary-text-color)",
  textTransform: "capitalize",
  ".MuiDataGrid-columnHeaderTitle": {
    textTransform: "none !important",
  },
  backgroundColor: "var(--subAccordian-background-color)",
  ".MuiDataGrid-cell ": {
    cursor: "pointer",
    alignContent: "center",
  },
  ".MuiDataGrid-cell:focus": {
    outline: "none",
  },
  ".MuiDataGrid-columnHeader": {
    height: "48px !important",
  },
  ".MuiDataGrid-columnHeader:focus": {
    outline: "none",
  },
  ".MuiInput-underline:before": {
    borderBottom: "1px solid var(--primary-text-color) !important",
  },
  ".MuiSvgIcon-fontSizeSmall": {
    fill: "var(--primary-text-color) !important",
  },
  ".MuiInputBase-input.MuiInput-input.MuiInputBase-inputTypeSearch.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd":
  {
    color: "var(--primary-text-color) !important",
  },
  ".MuiDataGrid-overlay": {
    color: "var(--primary-text-color) !important",
    backgroundColor: "var(--subAccordian-background-color)",
  },
  ".MuiDataGrid-actionsCell": { type: "actions" },
  ".MuiDataGrid-scrollbar": {
    height: "auto !important",
  },
  "--DataGrid-containerBackground": "var(--primary-highlight-color)"
}));
