import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, IconButton, MenuItem, Tooltip, Typography } from "@mui/material";
import { Add as AddIcon, AssignmentInd as AssignmentIndIcon, Save as SaveIcon, Cancel as CancelIcon, Flag as FlagIcon, ContentPasteSearch as ContentPasteSearchIcon, Category as CategoryIcon, EditCalendar as EditCalendarIcon } from "@mui/icons-material";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { setToast, setToastMsg, setToastType } from "../../Slices/toastSlice";
import { setIsSetDefaultRow } from "../../Slices/selectedActive";
import {
    priorityArray,
    taskStatusList,
    taskTypeOptions
} from "../Constant/constant.js";
import {
    TypeCommon,
    getIcon,
} from "../ReusableComponents/UtilityFunctions.js";
import { upsertTask } from "../../Services/TaskManageServices.js";
import { EditDueDateBox } from "../../Styles/TaskManagement/DetailTaskStyle.js";
import { AssignToName, DueDatePicker, TaskTypeSelect, PrioritySelect, StatusSelect, CategorySelect, TitleTextField } from "../../Styles/TaskManagement/SubTaskStyle.js";

const SubTaskDefaultRow = ({ typeOptions, parentTaskId, allData, categories, statusList, projectId }) => {
    const selectRef = useRef(null);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.selectedActiveView.role);
    const employeeCurrent = useSelector((state) => state.addEmployee.current);
    const adminData = useSelector((state) => state.adminData.adminData);
    const isSetDefaultRow = useSelector((state) => state.selectedActiveView.isSetDefaultRow);
    const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);

    const tasksType = typeOptions?.find(type => type?.taskTypeName === "Tasks");
    let currentDate = dayjs().endOf("day");
    const currentEmployeeLogin = user === "employee";
    const adminLogin = user === "admin";

    const [isEditing, setIsEditing] = useState(false);
    const [activeField, setActiveField] = useState(null);
    const [assignEmpId, setAssignEmpId] = useState("");
    const [formData, setFormData] = useState({
        type: tasksType?._id,
        date: new Date().toISOString().split("T")[0],
        taskType: "",
        priority: "",
        assignToName: "",
        status: statusList ? "" : taskStatusList[0],
        statusColor: "",
        statusValue: 0,
        category: "",
        title: "",
        dueDate: "",
        project: projectId ?? "",
        parentTaskId: parentTaskId ?? "",
        plannedHours: 0,
        description: "-"
    })
    const [error, setError] = useState({})

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => {
            return {
                ...prevData,
                [name]: value,
            };
        });
        if (error[name] && value !== "") {
            setError((prevError) => {
                const newError = { ...prevError };
                delete newError[name];
                return newError;
            });
        }
        setIsEditing(true)
    }

    function getuser() {
        if (currentEmployeeLogin) {
            return "user";
        } else if (adminLogin) {
            return "admin";
        }
    }

    function getEmpId() {
        if (currentEmployeeLogin) {
            return employeeCurrent?.empId;
        } else if (adminLogin) {
            return adminData?.empId;
        }
    }

    const currentEmployeeId = getEmpId();

    const handleSave = async () => {
        const newError = {};
        if (!formData.title) {
            newError.title = "Task name is required";
        }
        if (!formData.taskType) {
            newError.taskType = "Task type is required";
        }
        if (!formData.assignToName) {
            newError.assignToName = "Assign to is required";
        }
        if (!formData.dueDate) {
            newError.dueDate = "Due date is required";
        }
        if (!formData.priority) {
            newError.priority = "Priority is required";
        }
        if (!formData.category) {
            newError.category = "Category is required";
        }
        if (statusList && !formData.statusValue) {
            newError.statusValue = "Status is required";
        } else if (!statusList && !formData.status) {
            newError.status = "Status is required";
        }

        if (Object.keys(newError).length > 0) {
            setError(newError);
            return;
        }
        try {
            const getUser = getuser();
            const postData = JSON.stringify({
                formData,
                currentEmployeeId,
                assignEmpId,
            });
            const response = await upsertTask(getUser, postData);
            if (response.status === 200 || response.status === 201) {
                dispatch(setIsSetDefaultRow((prev) => !prev));
                resetForm();
                dispatch(setToast(true));
                dispatch(setToastMsg(response.data.message ?? "Task added."));
                dispatch(setToastType("success"));
            }
        } catch (error) {
        }
    };

    const resetForm = () => {
        setIsEditing(false);
        setActiveField(null);
        setError({})
        setFormData({
            type: tasksType?._id,
            date: new Date().toISOString().split("T")[0],
            taskType: "",
            priority: "",
            assignToName: "",
            status: statusList ? "" : taskStatusList[0],
            statusColor: "",
            statusValue: 0,
            category: "",
            title: "",
            dueDate: "",
            project: projectId ?? "",
            parentTaskId: parentTaskId ?? "",
            plannedHours: 0,
            description: "-"
        });
        setAssignEmpId("");
    }

    const handleCancel = () => {
        resetForm();
    };

    const textfieldSelectStyle = {};
    const subTask = "subTask";

    const handleDateChange = (newValue) => {
        const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
        setFormData((prevData) => ({
            ...prevData,
            dueDate: formattedDate,
        }));
        if (error["dueDate"] && newValue !== "") {
            setError((prevError) => {
                const newError = { ...prevError };
                delete newError["dueDate"];
                return newError;
            });
        }
    };

    const handleOutsideClick = (event) => {
        if (selectRef.current && !selectRef.current.contains(event.target)) {
            setActiveField(null);
        }
    };

    const handleIconClick = (field) => {
        setActiveField(field);
    };

    const handleFocus = () => {
        setIsEditing(true);
    };

    const handleSelectChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => {
            const newStatus =
                name === "statusValue"
                    ? statusList.find((item) => item?._id === value)?.status
                    : name === "status"
                        ? value
                        : prevData.status;
            const color =
                name === "statusValue"
                    ? statusList.find((item) => item?._id === value)?.color
                    : prevData.statusColor;
            return {
                ...prevData,
                [name]: value,
                status: newStatus,
                statusColor: color,
            };
        });
        if (name === "assignToName") {
            const selectedEmployee = allData?.find(
                (employee) => employee?.firstName === value
            );
            if (selectedEmployee) {
                setAssignEmpId(selectedEmployee?.empId);
            }
        }
        if (error[name] && value !== "") {
            setError((prevError) => {
                const newError = { ...prevError };
                delete newError[name];
                return newError;
            });
        }
        setActiveField(null);
    };

    useEffect(() => {
        if (activeField) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick); // Cleanup
        };
    }, [activeField]);

    useEffect(() => {
    }, [isSetDefaultRow]);

    return (
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            {!isEditing && (
                <Tooltip title="Add Task">
                    <IconButton onClick={() => setIsEditing(true)}>
                        <AddIcon style={{ color: isDarkTheme ? "#e0e0e0e3" : "var(--primary-text-color)" }} />
                    </IconButton>
                </Tooltip>
            )}
            {isEditing && (
                <TypeCommon subTask={subTask} isDarkTheme={isDarkTheme} formData={formData} typeOptions={typeOptions} handleChange={handleChange} textfieldSelectStyle={textfieldSelectStyle} />
            )}

            <TitleTextField
                variant="standard"
                placeholder={isEditing ? "Task name" : "Add subtask"}
                value={formData?.title || ""}
                name="title"
                onChange={handleChange}
                autoFocus={isEditing}
                onFocus={handleFocus}
                error={error}
                isdarktheme={isDarkTheme ? "true" : "false"}
                InputProps={{
                    style: {
                        color: isDarkTheme ? "#e6e6e6" : "black",
                    },
                }}
            />

            {isEditing && (
                <>
                    <TaskTypeSelect
                        name="taskType"
                        value={formData?.taskType}
                        onChange={handleChange}
                        displayEmpty
                        variant="standard"
                        error={error?.taskType}
                        isdarktheme={isDarkTheme ? "true" : "false"}
                    >
                        <MenuItem value="">
                            <em>Select TaskType</em>
                        </MenuItem>
                        {taskTypeOptions?.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TaskTypeSelect>
                    {!formData?.assignToName && activeField !== 'assignToName' ? (
                        <Tooltip title="Assign to">
                            <IconButton onClick={() => handleIconClick('assignToName')}>
                                <AssignmentIndIcon style={{ color: error?.assignToName ? "red" : (isDarkTheme ? "#e0e0e0e3" : "var(--primary-text-color)") }} />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <AssignToName
                            name="assignToName"
                            value={formData?.assignToName || ""}
                            onChange={handleSelectChange}
                            displayEmpty
                            MenuProps={{
                                PaperProps: {
                                    ref: selectRef,
                                },
                            }}
                            defaultOpen={true}
                            variant="standard"
                            isdarktheme={isDarkTheme ? "true" : "false"}
                        >
                            <MenuItem value="">
                                <em>Select assignToName</em>
                            </MenuItem>
                            {allData?.map((option) => (
                                <MenuItem key={option?.firstName} value={option?.firstName}>
                                    {option?.firstName}
                                </MenuItem>
                            ))}
                        </AssignToName>
                    )}
                    <Tooltip
                        title="Due Date"
                        placement="bottom"
                        arrow
                        sx={{ marginTop: "-10px" }}
                    >
                        <EditDueDateBox>
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="en-gb"
                            >
                                <DueDatePicker
                                    slots={{
                                        openPickerIcon: EditCalendarIcon,
                                    }}
                                    slotProps={{
                                        openPickerButton: {
                                            style: { color: error?.dueDate ? "red" : (isDarkTheme ? "#e0e0e0e3" : "var(--primary-text-color)") }
                                        },
                                    }}
                                    minDate={currentDate}
                                    onChange={(date) => {
                                        handleDateChange(date);
                                    }}
                                    value={dayjs(formData?.dueDate)}
                                />
                            </LocalizationProvider>
                        </EditDueDateBox>
                    </Tooltip>
                    {!formData?.priority && activeField !== 'priority' ? (
                        <Tooltip title="Set priority">
                            <IconButton onClick={() => handleIconClick('priority')}>
                                <FlagIcon style={{ color: error?.priority ? "red" : (isDarkTheme ? "#e0e0e0e3" : "var(--primary-text-color)") }} />
                            </IconButton>
                        </Tooltip>
                    ) :
                        <PrioritySelect
                            name="priority"
                            value={formData?.priority || ''}
                            onChange={handleSelectChange}
                            displayEmpty
                            MenuProps={{
                                PaperProps: {
                                    ref: selectRef,
                                },
                            }}
                            defaultOpen={true}
                            variant="standard"
                            isdarktheme={isDarkTheme ? "true" : "false"}
                        >
                            <MenuItem value="">
                                <em>Select Priority</em>
                            </MenuItem>
                            {priorityArray?.map((option) => (
                                <MenuItem key={option} value={option}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            height: "25px",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                marginRight: "8px",
                                            }}
                                        >
                                            {getIcon(option)}
                                        </Typography>
                                        <Typography>{option}</Typography>
                                    </Box>
                                </MenuItem>
                            ))}
                        </PrioritySelect>}

                    {(statusList ? !formData?.statusValue : !formData?.status) && activeField !== (statusList ? 'statusValue' : 'status') ? (
                        <Tooltip title="Set Status">
                            <IconButton onClick={() => handleIconClick(statusList ? 'statusValue' : 'status')}>
                                <ContentPasteSearchIcon style={{ color: (statusList ? error?.statusValue : error?.status) ? "red" : (isDarkTheme ? "#e0e0e0e3" : "var(--primary-text-color)") }} />
                            </IconButton>
                        </Tooltip>
                    ) :
                        <StatusSelect
                            name={statusList ? 'statusValue' : 'status'}
                            value={statusList ? formData.statusValue : formData?.status}
                            onChange={handleSelectChange}
                            displayEmpty
                            MenuProps={{
                                PaperProps: {
                                    ref: selectRef,
                                },
                            }}
                            defaultOpen={true}
                            variant="standard"
                            formData={formData}
                            isdarktheme={isDarkTheme ? "true" : "false"}
                        >
                            <MenuItem value="">
                                <em>Select Status</em>
                            </MenuItem>
                            {statusList
                                ? statusList?.map((option) => (
                                    <MenuItem key={option?._id} value={option?._id}>
                                        {option.status}
                                    </MenuItem>
                                ))
                                : taskStatusList?.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                        </StatusSelect>}

                    {!formData?.category && activeField !== 'category' ? (
                        <Tooltip title="Set category">
                            <IconButton onClick={() => handleIconClick('category')}>
                                <CategoryIcon style={{ color: error?.category ? "red" : (isDarkTheme ? "#e0e0e0e3" : "var(--primary-text-color)") }} />
                            </IconButton>
                        </Tooltip>
                    ) :
                        <CategorySelect
                            name="category"
                            value={formData?.category || ''}
                            onChange={handleSelectChange}
                            displayEmpty
                            MenuProps={{
                                PaperProps: {
                                    ref: selectRef,
                                },
                            }}
                            defaultOpen={true}
                            variant="standard"
                            isdarktheme={isDarkTheme ? "true" : "false"}
                        >
                            <MenuItem value="">
                                <em>Select Category</em>
                            </MenuItem>
                            {categories?.map((option) => (
                                <MenuItem
                                    key={option._id}
                                    value={option.name}
                                >
                                    {option.name}
                                </MenuItem>
                            ))}
                        </CategorySelect>}
                    <Tooltip title="Save">
                        <IconButton color="primary" onClick={handleSave}>
                            <SaveIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Cancel">
                        <IconButton color="error" onClick={handleCancel}>
                            <CancelIcon />
                        </IconButton>
                    </Tooltip>
                </>
            )
            }
        </Box >
    );
};

export default SubTaskDefaultRow;
