import React from "react";
import { Button, Grid, Box } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

import { ButtonGrid, GetFullYearTypography } from "../../../Styles/AttendanceManagement/AttendanceManagementStyle";

function MonthSlider(props) {
	const { month, setMonth, isMobile } = props;

	const handlePrevMonth = () => {
		const prevMonth = new Date(month.getFullYear(), month.getMonth() - 1);
		setMonth(prevMonth);
	};

	const handleNextMonth = () => {
		const nextMonth = new Date(month.getFullYear(), month.getMonth() + 1);
		setMonth(nextMonth);
	};

	return (
		<Box>
			<Grid container alignItems="center">
				<Grid item xs={1} md={1} lg={1}>
					<Button onClick={handlePrevMonth}>
						<ChevronLeft />
					</Button>
				</Grid>
				<Grid item xs={10} md={10} lg={10}>
					<GetFullYearTypography ismobile={isMobile ? "true" : "false"}>
						{month.toLocaleString("default", { month: "long" })}{" "}
						{month.getFullYear()}
					</GetFullYearTypography>
				</Grid>
				<ButtonGrid
					item
					xs={1}
					md={1}
					lg={1}
				>
					<Button onClick={handleNextMonth}>
						<ChevronRight />
					</Button>
				</ButtonGrid>
			</Grid>
		</Box>
	);
}

export default MonthSlider;
