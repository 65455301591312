import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";

import {
  formatDate,
  formatDateSlashTo_,
} from "../../ReusableComponents/UtilityFunctions";
import { ActivityTitle } from "../../ReusableComponents/CustomDesignMUI/CustomMUI";
import { AccessTimeIcons, NoActivityTypography, TitleTypography } from "../../../Styles/UpdateEmployee/UpdateAttendanceStyle";

const EmployeeActivity = ({ employeesNameArray, EmpId }) => {
  const { employeeId } = useParams();
  const allEmployeeAttendence = useSelector(
    (state) => state.employeeData.allEmployeeAttendenceData
  );
  const selectedDate = useSelector((state) => state.searchData.calenderValue);
  const selectedEmpId = employeeId || EmpId;

  const currentDate = formatDate(new Date());
  const [date, setDate] = useState();
  const [empId, setEmpId] = useState(selectedEmpId);
  const [records, setRecords] = useState([]);

  function extractTime(timestamp) {
    const dateTime = new Date(timestamp);
    const time = dateTime.toLocaleTimeString([], {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
    });
    return time;
  }

  function getName(empId) {
    const employee = employeesNameArray.find((state) => state[0] === empId);
    return employee ? employee[1] : 'Employee not found';
  }

  useEffect(() => {
    if (selectedDate) {
      setDate(selectedDate);
    } else {
      setDate(currentDate);
    }
    setEmpId(selectedEmpId);
    const filteredDataByEmployee = allEmployeeAttendence?.filter(
      (item) => item?.empId === empId
    );
    if (filteredDataByEmployee?.length !== 0) {
      const filteredDataBydate = filteredDataByEmployee[0]?.records?.filter(
        (item) => item?.date === formatDateSlashTo_(date)
      );
      const adminShowData = filteredDataBydate[0]?.dailytimer?.timer;
      setRecords(adminShowData);
      if (filteredDataBydate?.length === 0) {
        setRecords([]);
      } else {
        setRecords(adminShowData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, selectedEmpId, date, empId, allEmployeeAttendence]);


  return (
    <Box>
      <Typography
        sx={{
          color: "var(--primary-color)",
          display: "inline",
          fontWeight: "bold",
          fontSize: "130%",
        }}
      >
        Employee Activity
      </Typography>
      <Box sx={{ mt: 0.5, height: "100%", overflow: "auto" }}>
        <Timeline
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            ".MuiTimelineItem-root::before": {
              content: "none",
            },
          }}
        >
          {records.length === 0 ? (
            <NoActivityTypography>
              No activity{" "}
            </NoActivityTypography>
          ) : (
            records?.map((record, index) => (
              <Box key={index} sx={{ marginTop: "-5px" }}>
                <Box>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot
                        variant="outlined"
                        sx={{ borderColor: "var(--secondary-color)" }}
                      />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography sx={ActivityTitle}>Punch In at</Typography>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <AccessTimeIcons />
                        <TitleTypography>
                          {extractTime(record?.startTime)}
                        </TitleTypography>
                      </Box>
                    </TimelineContent>
                  </TimelineItem>
                </Box>
                {record?.endTime === 0 ? null : (
                  <>
                    <Box>
                      <TimelineItem>
                        <TimelineSeparator>
                          <TimelineDot
                            variant="outlined"
                            sx={{ borderColor: "var(--secondary-color)" }}
                          />
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                          <Typography sx={ActivityTitle}>
                            Punch Out at
                          </Typography>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <AccessTimeIcons />
                            <TitleTypography>
                              {extractTime(record?.endTime)}&nbsp;(
                              {record?.updatedByEmpId ? `${record?.reason} [ Updated by ${getName(record?.updatedByEmpId)}]  ` : record?.reason})
                            </TitleTypography>
                          </Box>
                        </TimelineContent>
                      </TimelineItem>
                    </Box>
                  </>
                )}
              </Box>
            ))
          )}
        </Timeline>
      </Box>
    </Box>
  );
};

export default EmployeeActivity;
