import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Box, useMediaQuery, IconButton } from "@mui/material";
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from "@mui/icons-material";

import CompanyLeaves from "./CompanyLeaves";
import CompanyHolidays from "./CompanyHolidays";
import AssignLeaves from "./AssignLeaves";
import usePermissions from "../RolePermission/usePermissions";
import ViewAssignLeaveBalance from "./ViewAssignLeaveBalance";
import {
  MainBox,
  LeaveButton,
  HolidayButton,
  AssignLeaveButton,
  ShowAssignLeaveButton,
  SliderBox,
  InnerSliderBox,
  CommanTabBox
} from "../../Styles/CompanyAndLeavePolicy/CompanyLeaveAndHolidayAccordianStyle";

function CompanyLeaveAndHolidayAccordian() {
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const user = useSelector((state) => state.selectedActiveView.role);
  const { hasPermission } = usePermissions();

  const [selectedTab, setSelectedTab] = useState("Leaves");
  const adminLogin = user === "admin";
  const employeeLogin = user === "employee";
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const scrollRef = useRef(null);

  const handleButtonClick = (buttonName) => {
    setSelectedTab(buttonName);
  };

  const getScrollAmount = () => {
    if (scrollRef.current) {
      return scrollRef.current.offsetWidth * 1.005;
    }
    return 700;
  };

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: -getScrollAmount(),
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: getScrollAmount(),
        behavior: "smooth",
      });
    }
  };

  return (
    <Box>
      <MainBox bottom={0}>
        {isMobile ? (
          <SliderBox>
            <IconButton
              onClick={scrollLeft}
              style={{
                marginRight: 12,
                color: isDarkTheme ? "#e0e0e0e3" : "#544f5a",
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
            <InnerSliderBox
              ref={scrollRef}
            >
              {hasPermission("Leaves Types") && (
                <CommanTabBox>
                  <LeaveButton
                    onClick={() => handleButtonClick("Leaves")}
                    selectedtab={selectedTab}
                  >
                    Leaves
                  </LeaveButton>
                </CommanTabBox>
              )}
              {hasPermission("Holidays") && (
                <CommanTabBox>
                  <HolidayButton
                    onClick={() => handleButtonClick("Holidays")}
                    selectedtab={selectedTab}
                  >
                    Holidays
                  </HolidayButton>
                </CommanTabBox>
              )}
              {hasPermission("Assign Leaves") && (
                <CommanTabBox>
                  <AssignLeaveButton
                    onClick={() => handleButtonClick("Assign Leaves")}
                    selectedtab={selectedTab}
                  >
                    Assign Leaves
                  </AssignLeaveButton>
                </CommanTabBox>
              )}
              {hasPermission("Show Assign Leaves") && (
                <CommanTabBox>
                  <ShowAssignLeaveButton
                    onClick={() => handleButtonClick("Show Assign Leaves")}
                    selectedtab={selectedTab}
                  >
                    Show Assign Leaves
                  </ShowAssignLeaveButton>
                </CommanTabBox>
              )}
            </InnerSliderBox>
            <IconButton
              onClick={scrollRight}
              style={{
                marginLeft: 12,
                color: isDarkTheme ? "#e0e0e0e3" : "#544f5a",
              }}
            >
              <ChevronRightIcon />
            </IconButton>
          </SliderBox>
        ) : (
          <>
            {hasPermission("Leaves Types") && (
              <LeaveButton
                onClick={() => handleButtonClick("Leaves")}
                selectedtab={selectedTab}
              >
                Leaves
              </LeaveButton>
            )}
            {hasPermission("Holidays") && (
              <HolidayButton
                onClick={() => handleButtonClick("Holidays")}
                selectedtab={selectedTab}
              >
                Holidays
              </HolidayButton>
            )}
            {hasPermission("Assign Leaves") && (
              <AssignLeaveButton
                onClick={() => handleButtonClick("Assign Leaves")}
                selectedtab={selectedTab}
              >
                Assign Leaves
              </AssignLeaveButton>
            )}
            {hasPermission("Show Assign Leaves") && (
              <ShowAssignLeaveButton
                onClick={() => handleButtonClick("Show Assign Leaves")}
                selectedtab={selectedTab}
              >
                Show Assign Leaves
              </ShowAssignLeaveButton>
            )}</>
        )}
      </MainBox>
      <Box marginTop={2}>
        {hasPermission("Leaves Types") && selectedTab === "Leaves" ? (
          <CompanyLeaves
            adminLogin={adminLogin}
            employeeLogin={employeeLogin}
            isDarkTheme={isDarkTheme}
          />
        ) : null}

        {hasPermission("Holidays") && selectedTab === "Holidays" ? (
          <CompanyHolidays
            adminLogin={adminLogin}
            employeeLogin={employeeLogin}
            isDarkTheme={isDarkTheme}
          />
        ) : null}
        {hasPermission("Assign Leaves") && selectedTab === "Assign Leaves" ? (
          <AssignLeaves
          // adminLogin={adminLogin}
          // employeeLogin={employeeLogin}
          // isDarkTheme={isDarkTheme}
          />
        ) : null}
        {hasPermission("Show Assign Leaves") &&
          selectedTab === "Show Assign Leaves" ? (
          <ViewAssignLeaveBalance />
        ) : null}
      </Box>
    </Box >
  );
}

export default CompanyLeaveAndHolidayAccordian;
