import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Box,
	Button,
	InputAdornment,
	Typography,
	useMediaQuery
} from "@mui/material";

import {
	setToast,
	setToastMsg,
	setToastType,
} from "../../Slices/toastSlice";
import SelectEmployeeForLeaves from "./SelectEmployeeForLeaves";
import { renderAsterisk } from "../ReusableComponents/UtilityFunctions";
import DialogComponent from '../ReusableComponents/DialogBoxComponents/DialogComponent'
import { errorMessage } from "../../Services/axiosInstance";
import { editMonthlyRecord, upsertAssignLeave, upsertMonthlyRecord } from "../../Services/LeaveManageServices";
import { DialogTitles, OutlinedInputs, CancelButton, DialogsActions, PerInputAdornment, ErrorTypography, CustomTextField, LabelTypography, DialogContents, InnerBoxOne, MainBox, TitleBox, DetailsBox } from "../../Styles/CompanyAndLeavePolicy/LeaveAssignCardStyle"

function LeaveAssignCard(props) {
	const {
		selectedDataDailog,
		open,
		setOpenDailogBox,
		change,
		setChange,
		employeeList,
		dates,
		selectEmployeeCard,
		selectDates,
		updateMonthlyRecord,
	} = props;
	const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
	const dispatch = useDispatch();
	const themeChange = useSelector((state) => state.theme.isDarkTheme);
	const isMobile = useMediaQuery("(max-width:600px)");

	const [assignData, setAssignData] = useState({
		startDate: "",
		endDate: "",
		percentage: "",
	});
	const [error, setError] = useState({});
	const [limitDate, setLimitDate] = useState("");
	const [leaveAssignMessage, setLeaveAssignMessage] = useState("");
	const [employeeSelectedForLeave, setEmployeeSelectedForLeave] = useState([]);
	const [employeeSelectedForLeaveEmpId, setEmployeeSelectedForLeaveEmpId] = useState([]);
	const [displayError, setDisplayError] = useState("");

	const formattedDate = selectedDataDailog?.nextDate
		? new Date(selectedDataDailog?.nextDate).toISOString().split("T")[0]
		: "";

	const nextDate = selectedDataDailog?.nextDate
		? new Date(selectedDataDailog?.nextDate)
		: new Date();
	const nextYear = nextDate.getFullYear() + 1;
	const formattedEndDate = new Date(nextYear, 2, 31)
		.toISOString()
		.split("T")[0];

	const onClose = () => {
		setOpenDailogBox(false);
	}

	const handleInputChange = (event) => {
		setDisplayError("");
		const { name, value } = event.target;
		// if (name !== "percentage") {
		setAssignData((prevData) => ({
			...prevData,
			[name]: value,
		}));
		setError((prevData) => ({
			...prevData,
			[name]: "",
		}));
		// } else {
		//   if (/^(100|\d{1,2})?$/.test(value)) {
		//     setError((prevData) => ({
		//       ...prevData,
		//       [name]: "",
		//     }));
		//     setAssignData((prevData) => ({
		//       ...prevData,
		//       [name]: value,
		//     }));
		//   }
		// }
	};

	const assignLeaves = async () => {
		if (
			employeeSelectedForLeave.length ===
			employeeSelectedForLeaveEmpId.length &&
			employeeSelectedForLeaveEmpId.length > 0
		) {
			setDisplayError("");
			try {
				setLeaveAssignMessage("Leaves Assign!")
				const assignData = {
					endDate: dates.endDate.split("T")[0],
					percentage: dates.percentage,
					startDate: dates.startDate.split("T")[0],
					id: dates.id,
				};
				const postData = JSON.stringify({ assignData, employeeSelectedForLeave, employeeSelectedForLeaveEmpId, })
				const response = await upsertAssignLeave(postData)
				if (response.status === 200) {
					if (!response.data.success) {
						setDisplayError(response.data.message);
						setLeaveAssignMessage("");
					} else {
						setOpenDailogBox(false);
						if (change === true) {
							setChange(false);
						} else {
							setChange(true);
						}
						setLeaveAssignMessage("");
						dispatch(setToast(true));
						dispatch(setToastMsg(response.data.message ?? "Leaves Assign"));
						dispatch(setToastType("success"));
					}
				}
			} catch (error) {
				setDisplayError(errorMessage ?? "")
				setLeaveAssignMessage("");
			}
		} else {
			setDisplayError("Employee selection is not proper.");
		}
	};

	const validationAndsave = async () => {
		setDisplayError("");
		try {
			setLeaveAssignMessage("Monthly Record Added!")
			const postData = JSON.stringify({ assignData });
			const response = await upsertMonthlyRecord(postData)
			if (response.status === 200) {
				setOpenDailogBox(false);
				setError({});
				setAssignData({
					startDate: "",
					endDate: "",
					percentage: "",
				});
				setLeaveAssignMessage("");
				dispatch(setToast(true));
				dispatch(setToastMsg("Monthly Record Added!"));
				dispatch(setToastType("success"));
				if (change === true) {
					setChange(false);
				} else {
					setChange(true);
				}
			}
		} catch (error) {
			setDisplayError(errorMessage ?? "")
			setLeaveAssignMessage("");
		}
	};

	const validationAndUpdate = async () => {
		setDisplayError("");
		try {
			setLeaveAssignMessage("Records updated.!");
			const id = dates.id;
			const postData = JSON.stringify({ assignData, id });
			const response = await editMonthlyRecord(postData);
			if (response.status === 200) {
				setOpenDailogBox(false);
				setError({});
				setAssignData({
					startDate: "",
					endDate: "",
					percentage: "",
				});
				setLeaveAssignMessage("");
				dispatch(setToast(true));
				dispatch(setToastMsg(response.data.message ?? "Records updated sucessfully"));
				dispatch(setToastType("success"));
				if (change === true) {
					setChange(false);
				} else {
					setChange(true);
				}

			} else if (response.status === 202) {
				setDisplayError(response.data.message);
				setLeaveAssignMessage("");
			}
		} catch (error) {
			setDisplayError(errorMessage ?? "")
			setLeaveAssignMessage("");
		}
	};

	const validation = async () => {
		const newErrors = {};
		if (!assignData.startDate) {
			newErrors.startDate = "Please select start date";
		}
		if (!assignData.endDate) {
			newErrors.endDate = "Please select end date";
		}

		if (assignData.startDate && assignData.endDate) {
			if (assignData.endDate < assignData.startDate) {
				newErrors.endDate = "End date can't be smaller then start date";
			} else if (assignData.endDate === assignData.startDate) {
				newErrors.endDate = "End date can't be same as start date";
			}
		}

		if (!assignData.percentage) {
			newErrors.percentage = "Please enter percentage";
		} else if (!/^\d+(\.\d+)?$/.test(assignData.percentage)) {
			newErrors.percentage = "Please enter valid percentage";
		} else if (!updateMonthlyRecord) {
			if (selectedDataDailog?.percentageLeft < assignData.percentage) {
				newErrors.percentage = "Insufficient percentage";
			}
		} else if (updateMonthlyRecord) {
			if (
				selectedDataDailog?.percentageLeft + dates.percentage <
				assignData.percentage
			) {
				newErrors.percentage = "Insufficient percentage";
			}
		}
		setError(newErrors);
		const noErrors = Object.keys(newErrors).length === 0;

		if (noErrors) {
			updateMonthlyRecord ? validationAndUpdate() : validationAndsave();
		}
	};

	const renderForm = () => {
		return (
			<Box sx={{ padding: "13px" }}>
				<DialogTitles sx={{ padding: "0px 0px" }} id="Leave-Assign-dialog-title">
					<MainBox>
						<TitleBox ismobile={isMobile ? "true" : "false"} themechange={themeChange ? "true" : "false"}>
							Leave Assign
						</TitleBox>
						<DetailsBox themechange={themeChange ? "true" : "false"}>
							({selectedDataDailog?.year})
						</DetailsBox>
					</MainBox>
					{!isMobile ? <DetailsBox ismobile={isMobile ? "true" : "false"} themechange={themeChange ? "true" : "false"}>
						{updateMonthlyRecord
							? selectedDataDailog?.percentageLeft +
							dates.percentage
							: selectedDataDailog?.percentageLeft}
						% Balance
					</DetailsBox> : null}
				</DialogTitles>
				{isMobile ? <DetailsBox ismobile={isMobile ? "true" : "false"} themechange={themeChange ? "true" : "false"}>
					{updateMonthlyRecord
						? selectedDataDailog?.percentageLeft +
						dates.percentage
						: selectedDataDailog?.percentageLeft}
					% Balance
				</DetailsBox> : null}
				<DialogContents sx={{ padding: isMobile ? "5px 1px 5px 1px !important" : "7px 2px 5px 2px !important" }} themechange={themeChange ? "true" : "false"}>
					{selectDates || updateMonthlyRecord ? (
						<InnerBoxOne>
							<LabelTypography>
								Start Date{renderAsterisk()}
							</LabelTypography>
							<CustomTextField
								themechange={themeChange ? "true" : "false"}
								name="startDate"
								value={assignData.startDate}
								type="date"
								onChange={handleInputChange}
								inputProps={{
									min: updateMonthlyRecord
										? new Date(dates.startDate)
											.toISOString()
											.split("T")[0]
										: formattedDate,
									max: limitDate,
									startadornment: (
										<InputAdornment position="start">
										</InputAdornment>
									),
								}}
							/>
							<ErrorTypography>
								{error.startDate}
							</ErrorTypography>
							<br />
							<LabelTypography>
								End Date{renderAsterisk()}
							</LabelTypography>
							<CustomTextField
								themechange={themeChange ? "true" : "false"}
								name="endDate"
								value={assignData.endDate}
								type="date"
								onChange={handleInputChange}
								inputProps={{
									min: updateMonthlyRecord
										? new Date(dates.startDate)
											.toISOString()
											.split("T")[0]
										: formattedDate,
									max: limitDate,
									startadornment: (
										<InputAdornment position="start">
										</InputAdornment>
									),
								}}
							/>
							<ErrorTypography>
								{error.endDate}
							</ErrorTypography>
							<br />
							<LabelTypography>
								Percentage(%){renderAsterisk()}
							</LabelTypography>
							<OutlinedInputs
								fullWidth
								endAdornment={
									<PerInputAdornment
										themechange={themeChange ? "true" : "false"}
										position="end"
									>
										%
									</PerInputAdornment>
								}
								inputProps={{
									startadornment: (
										<InputAdornment position="start">
										</InputAdornment>
									),
								}}
								name="percentage"
								value={assignData.percentage}
								type="text"
								onChange={handleInputChange}
							/>
							<ErrorTypography>
								{error.percentage}
							</ErrorTypography>
						</InnerBoxOne>
					) : null}

					{selectEmployeeCard ? (
						<SelectEmployeeForLeaves
							setDisplayError={setDisplayError}
							employeeSelectedPreviously={dates?.employees}
							employeeList={employeeList}
							setEmployeeSelectedForLeave={
								setEmployeeSelectedForLeave
							}
							setEmployeeSelectedForLeaveEmpId={
								setEmployeeSelectedForLeaveEmpId
							}
							employeeSelectedForLeaveEmpId={
								employeeSelectedForLeaveEmpId
							}
						/>
					) : null}
				</DialogContents>
				{isMobile ? <Box sx={{ display: "flex", alignItems: "center" }}>
					{leaveAssignMessage && (
						<Typography
							sx={{
								ml: !isMobile && 2,
								color: isDarkTheme ? "#e0e0e0" : "#5e6e82",
							}}
						>
							{leaveAssignMessage}
						</Typography>
					)}
					{displayError && (
						<Typography sx={{ ml: !isMobile && 2, color: "red" }}>
							{displayError}
						</Typography>
					)}
				</Box> : null}
				<DialogsActions ismobile={isMobile ? "true" : "false"}>
					{!isMobile &&
						<Box sx={{ display: "flex", alignItems: "center" }}>
							{leaveAssignMessage && (
								<Typography
									sx={{
										ml: 2,
										color: isDarkTheme ? "#e0e0e0" : "#5e6e82",
									}}
								>
									{leaveAssignMessage}
								</Typography>
							)}
							{displayError && (
								<Typography sx={{ ml: 2, color: "red" }}>
									{displayError}
								</Typography>
							)}
						</Box>
					}
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<CancelButton
							isdarktheme={isDarkTheme ? "true" : "false"}
							onClick={() => {
								setOpenDailogBox(false);
								setError({});
								setAssignData({
									startDate: "",
									endDate: "",
									percentage: "",
								});
								setLeaveAssignMessage("")
							}}
						>
							Cancel
						</CancelButton>
						{selectDates ? (
							<Button
								variant="contained"
								color="primary"
								onClick={() => {
									validation();
								}}
							>
								Add Record
							</Button>
						) : null}
						{updateMonthlyRecord ? (
							<Button
								variant="contained"
								color="primary"
								onClick={() => {
									validation();
								}}
							>
								Update Record
							</Button>
						) : null}
						{selectEmployeeCard ? (
							<Button
								variant="contained"
								color="success"
								sx={{}}
								onClick={() => {
									assignLeaves();
								}}
							>
								Assign
							</Button>
						) : null}
					</Box>
				</DialogsActions>
				{/* </>
				)} */}
			</Box>)
	}

	useEffect(() => {
		setLimitDate(formattedEndDate);
		setAssignData((prevData) => ({
			...prevData,
			startDate: formattedDate,
		}));
	}, [formattedDate, formattedEndDate]);

	useEffect(() => {
		if (updateMonthlyRecord) {
			const date = new Date(dates.endDate);
			date.setDate(date.getDate() + 1);
			const newDateString = date.toISOString().split("T")[0];
			if (newDateString !== formattedDate) {
				setLimitDate(
					new Date(dates.endDate).toISOString().split("T")[0]
				);
			}
			setAssignData({
				startDate: new Date(dates.startDate)
					.toISOString()
					.split("T")[0],
				endDate: new Date(dates.endDate).toISOString().split("T")[0],
				percentage: dates.percentage,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateMonthlyRecord, dates]);

	return (
		<Box>
			<DialogComponent
				open={open}
				onClose={onClose}
				maxWidth="1000px"
				fullwidth="true"
				sx={{
					".MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper":
					{
						backgroundColor: isDarkTheme
							? "#0b1727 !important"
							: "#ffffff !important",
						height: "max-contant",
						marginLeft: isMobile ? "3%" : "12%",
						marginRight: isMobile ? "3%" : "10%",
						"@media (max-width: 600px)": {
							width: "100%",
						},
						width: !isMobile && "30vw",
						overflowY: isMobile && "scroll",
					},
				}}
				renderChildern={renderForm}
			/>
		</Box>
	);
}

export default LeaveAssignCard;
