import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  InputAdornment,
  Typography,
} from "@mui/material";

import {
  setToast,
  setToastMsg,
  setToastType,
} from "../../../Slices/toastSlice";
import {
  viewProfileTitle,
  viewEducationBox,
} from "../../ReusableComponents/CustomDesignMUI/CustomMUI";
import { setAllData } from "../../../Slices/UserDataSlice";
import { setAllLeaveData } from "../../../Slices/leaveData";
import {
  resetEmployeeDetail,
  setEmployeeAdded,
  set_academic,
  set_professional,
} from "../../../Slices/employeeDetailSlice";
import ExitDialog from "../ExitDialog";
import { renderAsterisk } from "../../ReusableComponents/UtilityFunctions.js";
import { errorMessage } from "../../../Services/axiosInstance";
import { upsertEducation } from "../../../Services/EmployeeManageServices";
import { MainBox, TitleBox, AddRecordBox, AddRecordButton, InputTextField } from "../../../Styles/UpdateEmployee/UpdateEducationStyle";
import { FooterBox } from "../../../Styles/UpdateEmployee/UpdatePersonalStyle.js";
import { CancelButton, SaveButton, SaveNextButton } from "../../../Styles/UpdateEmployee/UpdateContactStyle";

const DisplayEducation = (props) => {
  const { saveNextButtonCallback, previousButtonCallback } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const employee_id = useSelector((state) => state.addEmployee.personal.id);
  const academic = useSelector((state) => state.addEmployee.academicDetails);
  const professional = useSelector(
    (state) => state.addEmployee.professionalDetails
  );

  const [openExitDialog, setOpenExitDialog] = useState(false);
  const [displayError, setDisplayError] = useState("");
  const [displaySuccess, setDisplaySuccess] = useState("");
  const [academicRecordsArray, setAcademicRecordsArray] = useState(
    academic ? academic : []
  );
  const [academicRecordErrorsArray, setAcademicRecordErrorsArray] = useState(
    []
  );
  const [professionalRecordsArray, setProfessionalRecordsArray] = useState(
    professional ? professional : []
  );
  const [professionalRecordErrorsArray, setProfessionalRecordErrorsArray] =
    useState([]);

  const handleCloseCancelDialog = async () => {
    setOpenExitDialog(false);
  };

  const reset = () => {
    nullRecord();
  };

  const getEmptyacademicRecord = () => ({
    University: "",
    Course: "",
    Start_DateA: "",
    End_DateA: "",
  });

  const getEmptyacademicErrors = () => ({
    University: "",
    Course: "",
    Start_DateA: "",
    End_DateA: "",
  });

  const getEmptyProfessionalRecord = () => ({
    Certificate: "",
    Place: "",
    Start_DateP: "",
    End_DateP: "",
  });

  const getEmptyProfessionalerror = () => ({
    Certificate: "",
    Place: "",
    Start_DateP: "",
    End_DateP: "",
  });

  const nullRecord = () => {
    setAcademicRecordsArray(academic || []);
    setAcademicRecordErrorsArray([]);
    setProfessionalRecordsArray(professional || []);
    setProfessionalRecordErrorsArray([]);
  };

  const addRecord = () => {
    if (validateAllForms()) {
      setAcademicRecordsArray((prevArray) => [
        ...prevArray,
        getEmptyacademicRecord(),
      ]);
      setAcademicRecordErrorsArray((prevErrorsArray) => [
        ...prevErrorsArray,
        getEmptyacademicErrors(),
      ]);
    }
  };

  const addRecord2 = () => {
    if (validateAllForms2()) {
      setProfessionalRecordsArray((prevArray) => [
        ...prevArray,
        getEmptyProfessionalRecord(),
      ]);
      setProfessionalRecordErrorsArray((prevErrorsArray) => [
        ...prevErrorsArray,
        getEmptyProfessionalerror(),
      ]);
    }
  };

  const validateAllForms = () => {
    const allErrors = academicRecordsArray?.map((record, index) =>
      validateForm(index)
    );
    setAcademicRecordErrorsArray(allErrors);
    return allErrors.every((errors) =>
      Object.values(errors).every((error) => !error)
    );
  };

  const validateAllForms2 = () => {
    const allErrors = professionalRecordsArray?.map((record, index) =>
      validateForm2(index)
    );
    setProfessionalRecordErrorsArray(allErrors);
    return allErrors.every((errors) =>
      Object.values(errors).every((error) => !error)
    );
  };

  const handleInputChange1 = (index, event) => {
    const { name, value } = event.target;
    setAcademicRecordsArray((prevArray) =>
      prevArray?.map((record, i) =>
        i === index ? { ...record, [name]: value } : record
      )
    );
  };

  const handleInputChange2 = (index, event) => {
    const { name, value } = event.target;
    setProfessionalRecordsArray((prevArray) =>
      prevArray?.map((record, i) =>
        i === index ? { ...record, [name]: value } : record
      )
    );
  };

  const removeRecord1 = (index) => {
    setAcademicRecordsArray((prevArray) =>
      prevArray.filter((_, i) => i !== index)
    );
    setAcademicRecordErrorsArray((prevErrorsArray) =>
      prevErrorsArray.filter((_, i) => i !== index)
    );
  };

  const removeRecord2 = (index) => {
    setProfessionalRecordsArray((prevArray) =>
      prevArray.filter((_, i) => i !== index)
    );
    setProfessionalRecordErrorsArray((prevErrorsArray) =>
      prevErrorsArray.filter((_, i) => i !== index)
    );
  };

  const validateForm = (index) => {
    const currentRecord = academicRecordsArray[index];
    const errors = getEmptyacademicErrors();
    if (!currentRecord.University) {
      errors.University = "University name is required";
    } else if (!/^[a-zA-Z\s]*$/.test(currentRecord.University)) {
      errors.University = "University name should not contain numeric values";
    }
    if (!currentRecord.Course) {
      errors.Course = "Course name is required";
    }
    if (!currentRecord.Start_DateA) {
      errors.Start_DateA = "Start date is required";
    }
    if (!currentRecord.End_DateA) {
      errors.End_DateA = "End date is required";
    } else if (currentRecord.Start_DateA > currentRecord.End_DateA) {
      errors.End_DateA = "Invalid date.";
    }

    return errors;
  };

  const validateForm2 = (index) => {
    validateAllForms();
    const currentRecord = professionalRecordsArray[index];
    const errors = getEmptyProfessionalerror();
    if (!currentRecord.Certificate) {
      errors.Certificate = "Certificate name is required";
    } else if (!/^[a-zA-Z\s]*$/.test(currentRecord.Certificate)) {
      errors.Certificate = "Certificate name should not contain numeric values";
    }

    if (!currentRecord.Place) {
      errors.Place = "Place  is required";
    } else if (!/^[a-zA-Z\s]*$/.test(currentRecord.Place)) {
      errors.Place = "Place should not contain numeric values";
    }
    if (!currentRecord.Start_DateP) {
      errors.Start_DateP = "Start date is required";
    }
    if (!currentRecord.End_DateP) {
      errors.End_DateP = "End date is required";
    } else if (currentRecord.Start_DateP > currentRecord.End_DateP) {
      errors.End_DateP = "Invalid date.";
    }

    return errors;
  };

  const save = async () => {
    setDisplayError("");
    if (displaySuccess === "employee added.") {
    } else {
      try {
        const postData = JSON.stringify({ employee_id, academicRecordsArray, professionalRecordsArray, });
        const response = await upsertEducation(postData);
        if (response.status === 200 || response.status === 201) {
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message ?? "Employee data updated successfully."));
          dispatch(setToastType("success"));
          setDisplaySuccess("")
          navigate("/dashboard");
          dispatch(resetEmployeeDetail());
          dispatch(setAllData(response.data.user));
          dispatch(setAllLeaveData(response.data.leave));
          dispatch(set_academic(academicRecordsArray));
          dispatch(set_professional(professionalRecordsArray));
        }
      } catch (error) {
        setDisplayError(errorMessage ?? "")
      }
    }
  };

  const saveAndNext = async () => {
    setDisplayError("");
    if (displaySuccess === "employee added.") {
      saveNextButtonCallback();
    } else {
      try {
        const postData = JSON.stringify({ employee_id, academicRecordsArray, professionalRecordsArray, })
        const response = await upsertEducation(postData);
        if (response.status === 200 || response.status === 201) {
          saveNextButtonCallback();
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message ?? "Employee data updated successfully."));
          dispatch(setToastType("success"));
          dispatch(setAllData(response.data.user));
          dispatch(setAllLeaveData(response.data.leave));
          dispatch(set_academic(academicRecordsArray));
          dispatch(set_professional(professionalRecordsArray));
        }
      } catch (error) {
        setDisplayError(errorMessage ?? "")
      }
    }
  };

  return (
    <MainBox>
      <TitleBox>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Education Qualification
          </Typography>
        </Box>
        <Box></Box>
      </TitleBox>
      <hr />

      <AddRecordBox>
        <Typography sx={{ ...viewProfileTitle }}>Academic Records</Typography>
        <AddRecordButton
          variant="outlined"
          onClick={() => addRecord("academicRecords")}
        >
          + Add Record
        </AddRecordButton>
      </AddRecordBox>
      <Box>
        {Array.isArray(academicRecordsArray) &&
          academicRecordsArray?.map((record, index) => (
            <Box sx={viewEducationBox} key={index}>
              <Box sx={{ marginBottom: "15px" }} key={index}>
                <InputTextField
                  name="University"
                  value={academicRecordsArray[index]?.University}
                  onChange={(event) => handleInputChange1(index, event)}
                  label={
                    <>
                      University Name
                      {renderAsterisk()}
                    </>
                  }
                />

                {academicRecordErrorsArray[index]?.University && (
                  <Typography color="error">
                    {academicRecordErrorsArray[index].University}
                  </Typography>
                )}
                <InputTextField
                  name="Course"
                  value={academicRecordsArray[index]?.Course}
                  onChange={(event) => handleInputChange1(index, event)}
                  label={
                    <>
                      Course Name
                      {renderAsterisk()}
                    </>
                  }
                />
                {academicRecordErrorsArray[index]?.Course && (
                  <Typography color="error">
                    {academicRecordErrorsArray[index].Course}
                  </Typography>
                )}
                <InputTextField
                  InputProps={{
                    startAdornment: <InputAdornment position="start" />,
                  }}
                  isdarktheme={isDarkTheme ? "true" : "false"}
                  name="Start_DateA"
                  value={academicRecordsArray[index]?.Start_DateA}
                  onChange={(event) => handleInputChange1(index, event)}
                  type="date"
                  label={
                    <>
                      Start Year
                      {renderAsterisk()}
                    </>
                  }
                />
                {academicRecordErrorsArray[index]?.Start_DateA && (
                  <Typography color="error">
                    {academicRecordErrorsArray[index].Start_DateA}
                  </Typography>
                )}
                <InputTextField
                  InputProps={{
                    startAdornment: <InputAdornment position="start" />,
                  }}
                  isdarktheme={isDarkTheme ? "true" : "false"}
                  name="End_DateA"
                  value={academicRecordsArray[index]?.End_DateA}
                  onChange={(event) => handleInputChange1(index, event)}
                  label={
                    <>
                      End Year
                      {renderAsterisk()}
                    </>
                  }
                  type="date"
                />
                {academicRecordErrorsArray[index]?.End_DateA && (
                  <Typography color="error">
                    {academicRecordErrorsArray[index].End_DateA}
                  </Typography>
                )}
              </Box>

              <Button
                variant="outlined"
                color="error"
                sx={{ fontWeight: "bold" }}
                onClick={() => removeRecord1(index)}
              >
                Remove
              </Button>
            </Box>
          ))}
      </Box>

      <AddRecordBox>
        <Typography sx={viewProfileTitle}>Professional Records</Typography>
        <AddRecordButton
          variant="outlined"
          onClick={() => addRecord2()}
        >
          + Add Record
        </AddRecordButton>
      </AddRecordBox>

      {Array.isArray(professionalRecordsArray) &&
        professionalRecordsArray?.map((record, index) => (
          <Box key={index} sx={viewEducationBox}>
            <Box sx={{ marginBottom: "15px" }} key={index}>
              <Box>
                <InputTextField
                  name="Certificate"
                  value={professionalRecordsArray[index].Certificate}
                  onChange={(event) => handleInputChange2(index, event)}
                  label={
                    <>
                      Education Certificate
                      {renderAsterisk()}
                    </>
                  }
                  
                />
                {professionalRecordErrorsArray[index]?.Certificate && (
                  <Typography color="error">
                    {professionalRecordErrorsArray[index].Certificate}
                  </Typography>
                )}
                <InputTextField
                  name="Place"
                  value={professionalRecordsArray[index].Place}
                  onChange={(event) => handleInputChange2(index, event)}
                  label={
                    <>
                      Place
                      {renderAsterisk()}
                    </>
                  }
                />
                {professionalRecordErrorsArray[index]?.Place && (
                  <Typography color="error">
                    {professionalRecordErrorsArray[index].Place}
                  </Typography>
                )}
                <InputTextField
                  InputProps={{
                    startAdornment: <InputAdornment position="start" />,
                  }}
                  isdarktheme={isDarkTheme ? "true" : "false"}
                  name="Start_DateP"
                  value={professionalRecordsArray[index].Start_DateP}
                  type="date"
                  onChange={(event) => handleInputChange2(index, event)}
                  label={
                    <>
                      Start Date
                      {renderAsterisk()}
                    </>
                  }
                />
                {professionalRecordErrorsArray[index]?.Start_DateP && (
                  <Typography color="error">
                    {professionalRecordErrorsArray[index].Start_DateP}
                  </Typography>
                )}
                <InputTextField
                  InputProps={{
                    startAdornment: <InputAdornment position="start" />,
                  }}
                  isdarktheme={isDarkTheme ? "true" : "false"}
                  name="End_DateP"
                  value={professionalRecordsArray[index].End_DateP}
                  type="date"
                  onChange={(event) => handleInputChange2(index, event)}
                  label={
                    <>
                      End Date
                      {renderAsterisk()}
                    </>
                  }
                />
                {professionalRecordErrorsArray[index]?.End_DateP && (
                  <Typography color="error">
                    {professionalRecordErrorsArray[index].End_DateP}
                  </Typography>
                )}
              </Box>
            </Box>

            <Button
              variant="outlined"
              color="error"
              sx={{ fontWeight: "bold" }}
              onClick={() => removeRecord2(index)}
            >
              Remove
            </Button>
          </Box>
        ))}
      <Typography sx={{ color: "red", marginTop: "20px" }}>
        {displayError}
      </Typography>
      <Typography sx={{ color: "green", marginTop: "20px" }}>
        {displaySuccess}
      </Typography>
      <Box>
        <FooterBox>
          <Button
            sx={{ fontWeight: "bold", color: "gray", }}
            onClick={() => {
              previousButtonCallback();
            }}
          >
            Back
          </Button>
          <CancelButton
            onClick={() => {
              setOpenExitDialog(true);
              dispatch(setEmployeeAdded(""));
            }}
          >
            Cancel
          </CancelButton>
          <ExitDialog
            open={openExitDialog}
            reset={reset}
            onClose={handleCloseCancelDialog}
          />
          <SaveButton
            onClick={() => {
              if (validateAllForms2() && validateAllForms()) {
                save();
                dispatch(setEmployeeAdded(""));
              }
            }}
          >
            Save
          </SaveButton>
          <SaveNextButton
            variant="contained"
            onClick={() => {
              if (validateAllForms2() && validateAllForms()) {
                saveAndNext();
              }
            }}
          >
            Save & Next
          </SaveNextButton>
        </FooterBox>
      </Box>
    </MainBox>
  );
};

export default DisplayEducation;
