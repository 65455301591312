import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CardContent, Tooltip, Typography } from "@mui/material";
import { WorkHistory as WorkHistoryIcon, ListAlt as ListAltIcon, AccountTree as AccountTreeIcon, AssignmentTurnedIn as AssignmentTurnedInIcon } from "@mui/icons-material";

import { setToast, setToastMsg, setToastType } from "../../Slices/toastSlice";
import { setGetNotificationCount } from "../../Slices/selectedActive";
import {
  getNotificationByEmpId,
  upsertNotificationsMarkAsRead,
} from "../../Services/DashboardServices";
import {
  CommanBox,
  InnerCardOne,
  ShowAllButton,
} from "../../Styles/Notification/NotificationStyle";
import "./Notification.css";

function Notification(props) {
  const { isOpen, isMobile, toolTipStyle, onClose } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const user = useSelector((state) => state.selectedActiveView.role);
  const currentEmployeeId = useSelector(
    (state) => state.addEmployee.current?.empId
  );
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const currentAdminId = useSelector(
    (state) => state.adminData.adminData?.empId
  );
  const employeeName = useSelector(
    (state) => state.selectedActiveView.employeeName
  );
  const employeeDisplay = user === "employee";
  const adminLogin = user === "admin";
  const empId = employeeDisplay ? currentEmployeeId : currentAdminId;

  const [notifications, setNotifications] = useState([]);
  // const [pageSize, setPageSize] = useState(5);
  // const [pageNumber, setPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleReadMore = () => {
    setShowAll(!showAll);
    // setPageNumber((prevPageNumber) => prevPageNumber + 1);
    // setPageSize(-1);
    navigate("/notificationDetails");
    onClose();
  };

  const fetchNotification = async () => {
    const user = employeeDisplay ? "user" : adminLogin ? "admin" : "";
    const empId = employeeDisplay ? currentEmployeeId : currentAdminId;
    setIsLoading(true);
    if (user && empId) {
      try {
        const response = await getNotificationByEmpId(user, empId);
        if (response.status === 200 || response.status === 201) {
          setIsLoading(false);
          setNotifications((prevNotifications) => [
            ...response.data.notifications,
          ]);
          setTotalCount(response.data.totalCount);
        } else {
          setNotifications([]);
          setTotalCount(0);
        }
      } catch (error) {
        setIsLoading(false);
        console.log("error:", error);
      }
    }
  };

  const markAsRead = async (id, IsRead) => {
    const user = employeeDisplay ? "user" : adminLogin ? "admin" : "";
    const empId = employeeDisplay ? currentEmployeeId : currentAdminId;
    if (user && id) {
      try {
        const notifications = [{ id, empId, isRead: IsRead }];
        const postData = JSON.stringify({ notifications });
        const response = await upsertNotificationsMarkAsRead(user, postData);
        if (response.status === 200 || response.status === 201) {
          dispatch(setToast(true));
          dispatch(setToastMsg("Notification marked as read."));
          dispatch(setToastType("success"));
          dispatch(setGetNotificationCount(response?.data?.notificationCount));
          setNotifications((prevNotifications) =>
            prevNotifications?.map((notification) => {
              if (notification?._id === id) {
                const updatedUser = notification?.user?.map((user) =>
                  user?.empId === empId
                    ? { ...user, isRead: IsRead ? false : true }
                    : user
                );
                return { ...notification, user: updatedUser };
              }
              return notification;
            })
          );
        }
      } catch (error) {
        //   setDisplayMessageError(errorMessage ?? "")
      }
    }
  };

  const extractNotificationDetails = (message, type, user, assignBy) => {
    if (type === "Leave") {
      const leaveMessage = message ?? "";
      return {
        employee: "",
        leaveMessage,
        ticketNo: "",
        task: "",
        project: "",
        approvedTaskMessage: ""
      };
    }
    else if (type === "ApprovedTask") {
      const approvedTaskMessage = message ?? "";
      return {
        employee: "",
        approvedTaskMessage,
        ticketNo: "",
        task: "",
        project: "",
      };
    } else if (type === "Project") {
      const parts = message.split(",");
      if (parts?.length >= 2) {
        const projectName = parts[0].replace("Project:", "").trim();
        const leaderId = parts[1].replace("LeaderId:", "").trim();
        const role = empId === leaderId ? "Team Lead" : "Team Member";

        return {
          employee: "",
          project: projectName,
          assignBy: assignBy || "",
          role: role,
          leaveMessage: "",
          ticketNo: "",
          task: "",
          approvedTaskMessage: ""
        };
      }
    } else {
      if (message) {
        const parseMessage = (msg) => {
          const ticketNo = msg?.substring(
            msg?.indexOf("Ticket No:") + "Ticket No:".length,
            msg?.indexOf("Task:")
          ).trim();
          const task = msg?.substring(
            msg?.indexOf("Task:") + "Task:".length,
            msg?.indexOf("Assigned to:")
          ).trim();
          const assignTo = msg?.substring(
            msg?.indexOf("Assigned to:") + "Assigned to:".length
          ).trim();
          return { ticketNo, task, assignTo };
        };
        const { ticketNo, task, assignTo } = parseMessage(message);
        const employee = employeeName === assignTo;
        const assignby = employee ? assignBy : "";
        return {
          ticketNo,
          task,
          assignTo,
          project: "",
          assignBy: assignby,
          employee: "",
          leaveMessage: "",
          approvedTaskMessage: ""
        };
      }
    }
    return {
      ticketNo: "",
      task: "",
      assignTo: "",
      assignBy: "",
      employee: "",
      leaveMessage: "",
      approvedTaskMessage: ""
    };
  };

  const renderIcon = (type) => {
    switch (type) {
      case "Leave":
        return (
          <WorkHistoryIcon
            sx={{
              color: isDarkTheme
                ? "var(--secondary-text-color)"
                : "text.secondary",
              marginTop: "2px",
            }}
          />
        );
      case "Task":
        return (
          <ListAltIcon
            sx={{
              color: isDarkTheme
                ? "var(--secondary-text-color)"
                : "text.secondary",
              marginTop: "5px",
            }}
          />
        );
      case "Project":
        return (
          <AccountTreeIcon
            sx={{
              color: isDarkTheme
                ? "var(--secondary-text-color)"
                : "text.secondary",
              marginTop: "5px",
            }}
          />
        );
      case "ApprovedTask":
        return (
          <AssignmentTurnedInIcon
            sx={{
              color: isDarkTheme
                ? "var(--secondary-text-color)"
                : "text.secondary",
              marginTop: "3px",
            }}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchNotification();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <>
      <div
        ref={containerRef}
        style={{
          backgroundColor: isDarkTheme ? "#0B1727" : "#FFFFFF",
          overflowY: showAll ? "auto" : "",
          maxHeight: showAll ? "450px" : "480px",
        }}
        className={`${isMobile ? "notification-dialog-mobile" : "notification-dialog"
          }`}
      >
        {isLoading ? (
          <CommanBox>{"Loading..."}</CommanBox>
        ) : (
          <div className="notifications">
            {notifications?.length > 0 ? (
              notifications?.map((notification) => {
                const {
                  ticketNo,
                  task,
                  assignTo,
                  assignBy,
                  project,
                  role,
                  leaveMessage,
                  approvedTaskMessage
                } = extractNotificationDetails(
                  notification?.message,
                  notification?.type ?? "",
                  notification?.user ?? "",
                  notification?.assignBy ?? ""
                );
                const truncatedTask =
                  task?.length > 51 ? task.substring(0, 51) + "..." : task;
                const isRead = notification?.user?.find(
                  (item) => item?.empId === empId
                )?.isRead;
                return (
                  <InnerCardOne
                    style={{ cursor: "pointer" }}
                    onClick={() => markAsRead(notification?._id, isRead)}
                    key={notification._id}
                  >
                    <CardContent
                      sx={{
                        padding: "2%",
                        "&:last-child": { paddingBottom: "2%" },
                      }}
                    >
                      <div
                        style={{ display: "flex", alignItems: "flex-start" }}
                      >
                        {renderIcon(notification?.type ?? "")}
                        <div style={{ marginLeft: "8px" }}>
                          {leaveMessage ? (
                            <>
                              <Typography
                                variant="subtitle1"
                                sx={{ fontWeight: isRead ? "normal" : "bold" }}
                                color={
                                  isDarkTheme
                                    ? "var(--secondary-text-color)"
                                    : "text.secondary"
                                }
                                component="div"
                              >
                                {leaveMessage}
                              </Typography>
                            </>
                          ) : (
                            <>
                              {notification?.type === "Task" ? (
                                <>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{
                                      fontWeight: isRead ? "normal" : "bold",
                                    }}
                                    color={
                                      isDarkTheme
                                        ? "var(--secondary-text-color)"
                                        : "text.secondary"
                                    }
                                    component="div"
                                  >
                                    {assignBy
                                      ? `Assigned By: ${assignBy}`
                                      : `Assigned To: ${assignTo}`}
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{
                                      fontWeight: isRead ? "normal" : "bold",
                                    }}
                                    color={
                                      isDarkTheme
                                        ? "var(--secondary-text-color)"
                                        : "text.secondary"
                                    }
                                    component="div"
                                  >
                                    Ticket No: {ticketNo}
                                  </Typography>
                                  <Tooltip
                                    slotProps={{
                                      popper: {
                                        sx: toolTipStyle,
                                      },
                                    }}
                                    title={task}
                                    arrow
                                  >
                                    <Typography
                                      sx={{
                                        cursor: "pointer",
                                        fontWeight: isRead ? "normal" : "bold",
                                      }}
                                      variant="body1"
                                      color={
                                        isDarkTheme
                                          ? "var(--secondary-text-color)"
                                          : "text.secondary"
                                      }
                                    >
                                      Task: {truncatedTask}
                                    </Typography>
                                  </Tooltip>
                                </>
                              ) : notification?.type === "Project" ? (
                                <>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{
                                      fontWeight: isRead ? "normal" : "bold",
                                    }}
                                    color={
                                      isDarkTheme
                                        ? "var(--secondary-text-color)"
                                        : "text.secondary"
                                    }
                                    component="div"
                                  >
                                    {assignBy
                                      ? `${assignBy} has added you to the project ${project} as a ${role}.`
                                      : `${assignTo} has added you to the project ${project} as a ${role}.`}
                                  </Typography>
                                  <Tooltip
                                    slotProps={{
                                      popper: {
                                        sx: toolTipStyle,
                                      },
                                    }}
                                    title={project}
                                    arrow
                                  ></Tooltip>
                                </>
                              ) : notification?.type === "ApprovedTask" ? (
                                <>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{ fontWeight: isRead ? "normal" : "bold" }}
                                    color={
                                      isDarkTheme
                                        ? "var(--secondary-text-color)"
                                        : "text.secondary"
                                    }
                                    component="div"
                                  >
                                    {approvedTaskMessage}
                                  </Typography>
                                </>
                              ) : null}
                            </>
                          )}
                        </div>
                      </div>
                    </CardContent>
                  </InnerCardOne>
                );
              })
            ) : (
              <CommanBox>{"No notifications"}</CommanBox>
            )}
            {totalCount > 5 && notifications?.length !== totalCount && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  padding: "10px",
                }}
              >
                <ShowAllButton onClick={handleReadMore}>Show All</ShowAllButton>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default Notification;
