import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, TableBody, DialogContent, DialogTitle, Link, TableRow, TextField, Tooltip, Zoom, TableCell, Grid, IconButton, Typography, MenuItem, useMediaQuery, Checkbox } from '@mui/material';
import { Close, Edit as EditIcon, Delete as DeleteIcon, Schema as SchemaIcon } from '@mui/icons-material';

// import { MuiColorInput } from 'mui-color-input';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ColorPicker from 'react-pick-color';

import {
  setToast,
  setToastMsg,
  setToastType,
} from "../../Slices/toastSlice";
import { errorMessage } from "../../Services/axiosInstance";
import { renderAsterisk } from '../ReusableComponents/UtilityFunctions';
import DialogComponent from '../ReusableComponents/DialogBoxComponents/DialogComponent'
import { empTaskTableBodyCell, empTaskTableHeadCell } from '../ReusableComponents/CustomDesignMUI/CustomMUI';
import { getByIdWorkFlow, upsertWorkFlow, deleteStatus } from '../../Services/WorkFlowServices';
import { MainBox, CustomButton, WorkFlowTextField, InnerBoxTwoDialog, InnerBoxOneDialog, DoneIcons, ClearIcons, CustomTableCells, Tables, TableCells, TableContainers, TableHeads, TableMainBox, CloseIconButton, DialogsActions, InnerBoxOne, CancelButton, TitleBox, InnerBoxTwo, InnerBoxThree, ErrorTypography } from "../../Styles/WorkFlow/AddWorkFlowDialogStyle"
import { DeleteButton, MainBoxDialog } from "../../Styles/KnowledgeSharing/KnowledgeSharingStyle";

function AddWorkFlowDialog(props) {
  const { open, setOpenDialogBox, setGetWorkFlowData, editId, setEditId } = props
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const isMobile = useMediaQuery("(max-width:600px)");
  const dispatch = useDispatch();

  const [workflowName, setWorkflowName] = useState("");
  const [displayError, setDisplayError] = useState("");
  const [error, setError] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [errorStatus, setErrorStatus] = useState({});
  const [usedColors, setUsedColors] = useState([]);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [_IdForDelete, set_IdForDelete] = useState("");
  const [_dataThatDelete, set_DataThatDelete] = useState({});
  const [newStatus, setNewStatus] = useState("");
  const [pickerVisibility, setPickerVisibility] = useState({});

  const onClose = () => {
    setEditId("")
    setWorkflowName("");
    setPickerVisibility({});
    setTableData([]);
    setDisplayError("");
    setError([]);
    setErrorStatus({})
    setOpenDialogBox(false);
  }

  const toggleColorPicker = (id) => {
    setPickerVisibility((prev) => ({
      [id]: !prev[id],
    }));
  };

  const onCloseStatus = () => {
    set_IdForDelete("");
    setError([]);
    setNewStatus("");
    setPickerVisibility({});
    setOpenDialogDelete(false);
  }

  const resetForm = () => {
    setEditId("")
    setError([]);
    setDisplayError("");
    setPickerVisibility({});
    setErrorStatus({})
    setOpenDialogBox(false);
  }

  const handleDeleteConfirmStatus = async (idToDelete) => {
    const errors = {};
    if (!newStatus) {
      errors.status = "Please select status!";
      setError(errors);
      return;
    }
    const newStatusId = tableData?.find((item) => item?.value === newStatus)?._id;
    try {
      const response = await deleteStatus(editId, idToDelete?._id, newStatusId)
      if (response.status === 200 || response.status === 201) {
        const filterData = tableData?.filter((item) => item?._id !== idToDelete?._id)
        const renumberedData = filterData?.map((item, index) => ({
          ...item,
          value: index + 1
        }));
        setTableData(renumberedData);
        onCloseStatus()
        dispatch(setToast(true));
        dispatch(setToastMsg(response.data.message ?? "status record deleted."));
        dispatch(setToastType("success"));
      }
    } catch (error) {
      setDisplayError(errorMessage ?? "")
    }
  }

  const handleEditStatus = (item) => {
    setTableData(prevData =>
      prevData?.map(row =>
        row?.value === item?.value ? { ...row, isEdit: true } : row
      )
    );
  }

  const handleResetStatus = (row1) => {
    const errors = {};
    if (!row1?.status) {
      errors.status = "Status is required";
      setErrorStatus(errors);
      setTableData(prevData =>
        prevData?.map(row =>
          row?.value === row1?.value ? { ...row, isEdit: true, isValid: true } : row
        )
      );
    }
    else {
      setTableData(prevData =>
        prevData?.map(row =>
          row?.value === row1?.value ? { ...row, isEdit: false } : row
        )
      );
    }
  };

  const handleAddStatus = (row) => {
    const errors = {};
    if (!row.status) {
      errors.status = "Status is required";
      setErrorStatus(errors);
      setTableData(prevData =>
        prevData?.map(row1 =>
          row1?.value === row?.value ? { ...row1, isEdit: true, isValid: true } : row1
        )
      );
    } else {
      setTableData(prevData => {
        if (row?.isEdit) {
          return prevData.map(item =>
            item.value === row.value ? { ...item, status: row?.status, color: row?.color, isEdit: false, isValid: false } : item
          );
        } else {
          const maxId = prevData.reduce((max, item) => Math.max(max, item.value), 0);
          return [
            { value: maxId + 1, status: row?.status, color: row?.color, isEdit: false, isValid: false },
            ...prevData
          ];
        }
      });
      setErrorStatus({});
      setPickerVisibility({});
    }
  };

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const getUniqueColor = (usedColors, setUsedColors) => {
    let newColor;
    do {
      newColor = getRandomColor();
    } while (usedColors?.includes(newColor));
    setUsedColors(prev => [...prev, newColor]);
    return newColor;
  };

  const AddNewStatus = () => {
    setTableData(prevData => {
      const newId = prevData?.length === 0 ? 1 : Math.max(...prevData?.map(item => item?.value)) + 1;
      const uniqueColor = getUniqueColor(usedColors, setUsedColors);
      return [{ value: newId, status: "", color: uniqueColor, isEdit: true, isValid: true }, ...prevData].sort((a, b) => a.value - b.value);
    });
  }

  const handleStatusChange = (event, row) => {
    setErrorStatus([]);
    setTableData(prevData => {
      if (row?.isEdit) {
        return prevData.map(item =>
          item.value === row.value ? { ...item, status: event.target.value, isEdit: true, isValid: false } : item
        );
      }
    });
  };

  const handleColorInputChange = (colorValue, row) => {
    setTableData(prevData => {
      return prevData?.map(item =>
        item?.value === row?.value ? { ...item, color: colorValue } : item
      );
    });
  };

  const fetchData = async () => {
    if (editId) {
      try {
        const response = await getByIdWorkFlow(editId);
        if (response?.status === 200) {
          const workflowData = response?.data?.workFlow;
          setWorkflowName(workflowData?.workFlowName);

          setTableData(workflowData?.statusData);
        } else {
          setWorkflowName('');
          setTableData([]);
        }
      } catch (error) {
        console.log('Error:', error);
      }
    }
  };

  const handleRowCheckboxChange = (row, index) => {
    const updatedTableData = tableData.map((item, idx) => ({
      ...item,
      isFinal: idx === index
    }));

    setTableData(updatedTableData);
  };

  const textfieldstyle = {
    ".MuiOutlinedInput-input": {
      color: isDarkTheme ? "#e0e0e0cc !important" : "#5E6E82 !important",
      padding: "8px 0px 3px 8px",
      borderColor: "white",
    },
    fontSize: "6px",
  };

  const saveWorkFlow = async () => {
    const errors = {};
    const isAnyRecordInvalid = tableData?.some(record => record.isValid);
    if (!workflowName) {
      errors.workflowName = "Workflow name is required!";
      setError(errors);
      return;
    }
    if (isAnyRecordInvalid) {
      errors.status = "Status is required";
      setErrorStatus(errors);
      return;
    }
    try {
      const filteredStatusData = tableData.map(({ isEdit, isValid, ...rest }) => rest);
      const postData = { workFlowName: workflowName, statusData: filteredStatusData };
      if (editId) {
        postData["id"] = editId;
      }
      const response = await upsertWorkFlow(JSON.stringify(postData))
      if (response.status === 200 || response.status === 201) {
        setWorkflowName("");
        setTableData([]);
        resetForm()
        setGetWorkFlowData(prevData => {
          const newWorkflow = response?.data?.workFlow;
          if (editId) {
            return prevData?.map(workflow =>
              workflow?._id === newWorkflow?._id ? newWorkflow : workflow
            );
          } else {
            return [...prevData, newWorkflow];
          }
        });
        dispatch(setToast(true));
        dispatch(setToastMsg(response.data.message ?? (editId ? "WorkFlow Updated." : "WorkFlow Added.")));
        dispatch(setToastType("success"));
      }
    }
    catch (error) {
      setDisplayError(errorMessage ?? "")
    }
  }

  const renderForm = () => {
    return (
      <>
        <MainBox isdarktheme={isDarkTheme ? "true" : "false"}>
          <InnerBoxOne>
            <Box>
              <DialogTitle sx={{ fontWeight: "bold", padding: "0px 0px", fontSize: { xs: "1.2rem", sm: "1.5rem" } }}>
                <InnerBoxTwo>
                  <Box sx={{ padding: "8px 5px", }}>
                    <TitleBox>
                      <SchemaIcon
                        color="primary"
                        style={{ fontSize: "xx-large" }}
                      />
                    </TitleBox>
                  </Box>
                  <Box>
                    <Box
                      sx={{ fontSize: { xs: "1rem", sm: "1.2rem" }, color: isDarkTheme ? "#e0e0e0e3" : "#544f5a" }}
                    >
                      {editId ? "Edit WorkFlow" : "Add WorkFlow"}
                    </Box>
                  </Box>
                </InnerBoxTwo>
              </DialogTitle>
            </Box>
            <Box>
              <CloseIconButton
                ismobile={isMobile ? "true" : "false"}
                onClick={onClose}
                isdarktheme={isDarkTheme ? "true" : "false"}
              >
                <Close />
              </CloseIconButton>
            </Box>
          </InnerBoxOne>
          <DialogContent sx={{ padding: isMobile ? "8px" : "15px 7px 5px 7px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <InnerBoxThree ismobile={isMobile ? "true" : "false"} isdarktheme={isDarkTheme ? "true" : "false"}>
                <WorkFlowTextField
                  fullWidth
                  name="workflowName"
                  value={workflowName}
                  onChange={(event) => {
                    setWorkflowName(event.target.value);
                    setDisplayError("");
                    setError([]);
                  }}
                  variant="outlined"
                  label={
                    <>
                      WorkFlow Name
                      {renderAsterisk()}
                    </>
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  isdarktheme={isDarkTheme ? "true" : "false"}
                  isMobile={isMobile}
                  workflowName={workflowName}
                />
              </InnerBoxThree>
              <Box>
                <Tooltip
                  title="Add Status"
                  arrow
                  disableInteractive
                  TransitionComponent={Zoom}
                >
                  <Button
                    component={Link}
                    variant="contained"
                    sx={{
                      fontWeight: "bold",
                      backgroundColor: "var(--secondary-color)",
                      color: "#ffffff",
                      "@media (max-width: 600px)": {
                        fontSize: "13px",
                        padding: "8px"
                      },
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      AddNewStatus();
                    }}
                  >
                    + Add Status
                  </Button>
                </Tooltip>
              </Box>
            </Box>
            <ErrorTypography>{error.workflowName}</ErrorTypography>
            <Box sx={{ marginTop: "10px" }}>{renderStatus()}</Box>
          </DialogContent>
          <ErrorTypography>{displayError}</ErrorTypography>
          <DialogsActions>
            <CancelButton
              onClick={onClose}
              isdarktheme={isDarkTheme ? "true" : "false"}
            >
              Cancel
            </CancelButton>
            <CustomButton
              onClick={() => {
                saveWorkFlow();
              }}
              variant="contained"
              component={Link}
              color={"primary"}
            >
              {editId ? "Update" : "Save"}
            </CustomButton>
          </DialogsActions>
        </MainBox>
      </>
    );
  }

  const renderStatus = () => {
    const handleDragEnd = (result) => {
      if (!result.destination) return;

      const updatedTableData = Array.from(tableData);
      const [movedRow] = updatedTableData.splice(result.source.index, 1);
      updatedTableData.splice(result.destination.index, 0, movedRow);

      const reorderedData = updatedTableData.map((item, index) => ({
        ...item,
        value: tableData[index].value
      }));

      setTableData(reorderedData);
    };

    if (tableData?.length > 0) {
      return (
        <TableMainBox isdarktheme={isDarkTheme ? "true" : "false"}>
          <TableContainers>
            <Tables isdarktheme={isDarkTheme ? "true" : "false"}>
              <TableHeads>
                <TableCells
                  emptasktableheadcell={empTaskTableHeadCell}
                  isdarktheme={isDarkTheme ? "true" : "false"}
                >
                  Value
                </TableCells>
                <TableCells
                  emptasktableheadcell={empTaskTableHeadCell}
                  isdarktheme={isDarkTheme ? "true" : "false"}
                >
                  Final Status
                </TableCells>
                <TableCells
                  emptasktableheadcell={empTaskTableHeadCell}
                  isdarktheme={isDarkTheme ? "true" : "false"}
                >
                  Status
                </TableCells>
                <TableCells
                  emptasktableheadcell={empTaskTableHeadCell}
                  isdarktheme={isDarkTheme ? "true" : "false"}
                >
                  Color
                </TableCells>
                <TableCells
                  emptasktableheadcell={empTaskTableHeadCell}
                  isdarktheme={isDarkTheme ? "true" : "false"}
                >
                  Action
                </TableCells>
              </TableHeads>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="tableBody">
                  {(provided) => (
                    <TableBody
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {tableData.map((row, index) => {
                        return (
                          <Draggable
                            key={
                              row?._id
                                ? row?._id
                                : index
                            }
                            draggableId={row?.value?.toString()}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <TableRow
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                sx={{
                                  height: "100%",
                                  width: "100%",
                                  background:
                                    isDarkTheme
                                      ? "#142840"
                                      : "#ffffff",
                                }}
                              >
                                <CustomTableCells
                                  emptasktablebodycell={
                                    empTaskTableBodyCell
                                  }
                                  isdarktheme={
                                    isDarkTheme
                                      ? "true"
                                      : "false"
                                  }
                                  sx={{ width: "18%" }}
                                >
                                  {row.value}
                                </CustomTableCells>
                                <CustomTableCells
                                  emptasktablebodycell={
                                    empTaskTableBodyCell
                                  }
                                  isdarktheme={
                                    isDarkTheme
                                      ? "true"
                                      : "false"
                                  }
                                >
                                  <Checkbox
                                    sx={{
                                      color:
                                        isDarkTheme ? "#e0e0e0cc"
                                          : "var(--secondary-text-color)",
                                    }}
                                    checked={
                                      row?.isFinal ||
                                      false
                                    }
                                    onChange={() =>
                                      handleRowCheckboxChange(
                                        row,
                                        index
                                      )
                                    }
                                  />
                                </CustomTableCells>
                                {row.isEdit ? (
                                  <>
                                    <CustomTableCells
                                      isdarktheme={
                                        isDarkTheme
                                          ? "true"
                                          : "false"
                                      }
                                    >
                                      <Grid item sm={8}>
                                        <TextField
                                          fullWidth
                                          name="statusInput"
                                          value={
                                            row.status
                                          }
                                          sx={
                                            textfieldstyle
                                          }
                                          onChange={(
                                            event
                                          ) =>
                                            handleStatusChange(
                                              event,
                                              row
                                            )
                                          }
                                          variant="outlined"
                                          error={
                                            row?.isValid &&
                                            !!errorStatus?.status
                                          }
                                          helperText={
                                            row?.isValid
                                              ? errorStatus?.status || (
                                                <span
                                                  style={{
                                                    color:
                                                      "red",
                                                  }}
                                                >
                                                  {
                                                    "Status is required"
                                                  }
                                                </span>
                                              )
                                              : ""
                                          }
                                          InputProps={{
                                            style: {
                                              overflow:
                                                "auto",
                                            },
                                          }}
                                        />
                                      </Grid>
                                    </CustomTableCells>
                                  </>
                                ) : (
                                  <>
                                    <CustomTableCells
                                      emptasktablebodycell={
                                        empTaskTableBodyCell
                                      }
                                      isdarktheme={
                                        isDarkTheme
                                          ? "true"
                                          : "false"
                                      }
                                    >
                                      {row.status}
                                    </CustomTableCells>
                                  </>
                                )}
                                <TableCell
                                  sx={{ width: "22%" }}
                                >
                                  {/* <MuiColorInput className="custom-color-input" format="hex" value={row.color} onChange={(colorValue) => handleColorInputChange(colorValue, row)} /> */}
                                  {/* <TextField
                                                                    inputRef={colorPickerRef}
                                                                    type="color"
                                                                    value={row.color}
                                                                    onChange={(event) => handleColorInputChange(event, row)}
                                                                    style={{ position: 'absolute', opacity: 0, zIndex: 9999 }}
                                                                />*/}
                                  <Grid item sm={4}>
                                    <Box
                                      sx={{
                                        backgroundColor:
                                          row.color,
                                        width: "30px",
                                        height: "30px",
                                        borderRadius:
                                          "25%",
                                        marginTop:
                                          "8px",
                                        cursor:
                                          "pointer",
                                        position:
                                          "relative",
                                      }}
                                      onClick={() =>
                                        toggleColorPicker(
                                          row?._id
                                            ? row?._id
                                            : index
                                        )
                                      }
                                    />
                                    {pickerVisibility[
                                      row?._id
                                        ? row?._id
                                        : index
                                    ] && (
                                        <ColorPicker
                                          className="color-picker"
                                          color={
                                            row?.color
                                          }
                                          onChange={(
                                            color
                                          ) => {
                                            handleColorInputChange(
                                              color?.hex,
                                              row
                                            );
                                          }}
                                        />
                                      )}
                                  </Grid>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    ...empTaskTableBodyCell,
                                    paddingTop: "1px",
                                    paddingBottom:
                                      "1px",
                                    paddingRight:
                                      "20px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                    }}
                                  >
                                    {row?.isEdit ? (
                                      <>
                                        <IconButton
                                          onClick={() =>
                                            handleAddStatus(
                                              row
                                            )
                                          }
                                        >
                                          <DoneIcons
                                            variant="contained"
                                            sx={{
                                              fontSize:
                                                "20px",
                                            }}
                                          />
                                        </IconButton>
                                        <IconButton
                                          onClick={() =>
                                            handleResetStatus(
                                              row
                                            )
                                          }
                                        >
                                          <ClearIcons
                                            variant="contained"
                                            sx={{
                                              fontSize:
                                                "20px",
                                            }}
                                          />
                                        </IconButton>
                                      </>
                                    ) : (
                                      <>
                                        <Tooltip
                                          title="Edit"
                                          placement="bottom"
                                          arrow
                                          TransitionComponent={
                                            Zoom
                                          }
                                        >
                                          <IconButton
                                            sx={{
                                              color:
                                                isDarkTheme
                                                  ? "#e0e0e0e3"
                                                  : "#544f5a",
                                            }}
                                            variant="contained"
                                            onClick={() => {
                                              handleEditStatus(
                                                row
                                              );
                                            }}
                                          >
                                            <EditIcon
                                              sx={{
                                                fontSize:
                                                  "20px",
                                              }}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                          title="Delete"
                                          placement="bottom"
                                          arrow
                                          TransitionComponent={
                                            Zoom
                                          }
                                        >
                                          <IconButton
                                            sx={{
                                              color:
                                                isDarkTheme
                                                  ? "#d94a38"
                                                  : "#c62828",
                                            }}
                                            variant="contained"
                                            onClick={(
                                              e
                                            ) => {
                                              e.stopPropagation();
                                              setOpenDialogDelete(
                                                true
                                              );
                                              set_IdForDelete(
                                                row
                                              );
                                              set_DataThatDelete(
                                                row
                                              );
                                            }}
                                          >
                                            <DeleteIcon
                                              sx={{
                                                fontSize:
                                                  "20px",
                                              }}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      </>
                                    )}
                                  </Box>
                                </TableCell>
                              </TableRow>
                            )}
                          </Draggable>
                        );
                      }
                      )}
                      {provided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
            </Tables>
          </TableContainers>
        </TableMainBox>
      );
    }
  };

  const renderFormDeleteStatus = () => {
    return (
      <>
        <Box sx={{ background: isDarkTheme ? "#121e2e" : "#ffffff", padding: "8px" }}>
          <MainBoxDialog>
            <Box>
              <DialogTitle sx={{ fontWeight: "bold", padding: "0px 0px", fontSize: { xs: "1.2rem", sm: "1.5rem" } }}>
                <InnerBoxOneDialog>
                  <Box sx={{ padding: "8px 5px", }}>
                    <InnerBoxTwoDialog
                      isdarktheme={isDarkTheme ? "true" : "false"}
                    />
                  </Box>
                  <Box>
                    <Box
                      sx={{
                        fontSize: { xs: "1rem", sm: "1.2rem" },
                        color: isDarkTheme ? "#e0e0e0e3" : "#544f5a",
                      }}
                    >
                      Confirm Delete Status
                    </Box>
                  </Box>
                </InnerBoxOneDialog>
              </DialogTitle>
            </Box>
            <Box>
              <CloseIconButton
                onClick={onCloseStatus}
                isdarktheme={isDarkTheme ? "true" : "false"}
              >
                <Close />
              </CloseIconButton>
            </Box>
          </MainBoxDialog>
          <DialogContent sx={{ padding: "6px 4px 5px 7px" }}>
            <Typography
              sx={{
                color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82",
              }}
            >
              Are you sure you want to Delete{" "}
              <span
                style={{
                  textDecoration: "underline",
                }}
              >
                {_dataThatDelete?.status}
              </span>{" "}
              Status?
            </Typography>
            <Typography
              sx={{
                color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82",
                marginTop: "6px",
              }}
            >
              As you are Deleting{" "}
              <span
                style={{
                  textDecoration: "underline",
                }}
              >
                {_dataThatDelete?.status}
              </span>{" "}
              Status.
              <br />
              Please select alternate status to change for all
              tasks if any :
            </Typography>
            <Grid sx={{ marginTop: "2%" }} item xs={6}>
              <TextField
                fullWidth
                select
                label="Status"
                name={"status"}
                value={newStatus}
                onChange={(e) => {
                  setNewStatus(e.target.value); setError([]);
                }}
                variant="outlined"
                sx={{
                  width: isMobile ? "100%" : "80%",
                  padding: "0px 0px",
                  '& .MuiOutlinedInput-input': {
                    padding: newStatus === "" ? "12px 7px" : '8px',
                  },
                }}
                InputProps={{
                  style: { overflow: "auto" },
                }}
              >
                {tableData
                  ?.filter(
                    (item) =>
                      item?.status !== _dataThatDelete?.status
                  )
                  ?.map((option) => (
                    <MenuItem
                      key={option?._id}
                      value={option?.value}
                    >
                      {option?.status}
                    </MenuItem>
                  ))}
              </TextField>
              <Typography color="error">{error.status}</Typography>
            </Grid>
          </DialogContent>
          <DialogsActions displayError={displayError}>
            <Box sx={{ background: isDarkTheme ? "#121e2e" : "#ffffff" }}>
              <ErrorTypography>{displayError}</ErrorTypography>
            </Box>
            <Box>
              <CancelButton
                isdarktheme={isDarkTheme ? "true" : "false"}
                onClick={onCloseStatus}
              >
                Cancel
              </CancelButton>
              <DeleteButton
                variant="contained"
                onClick={() => {
                  handleDeleteConfirmStatus(_IdForDelete);
                }}
                component={Link}
                color="error"
              >
                Delete
              </DeleteButton>
            </Box>
          </DialogsActions>
        </Box>

      </>
    );
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [editId]);

  return (
    <Box>
      <DialogComponent
        open={open}
        onClose={onClose}
        maxWidth="280px"
        renderChildern={renderForm}
        PaperProps={{
          sx: {
            backgroundColor: isDarkTheme ? "#0b1727" : "#ffffff",
            marginTop: !isMobile && "3%",
            marginBottom: !isMobile && "auto",
            marginLeft: isMobile ? "3%" : "12%",
            marginRight: isMobile ? "3%" : "10%",
            borderRadius: !isMobile && "10px",
            position: !isMobile && "fixed",
            width: !isMobile && "600px",
            height: "auto",
            boxShadow: "none !important",
            zIndex: 1100,
            '@media (max-width: 600px)': {
              width: '100%',
            },
          },
        }}
      />
      <DialogComponent maxWidth="md" onClose={onCloseStatus} open={openDialogDelete} renderChildern={renderFormDeleteStatus} />
    </Box>
  )
}

export default AddWorkFlowDialog