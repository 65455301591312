import React, { useEffect,useState } from 'react'
import { Box } from '@mui/material'

import EmpDataGrid from '../AdminDashboard/Tables/EmpDataGrid'
import Loader from "../../components/Loader/Loader";
import { getTeamDetailData } from "../../Services/DashboardServices"
import { MainBox, InnerBoxOne, TitleTypography,LoadingBox } from "../../Styles/Team/TeamStyle"

function Team() {
    const [allData, setAllData] = useState([])
    const [isLoading, setIsLoading] = useState(false);

    const getTeamDetails = async () => {
        setIsLoading(true);
        try {
            const response = await getTeamDetailData();
            if (response.status === 200 || response.status === 201) {
                setAllData(response.data.User)
                setIsLoading(false);
            } else {
                setAllData([]);
                setIsLoading(false);
            }
        } catch (error) {
            setAllData([]);
            setIsLoading(false);
            console.log("error:", error);
        }
    };

    useEffect(() => {
        getTeamDetails();
        // eslint-disable-next-line
    }, [])

    return (
      <>
        {isLoading ? (
          <LoadingBox>
            <Loader />
          </LoadingBox>
        ) : (
          <Box>
            <MainBox>
              <InnerBoxOne bottom={0}>
                <TitleTypography variant="h6">Team</TitleTypography>
              </InnerBoxOne>
              <EmpDataGrid
                team={"Team"}
                Empdata={allData}
                selectedButton={"Active Employees"}
              />
            </MainBox>
          </Box>
        )}
      </>
    );
}

export default Team